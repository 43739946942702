import { useEffect } from "react";

import useAccountManagementData from "../../../state/accountManagement/UseAccountManagementData";
import useAccountManagementUsernameData from "../../../state/accountManagement/accountManagementUsername/UseAccountManagementUsernameData";

const useAccountManagementUsername = () => {
  const { accountManagementState } = useAccountManagementData();
  const { accountManagementUsernameState, accountManagementUsernameSaveState, resetUsernameSaveState,
    getAccountManagementUsername, getAccountManagementUsernameByUserAccountId, putAccountManagementUsername,
    putAccountManagementUsernameByUserAccountId
  } = useAccountManagementUsernameData();

  const onSubmitFormCallback = (formState) => {
    const userAccountId = accountManagementState.objData?.userAccountId;
    const usernameObj = { username: formState.username.trim() };

    const putAccountManagementUsernamePromise = accountManagementState.isAdminView === true
      ? putAccountManagementUsernameByUserAccountId(userAccountId, usernameObj)
      : putAccountManagementUsername(usernameObj);

    if (putAccountManagementUsernamePromise ?? false) {
      putAccountManagementUsernamePromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData?.success === true) {
            const getAccountManagementUsernamePromise = accountManagementState.isAdminView === true
              ? getAccountManagementUsernameByUserAccountId(userAccountId)
              : getAccountManagementUsername();

            if (getAccountManagementUsernamePromise ?? false) {
              getAccountManagementUsernamePromise.catch((e) => {
                //context error
              });
            }
          } else {
            //TODO handle error
          }
        }
      }).catch((e) => {
        //context error
      });
    }
  };

  useEffect(() => {
    if (accountManagementUsernameState.isObjLoaded === false) {
      const userAccountId = accountManagementState.objData?.userAccountId;

      const getAccountManagementUsernamePromise = accountManagementState.isAdminView === true
        ? getAccountManagementUsernameByUserAccountId(userAccountId)
        : getAccountManagementUsername();

      if (getAccountManagementUsernamePromise ?? false) {
        getAccountManagementUsernamePromise.catch((e) => {
          //context error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountManagementUsernameState.isObjLoaded]);

  return {
    isLoading: accountManagementUsernameState.isObjLoading,
    isSaving: accountManagementUsernameSaveState.isObjLoading,
    usernameObj: accountManagementUsernameState.objData,
    saveStateData: accountManagementUsernameSaveState.objData,
    resetSaveState: resetUsernameSaveState,
    onSubmitFormCallback
  };
};

export default useAccountManagementUsername;