import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";

const getAccountManagementEmailByUserAccountIdData = (userAccountId, state, setState) => {
  if (userAccountId > 0) {
    const api = initApi(API_NAMES.SECURITY, state, setState);
    const userAccountIdForUrl = encodeURIComponent(userAccountId);
    const url = `/UserAccounts/${userAccountIdForUrl}/EmailAddress`;

    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const getAccountManagementEmailData = (state, setState) => {
  const api = initApi(API_NAMES.SECURITY, state, setState);
  const url = `/UserAccounts/EmailAddress`;

  return api?.executeObject ? api.executeObject(url, 'GET') : null;
};

const putAccountManagementEmailByUserAccountIdData = (userAccountId, emailObj, state, setState) => {
  if (userAccountId > 0) {
    const api = initApi(API_NAMES.SECURITY, state, setState);
    const userAccountIdForUrl = encodeURIComponent(userAccountId);
    const url = `/UserAccounts/${userAccountIdForUrl}/EmailAddress`;

    return api?.executeObject ? api.executeObject(url, 'PUT', emailObj) : null;
  }
};

const putAccountManagementEmailData = (emailObj, state, setState) => {
  const api = initApi(API_NAMES.SECURITY, state, setState);
  const url = `/UserAccounts/ChangeEmailAddressStep1`;

  return api?.executeObject ? api.executeObject(url, 'PUT', emailObj) : null;
};

const putAccountManagementEmailConfirmationData = (confirmationObj, state, setState) => {
  const api = initApi(API_NAMES.SECURITY, state, setState);
  const url = `/UserAccounts/ChangeEmailAddressStep2`;

  return api?.executeObject ? api.executeObject(url, 'PUT', confirmationObj) : null;
};

const AccountManagementEmailData = {
  getAccountManagementEmailByUserAccountIdData,
  getAccountManagementEmailData,
  putAccountManagementEmailByUserAccountIdData,
  putAccountManagementEmailData,
  putAccountManagementEmailConfirmationData
};

export default AccountManagementEmailData;