import useLandingPageData from '../../../../state/landingPages/UseLandingPageData';

const usePersonBanner = () => {
  const { landingPageState } = useLandingPageData();
  const showMemberId = landingPageState?.currentPerson?.personas?.some(x => x.personaName !== 'Parent');

  return {
    showMemberId,
    profile: {
      personId: landingPageState?.currentPerson?.personId,
      memberId: landingPageState?.currentPerson?.memberId,
      name: landingPageState?.currentPerson?.name,
      initials: landingPageState?.currentPerson?.initials,
      profilePicUrl: landingPageState?.currentPerson?.profilePicUrl,
      formattedPersonas: landingPageState?.currentPerson?.formattedPersonas
    }
  };
};

export default usePersonBanner;