import React, { Fragment } from 'react';

import SecurityRolesGridLarge from './SecurityRolesGridLarge';
import SecurityRolesGridSmall from './SecurityRolesGridSmall';

const SecurityRolesGrid = ({ data, isLoading, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <SecurityRolesGridLarge
      data={data}
      isLoading={isLoading}
      onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked} />
    <SecurityRolesGridSmall
      data={data}
      isLoading={isLoading}
      onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default SecurityRolesGrid;