import React from 'react';

import DeleteIcon from "../../../../../common/components/icons/DeleteIcon";
import EditIcon from '../../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ securityRole, onEditClicked, onDeleteClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className="hidden-xs">{securityRole.orgRoleName || ''}</span>&nbsp;
      <div>
        <button
          className={global.IconButtonMobileMargin}
          type="button"
          onClick={() => { onDeleteClicked(securityRole.personOrgRoleDurationId); }}>
          <DeleteIcon />
        </button>
        <button
          className={global.IconButtonMobileMargin}
          type="button"
          onClick={() => { onEditClicked(securityRole); }}>
          <EditIcon />
        </button>
      </div>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Role</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{securityRole.orgRoleName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Organization</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{securityRole.orgUnitName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Effective Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{securityRole.effectiveDate ? formatDate(securityRole.effectiveDate) : <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Expiration Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{securityRole.expirationDate ? formatDate(securityRole.expirationDate) : <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const ManageUserAccountSecurityRolesSmallGrid = ({ data, isLoading, onEditClicked, onDeleteClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : Array.isArray(data) && data.length > 0
        ? data.map((securityRole, i) => (
          <GridRow key={i} securityRole={securityRole} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Security Roles</div>
            </div>
          </div>
        )
    }
  </div>
);

export default ManageUserAccountSecurityRolesSmallGrid;