import React, { createContext, useState } from 'react';

import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

export const AccountManagementUsernameStateContext = createContext();

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const AccountManagementUsernameContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <AccountManagementUsernameStateContext.Provider value={stateHook}>
      {children}
    </AccountManagementUsernameStateContext.Provider>
  );
};

export default AccountManagementUsernameContextProvider;