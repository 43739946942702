import { useState } from 'react';

import SystemMessageData from './SystemMessageData';

const useSystemMessageData = () => {
  const [systemMessageState, setSystemMessageState] = useState(SystemMessageData.INITIAL_STATE);

  const getSystemMessages = (destination) => {
    return SystemMessageData.getSystemMessages(destination, systemMessageState, setSystemMessageState);
  };

  return {
    systemMessageState,
    setSystemMessageState,
    getSystemMessages
  }
}

export default useSystemMessageData;