import React, { createContext, useState } from 'react';

import PaymentsByCheckData from './PaymentsByCheckData';

export const PaymentsByCheckStateContext = createContext();

const PaymentsByCheckContextProvider = ({ children }) => {
  const stateHook = useState(PaymentsByCheckData.INITIAL_STATE);

  return (
    <PaymentsByCheckStateContext.Provider value={stateHook}>
      {children}
    </PaymentsByCheckStateContext.Provider>
  );
};

export default PaymentsByCheckContextProvider;