import { useEffect, useState } from "react";

import useAccountManagementData from "../../../state/accountManagement/UseAccountManagementData";
import useAccountManagementEmailData from "../../../state/accountManagement/accountManagementEmail/UseAccountManagementEmailData";

const INITIAL_STATE = {
  displayEmailVerificationPopup: false,
  verificationCodeFromServer: '',
  newEmailAddress: ''
};

const useAccountManagementEmail = () => {
  const { accountManagementState } = useAccountManagementData();
  const { accountManagementEmailState, accountManagementEmailSaveState, resetEmailSaveState, getAccountManagementEmail,
    getAccountManagementEmailByUserAccountId, putAccountManagementEmail, putAccountManagementEmailByUserAccountId,
    putAccountManagementEmailConfirmation
  } = useAccountManagementEmailData();
  const [state, setState] = useState(INITIAL_STATE);

  const getAccountManagementEmailHelper = () => {
    const userAccountId = accountManagementState.objData?.userAccountId;

    const getAccountManagementEmailPromise = accountManagementState.isAdminView === true
      ? getAccountManagementEmailByUserAccountId(userAccountId)
      : getAccountManagementEmail();

    if (getAccountManagementEmailPromise ?? false) {
      getAccountManagementEmailPromise.catch((e) => {
        //context error
      });
    }
  };

  const onSubmitFormCallback = (formState) => {
    const userAccountId = accountManagementState.objData?.userAccountId;
    const emailObj = { emailAddress: formState.email.trim() };

    const putAccountManagementEmailPromise = accountManagementState.isAdminView === true
      ? putAccountManagementEmailByUserAccountId(userAccountId, emailObj)
      : putAccountManagementEmail(emailObj);

    if (putAccountManagementEmailPromise ?? false) {
      putAccountManagementEmailPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData?.success === true) {
            if (accountManagementState.isAdminView === true) {
              getAccountManagementEmailHelper();
            } else {
              setState({
                ...state,
                displayEmailVerificationPopup: true,
                verificationCodeFromServer: newState.objData.verificationCode,
                newEmailAddress: emailObj.emailAddress
              });
            }
          }
        }
      }).catch((e) => {
        //state error
      });
    }
  };

  const onSubmitVerificationFormCallback = (formState) => {
    if (formState.verificationCodeFromUser) {
      const confirmationObj = {
        emailAddress: state.newEmailAddress,
        verificationCodeFromServer: state.verificationCodeFromServer,
        verificationCodeFromUser: formState.verificationCodeFromUser.trim()
      };

      const putAccountManagementEmailConfirmationPromise = putAccountManagementEmailConfirmation(confirmationObj);

      if (putAccountManagementEmailConfirmationPromise ?? false) {
        putAccountManagementEmailConfirmationPromise.then((newState) => {
          if (newState ?? false) {
            if (newState.objData?.success === true) {
              setState(INITIAL_STATE);
              getAccountManagementEmailHelper();
            }
          }
        }).catch((e) => {
          //state error
        });
      }
    }
  };

  const onCloseEmailVerificationPopup = () => {
    setState(INITIAL_STATE);
    resetEmailSaveState();
    getAccountManagementEmailHelper();
  };

  useEffect(() => {
    if (accountManagementEmailState.isObjLoaded === false) {
      getAccountManagementEmailHelper();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountManagementEmailState.isObjLoaded]);

  return {
    state,
    isLoading: accountManagementEmailState.isObjLoading,
    isSaving: accountManagementEmailSaveState.isObjLoading,
    emailObj: accountManagementEmailState.objData,
    saveStateData: accountManagementEmailSaveState.objData,
    resetSaveState: resetEmailSaveState,
    onSubmitFormCallback,
    onSubmitVerificationFormCallback,
    onCloseEmailVerificationPopup
  };
};

export default useAccountManagementEmail;