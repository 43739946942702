import { useLocation, useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../NavLinks";

import useAccountManagementData from "../../../state/accountManagement/UseAccountManagementData";
import useAccountManagementSecurityRolesData from "../../../state/accountManagement/accountManagementSecurityRoles/UseAccountManagementSecurityRolesData";

import { DEFAULT_MAX_DATE } from "../../../../common/utils/Constants";

const useAccountManagementSecurityRolesEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { accountManagementState } = useAccountManagementData();
  const { accountManagementSecurityRolesSaveState, putAccountManagementSecurityRole
  } = useAccountManagementSecurityRolesData();

  const onSubmitFormCallback = (formState) => {
    const securityRole = location.state?.securityRole;
    const userAccountId = accountManagementState.objData?.userAccountId;
    const personOrgRoleDurationId = securityRole?.personOrgRoleDurationId;

    const putAccountManagementSecurityRolePromise = putAccountManagementSecurityRole(userAccountId, personOrgRoleDurationId, createSecurityRoleObj(formState, securityRole));

    if (putAccountManagementSecurityRolePromise ?? false) {
      putAccountManagementSecurityRolePromise.then((newState) => {
        if (newState ?? false) {
          navigate(NavLinks.ACCOUNT_MANAGEMENT_SECURITY_ROLES, { state: { doRefresh: true } });
        }
      }).catch((e) => {
        //context error
      });
    }
  };

  const onBackClicked = () => {
    navigate(NavLinks.ACCOUNT_MANAGEMENT_SECURITY_ROLES);
  };

  function createSecurityRoleObj(formState, securityRole) {
    return {
      ...securityRole,
      expirationDate: formState.hasNoExpirationDate === false ? formState.expirationDate : DEFAULT_MAX_DATE,
    };
  };

  return {
    isSaving: accountManagementSecurityRolesSaveState.isObjLoading,
    securityRoleData: location.state?.securityRole,
    onSubmitFormCallback,
    onBackClicked
  };
};

export default useAccountManagementSecurityRolesEdit;