import { useState } from 'react';

import validate from "./ManagePaymentsValidation";

import usePaymentsData from '../../../common/state/payments/UsePaymentsData';

import useForm from "../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  receiptNumber: ''
};

const INITIAL_RECEIPT_ERROR_STATE = {
  isError: false,
  errorMessage: ''
};

const useManagePayments = () => {
  const { paymentReceiptState, getPaymentReceipt, resetPaymentsReceiptState } = usePaymentsData();
  const { formState, errorState, handleSubmit, onFormValueChanged, setIsDirty
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [receiptErrorState, setReceiptErrorState] = useState(INITIAL_RECEIPT_ERROR_STATE);

  const onErrorModalClosed = () => {
    setReceiptErrorState(INITIAL_RECEIPT_ERROR_STATE);
    resetPaymentsReceiptState();
  };

  function submitFormCallback(formState) {
    if (formState.receiptNumber) {
      const getPaymentReceiptPromise = getPaymentReceipt(formState.receiptNumber);

      if (getPaymentReceiptPromise ?? false) {
        getPaymentReceiptPromise.then((newState) => {
          if (newState ?? false) {
            setIsDirty(true);
          }
        }).catch((e) => {
          setIsDirty(true);
          setReceiptErrorState({
            ...receiptErrorState,
            isError: true,
            errorMessage: e?.message || 'An error occurred.'
          });
        });
      }
    }
  }

  return {
    isLoading: paymentReceiptState.isObjLoading,
    formState,
    errorState,
    receiptErrorState,
    handleSubmit,
    onFormValueChanged,
    onErrorModalClosed
  };
};

export default useManagePayments;