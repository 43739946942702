import { useEffect } from 'react';

import validateAdd from './SecurityRolesFormAddValidation';
import validateEdit from './SecurityRolesFormEditValidation';

import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import UseForm from "../../../../../common/utils/UseForm";
import { BLANK_DATE_STRING, DEFAULT_ID, DEFAULT_MAX_DATE } from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

const INITIAL_FORM_STATE = {
  orgUnit: [],
  orgRoleId: DEFAULT_ID,
  orgRoleName: '--',
  effectiveDate: BLANK_DATE_STRING,
  expirationDate: BLANK_DATE_STRING,
  hasNoExpirationDate: false
};

const useSecurityRolesForm = (onSubmitFormCallback, securityRoleData) => {
  const { ACCOUNT_MANAGEMENT_SECURITY_ROLES_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { formState, errorState, setFormData, setFormState, handleSubmit, updateFormState, onValueTextPairChanged
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, securityRoleData ? validateEdit : validateAdd);

  const onOrgUnitChanged = (value) => {
    setFormState({
      ...formState,
      orgUnit: value,
      orgRoleId: DEFAULT_ID,
      orgRoleName: '--',
    });
  };

  useEffect(() => {
    if (securityRoleData) {
      const hasNoExpirationDate = securityRoleData.expirationDate
        ? formatDate(securityRoleData.expirationDate) === DEFAULT_MAX_DATE
        : false;

      setFormData({
        ...formState,
        hasNoExpirationDate,
        effectiveDate: securityRoleData.effectiveDate ? formatDate(securityRoleData.effectiveDate) : BLANK_DATE_STRING,
        expirationDate: securityRoleData.expirationDate ? formatDate(securityRoleData.expirationDate) : BLANK_DATE_STRING
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityRoleData]);

  return {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged,
    onOrgUnitChanged,
    ACCOUNT_MANAGEMENT_SECURITY_ROLES_ROLE_GROUP_ID
  };
};

export default useSecurityRolesForm;