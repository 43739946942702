import { useEffect, useState } from 'react';
import { useNavigate } from '../../../common/wrappers/ReactRouterDom';

import NavLinks from './NavLinks';

import AccountManagementConstants from './utils/AccountManagementConstants';

import useAccountManagementData from '../../state/accountManagement/UseAccountManagementData';

import useSecurityData from '../../../common/state/security/UseSecurityData';

const INITIAL_BACK_TO_STATE = {
  backToLabel: 'Back',
  backToRoute: '/'
};

const useAccountManagementContextView = () => {
  const navigate = useNavigate();
  const { userInfo } = useSecurityData();
  const { accountManagementState } = useAccountManagementData();
  const [backToState, setBackToState] = useState(INITIAL_BACK_TO_STATE);

  const onHomeClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.ACCOUNT_MANAGEMENT_USERNAME);
  };

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(backToState.backToRoute);
  };

  useEffect(() => {
    if (Array.isArray(userInfo?.appRoutes)) {
      const appRoutes = userInfo.appRoutes;

      if (accountManagementState.backToSystem === AccountManagementConstants.BACK_TO_SYSTEM_ADMIN) {
        setBackToState({
          ...backToState,
          backToLabel: 'Back to Manage User Accounts Search',
          backToRoute: appRoutes.find(x => x.routeName === 'ACCOUNT_MANAGEMENT_SEARCH')?.route
        });
      } else {
        setBackToState({
          ...backToState,
          backToLabel: 'Back to My Dashboard',
          backToRoute: appRoutes.find(x => x.routeName === 'HUB_LANDING_PAGES')?.route
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountManagementState.backToSystem, userInfo]);

  return {
    isLoaded: accountManagementState.isObjLoaded,
    accountUserInfo: accountManagementState.objData,
    backToLabel: backToState.backToLabel,
    onHomeClicked,
    onBackClicked
  };
};

export default useAccountManagementContextView;