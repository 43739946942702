import useMFAForm from "./UseMFAForm";

import PrimaryButton from "../../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../../common/components/buttons/SecondaryButton";
import InternationalPhoneInput from "../../../../../../../common/components/inputs/InternationalPhoneInput";

import global from '../../../../../../../common/components/GlobalStyle.module.css';

const MFAForm = ({ submitButtonText = 'Continue', secondaryButtonText = 'Back', emailAddress = '', onSubmitFormCallback,
  onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    onMFAOptionChanged,
    onMFAPhoneNumberChanged
  } = useMFAForm(onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className={"col-xs-12 usas-extra-top-margin"}>
          Please choose how you would like to receive the codes.
          <br /><br />
          <div style={{ backgroundColor: formState.useEmail === true ? '#F3F3F3' : '#ffffff', border: 'solid 1px #F3F3F3', borderRadius: 5, padding: 10 }}>
            <div style={{ float: 'left' }}>
              <input checked={formState.useEmail === true} id="device-email" name="device" onChange={() => { onMFAOptionChanged(true, false); }} type="radio" value="useEmail"></input>
            </div>
            <div onClick={() => { onMFAOptionChanged(true, false); }} style={{ cursor: 'default', float: 'left' }}>
              &nbsp;Email: {emailAddress}
            </div>
            <div style={{ clear: 'both' }}></div>
          </div>
          <br />
          <div style={{ backgroundColor: formState.useTextMessage === true ? '#F3F3F3' : '#ffffff', border: 'solid 1px #F3F3F3', borderRadius: 5, padding: 10 }}>
            <div style={{ float: 'left' }}>
              <input checked={formState.useTextMessage === true} name="device" onChange={() => { onMFAOptionChanged(false, true); }} type="radio" value="useTextMessage"></input>
            </div>
            <div onClick={() => { onMFAOptionChanged(false, true); }} style={{ cursor: 'default', float: 'left' }}>
              &nbsp;Text Message:
            </div>
            <div style={{ clear: 'both' }}>
              <InternationalPhoneInput
                name={'phoneNumber'}
                value={formState.phoneNumber}
                error={errorState.phoneNumber}
                message={errorState.phoneNumber}
                onChange={(value) => { onMFAPhoneNumberChanged(value); }} />
            </div>
          </div>
        </div>
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>
        </div>
        <div className="col-xs-12">
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
      {errorState.mfaOptions &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.mfaOptions}</p>
          </div>
        </div>
      }
    </form>
  );
};

export default MFAForm;