import React, { useState, createContext } from 'react';
import ManageUserAccountsData from './ManageUserAccountsData';

export const ManageUserAccountsStateContext = createContext();

const ManageUserAccountsContextProvider = ({ children }) => {
  const stateHook = useState({ ...ManageUserAccountsData.INITIAL_STATE });

  return (
    <ManageUserAccountsStateContext.Provider value={stateHook}>
      {children}
    </ManageUserAccountsStateContext.Provider>
  );
};

export default ManageUserAccountsContextProvider;