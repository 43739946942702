import { API_NAMES, initApi } from "../../../../../common/utils/HttpApiHelper";

const postChangePasswordData = (changePasswordObj, state, setState) => {
  if (changePasswordObj) {
    const api = initApi(API_NAMES.SECURITY, state, setState);
    const url = `/Account/ChangePassword`;

    return api?.executeObject ? api.executeObject(url, 'POST', changePasswordObj) : null;
  }
};

const AccountCreationChangePasswordData = {
  postChangePasswordData
};

export default AccountCreationChangePasswordData;