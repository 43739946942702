import React, { Fragment } from 'react';

import usePendingPayment from './UsePendingPayment';

import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import lpstyle from './../../LandingPages.module.css';

const PendingPayment = ({ pendingPayment }) => {
  const {  onClick } = usePendingPayment(pendingPayment);

  return (
    <Fragment>    
    <tr>
      <td style={{ padding: "0px 10px 0px 0px" }}>{pendingPayment.displayMessage}</td>
      <td className={lpstyle.LastColumn}><ActionIntraPageButton onClick={onClick} className={lpstyle.ActionButton}>Pay</ActionIntraPageButton></td>
    </tr>
    </Fragment>
  );
};

export default PendingPayment;