import { useState } from "react";

import useAccountCreationData from "../../../../../../state/landingPages/accountCreation/UseAccountCreationData";
import useAccountCreationChangePasswordData from "../../../../../../state/landingPages/accountCreation/accountCreationChangePassword/UseAccountCreationChangePasswordData";

const INITIAL_API_ERROR_POPUP_STATE = {
  displayPopup: false,
  errorMessage: ''
};

const useAccountCreationChangePassword = () => {
  const { accountCreationState, resetAccountCreationState } = useAccountCreationData();
  const { accountCreationChangePasswordState, postChangePassword,
    resetAccountCreationChangePasswordState } = useAccountCreationChangePasswordData();
  const [apiErrorPopupState, setAPIErrorPopupState] = useState(INITIAL_API_ERROR_POPUP_STATE);

  const onCancelClicked = () => {
    resetAccountCreationState(); // clears state and sends back to login
  };

  const onCloseAPIErrorPopupClicked = () => {
    resetAccountCreationChangePasswordState();
    setAPIErrorPopupState(INITIAL_API_ERROR_POPUP_STATE);
  };

  const onSubmitFormCallback = (formState) => {
    const changePasswordObj = {
      href: accountCreationState.forgotPasswordData?.href,
      newPassword: formState.newPassword,
      recoveryCode: formState.recoveryCode
    };

    const postChangePasswordPromise = postChangePassword(changePasswordObj);

    if (postChangePasswordPromise ?? false) {
      postChangePasswordPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData?.errorMessage === null) {
            resetAccountCreationState(); // clears state and sends back to login
          } else {
            setAPIErrorPopupState({
              ...apiErrorPopupState,
              displayPopup: true,
              errorMessage: newState.objData.errorMessage
            });
          }
        }
      }).catch((e) => {
        setAPIErrorPopupState({
          ...apiErrorPopupState,
          displayPopup: true,
          errorMessage: e?.message
        });
      });
    }
  };

  return {
    isLoading: accountCreationChangePasswordState.isObjLoading,
    username: accountCreationState.forgotPasswordData?.username,
    apiErrorPopupState,
    onSubmitFormCallback,
    onCancelClicked,
    onCloseAPIErrorPopupClicked,
  };
};

export default useAccountCreationChangePassword;