import { Route, Routes } from 'react-router-dom';

import useLandingPages from './UseLandingPages';

import LandingPageContextProvider from '../../state/landingPages/LandingPageContextProvider';
import AccountCreationContextProvider from '../../state/landingPages/accountCreation/AccountCreationContextProvider';
//import DashboardContextProvider from '../../state/landingPages/dashboard/DashboardContextProvider';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';

import AdultIndividualLanding from './views/individualLanding/AdultIndividualLanding';
import MinorIndividualLanding from './views/individualLanding/MinorIndividualLanding';
import ParentLanding from './views/parentLanding/ParentLanding';
import ChildLanding from './views/childLanding/ChildLanding';
import AccountCreation from './views/accountCreation/AccountCreation';
//import AccountCreationToggle from './views/accountCreationToggle/AccountCreationToggle';
//import Dashboard from './views/dashboard/Dashboard';

import AthleteMemberCard from './sections/myMemberCards/athlete/AthleteMemberCard';
import CoachMemberCard from './sections/myMemberCards/coach/CoachMemberCard';
import OfficialMemberCard from './sections/myMemberCards/official/OfficialMemberCard';
import MemberMemberCard from './sections/myMemberCards/member/MemberMemberCard';

import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

const LandingPagesRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes.HUB_LANDING_ATHLETE?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.HUB_LANDING_ATHLETE}
            editElement={<AthleteMemberCard />} />
        } />
      <Route path={navRoutes.HUB_LANDING_COACH?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.HUB_LANDING_COACH}
            editElement={<CoachMemberCard />} />
        } />
      <Route path={navRoutes.HUB_LANDING_OFFICIAL?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.HUB_LANDING_OFFICIAL}
            editElement={<OfficialMemberCard />} />
        } />
      <Route path={navRoutes.HUB_LANDING_MEMBER?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.HUB_LANDING_MEMBER}
            editElement={<MemberMemberCard />} />
        } />
      {/* <Route path={navRoutes.HUB_LANDING_SECRET?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.HUB_LANDING_SECRET}
            editElement={<AccountCreationToggle />} />
        } /> */}
      <Route path={'/'} element={<LandingPagesInner />} />
    </Routes>
  );
}

const LandingPagesInner = () => {
  const { stateStatus } = useLandingPages();

  switch (stateStatus) {
    case 'Loading':
      return <LoadingModal />;

    case 'Anonymous':
      return <AccountCreationContextProvider><AccountCreation /></AccountCreationContextProvider>;

    case 'AdultIndividual':
      return <AdultIndividualLanding />;

    case 'MinorIndividual':
      return <MinorIndividualLanding />;

    case 'Parent':
      return <ParentLanding />;

    // case 'Parent':
    //   return <Dashboard />;

    case 'Child':
      return <ChildLanding />;

    default:
      return <AccountCreationContextProvider><AccountCreation /></AccountCreationContextProvider>;
  }
};

const LandingPages = () => (
  <LandingPageContextProvider>
    {/* <DashboardContextProvider> */}
    <LandingPagesRoutes />
    {/* </DashboardContextProvider> */}
  </LandingPageContextProvider>
);

export default LandingPages;