import { useState } from 'react';

import ShoppingCartItemData from './ShoppingCartItemData';

const useShoppingCartItemData = () => {
  const [shoppingCartItemState, setShoppingCartItemState] = useState(ShoppingCartItemData.INITIAL_STATE);

  const getShoppingCartItems = (memberId) => {
    ShoppingCartItemData.getShoppingCartItemData(memberId, shoppingCartItemState, setShoppingCartItemState);
  };

  const deleteShoppingCartItem = (personId, deletionAction) => {
    ShoppingCartItemData.deleteShoppingCartItemData(personId, deletionAction, shoppingCartItemState, setShoppingCartItemState);
  }

  const clearArrayData = () => {
    setShoppingCartItemState({
      ...shoppingCartItemState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  }

  return {
    shoppingCartItemState,
    setShoppingCartItemState,
    getShoppingCartItems,
    deleteShoppingCartItem,
    clearArrayData
  };
};

export default useShoppingCartItemData;