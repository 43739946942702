import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';
import useGlobalRoutesData from '../../../../../common/state/security/UseGlobalRoutesData';
import { navigateToCrossUiRoute } from '../../../../../common/utils/FormatCrossUiRoute';

const usePendingPayment = (pendingPayment) => {
  const navigate = useNavigate();
  const { getGlobalRoute } = useGlobalRoutesData();

  const onClick = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const paymentsRoute = getGlobalRoute('PAYMENTS_SHOPPING_CART');
    if (paymentsRoute && paymentsRoute.route) {
      if (pendingPayment.itemSubsystemId) {
        navigateToCrossUiRoute(paymentsRoute.uIProjectName, paymentsRoute.route + '?ItemSubsystemId=' + pendingPayment.itemSubsystemId, navigate);
      }
      else {
        navigateToCrossUiRoute(paymentsRoute.uIProjectName, paymentsRoute.route, navigate);
      }
    }
  };


  return { onClick };
}

export default usePendingPayment;