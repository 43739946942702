import { Fragment } from "react";

import useAccountCreationOneTimePassword from "./UseAccountCreationOneTimePassword";

import OneTimePasswordForm from "../../components/forms/OneTimePasswordForm";

import Headings from "../../../../../../../common/components/headings/Headings";
import PopUpModal from "../../../../../../../common/components/dialogs/PopUpModal";
import SecondaryButton from "../../../../../../../common/components/buttons/SecondaryButton";

import Constants from "../../../../../../../common/utils/Constants";

import style from '../../../../LandingPages.module.css';

const AccountCreationOneTimePassword = () => {
  const {
    isLoading,
    device,
    loginErrorPopupState,
    onCancelClicked,
    onCloseLoginErrorPopupClicked,
    onSubmitFormCallback
  } = useAccountCreationOneTimePassword();

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 col-md-4" />
        <div className={['col-xs-12', 'col-md-4', style.CenterPageContent].join(' ')}>
          <Headings.H3>One Time Code</Headings.H3>
          <p>Look for the one-time code that was sent to {device} and enter it here.</p>
          <OneTimePasswordForm
            submitButtonText={'Submit'}
            secondaryButtonText={'Cancel'}
            onSubmitFormCallback={onSubmitFormCallback}
            onSecondaryButtonClicked={onCancelClicked} />
        </div>
        <div className="col-xs-12 col-md-4" />
      </div>
      <PopUpModal
        widthPct={60}
        maxWidth={720}
        title={'Login Error'}
        displayPopUp={loginErrorPopupState.displayPopup === true}>
        <div className="row">
          <div className="col-xs-12">
            <p>{loginErrorPopupState.errorMessage || 'An error occurred.'}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SecondaryButton type={'button'} onClick={onCloseLoginErrorPopupClicked}>Close</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default AccountCreationOneTimePassword;