import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../NavLinks";

import validate from '../components/forms/CourseEnrollmentDetailFormValidation';

import useCourseEnrollmentsData from "../../../state/courseEnrollments/UseCourseEnrollmentsData";

import Constants from "../../../../common/utils/Constants";
import UseForm from "../../../../common/utils/UseForm";
import { formatDate } from "../../../../common/utils/DateFunctions";

const INITIAL_OBJ_STATE = {
  objData: {},
  isObjLoading: false,
  isObjLoaded: true,
  isSaving: false,
  isSaved: false
};

const INITIAL_FORM_STATE = {
  lmsCourseId: Constants.DEFAULT_ID,
  lmsCourseName: '',
  courseEnrollmentDate: Constants.BLANK_DATE_STRING,
  courseCompletionDate: Constants.BLANK_DATE_STRING,
  courseExpirationDate: Constants.BLANK_DATE_STRING,
  noExpirationDate: false,
  coursePassed: false,
  hasExemption: false
};

const useCourseEnrollmentsManageDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { courseEnrollmentsState, postCourseEnrollment, putCourseEnrollment } = useCourseEnrollmentsData();
  const { formState, errorState, setFormData, setFormState, updateFormState, onValueTextPairChanged, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [objState, setObjState] = useState(INITIAL_OBJ_STATE);

  const onBackClicked = () => {
    navigate(NavLinks.COURSE_ENROLLMENTS_MANAGE);
  };

  const onCourseEnrollmentDateChanged = (courseEnrollmentDate) => {
    setFormState({
      ...formState,
      courseEnrollmentDate,
      courseCompletionDate: courseEnrollmentDate
    });
  };

  const onNoExpirationDateChanged = (noExpirationDate) => {
    setFormState({
      ...formState,
      noExpirationDate,
      courseExpirationDate: noExpirationDate === true ? Constants.DEFAULT_MAX_DATE : Constants.BLANK_DATE_STRING
    });
  };

  const onHasExemptionChanged = (hasExemption) => {
    setFormState({
      ...formState,
      hasExemption
    });
  };

  useEffect(() => {
    if (location.state?.courseEnrollment) {
      const courseEnrollment = location.state.courseEnrollment;
      setFormData({
        ...formState,
        lmsCourseId: courseEnrollment.lmsCourseId || Constants.DEFAULT_ID,
        lmsCourseName: courseEnrollment.courseName || '',
        courseEnrollmentDate: courseEnrollment.courseEnrollmentDate ? formatDate(courseEnrollment.courseEnrollmentDate) : Constants.BLANK_DATE_STRING,
        courseCompletionDate: courseEnrollment.courseCompletionDate ? formatDate(courseEnrollment.courseCompletionDate) : Constants.BLANK_DATE_STRING,
        courseExpirationDate: courseEnrollment.courseExpirationDate ? formatDate(courseEnrollment.courseExpirationDate) : Constants.BLANK_DATE_STRING,
        noExpirationDate: courseEnrollment.courseExpirationDate && formatDate(courseEnrollment.courseExpirationDate) === Constants.DEFAULT_MAX_DATE ? true : false,
        externalCssCourseId: courseEnrollment.externalCssCourseId || '',
        courseEnrollmentId: courseEnrollment.courseEnrollmentId,
        hasExemption: courseEnrollment.hasExemption
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (objState.isSaved === true) {
      navigate(NavLinks.COURSE_ENROLLMENTS_MANAGE, { state: { performGet: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objState.isSaved]);

  function submitFormCallback(formState) {
    if (location.state?.courseEnrollment) {

      const courseEnrollmentObj = {
        ...location.state.courseEnrollment,
        personId: courseEnrollmentsState.personId,
        courseEnrollmentId: formState?.courseEnrollmentId,
        courseCompletionDate: formState.courseCompletionDate,
        courseExpirationDate: formState.courseExpirationDate,
        coursePassed: true,
        hasExemption: formState.hasExemption
      };

      putCourseEnrollment(location.state?.courseEnrollment?.courseEnrollmentId, courseEnrollmentObj, objState, setObjState);
    } else {
      const courseEnrollmentObj = {
        personId: courseEnrollmentsState.personId,
        lmsCourseId: formState.lmsCourseId,
        courseEnrollmentDate: formState.courseEnrollmentDate,
        courseCompletionDate: formState.courseCompletionDate,
        courseExpirationDate: formState.courseExpirationDate,
        coursePassed: true,
        hasExemption: formState.hasExemption
      };

      postCourseEnrollment(courseEnrollmentObj, objState, setObjState);
    }
  };

  return {
    isEdit: location.state?.courseEnrollment ? true : false,
    isSaving: objState.isSaving,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onCourseEnrollmentDateChanged,
    onNoExpirationDateChanged,
    onHasExemptionChanged,
    onBackClicked
  };
};

export default useCourseEnrollmentsManageDetail;