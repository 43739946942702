import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';
import searchForUserAccounts from './SearchForUserAccountsData';
import deleteUserAccount from './DeleteUserAccountData';
import getLmsUserAccountByPersonId from './GetLmsUserAccountByPersonIdData';
import putLmsUserAccount from './PutLmsUserAccountData';
import postUserAccountCssUserIdByPersonId from './PostUserAccountCssUserIdByPersonIdData';


const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
};

const ManageUserAccountsData = {
  INITIAL_STATE: { ...INITIAL_STATE },
  searchForUserAccounts,
  deleteUserAccount,
  getLmsUserAccountByPersonId,
  putLmsUserAccount,
  postUserAccountCssUserIdByPersonId
};
export default ManageUserAccountsData;