import { Fragment } from 'react';

import CheckPaymentsGridLarge from './CheckPaymentsGridLarge';
import CheckPaymentsGridSmall from './CheckPaymentsGridSmall';

const CheckPaymentsGrid = ({ data, isLoading, onMatchCheckClicked }) => (
  <Fragment>
    <div className='row'>
      <div className="col-xs-12">
        <p>{data.length === 1 ? `${data.length} Search Result` : `${data.length} Search Results`}</p>
      </div>
    </div>
    <CheckPaymentsGridLarge
      data={data}
      isLoading={isLoading}
      onMatchCheckClicked={onMatchCheckClicked} />
    <CheckPaymentsGridSmall
      data={data}
      isLoading={isLoading}
      onMatchCheckClicked={onMatchCheckClicked} />
  </Fragment>
);

export default CheckPaymentsGrid;