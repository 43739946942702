import EditIcon from "../../../common/components/icons/EditIcon";

import { formatDate } from '../../../common/utils/DateFunctions';
import { FormatMoneyWithSymbol } from '../../../common/utils/FormatMoney';

import global from '../../../common/components/GlobalStyle.module.css';

const GridRow = ({ payment, onMatchCheckClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className="hidden-xs">{payment.orgUnitName || <span>&nbsp;</span>} {payment.orgUnitName === 'Unattached' ? ' - ' + payment.parentOrgUnitName : ''} {payment.parentOrgUnitCode ? '(' + payment.parentOrgUnitCode + ')' : ''}</span>&nbsp;
      <div className={global.IconButton}>
        <button
          className={global.IconButton}
          type="button"
          onClick={() => { onMatchCheckClicked(payment); }}>
          <EditIcon />
        </button>
      </div>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Organization</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{payment.orgUnitName || <span>&nbsp;</span>} {payment.orgUnitName === 'Unattached' ? ' - ' + payment.parentOrgUnitName : ''} {payment.parentOrgUnitCode ? '(' + payment.parentOrgUnitCode + ')' : ''}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Pymt. Person</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{(payment.firstName || payment.middleName || payment.lastName) ? `${payment.firstName || ''}  ${payment.preferredName !== '' && payment.preferredName !== payment.firstName ? '"' + payment.preferredName + '"' : ''} ${payment.middleName || ''} ${payment.lastName || ''}` : <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Pymt. Subsystem</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{payment.paymentSubsystem || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Pymt. Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{payment.paymentDate ? formatDate(payment.paymentDate) : <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Pymt. Amount</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{payment.amount >= 0 ? FormatMoneyWithSymbol(payment.amount/100) : <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const CheckPaymentsSmallGrid = ({ data, isLoading, onMatchCheckClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : Array.isArray(data) && data.length > 0
        ? data.map((payment, i) => (
          <GridRow key={i} payment={payment} onMatchCheckClicked={onMatchCheckClicked} />
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Unmatched Check Payments</div>
            </div>
          </div>
        )
    }
  </div>
);

export default CheckPaymentsSmallGrid;