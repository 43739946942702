import { useEffect } from "react";

import validate from './ValidateEmailFormValidation';

import UseForm from "../../../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  verificationCode: ''
};

const useValidateEmailForm = (onSubmitFormCallback) => {
  const { formState, errorState, isDirty, handleSubmit, updateFormState, setIsDirty
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    if (isDirty !== true) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
  };
};

export default useValidateEmailForm;