import { Fragment } from "react";

import useAccountManagementDeleteAccount from "./UseAccountManagementDeleteAccount";

import ValidationSuccessErrorPopup from "../components/dialogs/ValidationSuccessErrorPopup";

import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";

import Constants from "../../../../common/utils/Constants";

const AccountManagementDeleteAccount = () => {
  const {
    isLoading,
    accountUserInfo,
    state,
    onCloseErrorPopupClicked,
    onDeleteAccountClicked,
    onDeleteAccountModalConfirmClicked,
    onDeleteAccountModalCancelClicked
  } = useAccountManagementDeleteAccount();

  return (
    <Fragment>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12'>
          <Headings.H3>Delete{state.isAdminView === true ? ' ' : ' My '}Account</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          {state.isAdminView === true
            ? <p>If you delete {accountUserInfo.firstName || ''} {accountUserInfo.lastName || ''}'s account, they will no longer be able to access features such as their Member Card.</p>
            : <p>If you delete your account, you will be logged out and will no longer be able to access features such as your Member Card.</p>
          }
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type='button' onClick={onDeleteAccountClicked}>Delete{state.isAdminView === true ? ' ' : ' My '}Account</PrimaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={500}
        title="Please Confirm..."
        displayPopUp={state.displayDeletePopup}>
        <div className="row">
          <div className="col-xs-12 usas-extra-bottom-margin">
            {state.isAdminView === true
              ? <p align="center"><b>Are you sure you wish to delete {accountUserInfo.firstName || ''} {accountUserInfo.lastName || ''}'s account?</b></p>
              : <p align="center"><b>Are you sure you wish to delete your account?</b></p>
            }
          </div>
          <div className="col-xs-12">
            <PrimaryButton onClick={onDeleteAccountModalConfirmClicked}>Yes, Delete{state.isAdminView === true ? ' ' : ' My '}Account</PrimaryButton>&nbsp;
            <SecondaryButton onClick={onDeleteAccountModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <ValidationSuccessErrorPopup
        displayPopup={state.apiErrorMessage !== ''}
        errorMessage={state.apiErrorMessage || ''}
        onCancelClicked={onCloseErrorPopupClicked} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default AccountManagementDeleteAccount;