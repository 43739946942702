import { useEffect, useState } from "react";
import { useSearchParams } from "../../../../../../../common/wrappers/ReactRouterDom";

import {
  AC_STEP_EXISTING_ACCOUNT, AC_STEP_EXPIRED_PASSWORD, AC_STEP_FORGOT_PASSWORD, AC_STEP_FORGOT_USERNAME, AC_STEP_MFA, AC_STEP_ONE_TIME_PASSWORD, AC_STEP_USER_INFO
} from "../../utils/AccountCreationConstants";

import useAccountCreationData from "../../../../../../state/landingPages/accountCreation/UseAccountCreationData";
import useAccountCreationLoginData from "../../../../../../state/landingPages/accountCreation/accountCreationLogin/UseAccountCreationLoginData";

const INITIAL_LOGIN_ERROR_POPUP_STATE = {
  displayPopup: false,
  errorMessage: ''
};

const useAccountCreationLogin = () => {
  const [searchParams,] = useSearchParams();
  const { updateAccountCreationStep } = useAccountCreationData();
  const { accountCreationLoginState, postServerSideLogin, postClientSideLogin, resetAccountCreationLoginState
  } = useAccountCreationLoginData();
  const [loginErrorPopupState, setLoginErrorPopupState] = useState(INITIAL_LOGIN_ERROR_POPUP_STATE);

  const onForgotUsernameClicked = () => {
    updateAccountCreationStep(AC_STEP_FORGOT_USERNAME);
  };

  const onForgotPasswordClicked = () => {
    updateAccountCreationStep(AC_STEP_FORGOT_PASSWORD);
  };

  const onCreateALoginClicked = () => {
    updateAccountCreationStep(AC_STEP_EXISTING_ACCOUNT);
  };

  const onCloseLoginErrorPopupClicked = () => {
    resetAccountCreationLoginState();
    setLoginErrorPopupState(INITIAL_LOGIN_ERROR_POPUP_STATE);
  };

  const onSubmitFormCallback = (formState) => {
    const environmentId = searchParams.get('environmentId');
    const flowId = searchParams.get('flowId');

    if (environmentId === null && flowId === null) {
      doServerSideLogin(formState); // SWIMS login
    } else {
      doClientSideLogin(formState, environmentId, flowId); // canvas & reports login
    }
  };

  // skip to registration if parent is creating account for child
  useEffect(() => {
    const memberId = searchParams.get('memberId');

    if (memberId) {
      const childData = {
        memberId,
        firstName: searchParams.get('firstName'),
        lastName: searchParams.get('lastName'),
        middleName: searchParams.get('middleName'),
        preferredName: searchParams.get('preferredName'),
        birthDate: searchParams.get('birthDate')
      };

      updateAccountCreationStep(AC_STEP_USER_INFO, childData, 'childData');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function doServerSideLogin(formState) {
    const postServerSideLoginPromise = postServerSideLogin(formState.username, formState.password);

    if (postServerSideLoginPromise ?? false) {
      postServerSideLoginPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData?.oneTimePasswordRequired === true) {
            const loginData = {
              href: newState.objData?.href,
              device: newState.objData?.device,
              isServerSideLogin: true,
              isClientSideLogin: false
            };

            updateAccountCreationStep(AC_STEP_ONE_TIME_PASSWORD, loginData, 'loginData');
          } else if (newState.objData?.accountLockedOut === true) {
            const minutes = Math.ceil(newState.objData?.secondsUntilUnlock / 60);

            setLoginErrorPopupState({
              ...loginErrorPopupState,
              displayPopup: true,
              errorMessage: `Too many unsuccessful login attempts. Your account will unlock in ${minutes} minute${minutes === 1 ? '' : 's'}.`
            });
          } else if (newState.objData?.mustEnrollInMFA === true) {
            const loginData = {
              accessToken: newState.objData?.accessToken,
              emailAddress: newState.objData?.emailAddress
            };

            updateAccountCreationStep(AC_STEP_MFA, loginData, 'loginData');
          } else if (newState.objData?.mustChangePassword === true) {
            const loginData = {
              href: newState.objData?.href,
              username: formState.username,
              isServerSideLogin: true,
              isClientSideLogin: false
            };

            updateAccountCreationStep(AC_STEP_EXPIRED_PASSWORD, loginData, 'loginData');
          } else if (newState.objData?.accessToken === null) {
            setLoginErrorPopupState({
              ...loginErrorPopupState,
              displayPopup: true,
              errorMessage: 'The username and/or password is invalid.'
            });
          } else {
            // Successful login
            if (searchParams.get('retUrl')) {
              window.location.href = `${searchParams.get('retUrl')}?token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            } else if (window.location.href.indexOf('?') === -1) {
              window.location.href = `${window.location.href}?token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            } else {
              window.location.href = `${window.location.href}&token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            }
          }
        }
      }).catch((e) => {
        setLoginErrorPopupState({
          ...loginErrorPopupState,
          displayPopup: true,
          errorMessage: e?.message
        });
      });
    }
  };

  //TODO Missing MFA case? Also missing case for status 'FAILED' but error.code is not 'PASSWORD_LOCKED_OUT'
  function doClientSideLogin(formState, environmentId, flowId) {
    const postClientSideLoginPromise = postClientSideLogin(formState.username, formState.password, environmentId, flowId);

    if (postClientSideLoginPromise ?? false) {
      postClientSideLoginPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData?.status === 'OTP_REQUIRED') {
            const selectedDeviceId = newState.objData?.selectedDevice?.id;
            let selectedDevice = '';

            for (const device of newState.objData?._embedded?.devices) {
              if (device.id === selectedDeviceId) {
                selectedDevice = device.email || device.phone;
                break;
              }
            }

            const loginData = {
              href: newState.objData?._links['otp.check'].href,
              device: selectedDevice,
              isServerSideLogin: false,
              isClientSideLogin: true
            };

            updateAccountCreationStep(AC_STEP_ONE_TIME_PASSWORD, loginData, 'loginData');
          } else if (newState.objData?.status === 'FAILED' && newState.objData?.error?.code === 'PASSWORD_LOCKED_OUT') {
            const minutes = Math.ceil(newState.objData?.error?.secondsUntilUnlock / 60);

            setLoginErrorPopupState({
              ...loginErrorPopupState,
              displayPopup: true,
              errorMessage: `Too many unsuccessful login attempts. Your account will unlock in ${minutes} minute${minutes === 1 ? '' : 's'}.`
            });
          } else if (newState.objData?.status === 'MUST_CHANGE_PASSWORD' || newState.objData?.status === 'PASSWORD_EXPIRED') {
            const loginData = {
              href: newState.objData?._links['password.reset'].href,
              username: formState.username,
              isServerSideLogin: false,
              isClientSideLogin: true
            };

            updateAccountCreationStep(AC_STEP_EXPIRED_PASSWORD, loginData, 'loginData');
          } else if (newState.objData?.code === 'INVALID_DATA') {
            setLoginErrorPopupState({
              ...loginErrorPopupState,
              displayPopup: true,
              errorMessage: 'The username and/or password is invalid.'
            });
          } else {
            // Successful login
            if (newState.objData?.resumeUrl) {
              window.location.href = `https://auth.pingone.com/${environmentId}/as/resume?flowId=${flowId}`;
            } else if (searchParams.get('retUrl')) {
              window.location.href = `${searchParams.get('retUrl')}?token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            } else if (window.location.href.indexOf('?') === -1) {
              window.location.href = `${window.location.href}?token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            } else {
              window.location.href = `${window.location.href}&token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            }
          }
        }
      }).catch((e) => {
        setLoginErrorPopupState({
          ...loginErrorPopupState,
          displayPopup: true,
          errorMessage: e?.message
        });
      });
    }
  };

  return {
    isLoading: accountCreationLoginState.isObjLoading,
    loginErrorPopupState,
    onSubmitFormCallback,
    onForgotUsernameClicked,
    onForgotPasswordClicked,
    onCreateALoginClicked,
    onCloseLoginErrorPopupClicked
  };
};

export default useAccountCreationLogin;