import { API_NAMES, initApi } from '../../utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

const getPaymentsByCheckData = (state, setState) => {
  const api = initApi(API_NAMES.PAYMENTS, state, setState);
  const url = '/Payments/RetrieveCheckPayments';
  
  return api?.executeArray ? api.executeArray(url, 'GET') : null;
}

const postPaymentByCheckData = (paymentByCheckObj, state, setState) => {
  if (paymentByCheckObj) {
    const newState = {
      ...state,
      isSaving: true,
      isSaved: false
    };

    const api = initApi(API_NAMES.PAYMENTS, newState, setState);
    const url = '/Payments/PayByCheck';

    return api?.executeObject ? api.executeObject(url, 'POST', paymentByCheckObj) : null;
  }
}

const PaymentsByCheckData = {
  INITIAL_STATE: { ...BASIC_INITIAL_STATE },
  getPaymentsByCheckData,
  postPaymentByCheckData
};

export default PaymentsByCheckData;