import { useState } from "react";

import { AC_STEP_CHANGE_PASSWORD } from "../../utils/AccountCreationConstants";

import useAccountCreationData from "../../../../../../state/landingPages/accountCreation/UseAccountCreationData";
import useAccountCreationForgotPasswordData from "../../../../../../state/landingPages/accountCreation/accountCreationForgotPassword/UseAccountCreationForgotPasswordData";

const INITIAL_API_ERROR_POPUP_STATE = {
  displayPopup: false,
  errorMessage: ''
};

const useAccountCreationForgotPassword = () => {
  const { updateAccountCreationStep, resetAccountCreationState } = useAccountCreationData();
  const { accountCreationForgotPasswordState, postForgotPassword,
    resetAccountCreationForgotPasswordState } = useAccountCreationForgotPasswordData();
  const [apiErrorPopupState, setAPIErrorPopupState] = useState(INITIAL_API_ERROR_POPUP_STATE);

  const onCancelClicked = () => {
    resetAccountCreationState(); // clears state and sends back to login
  };

  const onCloseAPIErrorPopupClicked = () => {
    resetAccountCreationForgotPasswordState();
    setAPIErrorPopupState(INITIAL_API_ERROR_POPUP_STATE);
  };

  const onSubmitFormCallback = (formState) => {
    const postForgotPasswordPromise = postForgotPassword(formState.username);

    if (postForgotPasswordPromise ?? false) {
      postForgotPasswordPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData?.href) {
            updateAccountCreationStep(AC_STEP_CHANGE_PASSWORD, { href: newState.objData.href, username: formState.username }, 'forgotPasswordData');
          } else {
            setAPIErrorPopupState({
              ...apiErrorPopupState,
              displayPopup: true
            });
          }
        }
      }).catch((e) => {
        setAPIErrorPopupState({
          ...apiErrorPopupState,
          displayPopup: true,
          errorMessage: e?.message
        });
      });
    }
  };

  return {
    isLoading: accountCreationForgotPasswordState.isObjLoading,
    apiErrorPopupState,
    onSubmitFormCallback,
    onCancelClicked,
    onCloseAPIErrorPopupClicked,
  };
};

export default useAccountCreationForgotPassword;