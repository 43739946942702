
export const localValidate = (formState) => {
  let errors = {};

  if (formState.receiptNumber.trim() === '') {
    errors.receiptNumber = 'Receipt Number is required';
  }

  return errors;
};

const ManagePaymentsValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default ManagePaymentsValidation;