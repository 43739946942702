import React from 'react';

import useTopTwoOrgUnitTreeView from './UseTopTwoOrgUnitTreeView';

import TreeView from '../../../../../common/components/tree/TreeView';

import global from '../../../../../common/components/GlobalStyle.module.css';

const TopTwoOrgUnitTreeView = ({ label, name, value, error, message, selectableLeavesOnly, singleSelect,
  onChange, limitToUSASwimming = true }) => {
  const { orgUnitState, options } = useTopTwoOrgUnitTreeView(limitToUSASwimming);

  return orgUnitState.message
    ? <span className={global.LoadingMsg}>{orgUnitState.message}</span>
    : <TreeView
      label={label}
      name={name}
      value={value}
      error={error}
      message={message}
      isLoading={options.length === 0}
      options={options}
      selectableLeavesOnly={selectableLeavesOnly}
      singleSelect={singleSelect}
      onChange={onChange} />
};

export default TopTwoOrgUnitTreeView;