import { useEffect } from 'react';

import validate from './MFAFormValidation';

import UseForm from "../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  isMFAEnabled: '',
  useEmail: false,
  useTextMessage: false,
  phoneNumber: ''
};

const useMFAForm = (onSubmitFormCallback, mfaObj) => {
  const { formState, errorState, handleSubmit, resetForm, setFormData, setFormState, setIsDirty
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  const onMFAChanged = (value) => {
    if (value === true) {
      setFormState({
        ...formState,
        isMFAEnabled: value
      });
    } else {
      setFormState({
        ...INITIAL_FORM_STATE,
        isMFAEnabled: value
      });
    }

    setIsDirty(true);
  };

  const onMFAOptionChanged = (useEmail, useTextMessage) => {
    setFormState({
      ...formState,
      useEmail,
      useTextMessage,
      phoneNumber: useTextMessage === true ? formState.phoneNumber : '+1'
    });

    setIsDirty(true);
  };

  const onMFAPhoneNumberChanged = (value) => {
    setFormState({
      ...formState,
      useEmail: false,
      useTextMessage: true,
      phoneNumber: value
    });

    setIsDirty(true);
  };

  useEffect(() => {
    if (mfaObj) {
      setFormData({
        ...formState,
        isMFAEnabled: mfaObj.isEnabled === true ? true : mfaObj.isEnabled === false ? false : '',
        useEmail: mfaObj.email === true ? true : mfaObj.email === false ? false : '',
        useTextMessage: mfaObj.textMessage === true ? true : mfaObj.textMessage === false ? false : '',
        phoneNumber: mfaObj.phoneNumber || '+1'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mfaObj]);

  return {
    formState,
    errorState,
    handleSubmit,
    resetForm,
    onMFAChanged,
    onMFAOptionChanged,
    onMFAPhoneNumberChanged
  };
};

export default useMFAForm;