export const localValidate = (formState) => {
  let errors = {};

  if (formState.verificationCodeFromUser.trim() === '') {
    errors.verificationCodeFromUser = 'Verification Code is required';
  }

  return errors;
};

const EmailVerificationPopupFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default EmailVerificationPopupFormValidation;