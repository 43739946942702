import { useState } from "react";

import { AC_STEP_CONFIRM_AGE, AC_STEP_FORGOT_PASSWORD, AC_STEP_FORGOT_USERNAME } from "../../utils/AccountCreationConstants";

import useAccountCreationData from "../../../../../../state/landingPages/accountCreation/UseAccountCreationData";

const useAccountCreationExistingAccount = () => {
  const { accountCreationState, updateAccountCreationStep, resetAccountCreationState } = useAccountCreationData();
  const [displayRecoveryPopupState, setDisplayRecoveryPopupState] = useState(false);

  const onCancelClicked = () => {
    resetAccountCreationState(); // clears state and sends back to login
  };

  const onForgotUsernameClicked = () => {
    updateAccountCreationStep(AC_STEP_FORGOT_USERNAME);
  };

  const onForgotPasswordClicked = () => {
    updateAccountCreationStep(AC_STEP_FORGOT_PASSWORD);
  };

  const onSubmitFormCallback = (formState) => {
    if (formState.hasExistingAccount === true) {
      setDisplayRecoveryPopupState(true);
    } else {
      updateAccountCreationStep(AC_STEP_CONFIRM_AGE, formState, 'existingAccountData');
    }
  };

  return {
    existingAccountData: accountCreationState?.existingAccountData || {},
    displayRecoveryPopupState,
    onSubmitFormCallback,
    onCancelClicked,
    onForgotUsernameClicked,
    onForgotPasswordClicked
  };
};

export default useAccountCreationExistingAccount;