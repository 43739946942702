import { Fragment } from 'react';

import MyFamily from '../../sections/myFamily/MyFamily';
import MyActions from '../../sections/myActions/MyActions';
import MyAccount from '../../sections/myAccount/MyAccount';
import MyPayments from '../../sections/myPayments/MyPayments';
import PersonBanner from '../../sections/personBanner/PersonBanner';
import MyMemberCards from '../../sections/myMemberCards/MyMemberCards';
import MyCommunications from '../../sections/myCommunications/MyCommunications';
import MyPendingPayments from '../../sections/myPendingPayments/MyPendingPayments';

import MySystemMessages from '../../../../../common/components/systemMessages/MySystemMessages';

import style from '../../LandingPages.module.css';

const AdultIndividualLanding = () => (
  <Fragment>
    <div className='row'>
      <div className='col-xs-12'>
        <PersonBanner />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <MySystemMessages />
      </div>
    </div>
    <div className={['row', style.Dashboard].join(' ')}>
      <div className='col-xs-12 col-sm-6'>
        <MyMemberCards />
        <MyPendingPayments />
        <MyCommunications />
      </div>
      <div className='col-xs-12 col-sm-6'>
        <MyFamily />
        <MyActions />
        <MyAccount />
        <MyPayments />
      </div>
    </div>
  </Fragment>
);

export default AdultIndividualLanding;