import { useContext } from 'react';
import PersonData from '../../../../common/state/person/PersonData';
import { PersonStateContext } from './PersonContextProvider';

const usePersonData = () => {
  const [personState, setPersonState] = useContext(PersonStateContext);

  const getPersonByMemberId = (memberId) => {
    if (memberId && personState.isObjLoading !== true && personState.isObjLoaded !== true) {
      PersonData.getPersonByMemberId(memberId, personState, setPersonState);
    }
  };

  const resetPersonStateObjData = () => {
    setPersonState({
      ...personState,
      isObjLoading: false,
      isObjLoaded: false,
      objData: {},
      message: ''
    });
  }

  return { personState, getPersonByMemberId, resetPersonStateObjData };
};

export default usePersonData;