import { Route, Routes } from "react-router-dom";
import { Navigate } from "../../../common/wrappers/ReactRouterDom";

import AccountManagementContextView from "./AccountManagementContextView";
import AccountManagementLanding from "./accountManagementLanding/AccountManagementLanding";
import AccountManagementSearch from "./accountManagementSearch/AccountManagementSearch";
import AccountManagementUsername from "./accountManagementUsername/AccountManagementUsername";
import AccountManagementEmail from "./accountManagementEmail/AccountManagementEmail";
import AccountManagementMFA from "./accountManagementMFA/AccountManagementMFA";
import AccountManagementCSS from "./accountManagementCSS/AccountManagementCSS";
import AccountManagementChildren from "./accountManagementChildren/AccountManagementChildren";
import AccountManagementDeleteAccount from "./accountManagementDeleteAccount/AccountManagementDeleteAccount";
import AccountManagementPassword from "./accountManagementPassword/AccountManagementPassword";
import AccountManagementUserGroup from "./accountManagementUserGroup/AccountManagementUserGroup";
import AccountManagementUserGroupAdd from "./accountManagementUserGroup/AccountManagementUserGroupAdd";
import AccountManagementSecurityRoles from "./accountManagementSecurityRoles/AccountManagementSecurityRoles";

import AccountManagementContextProvider from "../../state/accountManagement/AccountManagementContextProvider";
import AccountManagementUsernameContextProvider from "../../state/accountManagement/accountManagementUsername/AccountManagementUsernameContextProvider";
import AccountManagementEmailContextProvider from "../../state/accountManagement/accountManagementEmail/AccountManagementEmailContextProvider";
import AccountManagementMFAContextProvider from "../../state/accountManagement/accountManagementMFA/AccountManagementMFAContextProvider";
import AccountManagementLMSUserContextProvider from "../../state/accountManagement/accountManagementLMSUser/AccountManagementLMSUserContextProvider";
import AccountManagementChildrenContextProvider from "../../state/accountManagement/accountManagementChildren/AccountManagementChildrenContextProvider";
import AccountManagementFiltersContextProvider from "../../state/accountManagement/AccountManagementFiltersContextProvider";
import AccountManagementUserGroupContextProvider from "../../state/accountManagement/accountManagementUserGroup/AccountManagementUserGroupContextProvider";
import UserGroupContextProvider from "../../state/userGroup/UserGroupContextProvider";
import AccountManagementSecurityRolesContextProvider from "../../state/accountManagement/accountManagementSecurityRoles/AccountManagementSecurityRolesContextProvider";
import AccountManagementSecurityRolesAdd from "./accountManagementSecurityRoles/AccountManagementSecurityRolesAdd";
import AccountManagementSecurityRolesEdit from "./accountManagementSecurityRoles/AccountManagementSecurityRolesEdit";

import LoadingModal from "../../../common/components/dialogs/LoadingModal";
import SecuredRouteElement from "../../../common/components/security/SecuredRouteElement";

import useNavRoutes from "../../../common/state/security/UseNavRoutes";
import OrgUnitContextProvider from "../../../common/state/orgUnit/OrgUnitContextProvider";
import OrgRoleContextProvider from "../../../common/state/orgRole/OrgRoleContextProvider";

const AccountManagementContextRoutesContextProviders = ({ children }) => (
  <AccountManagementUsernameContextProvider>
    <AccountManagementEmailContextProvider>
      <AccountManagementMFAContextProvider>
        <AccountManagementLMSUserContextProvider>
          <AccountManagementChildrenContextProvider>
            <AccountManagementUserGroupContextProvider>
              <AccountManagementSecurityRolesContextProvider>
                {children}
              </AccountManagementSecurityRolesContextProvider>
            </AccountManagementUserGroupContextProvider>
          </AccountManagementChildrenContextProvider>
        </AccountManagementLMSUserContextProvider>
      </AccountManagementMFAContextProvider>
    </AccountManagementEmailContextProvider>
  </AccountManagementUsernameContextProvider>
);

const AccountManagementBasicRoutesContextProviders = ({ children }) => (
  <AccountManagementContextProvider>
    <AccountManagementFiltersContextProvider>
      <UserGroupContextProvider>
        <OrgUnitContextProvider>
          <OrgRoleContextProvider>
            {children}
          </OrgRoleContextProvider>
        </OrgUnitContextProvider>
      </UserGroupContextProvider>
    </AccountManagementFiltersContextProvider>
  </AccountManagementContextProvider>
);

const AccountManagementContextRoutes = ({ navRoutes }) => (
  <AccountManagementContextRoutesContextProviders>
    <AccountManagementContextView>
      <Routes>
        <Route path={navRoutes.ACCOUNT_MANAGEMENT_USERNAME?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.ACCOUNT_MANAGEMENT_USERNAME}
              editElement={<AccountManagementUsername />} />
          } />
        <Route path={navRoutes.ACCOUNT_MANAGEMENT_EMAIL?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.ACCOUNT_MANAGEMENT_EMAIL}
              editElement={<AccountManagementEmail />} />
          } />
        <Route path={navRoutes.ACCOUNT_MANAGEMENT_PASSWORD?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.ACCOUNT_MANAGEMENT_PASSWORD}
              editElement={<AccountManagementPassword />} />
          } />
        <Route path={navRoutes.ACCOUNT_MANAGEMENT_MFA?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.ACCOUNT_MANAGEMENT_MFA}
              editElement={<AccountManagementMFA />} />
          } />
        <Route path={navRoutes.ACCOUNT_MANAGEMENT_CSS?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.ACCOUNT_MANAGEMENT_CSS}
              editElement={<AccountManagementCSS />} />
          } />
        <Route path={navRoutes.ACCOUNT_MANAGEMENT_CHILDREN?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.ACCOUNT_MANAGEMENT_CHILDREN}
              editElement={<AccountManagementChildren />} />
          } />
        <Route path={navRoutes.ACCOUNT_MANAGEMENT_USER_GROUP?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.ACCOUNT_MANAGEMENT_USER_GROUP}
              editElement={<AccountManagementUserGroup />} />
          } />
        <Route path={navRoutes.ACCOUNT_MANAGEMENT_USER_GROUP_ADD?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.ACCOUNT_MANAGEMENT_USER_GROUP_ADD}
              editElement={<AccountManagementUserGroupAdd />} />
          } />
        <Route path={navRoutes.ACCOUNT_MANAGEMENT_SECURITY_ROLES?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.ACCOUNT_MANAGEMENT_SECURITY_ROLES}
              editElement={<AccountManagementSecurityRoles />} />
          } />
        <Route path={navRoutes.ACCOUNT_MANAGEMENT_SECURITY_ROLES_ADD?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.ACCOUNT_MANAGEMENT_SECURITY_ROLES_ADD}
              editElement={<AccountManagementSecurityRolesAdd />} />
          } />
        <Route path={navRoutes.ACCOUNT_MANAGEMENT_SECURITY_ROLES_EDIT?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.ACCOUNT_MANAGEMENT_SECURITY_ROLES_EDIT}
              editElement={<AccountManagementSecurityRolesEdit />} />
          } />
        <Route path={navRoutes.ACCOUNT_MANAGEMENT_DELETE?.path}
          element={
            <SecuredRouteElement
              navRoute={navRoutes.ACCOUNT_MANAGEMENT_DELETE}
              editElement={<AccountManagementDeleteAccount />} />
          } />
      </Routes>
    </AccountManagementContextView>
  </AccountManagementContextRoutesContextProviders>
);

const AccountManagementBasicRoutes = ({ navRoutes }) => (
  <AccountManagementBasicRoutesContextProviders>
    <Routes>
      <Route path={navRoutes.ACCOUNT_MANAGEMENT_LANDING?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.ACCOUNT_MANAGEMENT_LANDING}
            editElement={<AccountManagementLanding />} />
        } />
      <Route path={navRoutes.ACCOUNT_MANAGEMENT_SEARCH?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.ACCOUNT_MANAGEMENT_SEARCH}
            editElement={<AccountManagementSearch />} />
        } />

      <Route path={'/*'} element={<AccountManagementContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes.ACCOUNT_MANAGEMENT_LANDING?.path} replace />} />
    </Routes>
  </AccountManagementBasicRoutesContextProviders>
);

const AccountManagement = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return <AccountManagementBasicRoutes navRoutes={navRoutes} />;
};

export default AccountManagement;