import useConfirmAgeForm from "./UseConfirmAgeForm";

import PrimaryButton from "../../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../../common/components/buttons/SecondaryButton";
import YesNoSwitch from "../../../../../../../common/components/yesNoSwitch/YesNoSwitch";

const ConfirmAgeForm = ({ confirmAgeData, submitButtonText = 'Continue', secondaryButtonText = 'Back', onSubmitFormCallback,
  onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState
  } = useConfirmAgeForm(onSubmitFormCallback, confirmAgeData);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12">
          <YesNoSwitch
            label={<p style={{ textAlign: 'center' }}>Are you over 16 years old?*</p>}
            name={'isOver16'}
            checked={formState.isOver16}
            error={errorState.isOver16}
            message={errorState.isOver16}
            onChange={(value) => updateFormState('isOver16', value)} />
        </div>
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>
        </div>
        <div className="col-xs-12">
          <SecondaryButton type="button" onClick={() => onSecondaryButtonClicked(formState)}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default ConfirmAgeForm;