import { useState } from 'react';

import AccountCreationForgotPasswordData from './AccountCreationForgotPasswordData';

import { BASIC_INITIAL_STATE } from '../../../../../common/utils/HttpHelper';

const INITIAL_ACCOUNT_CREATION_FORGOT_PASSWORD_STATE = {
  ...BASIC_INITIAL_STATE,
};

const useAccountCreationForgotPasswordData = () => {
  const [accountCreationForgotPasswordState, setAccountCreationForgotPasswordState] = useState(INITIAL_ACCOUNT_CREATION_FORGOT_PASSWORD_STATE);

  const postForgotPassword = (username) => {
    return AccountCreationForgotPasswordData.postForgotPasswordData(username, accountCreationForgotPasswordState, setAccountCreationForgotPasswordState);
  };

  const resetAccountCreationForgotPasswordState = () => {
    setAccountCreationForgotPasswordState(INITIAL_ACCOUNT_CREATION_FORGOT_PASSWORD_STATE);
  };

  return {
    accountCreationForgotPasswordState,
    resetAccountCreationForgotPasswordState,
    postForgotPassword
  };
};

export default useAccountCreationForgotPasswordData;