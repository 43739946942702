import React from 'react';

import useUserGroupMultiSelect from './UseUserGroupMultiSelect';

import MultiSelect from '../../../../../common/components/multiSelect/MultiSelect';

import global from '../../../../../common/components/GlobalStyle.module.css';

const UserGroupMultiSelect = ({ label, name, value, error, message, onChange }) => {
  const { userGroupState, options } = useUserGroupMultiSelect();

  return userGroupState.message
    ? <span className={global.LoadingMsg}>{userGroupState.message}</span>
    : (
      <MultiSelect
        label={label}
        name={name}
        value={value}
        options={options}
        error={error}
        message={message}
        isLoading={userGroupState.isLoading}
        onChange={onChange} />
    );
};

export default UserGroupMultiSelect;