import { useContext } from 'react';

import UserGroupData from './UserGroupData';

import { UserGroupStateContext } from './UserGroupContextProvider';

const useUserGroupData = () => {
  const [userGroupState, setUserGroupState] = useContext(UserGroupStateContext);

  const getUserGroups = () => {
    return UserGroupData.getUserGroupsData(userGroupState, setUserGroupState);
  };

  return {
    userGroupState,
    getUserGroups
  };
};

export default useUserGroupData;