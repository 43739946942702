export const localValidate = (formState) => {
  let errors = {};

  if (!(formState.userGroup?.length > 0)) {
    errors.userGroup = 'At least one User Group is required';
  }

  return errors;
};

const UserGroupAddFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default UserGroupAddFormValidation;