import React, { Fragment } from 'react';

import useRenewMemberships from './UseRenewMemberships';

import RenewMembershipsSelectionGrid from './RenewMembershipsSelectionGrid';
import RenewMembershipsSuccessGrid from './RenewMembershipsSuccessGrid';

import RenewalOptionDropdown from '../dropdowns/renewalOptionDropdown/RenewalOptionDropdown';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import PopUpModal from '../dialogs/PopUpModal';

import Constants from '../../utils/Constants';
import { formatDate } from '../../utils/DateFunctions';

import style from './RenewMemberships.module.css';
import global from '../GlobalStyle.module.css';
import Headings from '../headings/Headings';

const RenewMemberships = ({ omrView, myFamilyView, landingPageState }) => {
  const { personState, onClick, modalState, onModalCanceled, memberRegistrationRenewalState,
    gridState, onCheckboxChange, onRenewClicked, successModalState,
    onSuccessModalCanceled, selectionChangesState, onContinueClicked, errors,
    onSelectAll, onUnselectAll, onChangeMemberTypeClicked, memberTypeSelectionModalState,
    setMemberTypeSelectionModalFormState, memberTypeSelectionModalFormState, memberTypeSelectionModalErrorState,
    onMemberTypeSelectionContinueClicked, onMemberTypeSelectionModalCanceled, renewalOptionState,
    onHelperTextToggle, state, onShowRenewalConfirmationModalClicked } = useRenewMemberships(omrView);

  return (
    <Fragment>
      {(
        (personState?.objData?.membershipRenewalAvailable === true || personState?.objData?.membershipRenewalAvailable === 1) &&
        (
          (omrView === false && ((myFamilyView === true && landingPageState?.currentPerson?.children && landingPageState?.currentPerson?.children?.length > 0) ||
            (myFamilyView === false && landingPageState?.currentPerson?.children && landingPageState?.currentPerson?.children?.length === 0)))
          || omrView === true)
      ) &&
        <div className='row usas-extra-top-margin'>
          <div className='col-xs-12 usas-extra-top-margin'>
            <PrimaryButton onClick={onClick} className={style.ActionButton}>Renew Memberships</PrimaryButton>
          </div>
        </div>}
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={memberRegistrationRenewalState.isArrayLoading || personState.isObjLoading || renewalOptionState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={memberRegistrationRenewalState.isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={1400}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp && !memberRegistrationRenewalState.isArrayLoading}
        onModalCanceled={onModalCanceled}>
        <div className='row usas-extra-top-margin'>
          <div className={['col-xs-12 usas-extra-top-margin usas-extra-bottom-margin', style.CenterAlign].join(' ')}>
            <PrimaryButton onClick={onHelperTextToggle}>{state.showHelperText === true ? 'Hide Instructions' : 'Confused? Click Here'}</PrimaryButton>
          </div>
        </div>
        {state.showHelperText === true &&
          <div className='row'>
            <div className={['col-xs-12'].join(' ')}>
              <span><b><span>
                If you want to renew a membership with a different team than the one you see listed below, contact the new team for a link to their registration page.
              </span></b></span>
            </div>
            <div className={['col-xs-12 usas-extra-top-margin'].join(' ')}>
              <span><b><span>A 'Team is Expired - Contact Team for Instructions' message in the 'Select' column below indicates that the team is currently inactive. When a team is inactive, members cannot renew. Please contact your team for more information.
              </span></b></span>
            </div>
            <div className={['col-xs-12 usas-extra-top-margin'].join(' ')}>
              <span><b><span>A 'Team is Renewing' message in the 'Select' column below indicates that the team has opted to handle renewing the membership themselves. For these memberships, no further action is needed at this time. Please contact your team with any questions.
              </span></b></span>
            </div>
            <div className={['col-xs-12 usas-extra-top-margin'].join(' ')}>
              <span><b><span>A 'Change Member Type' button in the 'Select' column below indicates that the membership can be renewed as a different member type. To change the renewal membership to a different member type, click the 'Change Member Type' button and select the member type you'd like to renew as from the dropdown.
              </span></b></span>
            </div>
            <div className={['col-xs-12 usas-extra-top-margin'].join(' ')}>
              <span><b><span>A 'Not Offered at This Time' message in the 'Renewal Membership' column below indicates that the prior membership is not being offered as a renewal membership at this time. To change the renewal membership to a different member type, click the 'Change Member Type' button and select the member type you'd like to renew as from the dropdown.
              </span></b></span>
            </div>
            <div className={['col-xs-12 usas-extra-top-margin'].join(' ')}>
              <span>
                <span><b>Is a renewal membership you were expecting to see not appearing below? Check the 'My Family' section of your dashboard to ensure that the member is associated with your account. If they are not listed, use the 'Add Family Member' button to add them to your account.</b></span>
              </span>
            </div>
            <div className={['col-xs-12 usas-extra-top-margin usas-extra-bottom-margin'].join(' ')}>
              <div style={{ background: '#C1C6C8', padding: '8px' }}>
                <Headings.H6>
                  IMPORTANT: If a member is missing required information, you will see a 'Not Eligible for One Button Renew - Contact Team for Registration Link' message instead of a checkbox in the 'Select' column below. To be able to register a member with missing required information, please contact your team for a link to their registration page. On the team's registration page, you will need to scroll down to the member in the 'Renew Eligible Memberships' section and click on the 'Provide Missing Info & Renew' button.
                </Headings.H6 >
              </div>
            </div>
          </div>}
        {gridState.gridData.length > 5 && memberRegistrationRenewalState.isArrayLoading === false &&
          < div className='visible-md visible-lg' >
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
              <div className="col-xs-12 usas-extra-top-margin">
                <PrimaryButton type="button" onClick={onSelectAll}>Select All Results</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
              </div>
            </div>
            {errors?.general &&
              <div className="row">
                <div className="col-xs-12">
                  <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errors?.general || ''}</p>
                </div>
              </div>}
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
              <div className="col-xs-12 usas-extra-top-margin">
                <PrimaryButton type="button" onClick={onRenewClicked}>Renew</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
              </div>
            </div>
          </div >}
        {gridState.gridData.length > 0 && memberRegistrationRenewalState.isArrayLoading === false &&
          < div className='hidden-md hidden-lg' >
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
              <div className="col-xs-12 usas-extra-top-margin">
                <PrimaryButton type="button" onClick={onSelectAll}>Select All Results</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
              </div>
            </div>
            {errors?.general &&
              <div className="row">
                <div className="col-xs-12">
                  <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errors?.general}</p>
                </div>
              </div>}
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
              <div className="col-xs-12 usas-extra-top-margin">
                <PrimaryButton type="button" onClick={onRenewClicked}>Renew</PrimaryButton>&nbsp;
                <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
              </div>
            </div>
          </div >}
        <div className="row">
          <div className="col-xs-12">
            <RenewMembershipsSelectionGrid
              omrView={omrView}
              gridData={gridState.gridData}
              isLoading={memberRegistrationRenewalState.isArrayLoading}
              onCheckboxChange={onCheckboxChange}
              onChangeMemberTypeClicked={onChangeMemberTypeClicked}
              onShowRenewalConfirmationModalClicked={onShowRenewalConfirmationModalClicked}
            />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onSelectAll}>Select All Results</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onUnselectAll}>Unselect All Results</SecondaryButton>
          </div>
        </div>
        {errors?.general &&
          <div className="row">
            <div className="col-xs-12">
              <p className={[global.ErrorMessage, global.Multiline].join(' ')}>{errors?.general || ''}</p>
            </div>
          </div>}
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onRenewClicked}>Renew</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={1400}
        title={successModalState.modalTitle}
        displayPopUp={successModalState.displayPopUp && !memberRegistrationRenewalState.isSaving}
        onModalCanceled={onSuccessModalCanceled}>
        <div className="row">
          <div className='col-xs-12 usas-extra-bottom-margin'>
            <b>The following renewal memberships have been added to the shopping cart. Please click 'Continue' to check out and complete the renewal process.</b>
          </div>
          <div className="col-xs-12">
            <RenewMembershipsSuccessGrid
              gridData={selectionChangesState.arrayData?.filter(x => x.isSelected === true)}
              isLoading={memberRegistrationRenewalState.isArrayLoading}
            />
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onContinueClicked}>Continue</PrimaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={memberTypeSelectionModalState.modalTitle}
        displayPopUp={memberTypeSelectionModalState.displayPopUp}
        onModalCanceled={onMemberTypeSelectionModalCanceled}>
        <div className="row">
          <div className="col-xs-12 usas-extra-bottom-margin">
            <span><b>Selected Member:</b> {(memberTypeSelectionModalState?.membership?.firstName || memberTypeSelectionModalState?.membership?.middleName || memberTypeSelectionModalState?.membership?.lastName) ? `${memberTypeSelectionModalState?.membership?.firstName || ''}  ${memberTypeSelectionModalState?.membership?.preferredName !== '' && memberTypeSelectionModalState?.membership?.preferredName !== memberTypeSelectionModalState?.membership?.firstName ? '"' + memberTypeSelectionModalState?.membership?.preferredName + '"' : ''} ${memberTypeSelectionModalState?.membership?.middleName || ''} ${memberTypeSelectionModalState?.membership?.lastName || ''}` : ''} ({memberTypeSelectionModalState?.membership?.competitionGenderTypeName || ''}, DOB: {memberTypeSelectionModalState?.membership?.birthDate ? formatDate(memberTypeSelectionModalState?.membership?.birthDate) : ''})</span>
          </div>
          <div className="col-xs-12 usas-extra-bottom-margin">
            <span><b>Prior Membership (Expires):</b> {memberTypeSelectionModalState?.membership?.offeringName || ''} {memberTypeSelectionModalState?.membership?.currentExpirationDate ? `(${formatDate(memberTypeSelectionModalState?.membership?.currentExpirationDate)})` : ''}</span>
          </div>
          <div className="col-xs-12 usas-extra-bottom-margin">
            <span><b>Renewal Membership (Expires):</b> {memberTypeSelectionModalState?.membership?.renewalOfferingName || ''} {memberTypeSelectionModalState?.membership?.renewalExpirationDate ? `(${formatDate(memberTypeSelectionModalState?.membership?.renewalExpirationDate)})` : ''}{memberTypeSelectionModalState?.membership?.hasPreviousRegistration === true && memberTypeSelectionModalState?.membership?.renewalOfferingInstanceId === null && <b>Not Offered at This Time</b>}</span>
          </div>
        </div>
        <form>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
              <RenewalOptionDropdown
                label={"Change Renewal Member Type To*"}
                name={"renewalOfferingInstanceId"}
                value={memberTypeSelectionModalFormState.renewalOfferingInstanceId}
                error={memberTypeSelectionModalErrorState.renewalOfferingInstanceId}
                message={memberTypeSelectionModalErrorState.renewalOfferingInstanceId}
                onChange={(newValue, newValueLabel, e) =>
                  e && e.target && e.target.value && setMemberTypeSelectionModalFormState({ ...memberTypeSelectionModalFormState, renewalOfferingInstanceId: newValue, renewalOfferingName: newValueLabel })
                }
                personId={memberTypeSelectionModalState.personId}
                offeringInstanceId={memberTypeSelectionModalState.offeringInstanceId}
                orgUnitId={memberTypeSelectionModalState.orgUnitId}
              />
            </div>
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onMemberTypeSelectionContinueClicked}>Save</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onMemberTypeSelectionModalCanceled}>Cancel</SecondaryButton>
            </div>
          </div>
        </form>
      </PopUpModal>
    </Fragment>
  );
};

export default RenewMemberships;