import React, { Fragment } from "react";

import useCourseEnrollmentsSearch from "./UseCourseEnrollmentsSearch";

import CourseEnrollmentsSearchForm from "../components/forms/CourseEnrollmentsSearchForm";

import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";

import Constants from "../../../../common/utils/Constants";

import global from '../../../../common/components/GlobalStyle.module.css';
import CourseEnrollmentsSearchGrid from "../components/grids/CourseEnrollmentsSearchGrid";

const CourseEnrollmentsSearch = () => {
  const {
    courseEnrollmentsState,
    courseEnrollmentsFilterState,
    onShowFiltersClicked,
    onSubmitFormCallback,
    onEditPersonCourseEnrollments
  } = useCourseEnrollmentsSearch();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Course Enrollments Search</Headings.H3>
        </div>
      </div>
      <div className={courseEnrollmentsFilterState.showFilters === true ? global.DisplayComponent : global.HideComponent}>
        <CourseEnrollmentsSearchForm onSubmitFormCallback={onSubmitFormCallback} />
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <CourseEnrollmentsSearchGrid
            data={courseEnrollmentsState.arrayData}
            isLoading={courseEnrollmentsState.isArrayLoading}
            showFilters={courseEnrollmentsFilterState.showFilters}
            onEditPersonCourseEnrollments={onEditPersonCourseEnrollments} 
            onShowFiltersClicked={onShowFiltersClicked}/>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={courseEnrollmentsState.isArrayLoading || courseEnrollmentsState.isObjLoading} />
    </Fragment>
  );
};

export default CourseEnrollmentsSearch;