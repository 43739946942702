import React, { createContext, useState } from 'react';

import CommunicationsHubData from './CommunicationsHubData';

export const CommunicationsHubStateContext = createContext();

const CommunicationsHubContextProvider = ({ children }) => {
  const stateHook = useState(CommunicationsHubData.INITIAL_STATE);

  return (
    <CommunicationsHubStateContext.Provider value={stateHook}>
      {children}
    </CommunicationsHubStateContext.Provider>
  );
};

export default CommunicationsHubContextProvider;