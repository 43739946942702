import { useEffect } from 'react';

import validate from './CSSFormValidation';

import UseForm from "../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  cssUserId: ''
};

const useCSSForm = (onSubmitFormCallback, userAccountObj) => {
  const { formState, errorState, handleSubmit, updateFormState, resetForm, setFormData
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    if (userAccountObj) {
      setFormData({
        ...formState,
        cssUserId: userAccountObj.cssUserId || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccountObj]);

  return {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    resetForm
  };
};

export default useCSSForm;