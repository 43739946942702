import { useEffect, useState } from "react";

import validate from './UserInfoFormValidation';

import UseForm from "../../../../../../../common/utils/UseForm";
import Constants from "../../../../../../../common/utils/Constants";

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  preferredName: '',
  middleName: '',
  email: '',
  birthDate: Constants.BLANK_DATE_STRING,
  memberId: '',
  password: '',
  confirmPassword: ''
};

const INITIAL_REQUIREMENTS_STATE = {
  password10Characters: false,
  passwordNumber: false,
  passwordSpecialCharacter: false,
  passwordLowercaseCharacter: false,
  passwordUppercaseCharacter: false,
  passwordNotEqualToEmail: false
};

const INITIAL_HIDE_PASSWORD_STATE = {
  hidePassword: true,
  hideConfirmPassword: true
};

// eslint-disable-next-line no-useless-escape
const includesSpecialCharacterRegex = /[!"#$%&'()*+,-.\/:;<=>?@[\]\\^_`{|}~]/;
const includesNumberRegex = /[0-9]/;
const includesLowerCaseRegex = /[a-z]/;
const includesUpperCaseRegex = /[A-Z]/;

const useUserInfoForm = (onSubmitFormCallback, userInfoData, childData) => {
  const { formState, errorState, isDirty, handleSubmit, updateFormState, setIsDirty, setFormData
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);
  const [requirementsState, setRequirementsState] = useState(INITIAL_REQUIREMENTS_STATE);
  const [enableSaveButtonState, setEnableSaveButtonState] = useState(false);
  const [hidePasswordState, setHidePasswordState] = useState(INITIAL_HIDE_PASSWORD_STATE);

  const onPasswordChanged = (value) => {
    updateRequirementState(value, formState.email);
    updateFormState('password', value);
  };

  const onEmailChanged = (value) => {
    updateRequirementState(formState.password, value);
    updateFormState('email', value);
  };

  const onToggleHideShowPassword = (fieldName) => {
    setHidePasswordState({
      ...hidePasswordState,
      [fieldName]: !hidePasswordState[fieldName]
    })
  };

  useEffect(() => {
    if (userInfoData) {
      setFormData({
        ...formState,
        firstName: childData?.firstName || userInfoData.firstName || '',
        lastName: childData?.lastName || userInfoData.lastName || '',
        preferredName: childData?.preferredName || userInfoData.preferredName || '',
        middleName: childData?.middleName || userInfoData.middleName || '',
        birthDate: childData?.birthDate || userInfoData.birthDate || Constants.BLANK_DATE_STRING,
        memberId: childData?.memberId || userInfoData.memberId || '',
        email: userInfoData.email || '',
        password: userInfoData.password || '',
        confirmPassword: userInfoData.confirmPassword || ''
      }, true);

      updateRequirementState(userInfoData.password || '', userInfoData.email || '');
    } else if (childData) {
      setFormData({
        ...formState,
        firstName: childData.firstName || '',
        lastName: childData.lastName || '',
        preferredName: childData.preferredName || '',
        middleName: childData.middleName || '',
        birthDate: childData.birthDate || Constants.BLANK_DATE_STRING,
        memberId: childData.memberId
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDirty !== true) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  function updateRequirementState(password, email) {
    let newEnableSaveButtonState = true;
    let newRequirementsState = { ...requirementsState };

    // email
    if (!password || email === password || password.includes(' ')) {
      newRequirementsState.passwordNotEqualToEmail = false;
      newEnableSaveButtonState = false;
    } else {
      newRequirementsState.passwordNotEqualToEmail = true;
    }

    // length
    if (password.length < 10) {
      newRequirementsState.password10Characters = false;
      newEnableSaveButtonState = false;
    } else {
      newRequirementsState.password10Characters = true;
    }

    // number
    if (includesNumberRegex.test(password) !== true) {
      newRequirementsState.passwordNumber = false;
      newEnableSaveButtonState = false;
    } else {
      newRequirementsState.passwordNumber = true;
    }

    // lower case
    if (includesLowerCaseRegex.test(password) !== true) {
      newRequirementsState.passwordLowercaseCharacter = false;
      newEnableSaveButtonState = false;
    } else {
      newRequirementsState.passwordLowercaseCharacter = true;
    }

    // upper case
    if (includesUpperCaseRegex.test(password) !== true) {
      newRequirementsState.passwordUppercaseCharacter = false;
      newEnableSaveButtonState = false;
    } else {
      newRequirementsState.passwordUppercaseCharacter = true;
    }

    // special chars
    if (includesSpecialCharacterRegex.test(password) !== true) {
      newRequirementsState.passwordSpecialCharacter = false;
      newEnableSaveButtonState = false;
    } else {
      newRequirementsState.passwordSpecialCharacter = true;
    }

    setEnableSaveButtonState(newEnableSaveButtonState);
    setRequirementsState(newRequirementsState);
  };

  return {
    formState,
    errorState,
    requirementsState,
    enableSaveButtonState,
    hidePasswordState,
    handleSubmit,
    updateFormState,
    onPasswordChanged,
    onEmailChanged,
    onToggleHideShowPassword
  };
};

export default useUserInfoForm;