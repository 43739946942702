import React from 'react';

import style from './NameBox.module.css';

const NameBox = ({ size, profile, showMemberId }) => {
  const upperSize = size ? size.toUpperCase() : '';
  
  return (
    <div className={style.NameBox}>
      <div className={upperSize === 'SMALL' ? style.SmallName : style.Name}>{profile.name}</div>
      <div className={style.Personas}>{profile.formattedPersonas}</div>
      {showMemberId === true && <div className={style.Personas}>Member ID: {profile.memberId}</div>}
    </div>
  );
};

export default NameBox;