import React, { Fragment } from 'react';

import useMyCommunications from './UseMyCommunications';

import Message from './Message';

import Spinner from '../../../../../common/components/spinners/Spinner';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';

import style from './../../LandingPages.module.css';

const MyCommunications = () => {
  const { messages, isLoading, modalState, onModalCanceled } = useMyCommunications();

  if (isLoading === true) {
    return <Spinner />;
  } else if (Array.isArray(messages) !== true || messages.length === 0) {
    return <Fragment />;
  } else {
    return (
      <Fragment>
        <div className={style.SectionBorder}>
          <div className={style.SectionTitle}>Messages</div>
          <table className={style.Table}>
            <tbody>
              {messages.map((message, i) => <Message message={message} key={i} />)}
            </tbody>
          </table>
        </div>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={modalState.modalTitle}
          displayPopUp={modalState.displayPopUp}
          onModalCanceled={onModalCanceled}>
          <div className="row">
            <div className="col-xs-12">
              <p><b>{modalState.message}</b></p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
      </Fragment>
    );
  }
};

export default MyCommunications;