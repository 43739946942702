import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '../../../common/wrappers/ReactRouterDom';

import validate from "./MatchCheckPaymentsValidation";
import NavLinks from './NavLinks';

import usePaymentsByCheckData from '../../../common/state/paymentsByCheck/UsePaymentsByCheckData';

import useForm from "../../../common/utils/UseForm";
import Constants from '../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  paymentSubsystemId: Constants.DEFAULT_ID,
  paymentSubsystemName: '',
  firstOrPreferredName: '',
  lastName: ''
};

const paymentSubsystemOptions = [
  { id: Constants.DEFAULT_ID, name: "--" },
  { id: 1, name: "OME" },
  { id: 2, name: "OMR" },
  { id: 3, name: "SAT" },
];

const useMatchCheckPayments = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { paymentsByCheckState, getPaymentsByCheck, confirmPaymentByCheckSave } = usePaymentsByCheckData();
  const { formState, errorState, handleSubmit, onFormValueChanged, onValueTextPairChanged, setFormState,
    setErrors } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [gridState, setGridState] = useState({ gridData: [] });

  const onFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
  };

  const onClearFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setFormState(INITIAL_FORM_STATE);
    const filteredGridData = filter(INITIAL_FORM_STATE);
    setGridState({ ...gridState, gridData: filteredGridData });
    setErrors({});
  };

  const onMatchCheckClicked = (payment) => {
    navigate(NavLinks.MATCH_CHECK_PAYMENTS_DETAIL, { state: { payment: payment } });
  };

  useEffect(() => {
    if (paymentsByCheckState.isArrayLoaded === false &&
      paymentsByCheckState.isArrayLoading === false) {
      getPaymentsByCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsByCheckState])

  useEffect(() => {
    if (location.state && location.state?.performGet === true && paymentsByCheckState.isSaved === true) {
      confirmPaymentByCheckSave();
      getPaymentsByCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsByCheckState.isSaved]);

  useEffect(() => {
    if (paymentsByCheckState.isArrayLoaded === true && paymentsByCheckState.isArrayLoading === false) {
      const filteredGridData = filter(formState);
      setGridState({ ...gridState, gridData: filteredGridData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsByCheckState.isArrayLoaded, paymentsByCheckState.isArrayLoading])

  function submitFormCallback(formState) {
    const filteredGridData = filter(formState);
    setGridState({
      ...gridState,
      gridData: filteredGridData
    });

  }

  function filter(formState) {
    let arrayDataCopy = [];
    arrayDataCopy = JSON.parse(JSON.stringify(paymentsByCheckState.arrayData));

    let results = [];
    let subsystemFilter = formState.paymentSubsystemId > 0 ? true : false;
    let firstOrPreferredNameFilter = formState.firstOrPreferredName?.trim() !== '' ? true : false;
    let lastNameFilter = formState.lastName?.trim() !== '' ? true : false;

    //No filters used
    if (subsystemFilter === false && firstOrPreferredNameFilter === false && lastNameFilter === false) {
      results = arrayDataCopy;
    }
    else {
      //Filter data
      for (let i = 0; i < arrayDataCopy.length; i++) {
        let subsystemMatch = false;
        let firstOrPreferredNameMatch = false;
        let lastNameMatch = false;
        if (subsystemFilter === true) {
          if (matchAnyPart(formState.paymentSubsystemName?.trim(), arrayDataCopy[i].paymentSubsystem) === true) {
            subsystemMatch = true;
          }
        }
        if (firstOrPreferredNameFilter === true) {
          if (match('*' + formState.firstOrPreferredName?.trim() + '*', arrayDataCopy[i].firstName) === true ||
            match('*' + formState.firstOrPreferredName?.trim() + '*', arrayDataCopy[i].preferredName) === true) {
            firstOrPreferredNameMatch = true;
          }
        }
        if (lastNameFilter === true) {
          if (match('*' + formState.lastName?.trim() + '*', arrayDataCopy[i].lastName) === true) {
            lastNameMatch = true;
          }
        }
        //Does the payment meet all the filter requirements?
        if ((subsystemFilter === false || (subsystemFilter === true && subsystemMatch === true)) &&
          (firstOrPreferredNameFilter === false || (firstOrPreferredNameFilter === true && firstOrPreferredNameMatch === true)) &&
          (lastNameFilter === false || (lastNameFilter === true && lastNameMatch === true))) {
          results.push(arrayDataCopy[i]);
        }
      }
    }

    return results;
  }

  function match(providedFilter, personName) {
    let providedFilterLowerCase = providedFilter.toLowerCase();
    let personNameLowerCase = personName.toLowerCase();
    if (providedFilterLowerCase.length === 0 && personNameLowerCase.length === 0)
      return true;

    if (providedFilterLowerCase.length > 1 && providedFilterLowerCase[0] === '*' &&
      personNameLowerCase.length === 0)
      return false;

    if ((providedFilterLowerCase.length > 1 && providedFilterLowerCase[0] === '?') ||
      (providedFilterLowerCase.length !== 0 && personNameLowerCase.length !== 0 &&
        providedFilterLowerCase[0] === personNameLowerCase[0]))
      return match(providedFilterLowerCase.substring(1),
        personName.substring(1));

    if (providedFilterLowerCase.length > 0 && providedFilterLowerCase[0] === '*')
      return match(providedFilterLowerCase.substring(1), personNameLowerCase) ||
        match(providedFilterLowerCase, personNameLowerCase.substring(1));

    return false;
  }

  function matchAnyPart(providedFilter, subsystemName) {
    let providedFilterLowerCase = providedFilter.toLowerCase();
    let subsystemNameLowerCase = subsystemName.toLowerCase();
    if (providedFilterLowerCase.length === 0 && subsystemNameLowerCase.length === 0)
      return true;

    if (providedFilterLowerCase.length > 1 && subsystemNameLowerCase.includes(providedFilterLowerCase) === true)
      return true;

    return false;
  }

  return {
    paymentsByCheckState,
    gridState,
    paymentSubsystemOptions,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onMatchCheckClicked,
    onFilterClicked,
    onClearFilterClicked
  }
}

export default useMatchCheckPayments;