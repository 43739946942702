import React from "react";

import LmsAdminCoursesDropdown from "../dropdowns/LmsAdminCoursesDropdown";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import ReadOnly from "../../../../../common/components/readOnly/ReadOnly";
import DatePicker from "../../../../../common/components/datepickers/DatePicker";
import YesNoSwitch from "../../../../../common/components/yesNoSwitch/YesNoSwitch";
import Input from "../../../../../common/components/inputs/Input";

import global from '../../../../../common/components/GlobalStyle.module.css';

const CourseEnrollmentDetailForm = ({ formState, errorState, isEdit, onFormValueChanged, onValueTextPairChanged,
  onCourseEnrollmentDateChanged, onNoExpirationDateChanged, onHasExemptionChanged, handleSubmit, onBackClicked }) => (
  <form onSubmit={handleSubmit} noValidate>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        {isEdit === true
          ? (
            <ReadOnly
              label='Course'
              name='lmsCourseId'
              value={formState.lmsCourseName} />
          ) : (
            <LmsAdminCoursesDropdown
              label='Course*'
              name='lmsCourseId'
              value={formState.lmsCourseId}
              error={errorState.lmsCourseId}
              message={errorState.lmsCourseId}
              onChange={(newValue, newValueLabel, e) => {
                onValueTextPairChanged(newValue, 'lmsCourseId', newValueLabel, 'lmsCourseName');
              }} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {isEdit === true
          ? (
            <ReadOnly
              label='Enrollment Date'
              name='courseEnrollmentDate'
              value={formState.courseEnrollmentDate} />
          ) : (
            <DatePicker
              label="Enrollment Date*"
              name="courseEnrollmentDate"
              value={formState.courseEnrollmentDate}
              error={errorState.courseEnrollmentDate}
              message={errorState.courseEnrollmentDate}
              onChange={(value) => { onCourseEnrollmentDateChanged(value); }}
              countOfYears={150}
              startYearOffset={-145} />
          )
        }
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        {isEdit === true
          ? (
            <DatePicker
              label="Completion Date*"
              name="courseCompletionDate"
              value={formState.courseCompletionDate}
              error={errorState.courseCompletionDate}
              message={errorState.courseCompletionDate}
              onChange={(value) => { onFormValueChanged('courseCompletionDate', value); }}
              countOfYears={150}
              startYearOffset={-145} />
          ) : (
            <ReadOnly
              label='Completion Date'
              name='courseCompletionDate'
              value={formState.courseCompletionDate} />
          )
        }
      </div>
      <div className={formState.noExpirationDate === true ? global.HideComponent : global.DisplayComponent}>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Expiration Date*"
            name="courseExpirationDate"
            value={formState.courseExpirationDate}
            error={errorState.courseExpirationDate}
            message={errorState.courseExpirationDate}
            onChange={(value) => { onFormValueChanged('courseExpirationDate', value); }}
            countOfYears={150}
            startYearOffset={-145} />
        </div>
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <YesNoSwitch
          label="No Expiration Date?"
          name="noExpirationDate"
          checked={formState.noExpirationDate === true}
          error={errorState.noExpirationDate}
          message={errorState.noExpirationDate}
          onChange={(value) => { onNoExpirationDateChanged(value) }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <YesNoSwitch
          label="Has Exemption?"
          name="hasExemption"
          checked={formState.hasExemption === true}
          error={errorState.hasExemption}
          message={errorState.hasExemption}
          onChange={(value) => { onHasExemptionChanged(value) }} />
      </div>
    </div>
    {errorState.errorOnSubmitAction &&
      <div className="row">
        <div className="col-xs-12">
          <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
        </div>
      </div>
    }
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
        <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
      </div>
    </div>
  </form>
);

export default CourseEnrollmentDetailForm;