import Constants from "../../../../../common/utils/Constants";
import { isValidMemberId, isValidName } from "../../../../../common/utils/validation";

export const CourseEnrollmentsSearchFormValidation = (formState) => {
  let errors = {};
  let filterCount = 0;

  //First Name
  if (formState.firstName.trim() !== '') {
    if (!isValidName(formState.firstName.trim())) {
      errors.firstName = 'First Name cannot contain numbers, special characters, or exceed 100 characters';
    } else {
      filterCount += 1;
    }
  }

  //Last Name
  if (formState.lastName.trim() !== '') {
    if (!isValidName(formState.lastName.trim())) {
      errors.lastName = 'Last Name cannot contain numbers, special characters, or exceed 100 characters';
    } else {
      filterCount += 1;
    }
  }

  // Course
  if (formState.lmsCourseId !== Constants.DEFAULT_ID) {
    filterCount += 1;
  }

  //Member Id
  if (formState.memberId.trim() !== '') {
    if (!isValidMemberId(formState.memberId.trim())) {
      errors.memberId = 'Member Id must be 14 alphanumeric characters';
    } else {
      filterCount += 2; // member id counts as two filters
    }
  }

  if (filterCount < 2) {
    errors.filter = 'Please enter information in at least two search filter';
  }

  return errors;
};

export default CourseEnrollmentsSearchFormValidation;