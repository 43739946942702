import React from 'react';

import DeleteIcon from "../../../../../common/components/icons/DeleteIcon";

import { formatDate } from "../../../../../common/utils/DateFunctions";

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ person, onDeleteClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className="hidden-xs">{person.firstName || ''}&nbsp;{person.middleName || ''}&nbsp;{person.lastName || ''}</span>&nbsp;
      <div className={global.IconButton}>
        <button
          className={global.IconButton}
          type="button"
          onClick={() => { onDeleteClicked(person); }}>
          <DeleteIcon />
        </button>
      </div>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>First Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{person.firstName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Middle Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{person.middleName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Last Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{person.lastName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Member Id</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{person.memberId || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Birth Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{person.birthDate ? formatDate(person.birthDate) : <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const ManageUserAccountChildrenSmallGrid = ({ data, isLoading, onDeleteClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Loading...
            </div>
          </div>
        </div>
      ) : Array.isArray(data) && data.length > 0
        ? data.map((person, i) => (
          <GridRow key={i} person={person} onDeleteClicked={onDeleteClicked} />
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Children</div>
            </div>
          </div>
        )
    }
  </div>
);

export default ManageUserAccountChildrenSmallGrid;