import { Fragment } from "react";

import useMatchCheckPayments from "./UseMatchCheckPayments";

import CheckPaymentsGrid from "./CheckPaymentsGrid";

import Headings from "../../../common/components/headings/Headings";
import PopUpModal from "../../../common/components/dialogs/PopUpModal";
import Input from '../../../common/components/inputs/Input';
import Dropdown from '../../../common/components/dropdowns/Dropdown';

import Constants from "../../../common/utils/Constants";

import global from '../../../common/components/GlobalStyle.module.css';

const MatchCheckPayments = () => {
  const {
    paymentsByCheckState,
    gridState,
    paymentSubsystemOptions,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onMatchCheckClicked,
    onFilterClicked,
    onClearFilterClicked } = useMatchCheckPayments();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Match Check Payments</Headings.H3>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="col-xs-12 col-md-4" >
            <Dropdown
              label="Payment Subsystem"
              name="paymentSubsystemId"
              options={paymentSubsystemOptions}
              value={formState.paymentSubsystemId}
              error={errorState.paymentSubsystemId}
              message={errorState.paymentSubsystemId}
              isLoading={false}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(newValue, 'paymentSubsystemId', newValueLabel, 'paymentSubsystemName');
              }} />
          </div>
          <div className="col-xs-12 col-md-4" >
            <Input
              label="Payment Person First or Preferred Name"
              name="firstOrPreferredName"
              value={formState.firstOrPreferredName}
              error={errorState.firstOrPreferredName}
              message={errorState.firstOrPreferredName}
              onChange={(value) => { onFormValueChanged('firstOrPreferredName', value) }} />
          </div>
          <div className="col-xs-12 col-md-4" >
            <Input
              label="Payment Person Last Name"
              name="lastName"
              value={formState.lastName}
              error={errorState.lastName}
              message={errorState.lastName}
              onChange={(value) => { onFormValueChanged('lastName', value) }} />
          </div>
          <div className='col-xs-5 col-md-2 col-lg-2'>
            <button className={global.PlainButton} onClick={(e) => onFilterClicked(e)}>Filter</button>
          </div>
          <div className='col-xs-7 col-md-4 col-lg-3'>
            <button className={global.PlainButton} onClick={(e) => onClearFilterClicked(e)}>Clear Filter</button>
          </div>
        </form>
        <div className="col-xs-12 usas-extra-top-margin">
          <CheckPaymentsGrid
            data={gridState.gridData}
            isLoading={paymentsByCheckState.isArrayLoading}
            onMatchCheckClicked={onMatchCheckClicked}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={paymentsByCheckState.isArrayLoading} />
    </Fragment>);
}

export default MatchCheckPayments;