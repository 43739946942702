import React from 'react';

import useOrgRoleByRoleGroupDropdown from './UseOrgRoleByRoleGroupDropdown';

import Dropdown from '../../../../../common/components/dropdowns/Dropdown';

import global from '../../../../../common/components/GlobalStyle.module.css';

const OrgRoleByRoleGroupDropdown = ({ label, name, value, error, message, onChange, roleGroupId, orgUnitId }) => {
  const { orgRoleState, options } = useOrgRoleByRoleGroupDropdown(roleGroupId, orgUnitId);

  return orgRoleState.message
    ? <span className={global.LoadingMsg}>{orgRoleState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={options.length === 0}
      />
    );
};

export default OrgRoleByRoleGroupDropdown;