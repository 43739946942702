export const localValidate = (formState) => {
  let errors = {};

  if (formState.isOver16 !== true && formState.isOver16 !== false) {
    errors.isOver16 = 'Must select Yes or No';
  }

  return errors;
};

const ConfirmAgeFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default ConfirmAgeFormValidation;