import { useEffect, useState } from "react";

import {
  AC_DUP_MATCH_EMAIL, AC_DUP_MATCH_MEMBER_ID, AC_DUP_MATCH_MEMBER_ID_EMAIL, AC_DUP_MATCH_MEMBER_ID_EMAIL_NAME_DOB, AC_DUP_MATCH_NAME_DOB,
  AC_DUP_MATCH_NAME_DOB_EMAIL,
  AC_DUP_MATCH_NONE
} from "../../utils/AccountCreationConstants";

const INITIAL_STATE = {
  matchType: '',
  dupData: undefined
};

const usePersonDupPopupManager = (dupCheckState, isMemberIdDupCheck, onContinueClicked) => {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });

    if (dupCheckState.isObjLoaded === true) {
      if (isMemberIdDupCheck === true) {
        handleMemberIdDupCheck(dupCheckState.objData?.matches || []);
      } else {
        handleGeneralDupCheck(dupCheckState.objData?.matches || []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dupCheckState.isObjLoaded, isMemberIdDupCheck]);

  // 6 outcomes 
  // 1. MemberId, Person Email, Name, & DOB Match -> continue
  // 2. MemberId, Person Email, Name, & DOB Match but person already linked -> error popup
  // 3. MemberId & Person Email Match -> error popup
  // 4. MemberId Match -> error popup
  // 5. No matches by memberId -> error popup
  // 6. No matches at all -> error popup
  function handleMemberIdDupCheck(matches) {
    if (Array.isArray(matches) && matches.length > 0) {
      const memberIdEmailNameDOBMatch = matches.find(x => x.isMemberIdAndPersonEmailMatch === true && x.isNameAndBirthDateMatch === true);

      if (memberIdEmailNameDOBMatch) {
        if (memberIdEmailNameDOBMatch.isAlreadyLinked === false) {
          onContinueClicked(memberIdEmailNameDOBMatch.personId);
        } else {
          setState({
            ...state,
            matchType: AC_DUP_MATCH_MEMBER_ID_EMAIL_NAME_DOB,
            dupData: memberIdEmailNameDOBMatch
          });
        }
      } else {
        const memberIdEmailMatch = matches.find(x => x.isMemberIdAndPersonEmailMatch === true);

        if (memberIdEmailMatch) {
          setState({
            ...state,
            matchType: AC_DUP_MATCH_MEMBER_ID_EMAIL,
            dupData: memberIdEmailMatch
          });
        } else {
          const memberIdMatch = matches.find(x => x.isMemberIdMatch === true);

          if (memberIdMatch) {
            setState({
              ...state,
              matchType: AC_DUP_MATCH_MEMBER_ID,
              dupData: memberIdMatch
            });
          } else {
            setState({
              ...state,
              matchType: AC_DUP_MATCH_NONE
            });
          }
        }
      }
    } else {
      setState({
        ...state,
        matchType: AC_DUP_MATCH_NONE
      });
    }
  };

  // 4 outcomes 
  // 1. Name, DOB, & Account Email Match -> error popup
  // 2. Account Email Match -> error popup
  // 3. Name & DOB Match(es) -> grid popup
  // 4. No matches at all -> continue
  function handleGeneralDupCheck(matches) {
    if (Array.isArray(matches) && matches.length > 0) {
      const nameBODEmailMatch = matches.find(x => x.isNameAndBirthDateMatch === true && x.isAccountEmailMatch === true);

      if (nameBODEmailMatch) {
        setState({
          ...state,
          matchType: AC_DUP_MATCH_NAME_DOB_EMAIL,
          dupData: nameBODEmailMatch
        });
      } else {
        const emailMatch = matches.find(x => x.isAccountEmailMatch === true);

        if (emailMatch) {
          setState({
            ...state,
            matchType: AC_DUP_MATCH_EMAIL,
            dupData: emailMatch
          });
        } else {
          setState({
            ...state,
            matchType: AC_DUP_MATCH_NAME_DOB,
            dupData: matches
          });
        }
      }
    } else {
      onContinueClicked();
    }
  };

  return {
    state
  };
};

export default usePersonDupPopupManager;