/* eslint-disable react/no-unescaped-entities */
import { Fragment } from "react";

import useAccountCreationForgotPassword from "./UseAccountCreationForgotPassword";

import ForgotPasswordForm from "../../components/forms/ForgotPasswordForm";

import Headings from "../../../../../../../common/components/headings/Headings";
import PopUpModal from "../../../../../../../common/components/dialogs/PopUpModal";
import SecondaryButton from "../../../../../../../common/components/buttons/SecondaryButton";

import Constants from "../../../../../../../common/utils/Constants";

import style from '../../../../LandingPages.module.css';

const AccountCreationForgotPassword = () => {
  const {
    isLoading,
    apiErrorPopupState,
    onCancelClicked,
    onCloseAPIErrorPopupClicked,
    onSubmitFormCallback
  } = useAccountCreationForgotPassword();

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 col-md-4" />
        <div className={['col-xs-12', 'col-md-4', style.CenterPageContent].join(' ')}>
          <Headings.H3>Forgot Password</Headings.H3>
          <p>Enter your username, and we'll send password reset instructions to your email address.</p>
          <ForgotPasswordForm
            submitButtonText={'Submit'}
            secondaryButtonText={'Cancel'}
            onSubmitFormCallback={onSubmitFormCallback}
            onSecondaryButtonClicked={onCancelClicked} />
        </div>
        <div className="col-xs-12 col-md-4" />
      </div>
      <PopUpModal
        widthPct={60}
        maxWidth={720}
        title={'Error'}
        displayPopUp={apiErrorPopupState.displayPopup === true}>
        <div className="row">
          <div className="col-xs-12">
            <p>{apiErrorPopupState.errorMessage || 'An error occurred.'}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SecondaryButton type={'button'} onClick={onCloseAPIErrorPopupClicked}>Close</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default AccountCreationForgotPassword;