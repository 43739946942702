import { useState } from "react";
import { useSearchParams } from "../../../../../../../common/wrappers/ReactRouterDom";

import useAccountCreationData from "../../../../../../state/landingPages/accountCreation/UseAccountCreationData";
import useAccountCreationOneTimePasswordData from "../../../../../../state/landingPages/accountCreation/accountCreationOneTimePassword/UseAccountCreationOneTimePasswordData";

const INITIAL_LOGIN_ERROR_POPUP_STATE = {
  displayPopup: false,
  errorMessage: '',
  doFullReset: false
};

const useAccountCreationOneTimePassword = () => {
  const [searchParams,] = useSearchParams();
  const { accountCreationState, resetAccountCreationState } = useAccountCreationData();
  const { accountCreationOneTimePasswordState, postClientSideOneTimePassword, postServerSideOneTimePassword,
    resetAccountCreationOneTimePasswordState } = useAccountCreationOneTimePasswordData();
  const [loginErrorPopupState, setLoginErrorPopupState] = useState(INITIAL_LOGIN_ERROR_POPUP_STATE);

  const onCancelClicked = () => {
    resetAccountCreationState(); // clears state and sends back to login
  };

  const onCloseLoginErrorPopupClicked = () => {
    if (loginErrorPopupState.doFullReset === true) {
      resetAccountCreationState(); // clears state and sends back to login
    } else {
      resetAccountCreationOneTimePasswordState();
      setLoginErrorPopupState(INITIAL_LOGIN_ERROR_POPUP_STATE);
    }
  };

  const onSubmitFormCallback = (formState) => {
    if (accountCreationState.loginData?.isServerSideLogin === true) {
      doServerSideLogin(accountCreationState.loginData.href, formState.oneTimePassword);
    } else if (accountCreationState.loginData?.isClientSideLogin === true) {
      doClientSideLogin(accountCreationState.loginData.href, formState.oneTimePassword);
    } else {
      setLoginErrorPopupState({
        ...loginErrorPopupState,
        displayPopup: true,
        doFullReset: true
      });
    }
  };

  function doServerSideLogin(href, oneTimePassword) {
    const postServerSideOneTimePasswordPromise = postServerSideOneTimePassword(href, oneTimePassword);

    if (postServerSideOneTimePasswordPromise ?? false) {
      postServerSideOneTimePasswordPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData.accessToken === null) {
            setLoginErrorPopupState({
              ...loginErrorPopupState,
              displayPopup: true,
              errorMessage: 'The one-time password is invalid.'
            });
          } else {
            // Successful login
            if (searchParams.get('retUrl')) {
              window.location.href = `${searchParams.get('retUrl')}?token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            } else if (window.location.href.indexOf('?') === -1) {
              window.location.href = `${window.location.href}?token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            } else {
              window.location.href = `${window.location.href}&token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            }
          }
        }
      }).catch((e) => {
        setLoginErrorPopupState({
          ...loginErrorPopupState,
          displayPopup: true,
          errorMessage: e?.message
        });
      });
    }
  };

  function doClientSideLogin(href, oneTimePassword) {
    const postClientSideOneTimePasswordPromise = postClientSideOneTimePassword(href, oneTimePassword);

    if (postClientSideOneTimePasswordPromise ?? false) {
      postClientSideOneTimePasswordPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData.code === 'INVALID_DATA') {
            setLoginErrorPopupState({
              ...loginErrorPopupState,
              displayPopup: true,
              errorMessage: 'The one-time password is invalid.'
            });
          } else {
            const environmentId = searchParams.get('environmentId');
            const flowId = searchParams.get('flowId');

            // Successful login
            if (newState.objData?.resumeUrl) {
              window.location.href = `https://auth.pingone.com/${environmentId}/as/resume?flowId=${flowId}`;
            } else if (searchParams.get('retUrl')) {
              window.location.href = `${searchParams.get('retUrl')}?token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            } else if (window.location.href.indexOf('?') === -1) {
              window.location.href = `${window.location.href}?token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            } else {
              window.location.href = `${window.location.href}&token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            }
          }
        }
      }).catch((e) => {
        setLoginErrorPopupState({
          ...loginErrorPopupState,
          displayPopup: true,
          errorMessage: e?.message
        });
      });
    }
  };

  return {
    isLoading: accountCreationOneTimePasswordState.isObjLoading,
    device: accountCreationState.loginData?.device || '',
    loginErrorPopupState,
    onSubmitFormCallback,
    onCancelClicked,
    onCloseLoginErrorPopupClicked
  };
};

export default useAccountCreationOneTimePassword;