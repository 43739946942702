import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";

const deleteUserAccountData = (state, setState) => {
  const api = initApi(API_NAMES.SECURITY, state, setState);
  const url = `/Account/Delete`;

  return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
};

const deleteUserAccountByPersonIdData = (personId, state, setState) => {
  if (personId) {
    const api = initApi(API_NAMES.SECURITY, state, setState);
    const personIdForUrl = encodeURIComponent(personId);
    const url = `/Account/Person/${personIdForUrl}/Delete`;

    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const AccountManagementDeleteUserAccountData = {
  deleteUserAccountData,
  deleteUserAccountByPersonIdData
};

export default AccountManagementDeleteUserAccountData;