export const AC_STEP_LOGIN = 'Login';
export const AC_STEP_ONE_TIME_PASSWORD = 'One Time Password';
export const AC_STEP_MFA = 'MFA';
export const AC_STEP_EXISTING_ACCOUNT = 'Existing Account';
export const AC_STEP_EXPIRED_PASSWORD = 'Expired Password';
export const AC_STEP_FORGOT_PASSWORD = 'Forgot Password';
export const AC_STEP_CHANGE_PASSWORD = 'Change Password';
export const AC_STEP_FORGOT_USERNAME = 'Forgot Username';
export const AC_STEP_CONFIRM_AGE = 'Confirm Age';
export const AC_STEP_USER_INFO = 'User Info';
export const AC_STEP_VALIDATE_EMAIL = 'Validate Email';
export const AC_DUP_MATCH_NONE = 'None';
export const AC_DUP_MATCH_MEMBER_ID_EMAIL_NAME_DOB = 'Member Id, Email, Name, and Birth Date';
export const AC_DUP_MATCH_MEMBER_ID = 'Member Id';
export const AC_DUP_MATCH_MEMBER_ID_EMAIL = 'Member Id and Email Match';
export const AC_DUP_MATCH_NAME_DOB_EMAIL = 'Name, Birth Date, and Email Match';
export const AC_DUP_MATCH_EMAIL = 'Email Match';
export const AC_DUP_MATCH_NAME_DOB = 'Name and Birth Date Match';

const AccountCreationConstants = {
  AC_STEP_LOGIN,
  AC_STEP_ONE_TIME_PASSWORD,
  AC_STEP_MFA,
  AC_STEP_EXISTING_ACCOUNT,
  AC_STEP_EXPIRED_PASSWORD,
  AC_STEP_FORGOT_PASSWORD,
  AC_STEP_CHANGE_PASSWORD,
  AC_STEP_FORGOT_USERNAME,
  AC_STEP_CONFIRM_AGE,
  AC_STEP_USER_INFO,
  AC_STEP_VALIDATE_EMAIL,
  AC_DUP_MATCH_NONE,
  AC_DUP_MATCH_MEMBER_ID,
  AC_DUP_MATCH_MEMBER_ID_EMAIL,
  AC_DUP_MATCH_MEMBER_ID_EMAIL_NAME_DOB,
  AC_DUP_MATCH_EMAIL,
  AC_DUP_MATCH_NAME_DOB,
  AC_DUP_MATCH_NAME_DOB_EMAIL
};

export default AccountCreationConstants;