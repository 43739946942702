import useValidateEmailForm from "./UseValidateEmailForm";

import PrimaryButton from "../../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../../common/components/buttons/SecondaryButton";
import Input from "../../../../../../../common/components/inputs/Input";

const ValidateEmailForm = ({ submitButtonText = 'Continue', secondaryButtonText = 'Back', onSubmitFormCallback,
  onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState
  } = useValidateEmailForm(onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12">
          <Input
            label={'Verification Code*'}
            name={'verificationCode'}
            value={formState.verificationCode}
            error={errorState.verificationCode}
            message={errorState.verificationCode}
            onChange={(value) => updateFormState('verificationCode', value)} />
        </div>
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>
        </div>
        <div className="col-xs-12">
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default ValidateEmailForm;