/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import SGColumnHeader from '../../../../../common/components/grids/SGColumnHeader';
import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import useSortableGrid from '../../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../../common/utils/SGConstants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ enrollmentCourse, onEdit, onDelete }) => {
  return (
    <tr>
      <td>{enrollmentCourse.courseCode || ''}</td>
      <td>{enrollmentCourse.courseEnrollmentDate ? formatDate(enrollmentCourse.courseEnrollmentDate) : ''}</td>
      <td>{enrollmentCourse.courseCompletionDate ? formatDate(enrollmentCourse.courseCompletionDate) : ''}</td>
      <td>{enrollmentCourse.courseExpirationDate ? formatDate(enrollmentCourse.courseExpirationDate) : ''}</td>
      <td>{enrollmentCourse.coursePassed === true ? 'Yes' : 'No'}</td>
      <td>{enrollmentCourse.hasExemption === true ? 'Yes' : ''}</td>
      <td>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onEdit(enrollmentCourse)}>
          <EditIcon />
        </button>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onDelete(enrollmentCourse.courseEnrollmentId)}>
          <DeleteIcon />
        </button>
      </td>
    </tr>
  );
}

const CourseEnrollmentsManagementGridLarge = ({ data, isLoading, onEdit, onDelete }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(data);

  return (
    <Fragment>
      <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
        <thead>
          <tr>
            <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Course Code'} columnField={'courseCode'}
              sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
            <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Enrollment Date'} columnField={'courseEnrollmentDate'}
              sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
            <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Completion Date'} columnField={'courseCompletionDate'}
              sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
            <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Expiration Date'} columnField={'courseExpirationDate'}
              sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
            <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Course Passed?'} columnField={'coursePassed'}
              sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
            <th>Has Exemption</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isLoading === true
            ? <tr><td colSpan="6">Loading...</td></tr>
            : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
              ? sortableGridState.sortedGridData.map((enrollmentCourse, i) => <GridRow key={i} enrollmentCourse={enrollmentCourse} onEdit={onEdit} onDelete={onDelete} />)
              : <tr><td colSpan="6">No Course Enrollments</td></tr>
          }
        </tbody>
      </table>
    </Fragment>
  );
};

export default CourseEnrollmentsManagementGridLarge;