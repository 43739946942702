import { useState } from 'react';

import AccountManagementPasswordData from './AccountManagementPasswordData';

import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

const INITIAL_ACCOUNT_MANAGEMENT_PASSWORD_STATE = {
  ...BASIC_INITIAL_STATE
};

const useAccountManagementPasswordData = () => {
  const [accountManagementPasswordState, setAccountManagementPasswordState] = useState(INITIAL_ACCOUNT_MANAGEMENT_PASSWORD_STATE);

  const postAccountManagementPasswordByUserAccountId = (userAccountId, passwordObj) => {
    return AccountManagementPasswordData.postAccountManagementPasswordByUserAccountIdData(userAccountId, passwordObj, accountManagementPasswordState, setAccountManagementPasswordState);
  };

  const postAccountManagementPassword = (passwordObj) => {
    return AccountManagementPasswordData.postAccountManagementPasswordData(passwordObj, accountManagementPasswordState, setAccountManagementPasswordState);
  };

  const resetPasswordSaveState = () => {
    setAccountManagementPasswordState({
      ...INITIAL_ACCOUNT_MANAGEMENT_PASSWORD_STATE
    });
  };

  return {
    accountManagementPasswordState,
    resetPasswordSaveState,
    postAccountManagementPasswordByUserAccountId,
    postAccountManagementPassword
  };
};

export default useAccountManagementPasswordData;