import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";

const postAccountCreateStep1Data = (stepObj, state, setState) => {
  if (stepObj) {
    const api = initApi(API_NAMES.SECURITY, state, setState);
    const url = `/Account/CreateStep1`;

    return api?.executeObject ? api.executeObject(url, 'POST', stepObj) : null;
  }
};

const postAccountCreateStep2Data = (stepObj, state, setState) => {
  if (stepObj) {
    const api = initApi(API_NAMES.SECURITY, state, setState);
    const url = `/Account/CreateStep2`;

    return api?.executeObject ? api.executeObject(url, 'POST', stepObj) : null;
  }
};

const AccountCreationData = {
  postAccountCreateStep1Data,
  postAccountCreateStep2Data
};

export default AccountCreationData;