export const localValidate = (formState) => {
  let errors = {};

  if (formState.verificationCode.trim() === '') {
    errors.verificationCode = 'Verification Code is required';
  }

  return errors;
};

const ValidateEmailFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default ValidateEmailFormValidation;