import React from 'react';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ person, onDeleteClicked }) => (
  <tr>
    <td>{person.firstName || ''}</td>
    <td>{person.middleName || ''}</td>
    <td>{person.lastName || ''}</td>
    <td>{person.memberId || ''}</td>
    <td>{person.birthDate ? formatDate(person.birthDate) : ''}</td>
    <td>
      <button
        className={global.IconButtonMargin}
        type="button"
        onClick={() => { onDeleteClicked(person) }}>
        <DeleteIcon />
      </button>
    </td>
  </tr>
);

const ChildrenGridLarge = ({ data, isLoading, onDeleteClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>First Name</th>
        <th>Middle Name</th>
        <th>Last Name</th>
        <th>Member Id</th>
        <th>Birth Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="6">Loading...</td></tr>
        : Array.isArray(data) && data.length > 0
          ? data.map((person, i) => <GridRow key={i} person={person} onDeleteClicked={onDeleteClicked} />)
          : <tr><td colSpan="6">No Children</td></tr>
      }
    </tbody>
  </table>
);

export default ChildrenGridLarge;