import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../NavLinks";

import useAccountManagementData from "../../../state/accountManagement/UseAccountManagementData";
import useAccountManagementSecurityRolesData from "../../../state/accountManagement/accountManagementSecurityRoles/UseAccountManagementSecurityRolesData";

import { DEFAULT_MAX_DATE } from "../../../../common/utils/Constants";

const useAccountManagementSecurityRolesAdd = () => {
  const navigate = useNavigate();
  const { accountManagementState } = useAccountManagementData();
  const { accountManagementSecurityRolesSaveState, postAccountManagementSecurityRole
  } = useAccountManagementSecurityRolesData();

  const onSubmitFormCallback = (formState) => {
    const userAccountId = accountManagementState.objData?.userAccountId;

    const postAccountManagementSecurityRolePromise = postAccountManagementSecurityRole(userAccountId, createSecurityRoleObj(formState));

    if (postAccountManagementSecurityRolePromise ?? false) {
      postAccountManagementSecurityRolePromise.then((newState) => {
        if (newState ?? false) {
          navigate(NavLinks.ACCOUNT_MANAGEMENT_SECURITY_ROLES, { state: { doRefresh: true } });
        }
      }).catch((e) => {
        //context error
      });
    }
  };

  const onBackClicked = () => {
    navigate(NavLinks.ACCOUNT_MANAGEMENT_SECURITY_ROLES);
  };

  function createSecurityRoleObj(formState) {
    return {
      orgUnitId: formState.orgUnit[0]?.id,
      orgRoleId: formState.orgRoleId,
      effectiveDate: formState.effectiveDate,
      expirationDate: formState.hasNoExpirationDate === false ? formState.expirationDate : DEFAULT_MAX_DATE,
    };
  };

  return {
    isSaving: accountManagementSecurityRolesSaveState.isObjLoading,
    onSubmitFormCallback,
    onBackClicked
  };
};

export default useAccountManagementSecurityRolesAdd;