import React, { Fragment } from 'react';
import useMySystemMessage from './UseMySystemMessage';
import SystemMessage from './SystemMessage';
import global from '../../../common/components/GlobalStyle.module.css';

const MySystemMessages = ({app}) => {
  const { systemMessageState } = useMySystemMessage(app);

  return (
    <Fragment>
      {systemMessageState?.isArrayLoaded !== true
        ? (
          <div>
            {systemMessageState?.isArrayLoading === true
              ? <p className={global.UsasLabel}>Loading...</p>
              : <Fragment />
            }
          </div>
        )
        : Array?.isArray(systemMessageState?.arrayData) === true && systemMessageState?.arrayData?.length > 0
        && systemMessageState?.arrayData?.map((message, i) => <SystemMessage message={message} key={i} />)
      }
    </Fragment>
  );
}

export default MySystemMessages;