import React from "react";

import useCourseEnrollmentsSearchForm from "./UseCourseEnrollmentsSearchForm";

import LmsAdminCoursesDropdown from "../dropdowns/LmsAdminCoursesDropdown";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import Input from "../../../../../common/components/inputs/Input";

import global from '../../../../../common/components/GlobalStyle.module.css';

const CourseEnrollmentsSearchForm = ({ onSubmitFormCallback }) => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    clearForm
  } = useCourseEnrollmentsSearchForm(onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="First Name"
            name="firstName"
            value={formState.firstName}
            error={errorState.firstName}
            message={errorState.firstName}
            onChange={(value) => { onFormValueChanged('firstName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Last Name"
            name="lastName"
            value={formState.lastName}
            error={errorState.lastName}
            message={errorState.lastName}
            onChange={(value) => { onFormValueChanged('lastName', value) }} />
        </div>
        {/* <div className="col-xs-12 col-sm-6 col-md-4">
          <LmsAdminCoursesDropdown
            label='Course'
            name='lmsCourseId'
            value={formState.lmsCourseId}
            error={errorState.lmsCourseId}
            message={errorState.lmsCourseId}
            onChange={(newValue, newValueLabel, e) => {
              onValueTextPairChanged(newValue, 'lmsCourseId', newValueLabel, 'lmsCourseName');
            }} />
        </div> */}
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Member Id"
            name="memberId"
            value={formState.memberId}
            error={errorState.memberId}
            message={errorState.memberId}
            onChange={(value) => { onFormValueChanged('memberId', value) }} />
        </div>
      </div>
      {(errorState.filter || errorState.errorOnSubmitAction) &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.filter || ''}</p>
            <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
          </div>
        </div>
      }
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">Search for Course Enrollments</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={clearForm}>Clear Form</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default CourseEnrollmentsSearchForm;