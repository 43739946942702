import React from "react";

import LmsAdminCoursesDropdown from "../dropdowns/LmsAdminCoursesDropdown";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import DatePicker from "../../../../../common/components/datepickers/DatePicker";
import Input from "../../../../../common/components/inputs/Input";

import global from '../../../../../common/components/GlobalStyle.module.css';

const CourseEnrollmentNoCompletionForm = ({ formState, errorState, onFormValueChanged, onValueTextPairChanged, handleSubmit, onBackClicked }) => (
  <form onSubmit={handleSubmit} noValidate>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <LmsAdminCoursesDropdown
          label='Course*'
          name='lmsCourseId'
          value={formState.lmsCourseId}
          error={errorState.lmsCourseId}
          message={errorState.lmsCourseId}
          onChange={(newValue, newValueLabel, e) => {
            onValueTextPairChanged(newValue, 'lmsCourseId', newValueLabel, 'lmsCourseName');
          }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker
          label="Enrollment Date*"
          name="courseEnrollmentDate"
          value={formState.courseEnrollmentDate}
          error={errorState.courseEnrollmentDate}
          message={errorState.courseEnrollmentDate}
          onChange={(value) => { onFormValueChanged('courseEnrollmentDate', value); }}
          countOfYears={150}
          startYearOffset={-145} />
      </div>

      {/* <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="External Css Course Id"
          name="externalCssCourseId"
          value={formState.externalCssCourseId}
          error={errorState.externalCssCourseId}
          message={errorState.externalCssCourseId}
          onChange={(value) => { onFormValueChanged('externalCssCourseId', value); }} />
      </div> */}
    </div>
    {errorState.errorOnSubmitAction &&
      <div className="row">
        <div className="col-xs-12">
          <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
        </div>
      </div>
    }
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
        <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
      </div>
    </div>
  </form>
);

export default CourseEnrollmentNoCompletionForm;