import React, { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const SmallGridRow = ({ person, onEdit }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{person.firstName || ''} {person.lastName}</span>&nbsp;
      <button
        className={global.IconButton}
        type="button"
        onClick={(e) => onEdit(person.personId)}>
        <EditIcon />
      </button>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{person.firstName || <span>&nbsp;</span>} {person.lastName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Member Id</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{person.memberId || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Birth Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{person.birthDate ? formatDate(person.birthDate) : <span>&nbsp;</span>}</div>
      </div>
    </div>
  </div>
);

const CourseEnrollmentsSearchGridSmall = ({ data, isLoading, onEdit }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
      {isLoading === true
        ? (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>Loading...</div>
            </div>
          </div>
        ) : Array.isArray(data) && data.length > 0
          ? data.map((person, i) => <SmallGridRow key={i} person={person} onEdit={onEdit} />)
          : (
            <div className={global.SmallTableRow}>
              <div className={global.SmallTableRowHead}>&nbsp;</div>
              <div className={global.SmallTableRowInfo}>
                <div className={global.SmallTableRowLabels}>No Results</div>
              </div>
            </div>
          )
      }
    </div>
  </Fragment>
);

export default CourseEnrollmentsSearchGridSmall;