import { Fragment } from "react";

import useAccountManagementEmail from "./UseAccountManagementEmail";

import EmailForm from "../components/forms/EmailForm";
import EmailVerificationPopupForm from "../components/forms/EmailVerificationPopupForm";
import ValidationSuccessErrorPopup from "../components/dialogs/ValidationSuccessErrorPopup";

import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";

import Constants from "../../../../common/utils/Constants";

const AccountManagementEmail = () => {
  const {
    isLoading,
    isSaving,
    state,
    emailObj,
    saveStateData,
    resetSaveState,
    onSubmitFormCallback,
    onSubmitVerificationFormCallback,
    onCloseEmailVerificationPopup
  } = useAccountManagementEmail();

  return (
    <Fragment>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12'>
          <Headings.H3>Email</Headings.H3>
        </div>
      </div>
      <EmailForm
        emailObj={emailObj}
        onSubmitFormCallback={onSubmitFormCallback} />
      {state.displayEmailVerificationPopup === true
        ? (
          <EmailVerificationPopupForm
            emailAddress={state.newEmailAddress}
            errorMessage={saveStateData?.errorMessage || ''}
            onSubmitFormCallback={onSubmitVerificationFormCallback}
            onCancelClicked={onCloseEmailVerificationPopup} />
        ) : (
          <ValidationSuccessErrorPopup
            displayPopup={saveStateData?.success === false}
            errorMessage={saveStateData?.errorMessage || ''}
            onCancelClicked={resetSaveState} />
        )
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default AccountManagementEmail;