import { Fragment } from 'react';

import useAccountManagementSearch from './UseAccountManagementSearch';

import UserAccountSearchForm from '../components/forms/UserAccountSearchForm';
import UserAccountSearchGrid from '../components/grids/UserAccountSearchGrid';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';

import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const AccountManagementSearch = () => {
  const {
    isSaving,
    isLoading,
    searchFilterObject,
    showFilters,
    sortBy,
    gridData,
    state,
    onSubmitFormCallback,
    toggleAccountManagementFilters,
    onEditUserAccountClicked,
    onDeleteAccountClicked,
    onDeleteAccountModalConfirmClicked,
    onDeleteAccountModalCancelClicked,
    onCloseErrorPopupClicked
  } = useAccountManagementSearch();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Manage User Accounts</Headings.H3>
        </div>
      </div>
      <div className={showFilters === true ? global.DisplayComponent : global.HideComponent}>
        <UserAccountSearchForm
          onSubmitFormCallback={onSubmitFormCallback}
          searchFilterObject={searchFilterObject}
          sortBy={sortBy} />
      </div>
      <UserAccountSearchGrid
        isLoading={isLoading}
        showFilters={showFilters}
        gridData={gridData}
        onEditClicked={onEditUserAccountClicked}
        onDeleteClicked={onDeleteAccountClicked}
        onFiltersToggle={toggleAccountManagementFilters} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete User Account'}
        displayPopUp={state.displayDeletePopUp === true}
        onModalCanceled={onDeleteAccountModalCancelClicked}>
        <div className='row'>
          <div className="col-xs-12 usas-extra-top-margin">
            <p><strong>Member Name:</strong>&nbsp;{state.userAccountToDelete?.firstName || ''} {state.userAccountToDelete?.preferredName ? `"${state.userAccountToDelete?.preferredName}"` : ''} {state.userAccountToDelete?.middleName || ''} {state.userAccountToDelete?.lastName || ''}</p>
            <p><strong>User Account Email:</strong>&nbsp;{state.userAccountToDelete?.email || ''}</p>
            <p><strong>Member Email:</strong>&nbsp;{state.userAccountToDelete?.personEmail || ''}</p>
            <p><strong>Username:</strong>&nbsp;{state.userAccountToDelete?.userName || ''}</p>
            <p><strong>Member Id:</strong>&nbsp;{state.userAccountToDelete?.memberId || ''}</p>
            <p><strong>Css User Id:</strong>&nbsp;{state.userAccountToDelete?.cssUserId || ''}</p>
            <p>Are you sure you want to delete this user account?</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteAccountModalConfirmClicked}>Yes, Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteAccountModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Deletion Error'}
        displayPopUp={state.apiErrorMessage !== ''}
        onModalCanceled={onCloseErrorPopupClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{state.apiErrorMessage}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onCloseErrorPopupClicked}>Close</PrimaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={90}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default AccountManagementSearch;