import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import CourseEnrollmentsContextView from './CourseEnrollmentsContextView';

import CourseEnrollmentsSearch from './courseEnrollmentsSearch/CourseEnrollmentsSearch';
import CourseEnrollmentsManage from './courseEnrollmentsManage/CourseEnrollmentsManage';
import CourseEnrollmentsManageDetail from './courseEnrollmentsManage/CourseEnrollmentsManageDetail';
import CourseEnrollmentsManageNoCompletion from './courseEnrollmentsManage/CourseEnrollmentsManageNoCompletion';

import CourseEnrollmentsContextProvider from '../../state/courseEnrollments/CourseEnrollmentsContextProvider';
import CourseEnrollmentsFilterContextProvider from '../../state/courseEnrollments/CourseEnrollmentsFiltersContextProvider';
import LmsAdminCoursesContextProvider from '../../state/courseEnrollments/lmsAdminCourses/LmsAdminCoursesContextProvider';

import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';

const CourseEnrollmentsContextRoutes = ({ navRoutes }) => (
  <CourseEnrollmentsContextView>
    <Routes>
      <Route path={navRoutes.COURSE_ENROLLMENTS_MANAGE?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.COURSE_ENROLLMENTS_MANAGE}
            editElement={<CourseEnrollmentsManage />} />
        }
      />
      <Route path={navRoutes.COURSE_ENROLLMENTS_MANAGE_DETAIL?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.COURSE_ENROLLMENTS_MANAGE_DETAIL}
            editElement={<CourseEnrollmentsManageDetail />} />
        }
      />
      <Route path={navRoutes.COURSE_ENROLLMENTS_MANAGE_ADDNOCOMPLETION?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.COURSE_ENROLLMENTS_MANAGE_ADDNOCOMPLETION}
            editElement={<CourseEnrollmentsManageNoCompletion />} />
        }
      />
    </Routes>
  </CourseEnrollmentsContextView>
);

const CourseEnrollmentsRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes.COURSE_ENROLLMENTS_SEARCH?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.COURSE_ENROLLMENTS_SEARCH}
            editElement={<CourseEnrollmentsSearch />} />
        }
      />

      <Route path={'/*'} element={<CourseEnrollmentsContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes.COURSE_ENROLLMENTS_SEARCH?.route} replace />} />
    </Routes>
  );
};

const CourseEnrollments = () => (
  <CourseEnrollmentsContextProvider>
    <CourseEnrollmentsFilterContextProvider>
      <LmsAdminCoursesContextProvider>
        <CourseEnrollmentsRoutes />
      </LmsAdminCoursesContextProvider>
    </CourseEnrollmentsFilterContextProvider>
  </CourseEnrollmentsContextProvider>
);

export default CourseEnrollments;