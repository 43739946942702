import React from 'react';

import useCardButton from './UseCardButton';

import style from './CardButton.module.css';

const CardButton = ({ card }) => {
  const { onClick } = useCardButton(card);

  return (
    <button className={style.CardButton} onClick={(e) => onClick(e, card)}>
      <span className={style.CardName}>{card.name}</span>
      <span className={style.CardRole}>
        <span className={card.status === 'Good Standing' ? style.CardStatusGood : card.status === 'Not In Good Standing' ? style.CardStatusBad : style.CardStatusCalculating}>{card.status}</span>
      </span>
    </button>
  );
};

export default CardButton;