import { Fragment } from "react";

import useMFAForm from "./UseMFAForm";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import YesNoSwitch from "../../../../../common/components/yesNoSwitch/YesNoSwitch";
import InternationalPhoneInput from "../../../../../common/components/inputs/InternationalPhoneInput";

import global from '../../../../../common/components/GlobalStyle.module.css';

const MFAForm = ({ submitButtonText = 'Save', canToggleMFA = false, emailAddress = '', mfaObj, onSubmitFormCallback }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    resetForm,
    onMFAChanged,
    onMFAOptionChanged,
    onMFAPhoneNumberChanged
  } = useMFAForm(onSubmitFormCallback, mfaObj);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        {canToggleMFA !== true && formState.isMFAEnabled === true && (
          <div className="col-xs-12">
            <p>LSC and HQ users are required to have MFA enabled*</p>
          </div>
        )}
        <div className="col-xs-12 col-sm-6 col-md-4">
          <YesNoSwitch
            label='Enable Multi-Factor Authentication?*'
            name="isMFAEnabled"
            checked={formState.isMFAEnabled}
            error={errorState.isMFAEnabled}
            message={errorState.isMFAEnabled}
            disabled={canToggleMFA !== true && formState.isMFAEnabled === true}
            onChange={(value) => { onMFAChanged(value); }} />
        </div>
      </div>
      {formState.isMFAEnabled === true && (
        <Fragment>
          <div className="row">
            <div className={[global.CenterText, "col-xs-12 col-md-6 usas-extra-top-margin"]}>
              Please choose how you would like to receive the codes.
              <br /><br />
              <div style={{ backgroundColor: formState.useEmail === true ? '#F3F3F3' : '#ffffff', border: 'solid 1px #F3F3F3', borderRadius: 5, padding: 10 }}>
                <div style={{ float: 'left' }}>
                  <input checked={formState.useEmail === true} id="device-email" name="device" onChange={() => { onMFAOptionChanged(true, false); }} type="radio" value="useEmail"></input>
                </div>
                <div onClick={() => { onMFAOptionChanged(true, false); }} style={{ cursor: 'default', float: 'left' }}>
                  &nbsp;Email: {emailAddress}
                </div>
                <div style={{ clear: 'both' }}></div>
              </div>
              <br />
              <div style={{ backgroundColor: formState.useTextMessage === true ? '#F3F3F3' : '#ffffff', border: 'solid 1px #F3F3F3', borderRadius: 5, padding: 10 }}>
                <div style={{ float: 'left' }}>
                  <input checked={formState.useTextMessage === true} name="device" onChange={() => { onMFAOptionChanged(false, true); }} type="radio" value="useTextMessage"></input>
                </div>
                <div onClick={() => { onMFAOptionChanged(false, true); }} style={{ cursor: 'default', float: 'left' }}>
                  &nbsp;Text Message:
                </div>
                <div style={{ clear: 'both' }}>
                  <InternationalPhoneInput
                    name={'phoneNumber'}
                    value={formState.phoneNumber}
                    error={errorState.phoneNumber}
                    message={errorState.phoneNumber}
                    onChange={(value) => { onMFAPhoneNumberChanged(value); }} />
                </div>
              </div>
            </div>
          </div>
          {(errorState.mfaOptions) &&
            <div className="row">
              <div className="col-xs-12">
                <p className={global.ErrorMessage}>{errorState.mfaOptions}</p>
              </div>
            </div>
          }
        </Fragment>
      )}
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default MFAForm;