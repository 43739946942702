import validate from './UserGroupAddFormValidation';

import UseForm from "../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  userGroup: []
};

const useUserGroupAddForm = (onSubmitFormCallback) => {
  const { formState, errorState, handleSubmit, updateFormState
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  return {
    formState,
    errorState,
    handleSubmit,
    updateFormState
  };
};

export default useUserGroupAddForm;