import { useEffect } from "react";

import validate from './CourseEnrollmentsSearchFormValidation';

import useCourseEnrollmentsData from "../../../../state/courseEnrollments/UseCourseEnrollmentsData";

import UseForm from "../../../../../common/utils/UseForm";
import Constants from "../../../../../common/utils/Constants";

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  lmsCourseId: Constants.DEFAULT_ID,
  lmsCourseName: '',
  memberId: ''
};

const useCourseEnrollmentsSearchForm = (submitFormCallback) => {
  const { courseEnrollmentsFilterState } = useCourseEnrollmentsData();
  const { formState, errorState, setFormData, updateFormState, onValueTextPairChanged, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const clearForm = () => {
    setFormData({
      ...INITIAL_FORM_STATE
    }, true);
  };

  useEffect(() => {
    setFormData({
      ...formState,
      ...courseEnrollmentsFilterState.filterObject
    }, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    clearForm
  };
};

export default useCourseEnrollmentsSearchForm;