import { useEffect } from "react";

import validate from './UserAccountSearchFormValidation';

import UseForm from "../../../../../common/utils/UseForm";

const sortByOptions = [
  { id: 1, name: "First Name", value: "firstName desc" },
  { id: 2, name: "Last Name", value: "lastName desc" },
  { id: 3, name: "Username", value: 'userName' },
  { id: 4, name: "Email", value: 'email' },
  { id: 5, name: "Member Id", value: 'memberId' }
];

const INITIAL_FORM_STATE = {
  userName: '',
  memberId: '',
  email: '',
  firstName: '',
  lastName: '',
  sortById: sortByOptions[0].id,
  sortByName: sortByOptions[0].name
};

const useUserAccountSearchForm = (onSubmitFormCallback, searchFilterObject, sortBy) => {
  const { formState, errorState, handleSubmit, updateFormState, onValueTextPairChanged, setFormState, setErrors
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  const onClearFormClicked = () => {
    setFormState({ ...INITIAL_FORM_STATE });
    setErrors({});
  };

  useEffect(() => {
    let newFormState = {
      userName: searchFilterObject.userName|| '',
      memberId: searchFilterObject.memberId || '',
      email: searchFilterObject.email || '',
      firstName: searchFilterObject.firstName || '',
      lastName: searchFilterObject.lastName || '',
      sortById: sortByOptions.find(x => x.value === sortBy)?.id || sortByOptions[0].id,
      sortByName: sortByOptions.find(x => x.value === sortBy)?.name || sortByOptions[0].name
    };

    const validatePromise = validate(newFormState);

    validatePromise.then((errors) => {
      // if no errors and filterObject had values, do search
      if (Object.keys(errors).length === 0 && Object.keys(searchFilterObject).length > 1) {
        onSubmitFormCallback(newFormState);
      }

      setFormState({
        ...formState,
        ...newFormState
      });
    }).catch((e) => {
      // local error
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onClearFormClicked,
    sortByOptions
  };
};

export default useUserAccountSearchForm;