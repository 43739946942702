import { useState } from 'react';

import AccountCreationLoginData from './AccountCreationLoginData';

import { BASIC_INITIAL_STATE } from '../../../../../common/utils/HttpHelper';

const INITIAL_ACCOUNT_CREATION_LOGIN_STATE = {
  ...BASIC_INITIAL_STATE,
  isServerSideLogin: false,
  isClientSideLogin: false
};

const useAccountCreationLoginData = () => {
  const [accountCreationLoginState, setAccountCreationLoginState] = useState(INITIAL_ACCOUNT_CREATION_LOGIN_STATE);

  const postServerSideLogin = (username, password) => {
    return AccountCreationLoginData.postServerSideLoginData(username, password, accountCreationLoginState, setAccountCreationLoginState);
  };

  const postClientSideLogin = (username, password, environmentId, flowId) => {
    return AccountCreationLoginData.postClientSideLoginData(username, password, environmentId, flowId, accountCreationLoginState, setAccountCreationLoginState);
  };

  const resetAccountCreationLoginState = () => {
    setAccountCreationLoginState(INITIAL_ACCOUNT_CREATION_LOGIN_STATE);
  };

  return {
    accountCreationLoginState,
    resetAccountCreationLoginState,
    postServerSideLogin,
    postClientSideLogin
  };
};

export default useAccountCreationLoginData;