import { useEffect } from 'react';
import useSystemMessageData from '../../state/systemMessages/UseSystemMessageData';

const useMySystemMessage = (app) => {
  const { systemMessageState, getSystemMessages } = useSystemMessageData();

  useEffect(() => {
    if (systemMessageState?.isArrayLoaded !== true
      && systemMessageState?.isArrayLoading !== true) {

      getSystemMessages(app);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemMessageState]);

  return {
    systemMessageState
  };
};

export default useMySystemMessage;