import { useState } from 'react';

import validate from './PasswordAdminFormValidation';

import UseForm from "../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  newPassword: '',
  confirmPassword: ''
};

const INITIAL_REQUIREMENTS_STATE = {
  password10Characters: false,
  passwordNumber: false,
  passwordSpecialCharacter: false,
  passwordLowercaseCharacter: false,
  passwordUppercaseCharacter: false,
  passwordNotEqualToUsername: false
};

const INITIAL_HIDE_PASSWORD_STATE = {
  hideCurrentPassword: true,
  hideNewPassword: true,
  hideConfirmPassword: true
};

// eslint-disable-next-line no-useless-escape
const includesSpecialCharacterRegex = /[!"#$%&'()*+,-.\/:;<=>?@[\]\\^_`{|}~]/;
const includesNumberRegex = /[0-9]/;
const includesLowerCaseRegex = /[a-z]/;
const includesUpperCaseRegex = /[A-Z]/;

const usePasswordAdminForm = (onSubmitFormCallback, userName) => {
  const { formState, errorState, handleSubmit, updateFormState, setFormState, setErrors
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);
  const [requirementsState, setRequirementsState] = useState(INITIAL_REQUIREMENTS_STATE);
  const [enableSaveButtonState, setEnableSaveButtonState] = useState(false);
  const [hidePasswordState, setHidePasswordState] = useState(INITIAL_HIDE_PASSWORD_STATE);

  const onNewPasswordChanged = (value) => {
    updateRequirementState(value, userName);
    updateFormState('newPassword', value);
  };

  const onToggleHideShowPassword = (fieldName) => {
    setHidePasswordState({
      ...hidePasswordState,
      [fieldName]: !hidePasswordState[fieldName]
    })
  };

  const onClearFormClicked = () => {
    setFormState({ ...INITIAL_FORM_STATE });
    setRequirementsState({...INITIAL_REQUIREMENTS_STATE});
    setEnableSaveButtonState(false);
    setErrors({});
  };

  function updateRequirementState(password, userName) {
    let newEnableSaveButtonState = true;
    let newRequirementsState = { ...requirementsState };

    // userName
    if (!password || userName === password || password.includes(' ')) {
      newRequirementsState.passwordNotEqualToUsername = false;
      newEnableSaveButtonState = false;
    } else {
      newRequirementsState.passwordNotEqualToUsername = true;
    }

    // length
    if (password.length < 10) {
      newRequirementsState.password10Characters = false;
      newEnableSaveButtonState = false;
    } else {
      newRequirementsState.password10Characters = true;
    }

    // number
    if (includesNumberRegex.test(password) !== true) {
      newRequirementsState.passwordNumber = false;
      newEnableSaveButtonState = false;
    } else {
      newRequirementsState.passwordNumber = true;
    }

    // lower case
    if (includesLowerCaseRegex.test(password) !== true) {
      newRequirementsState.passwordLowercaseCharacter = false;
      newEnableSaveButtonState = false;
    } else {
      newRequirementsState.passwordLowercaseCharacter = true;
    }

    // upper case
    if (includesUpperCaseRegex.test(password) !== true) {
      newRequirementsState.passwordUppercaseCharacter = false;
      newEnableSaveButtonState = false;
    } else {
      newRequirementsState.passwordUppercaseCharacter = true;
    }

    // special chars
    if (includesSpecialCharacterRegex.test(password) !== true) {
      newRequirementsState.passwordSpecialCharacter = false;
      newEnableSaveButtonState = false;
    } else {
      newRequirementsState.passwordSpecialCharacter = true;
    }

    setEnableSaveButtonState(newEnableSaveButtonState);
    setRequirementsState(newRequirementsState);
  };

  return {
    formState,
    errorState,
    requirementsState,
    enableSaveButtonState,
    hidePasswordState,
    handleSubmit,
    updateFormState,
    onToggleHideShowPassword,
    onNewPasswordChanged,
    onClearFormClicked
  };
};

export default usePasswordAdminForm;