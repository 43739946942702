import validate from './EmailVerificationPopupFormValidation';

import UseForm from "../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  verificationCodeFromUser: ''
};

const useEmailVerificationPopupForm = (onSubmitFormCallback) => {
  const { formState, errorState, updateFormState, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  return {
    formState,
    errorState,
    updateFormState,
    handleSubmit
  };
};

export default useEmailVerificationPopupForm;