import React from 'react';

import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import style from './../../LandingPages.module.css';

const Message = ({ message }) => {
  const onClick = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    message.onClick();
  };

  return (
    <tr>
      <td className={message.isNew === true ? style.FirstColumn : ''}>{message.subject}</td>
      <td style={{ padding: "0px 10px" }}>{message.dateSent}</td>
      <td className={style.LastColumn}><ActionIntraPageButton onClick={onClick} className={style.ActionButton}>View</ActionIntraPageButton></td>
    </tr>
  );
};

export default Message;