import { useEffect } from "react";

import validate from './ConfirmAgeFormValidation';

import UseForm from "../../../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  isOver16: ''
};

const useConfirmAgeForm = (onSubmitFormCallback, confirmAgeData) => {
  const { formState, errorState, isDirty, handleSubmit, updateFormState, setIsDirty, setFormData
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    if (confirmAgeData?.isOver16 === true || confirmAgeData?.isOver16 === false) {
      setFormData({
        ...formState,
        isOver16: confirmAgeData.isOver16
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDirty !== true) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
  };
};

export default useConfirmAgeForm;