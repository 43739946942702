import { useEffect } from "react";

import useAccountManagementData from "../../../state/accountManagement/UseAccountManagementData";
import useAccountManagementLMSUserData from "../../../state/accountManagement/accountManagementLMSUser/UseAccountManagementLMSUserData";

const useAccountManagementCSS = () => {
  const { accountManagementState } = useAccountManagementData();
  const { accountManagementLMSUserState, setAccountManagementLMSUserState, getLMSUser, putLMSUser, postLMSUser
  } = useAccountManagementLMSUserData();

  useEffect(() => {
    if (accountManagementLMSUserState.isObjLoaded !== true) {
      const personId = accountManagementState.objData.personId;

      const getLMSUserPromise = getLMSUser(personId);

      if (getLMSUserPromise ?? false) {
        getLMSUserPromise.catch((e) => {
          //context error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountManagementLMSUserState.isObjLoaded]);

  const onSubmitFormCallback = (formState) => {
    if (accountManagementLMSUserState.objData?.lmsUserAccountId > 0) {
      const userAccountObj = accountManagementLMSUserState.objData;
      const newUserAccountObj = {
        lmsUserAccountId: userAccountObj.lmsUserAccountId,
        swimsUserAccountId: userAccountObj.swimsUserAccountId,
        lmsUserId: userAccountObj.lmsUserId,
        cssUserId: formState.cssUserId.trim(),
        cssUserHasAcceptedTAndC: userAccountObj.cssUserHasAcceptedTAndC
      };
      const putLMSUserPromise = putLMSUser(userAccountObj.lmsUserAccountId, newUserAccountObj);

      if (putLMSUserPromise ?? false) {
        putLMSUserPromise.then((newState) => {
          if (newState ?? false) {
            setAccountManagementLMSUserState({
              ...newState,
              isSaving: false
            });
          }
        }).catch((e) => {
          //context error
        });
      }
    } else {
      const personId = accountManagementState.objData?.personId;
      const userAccountCssObj = {
        cssUserId: formState.cssUserId.trim(),
        cssUserHasAcceptedTAndC: false
      };
      const postLMSUserPromise = postLMSUser(personId, userAccountCssObj);

      if (postLMSUserPromise ?? false) {
        postLMSUserPromise.then((newState) => {
          if (newState ?? false) {
            setAccountManagementLMSUserState({
              ...newState,
              isSaving: false
            });
          }
        }).catch((e) => {
          //context error
        });
      }
    }
  };

  return {
    isLoading: accountManagementLMSUserState.isObjLoading,
    isSaving: accountManagementLMSUserState.isSaving,
    userAccountObj: accountManagementLMSUserState.objData,
    onSubmitFormCallback
  };
};

export default useAccountManagementCSS;