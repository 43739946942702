import useUserAccountSearchForm from "./UseUserAccountSearchForm";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import Dropdown from "../../../../../common/components/dropdowns/Dropdown";
import Input from "../../../../../common/components/inputs/Input";

import global from '../../../../../common/components/GlobalStyle.module.css';

const UserAccountSearchForm = ({ onSubmitFormCallback, searchFilterObject, sortBy }) => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onClearFormClicked,
    sortByOptions
  } = useUserAccountSearchForm(onSubmitFormCallback, searchFilterObject, sortBy);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Legal First Name or Preferred Name"
            name="firstName"
            value={formState.firstName}
            error={errorState.firstName}
            message={errorState.firstName}
            onChange={(value) => { onFormValueChanged('firstName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Legal Last Name"
            name="lastName"
            value={formState.lastName}
            error={errorState.lastName}
            message={errorState.lastName}
            onChange={(value) => { onFormValueChanged('lastName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Email (User Account or Member)"
            name="email"
            value={formState.email}
            error={errorState.email}
            message={errorState.email}
            onChange={(value) => { onFormValueChanged('email', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Member Id"
            name="memberId"
            value={formState.memberId}
            error={errorState.memberId}
            message={errorState.memberId}
            onChange={(value) => { onFormValueChanged('memberId', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Username"
            name="userName"
            value={formState.userName}
            error={errorState.userName}
            message={errorState.userName}
            onChange={(value) => { onFormValueChanged('userName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Dropdown
            label="Sort Results By"
            name="sortById"
            options={sortByOptions}
            value={formState.sortById}
            error={errorState.sortById}
            message={errorState.sortById}
            isLoading={false}
            onChange={(newValue, newValueLabel, e) => {
              e && e.target && e.target.value &&
                onValueTextPairChanged(newValue, 'sortById', newValueLabel, 'sortByName');
            }} />
        </div>
      </div>
      <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
        <div className='col-xs-12'>
          {errorState?.filter && <p className={global.ErrorMessage}>{errorState.filter}</p>}
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">Search for User</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onClearFormClicked}>Clear Form</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default UserAccountSearchForm;