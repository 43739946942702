export const localValidate = (formState) => {
  let errors = {};

  if (formState.memberId.trim() === '') {
    errors.memberId = 'Please enter a Member ID';
  }

  return errors;
};

const ManageCartsValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default ManageCartsValidation;