import { useContext, useState } from 'react';

import AccountManagementSecurityRolesData from './AccountManagementSecurityRolesData';

import { AccountManagementSecurityRolesStateContext } from './AccountManagementSecurityRolesContextProvider';

import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

const INITIAL_ACCOUNT_MANAGEMENT_SECURITY_ROLES_SAVE_STATE = {
  ...BASIC_INITIAL_STATE
};

const useAccountManagementSecurityRolesData = () => {
  const [accountManagementSecurityRolesState, setAccountManagementSecurityRolesState] = useContext(AccountManagementSecurityRolesStateContext);
  const [accountManagementSecurityRolesSaveState, setAccountManagementSecurityRolesSaveState] = useState(INITIAL_ACCOUNT_MANAGEMENT_SECURITY_ROLES_SAVE_STATE);

  const postAccountManagementSecurityRole = (userAccountId, securityRoleObj) => {
    return AccountManagementSecurityRolesData.postAccountManagementSecurityRoleData(userAccountId, securityRoleObj, accountManagementSecurityRolesSaveState, setAccountManagementSecurityRolesSaveState);
  };

  const getAccountManagementSecurityRoles = (userAccountId) => {
    return AccountManagementSecurityRolesData.getAccountManagementSecurityRolesData(userAccountId, accountManagementSecurityRolesState, setAccountManagementSecurityRolesState);
  };

  const putAccountManagementSecurityRole = (userAccountId, personOrgRoleDurationId, securityRoleObj) => {
    return AccountManagementSecurityRolesData.putAccountManagementSecurityRoleData(userAccountId, personOrgRoleDurationId, securityRoleObj, accountManagementSecurityRolesSaveState, setAccountManagementSecurityRolesSaveState);
  };

  const deleteAccountManagementSecurityRole = (userAccountGroupId) => {
    return AccountManagementSecurityRolesData.deleteAccountManagementSecurityRoleData(userAccountGroupId, accountManagementSecurityRolesSaveState, setAccountManagementSecurityRolesSaveState);
  };

  const resetSecurityRolesSaveState = () => {
    setAccountManagementSecurityRolesSaveState({
      ...INITIAL_ACCOUNT_MANAGEMENT_SECURITY_ROLES_SAVE_STATE
    });
  };

  return {
    accountManagementSecurityRolesState,
    accountManagementSecurityRolesSaveState,
    resetSecurityRolesSaveState,
    postAccountManagementSecurityRole,
    getAccountManagementSecurityRoles,
    putAccountManagementSecurityRole,
    deleteAccountManagementSecurityRole
  };
};

export default useAccountManagementSecurityRolesData;