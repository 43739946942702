import { useContext } from 'react';

import PaymentsByCheckData from './PaymentsByCheckData';
import { PaymentsByCheckStateContext } from './PaymentsByCheckContextProvider';

const usePaymentsByCheckData = () => {
  const [paymentsByCheckState, setPaymentsByCheckState] = useContext(PaymentsByCheckStateContext);

  const getPaymentsByCheck = () => {
    return PaymentsByCheckData.getPaymentsByCheckData(paymentsByCheckState, setPaymentsByCheckState);
  };

  const postPaymentByCheck = (paymentByCheckObj) => {
    return PaymentsByCheckData.postPaymentByCheckData(paymentByCheckObj, paymentsByCheckState, setPaymentsByCheckState);
  };

  const confirmPaymentByCheckSave = () => {
    setPaymentsByCheckState({
      ...paymentsByCheckState,
      isSaved: false
    });
  };

  return {
    paymentsByCheckState,
    setPaymentsByCheckState,
    getPaymentsByCheck,
    postPaymentByCheck,
    confirmPaymentByCheckSave
  };
};

export default usePaymentsByCheckData;