import { LmsHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const PutCourseEnrollmentData = (courseEnrollmentId, courseEnrollmentObj, state, setState) => {
  if (state.isSaving !== true && !state.message) {
    let newState = {
      ...state,
      isSaving: true,
      isSaved: false,
      message: 'Saving...'
    };
    setState(newState);

    const courseEnrollmentIdForUrl = courseEnrollmentId ? encodeURIComponent(courseEnrollmentId) : 'NaN';
    const url = `/CourseEnrollments/courseenrollment/${courseEnrollmentIdForUrl}/dates`;
    LmsHttpHelper(url, 'PUT', courseEnrollmentObj)
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isObjLoaded: true,
            isSaving: false,
            isSaved: true,
            objData,
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => { 
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isObjLoaded: false,
          isSaving: false,
          isSaved: false,
          message: message
        });
      });
  }
};

export default PutCourseEnrollmentData;