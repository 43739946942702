import React, { createContext, useState } from 'react';

import LmsAdminCoursesData from './LmsAdminCoursesData';

export const LmsAdminCoursesStateContext = createContext();

const LmsAdminCoursesContextProvider = ({ children }) => {
  const stateHook = useState(LmsAdminCoursesData.INITIAL_STATE);

  return (
    <LmsAdminCoursesStateContext.Provider value={stateHook}>
      {children}
    </LmsAdminCoursesStateContext.Provider>
  );
};

export default LmsAdminCoursesContextProvider;