import DeleteIcon from '../../../common/components/icons/DeleteIcon';

import global from '../../../common/components/GlobalStyle.module.css';

const GridRow = ({ cartItem, onDelete }) => (
  <tr>
    <td>{cartItem.itemDescription}</td>
    <td>${cartItem.itemPrice/100}</td>
    <td>
        {onDelete &&
          <button className={global.IconButtonMargin}
            type="button"
            onClick={() => onDelete(cartItem.personId, cartItem.deletionAction)}>
            <DeleteIcon />
          </button>
        }
      </td>
  </tr>
);

const ManageCartsGridLarge = ({ data, isLoading, onDelete }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Shopping Cart Item Description</th>
        <th>Item Price</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? (
          <tr>
            <td colSpan="3">Loading...</td>
          </tr>
        ) : Array.isArray(data) && data.length > 0
          ? data.map((cartItem, i) => (
            <GridRow
              key={i}
              cartItem={cartItem}
              onDelete={onDelete} />
          )) : (
            <tr>
              <td colSpan="3">No Shopping Cart Items</td>
            </tr>
          )
      }
    </tbody>
  </table>
);

export default ManageCartsGridLarge;