import { Fragment } from "react";

import PersonDupGridLarge from "./PersonDupGridLarge";
import PersonDupGridSmall from "./PersonDupGridSmall";

const PersonDupGrid = ({ data, isLoading, onLinkPersonClicked }) => (
  <Fragment>
    <PersonDupGridLarge data={data} isLoading={isLoading} onLinkPersonClicked={onLinkPersonClicked} />
    <PersonDupGridSmall data={data} isLoading={isLoading} onLinkPersonClicked={onLinkPersonClicked} />
  </Fragment>
);

export default PersonDupGrid;