import { Fragment } from "react";

import useAddChildPopupForm from "./UseAddChildPopupForm";

import PopUpModal from "../../../../../common/components/dialogs/PopUpModal";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import Input from "../../../../../common/components/inputs/Input";

import Constants from "../../../../../common/utils/Constants";

import global from '../../../../../common/components/GlobalStyle.module.css';

const AddChildPopupForm = ({ onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    isLoading,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged
  } = useAddChildPopupForm(onSubmitFormCallback);

  return (
    <Fragment>
      <PopUpModal
        widthPct={90}
        maxWidth={400}
        title={'Add Family Member'}
        displayPopUp={isLoading !== true}
        onModalCanceled={onSecondaryButtonClicked} >
        <form onSubmit={handleSubmit} noValidate>
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12">
              <Input
                label="Member Id*"
                name="memberId"
                value={formState.memberId}
                error={errorState.memberId}
                message={errorState.memberId}
                onChange={(value) => { onFormValueChanged('memberId', value) }} />
            </div>
          </div>
          {errorState.apiError &&
            <div className="row">
              <div className="col-xs-12 usas-extra-top-margin">
                <p className={global.ErrorMessage}>{errorState.apiError}</p>
              </div>
            </div>
          }
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </form>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default AddChildPopupForm;