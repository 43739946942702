import { useState } from 'react';

import AccountCreationExpiredPasswordData from './AccountCreationExpiredPasswordData';

import { BASIC_INITIAL_STATE } from '../../../../../common/utils/HttpHelper';

const INITIAL_ACCOUNT_CREATION_ONE_TIME_PASSWORD_STATE = {
  ...BASIC_INITIAL_STATE,
  isServerSideLogin: false,
  isClientSideLogin: false
};

const useAccountCreationExpiredPasswordData = () => {
  const [accountCreationExpiredPasswordState, setAccountCreationExpiredPasswordState] = useState(INITIAL_ACCOUNT_CREATION_ONE_TIME_PASSWORD_STATE);

  const postServerSideResetPassword = (href, currentPassword, newPassword) => {
    return AccountCreationExpiredPasswordData.postServerSideResetPasswordData(href, currentPassword, newPassword, accountCreationExpiredPasswordState, setAccountCreationExpiredPasswordState);
  };

  const postClientSideResetPassword = (href, currentPassword, newPassword) => {
    return AccountCreationExpiredPasswordData.postClientSideResetPasswordData(href, currentPassword, newPassword, accountCreationExpiredPasswordState, setAccountCreationExpiredPasswordState);
  };

  const resetAccountCreationExpiredPasswordState = () => {
    setAccountCreationExpiredPasswordState(INITIAL_ACCOUNT_CREATION_ONE_TIME_PASSWORD_STATE);
  };

  return {
    accountCreationExpiredPasswordState,
    resetAccountCreationExpiredPasswordState,
    postServerSideResetPassword,
    postClientSideResetPassword
  };
};

export default useAccountCreationExpiredPasswordData;