import React, { Fragment } from 'react';

import UserGroupGridLarge from './UserGroupGridLarge';
import UserGroupGridSmall from './UserGroupGridSmall';

const UserGroupGrid = ({ data, isLoading, onDeleteClicked }) => (
  <Fragment>
    <UserGroupGridLarge
      data={data}
      isLoading={isLoading}
      onDeleteClicked={onDeleteClicked} />
    <UserGroupGridSmall
      data={data}
      isLoading={isLoading}
      onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default UserGroupGrid;