import { API_NAMES, initApi } from "../../../../../common/utils/HttpApiHelper";

const postForgotPasswordData = (username, state, setState) => {
  if (username) {
    const api = initApi(API_NAMES.SECURITY, state, setState);
    const url = `/Account/ForgotPassword`;

    return api?.executeObject ? api.executeObject(url, 'POST', { username }) : null;
  }
};

const AccountCreationForgotPasswordData = {
  postForgotPasswordData
};

export default AccountCreationForgotPasswordData;