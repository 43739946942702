import Constants from "../../../../../common/utils/Constants";
import { isValidDate } from "../../../../../common/utils/validation";

export const CourseEnrollmentNoCompletionFormValidation = (formState) => {
  let errors = {};

  if (formState.lmsCourseId < 0) {
    errors.lmsCourseId = 'Course is required';
  }

  if (formState.courseEnrollmentDate === Constants.BLANK_DATE_STRING || formState.courseEnrollmentDate === '') {
    errors.courseEnrollmentDate = 'Enrollment Date is required';
  } else if (!isValidDate(formState.courseEnrollmentDate)) {
    errors.courseEnrollmentDate = 'Enrollment Date must be a valid date';
  }

  return errors;
};

export default CourseEnrollmentNoCompletionFormValidation;