import { Fragment } from "react";

import usePersonDupPopupManager from "./UsePersonDupPopupManager";

import PersonDupGrid from "../grids/PersonDupGrid";

import {
  AC_DUP_MATCH_EMAIL, AC_DUP_MATCH_MEMBER_ID, AC_DUP_MATCH_MEMBER_ID_EMAIL, AC_DUP_MATCH_MEMBER_ID_EMAIL_NAME_DOB, AC_DUP_MATCH_NAME_DOB,
  AC_DUP_MATCH_NAME_DOB_EMAIL, AC_DUP_MATCH_NONE
} from "../../utils/AccountCreationConstants";

import PopUpModal from "../../../../../../../common/components/dialogs/PopUpModal";
import SecondaryButton from "../../../../../../../common/components/buttons/SecondaryButton";
import PrimaryButton from "../../../../../../../common/components/buttons/PrimaryButton";

import { formatDate } from "../../../../../../../common/utils/DateFunctions";

const PersonDupPopupManager = ({ isMemberIdDupCheck = false, dupCheckState, onContinueClicked, onCancelClicked, onReturnToLoginClicked, onLinkPersonClicked }) => {
  const {
    state
  } = usePersonDupPopupManager(dupCheckState, isMemberIdDupCheck, onContinueClicked);

  switch (state.matchType) {
    case AC_DUP_MATCH_MEMBER_ID_EMAIL_NAME_DOB:
      return (
        <PopUpModal
          widthPct={60}
          maxWidth={720}
          title={'Existing Account Found'}
          displayPopUp={true}>
          <div className="row">
            <div className="col-xs-12">
              <p>A person with the same Member Id, First Name, Last Name, Birth Date, and Email was found but they have already been linked to an existing account</p>
              <p><b>Existing Account Username:</b> {state.dupData?.username}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <PrimaryButton type={'button'} onClick={onReturnToLoginClicked}>Return To Login</PrimaryButton>&nbsp;
              <SecondaryButton type={'button'} onClick={onCancelClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
      );

    case AC_DUP_MATCH_MEMBER_ID_EMAIL:
      return (
        <PopUpModal
          widthPct={60}
          maxWidth={720}
          title={`Existing ${state.dupData?.isAlreadyLinked === true ? 'Account' : 'Person'} Found`}
          displayPopUp={true}>
          {state.dupData?.isAlreadyLinked === true
            ? (
              <div className="row">
                <div className="col-xs-12">
                  <p>A person with the same Member Id and Email was found but they have already been linked to an existing account</p>
                  <p><b>Existing Account Username:</b> {state.dupData?.username}</p>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-xs-12">
                  <p>A person with the same Member Id and Email was found but with a different First Name, Last Name, and or Birth Date</p>
                </div>
                <div className="col-xs-12">
                  <p><b>Existing Person First Name:</b> {state.dupData?.personInfo?.firstName}</p>
                  <p><b>Existing Person Last Name:</b> {state.dupData?.personInfo?.lastName}</p>
                  <p><b>Existing Person Birth Date:</b> {state.dupData?.personInfo?.birthDate ? formatDate(state.dupData.personInfo.birthDate) : ''}</p>
                </div>
                <div className="col-xs-12">
                  <p>To link to this person please make sure the values you entered match the values listed above</p>
                </div>
              </div>
            )
          }
          <div className="row">
            <div className="col-xs-12">
              <PrimaryButton type={'button'} onClick={onReturnToLoginClicked}>Return To Login</PrimaryButton>&nbsp;
              <SecondaryButton type={'button'} onClick={onCancelClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
      );

    case AC_DUP_MATCH_MEMBER_ID:
      return (
        <PopUpModal
          widthPct={60}
          maxWidth={720}
          title={`Existing ${state.dupData?.isAlreadyLinked === true ? 'Account' : 'Person'} Found`}
          displayPopUp={true}>
          {state.dupData?.isAlreadyLinked === true
            ? (
              <div className="row">
                <div className="col-xs-12">
                  <p>A person with the same Member Id was found but they have already been linked to an account</p>
                  <p><b>Existing Account Email:</b> {state.dupData?.personInfo?.censoredExistingAccountEmail}</p>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-xs-12">
                  <p>A person with the same Member Id was found but with a different First Name, Last Name, Birth Date, and or Email</p>
                </div>
                <div className="col-xs-12">
                  <p><b>Existing Person First Name:</b> {state.dupData?.personInfo?.firstName}</p>
                  <p><b>Existing Person Last Name:</b> {state.dupData?.personInfo?.lastName}</p>
                  <p><b>Existing Person Birth Date:</b> {state.dupData?.personInfo?.birthDate ? formatDate(state.dupData.personInfo.birthDate) : ''}</p>
                  <p><b>Existing Person Email:</b> {state.dupData?.personInfo?.personEmail}</p>
                </div>
                <div className="col-xs-12">
                  <p>To link to this person please make sure the values you entered match the values listed above</p>
                </div>
              </div>
            )
          }
          <div className="row">
            <div className="col-xs-12">
              <PrimaryButton type={'button'} onClick={onReturnToLoginClicked}>Return To Login</PrimaryButton>&nbsp;
              <SecondaryButton type={'button'} onClick={onCancelClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
      );

    case AC_DUP_MATCH_NONE:
      return (
        <PopUpModal
          widthPct={60}
          maxWidth={720}
          title={'No Person Found'}
          displayPopUp={true}>
          <div className="row">
            <div className="col-xs-12">
              <p>No person was found with the provided Member Id</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <PrimaryButton type={'button'} onClick={onReturnToLoginClicked}>Return To Login</PrimaryButton>&nbsp;
              <SecondaryButton type={'button'} onClick={onCancelClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
      );

    case AC_DUP_MATCH_NAME_DOB_EMAIL:
      return (
        <PopUpModal
          widthPct={60}
          maxWidth={720}
          title={'Existing Account Found'}
          displayPopUp={true}>
          <div className="row">
            <div className="col-xs-12">
              <p>A person with the same First Name, Last Name, Birth Date, and Email was found but they have already been linked to an existing account</p>
              <p><b>Existing Account Username:</b> {state.dupData?.username}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <PrimaryButton type={'button'} onClick={onReturnToLoginClicked}>Return To Login</PrimaryButton>&nbsp;
              <SecondaryButton type={'button'} onClick={onCancelClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
      );

    case AC_DUP_MATCH_EMAIL:
      return (
        <PopUpModal
          widthPct={60}
          maxWidth={720}
          title={'Existing Account Found'}
          displayPopUp={true}>
          <div className="row">
            <div className="col-xs-12">
              <p>An existing account with the same Email was found</p>
              <p><b>Existing Account Username:</b> {state.dupData?.username}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <PrimaryButton type={'button'} onClick={onReturnToLoginClicked}>Return To Login</PrimaryButton>&nbsp;
              <SecondaryButton type={'button'} onClick={onCancelClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
      );

    case AC_DUP_MATCH_NAME_DOB:
      return (
        <PopUpModal
          widthPct={90}
          maxWidth={1020}
          title={'Existing Person(s) Found'}
          displayPopUp={true}>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <p>At least one person with the same First Name, Last Name, and Birth Date already exists in our database.
                You can choose to link your account to an existing person or continue without linking.
              </p>
            </div>
          </div>
          <PersonDupGrid
            data={state.dupData}
            isLoading={false}
            onLinkPersonClicked={onLinkPersonClicked} />
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12">
              <PrimaryButton type={'button'} onClick={() => onContinueClicked()}>Continue Without Linking</PrimaryButton>&nbsp;
              <SecondaryButton type={'button'} onClick={onCancelClicked}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
      );

    default:
      return (
        <Fragment />
      );
  }
};

export default PersonDupPopupManager;