import { useContext } from 'react';

import { useNavigate } from '../../../common/wrappers/ReactRouterDom';
import { navigateToCrossUiRoute } from '../../../common/utils/FormatCrossUiRoute';

import useSecurityData from '../../../common/state/security/UseSecurityData';
import useChildrenData from './children/UseChildrenData';
import usePersonData from './person/UsePersonData';

import { LandingPageStateContext } from './LandingPageContextProvider';
import { formatDate } from '../../../common/utils/DateFunctions';
import { evaluateBirthDate } from '../../../common/utils/validation';

const useLandingPageData = () => {
  const [landingPageState, setLandingPageState] = useContext(LandingPageStateContext);
  const { securityState } = useSecurityData();
  const { personState } = usePersonData();
  const { childrenState } = useChildrenData();
  const navigate = useNavigate();

  const formatInitials = (objData) => {
    return `${(objData?.preferredName || objData?.firstName || ' ')[0]}${(objData?.lastName || ' ')[0]}`;
  };

  const formatName = (objData) => {
    return `${objData?.preferredName || objData?.firstName || ''} ${objData?.lastName}`;
  };

  const formatPersonas = (objData) => {
    if (Array.isArray(objData?.personas) === false) {
      return '';
    }

    return objData?.personas.map(p => p.personaName).join(', ');
  };

  const formatChildren = () => {
    const children = [];

    if (Array.isArray(childrenState.arrayData)) {
      childrenState.arrayData.forEach(child => {
        const ageInYears = evaluateBirthDate(child.birthDate)?.ageInYears;
        children.push({
          personId: child.personId,
          memberId: child.memberId,
          birthDate: formatDate(child.birthDate),
          isMinorEligible: (7 < ageInYears && ageInYears < 18 ? true : false),
          hasUserAccount: child.hasUserAccount,
          initials: formatInitials(child),
          name: formatName(child),
          profilePicUrl: child.profilePicUrl,
          personas: child.personas,
          formattedPersonas: formatPersonas(child),
          children: []
        });
      });
    }

    return children;
  };

  const createLandingPageState = () => {
    const loggedInPerson = {
      personId: personState.objData?.personId,
      memberId: personState.objData?.memberId,
      initials: formatInitials(personState.objData),
      name: formatName(personState.objData),
      profilePicUrl: securityState.userInfo?.profilePic,
      personas: securityState.userInfo?.personas || '',
      formattedPersonas: formatPersonas(securityState.userInfo),
      children: formatChildren()
    };

    const ageInYears = evaluateBirthDate(personState.objData?.birthDate)?.ageInYears;
    const isMinor = ageInYears < 18 ? true : false;

    const stateStatus = !securityState.userInfo?.memberId
      ? 'Anonymous'
      : Array.isArray(childrenState.arrayData) && childrenState.arrayData.length > 0
        ? 'Parent'
        :
        isMinor === true ?
          'MinorIndividual' : 'AdultIndividual';
    setLandingPageState({
      ...landingPageState,
      stateStatus,
      loggedInPerson,
      currentPerson: { ...loggedInPerson }
    });
  };

  const setChildAsCurrent = (personId) => {
    const currentPerson = landingPageState.loggedInPerson.children.find(x => x.personId === personId);
    if (currentPerson) {
      setLandingPageState({
        ...landingPageState,
        stateStatus: 'Child',
        currentPerson: { ...currentPerson }
      });
    }
  };

  const setLoggedInAsCurrent = () => {
    const ageInYears = evaluateBirthDate(landingPageState.loggedInPerson?.birthDate)?.ageInYears;
    const isMinor = ageInYears < 18 ? true : false;
    setLandingPageState({
      ...landingPageState,
      stateStatus: Array.isArray(childrenState.arrayData) && childrenState.arrayData.length > 0 ? 'Parent' : isMinor === true ? 'MinorIndividual' : 'AdultIndividual',
      currentPerson: { ...landingPageState.loggedInPerson }
    });
  };

  const setAnonymous = () => {
    setLandingPageState({
      ...landingPageState,
      stateStatus: 'Anonymous',
    });
  };

  const navigateToDeepRoute = (routeObj) => {
    if (routeObj.uIProjectName && routeObj.route) {
      navigateToCrossUiRoute(routeObj.uIProjectName, routeObj.route, navigate);
    }
  };

  const navigateToRedirectUrl = (routeObj) => {
    if (routeObj.uIProjectName && routeObj.redirectUrl) {
      navigateToCrossUiRoute(routeObj.uIProjectName, routeObj.redirectUrl, navigate);
    }
  };

  return {
    landingPageState,
    childrenState,
    createLandingPageState,
    setChildAsCurrent,
    setLoggedInAsCurrent,
    setAnonymous,
    navigateToDeepRoute,
    navigateToRedirectUrl
  };
};

export default useLandingPageData;