import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";

const postAccountManagementPasswordByUserAccountIdData = (userAccountId, passwordObj, state, setState) => {
  if (userAccountId > 0) {
    const api = initApi(API_NAMES.SECURITY, state, setState);
    const userAccountIdForUrl = encodeURIComponent(userAccountId);
    const url = `/UserAccounts/${userAccountIdForUrl}/Password`;

    return api?.executeObject ? api.executeObject(url, 'PUT', passwordObj) : null;
  }
};

const postAccountManagementPasswordData = (passwordObj, state, setState) => {
  const api = initApi(API_NAMES.SECURITY, state, setState);
  const url = `/UserAccounts/Password`;

  return api?.executeObject ? api.executeObject(url, 'PUT', passwordObj) : null;
};

const AccountManagementPasswordData = {
  postAccountManagementPasswordByUserAccountIdData,
  postAccountManagementPasswordData
};

export default AccountManagementPasswordData;