const NavLinks = {
  ACCOUNT_MANAGEMENT_LANDING: '/accountmanagement/landing',
  ACCOUNT_MANAGEMENT_SEARCH: '/accountmanagement/search',
  ACCOUNT_MANAGEMENT_USERNAME: '/accountmanagement/username',
  ACCOUNT_MANAGEMENT_EMAIL: '/accountmanagement/email',
  ACCOUNT_MANAGEMENT_PASSWORD: '/accountmanagement/password',
  ACCOUNT_MANAGEMENT_MFA: '/accountmanagement/mfa',
  ACCOUNT_MANAGEMENT_CSS: '/accountmanagement/css',
  ACCOUNT_MANAGEMENT_CHILDREN: '/accountmanagement/children',
  ACCOUNT_MANAGEMENT_USER_GROUP: '/accountmanagement/usergroup',
  ACCOUNT_MANAGEMENT_USER_GROUP_ADD: '/accountmanagement/usergroup/add',
  ACCOUNT_MANAGEMENT_SECURITY_ROLES: '/accountmanagement/securityroles',
  ACCOUNT_MANAGEMENT_SECURITY_ROLES_ADD: '/accountmanagement/securityroles/add',
  ACCOUNT_MANAGEMENT_SECURITY_ROLES_EDIT: '/accountmanagement/securityroles/edit',
  ACCOUNT_MANAGEMENT_DELETE: '/accountmanagement/delete'
};

export default NavLinks;