import React, { Fragment } from "react";

import CourseEnrollmentsSearchGridLarge from "./CourseEnrollmentsSearchGridLarge";
import CourseEnrollmentsSearchGridSmall from "./CourseEnrollmentsSearchGridSmall";

import global from '../../../../../common/components/GlobalStyle.module.css';

const CourseEnrollmentsSearchGrid = ({ data, isLoading, showFilters, onShowFiltersClicked,
  onEditPersonCourseEnrollments }) => (
  <Fragment>
    {isLoading === false
      ? (
        <div className="row">
          <div className="col-xs-12 col-sm-10">
            {data.length > 499
              ? <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p>
              : <p>{data.length === 1 ? `${data.length} Search Result` : `${data.length} Search Results`}</p>
            }
          </div>
          <div className="col-xs-12 col-sm-2">
            <button className={global.ToggleButton} type="button" onClick={() => onShowFiltersClicked()}>{showFilters === true ? 'Hide Filters' : 'Show Filters'}</button>
          </div>
        </div>
      ) : <div className="row usas-extra-top-margin usas-extra-bottom-margin"></div>
    }
    <CourseEnrollmentsSearchGridLarge data={data} isLoading={isLoading} onEdit={onEditPersonCourseEnrollments} />
    <CourseEnrollmentsSearchGridSmall data={data} isLoading={isLoading} onEdit={onEditPersonCourseEnrollments} />
  </Fragment>
);

export default CourseEnrollmentsSearchGrid;