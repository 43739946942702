/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import SGColumnHeader from '../../../../../common/components/grids/SGColumnHeader';

import useSortableGrid from '../../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../../common/utils/SGConstants';
import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ person, onEdit }) => (
  <tr>
    <td><a onClick={(e) => onEdit(person.personId)}>{person.firstName || ''} {person.lastName || ''}</a></td>
    <td>{person.memberId || ''}</td>
    <td>{person.birthDate ? formatDate(person.birthDate) : ''}</td>
  </tr>
);

const CourseEnrollmentsSearchGridLarge = ({ data, isLoading, onEdit }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(data);

  return (
    <Fragment>
      <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
        <thead>
          <tr>
            <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Name'} columnField={'lastName'}
              sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
            <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Member Id'} columnField={'memberId'}
              sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
            <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Birth Date'} columnField={'birthDate'}
              sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          </tr>
        </thead>
        <tbody>
          {isLoading === true
            ? <tr><td colSpan="3">Loading...</td></tr>
            : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
              ? sortableGridState.sortedGridData.map((person, i) => <GridRow key={i} person={person} onEdit={onEdit} />)
              : <tr><td colSpan="3">No Results</td></tr>
          }
        </tbody>
      </table>
    </Fragment>
  );
};

export default CourseEnrollmentsSearchGridLarge;