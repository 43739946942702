import { PersonHttpHelper, NO_DATA_MESSAGE, BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

const getActions = (personId, state, setState) => {
  if (state.isArrayLoading !== true && state.isArrayLoaded !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      arrayData: [],
      message: 'Loading...'
    };
    setState(newState);

    const personIdForUrl = personId ? encodeURIComponent(personId) : 'NaN';
    const url = `/person/${personIdForUrl}/actions`;
    PersonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) arrayData = [];
        if (Array.isArray(arrayData) === false) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            message: '',
          };
          setState(newState);
          return arrayData;
        }
      })
    .catch((e) => {
      const message = e && e.message ? e.message : NO_DATA_MESSAGE;
      setState({
        ...newState,
        isArrayLoading: false,
        isArrayLoaded: false,
        message: message
      });
    });
  };
};

const ActionsData = { INITIAL_STATE: BASIC_INITIAL_STATE, getActions };
export default ActionsData;