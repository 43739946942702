/* eslint-disable react/no-unescaped-entities */
import { Fragment } from "react";

import useAccountCreationForgotUsername from "./UseAccountCreationForgotUsername";

import ForgotUsernameForm from "../../components/forms/ForgotUsernameForm";

import Headings from "../../../../../../../common/components/headings/Headings";
import PopUpModal from "../../../../../../../common/components/dialogs/PopUpModal";
import SecondaryButton from "../../../../../../../common/components/buttons/SecondaryButton";

import Constants from "../../../../../../../common/utils/Constants";

import style from '../../../../LandingPages.module.css';

const AccountCreationForgotUsername = () => {
  const {
    isLoading,
    apiErrorPopupState,
    displayUsernameSentPopup,
    onCancelClicked,
    onCloseAPIErrorPopupClicked,
    onReturnToLoginClicked,
    onSubmitFormCallback
  } = useAccountCreationForgotUsername();

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 col-md-4" />
        <div className={['col-xs-12', 'col-md-4', style.CenterPageContent].join(' ')}>
          <Headings.H3>Forgot Username</Headings.H3>
          <p>Enter your email address, and we'll send your username.</p>
          <ForgotUsernameForm
            submitButtonText={'Submit'}
            secondaryButtonText={'Cancel'}
            onSubmitFormCallback={onSubmitFormCallback}
            onSecondaryButtonClicked={onCancelClicked} />
        </div>
        <div className="col-xs-12 col-md-4" />
      </div>
      <PopUpModal
        widthPct={60}
        maxWidth={720}
        title={'Error'}
        displayPopUp={apiErrorPopupState.displayPopup === true}>
        <div className="row">
          <div className="col-xs-12">
            <p>{apiErrorPopupState.errorMessage || 'An error occurred.'}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SecondaryButton type={'button'} onClick={onCloseAPIErrorPopupClicked}>Close</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={60}
        maxWidth={720}
        title={'Username Sent'}
        displayPopUp={displayUsernameSentPopup === true}>
        <div className="row">
          <div className="col-xs-12">
            <p>If you have an active account with a valid email address, you will receive an email with your username.</p>
            <p>If you do not have an account or email, please contact USA Swimming to recover your username.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SecondaryButton type={'button'} onClick={onReturnToLoginClicked}>Return To Login</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default AccountCreationForgotUsername;