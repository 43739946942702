import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import PopUpModal from "../../../../../common/components/dialogs/PopUpModal";

import global from '../../../../../common/components/GlobalStyle.module.css';

const ValidationSuccessErrorPopup = ({ displayPopup, errorTitle = 'Validation Error', errorMessage, onCancelClicked }) => (
  <PopUpModal
    widthPct={50}
    maxWidth={720}
    title={errorTitle}
    displayPopUp={displayPopup === true}
    onModalCanceled={onCancelClicked}>
    <div className="row">
      <div className="col-xs-12 usas-extra-bottom-margin">
        <p className={global.ErrorMessage}>{errorMessage}</p>
      </div>
      <div className="col-xs-12">
        <SecondaryButton onClick={onCancelClicked}>Cancel</SecondaryButton>
      </div>
    </div>
  </PopUpModal>
);

export default ValidationSuccessErrorPopup;