import EditIcon from '../../../common/components/icons/EditIcon';

import SGColumnHeader from '../../../common/components/grids/SGColumnHeader';

import useSortableGrid from '../../../common/utils/UseSortableGrid';
import SGConstants from '../../../common/utils/SGConstants';
import { formatDate } from '../../../common/utils/DateFunctions';
import { FormatMoneyWithSymbol } from '../../../common/utils/FormatMoney';

import global from '../../../common/components/GlobalStyle.module.css';

const GridRow = ({ payment, onMatchCheckClicked }) => (
  <tr>
    <td>{payment.orgUnitName || ''} {payment.orgUnitName === 'Unattached' ? ' - ' + payment.parentOrgUnitName : ''} {payment.parentOrgUnitCode ? '(' + payment.parentOrgUnitCode + ')' : ''}</td>
    <td>{(payment.firstName || payment.middleName || payment.lastName) ? `${payment.firstName || ''} ${payment.preferredName !== '' && payment.preferredName !== payment.firstName ? '"' + payment.preferredName + '"' : ''} ${payment.middleName || ''} ${payment.lastName || ''}` : ''}</td>
    <td>{payment.paymentSubsystem || ''}</td>
    <td>{payment.paymentDate ? formatDate(payment.paymentDate) : ''}</td>
    <td>{payment.amount >= 0 ? FormatMoneyWithSymbol(payment.amount/100) : ''}</td>
    <td>
      <button
        className={global.IconButtonMargin}
        type="button"
        onClick={() => { onMatchCheckClicked(payment) }}>
        <EditIcon />
      </button>
    </td>
  </tr>
);

const CheckPaymentsGridLarge = ({ data, isLoading, onMatchCheckClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(data);
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Organization'} columnField={'orgUnitName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th>Payment Person</th>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Payment Subsystem'} columnField={'paymentSubsystem'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Payment Date'} columnField={'paymentDate'}
            sortType={SGConstants.SORT_TYPE_DATE} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Payment Amount'} columnField={'amount'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="6">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((payment, i) => <GridRow key={i} payment={payment} onMatchCheckClicked={onMatchCheckClicked} />)
            : <tr><td colSpan="6">No Unmatched Check Payments</td></tr>
        }
      </tbody>
    </table>
  );
};

export default CheckPaymentsGridLarge;