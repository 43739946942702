import { useEffect } from "react";
import { useLocation, useNavigate } from "../../../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../../../NavLinks";

import useLandingPageData from "../../../../../state/landingPages/UseLandingPageData";
import useMemberCardData from "../../../../../state/landingPages/memberCard/UseMemberCardData";

const useOfficialMemberCard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { landingPageState } = useLandingPageData();
  const { memberCardState, getMemberCard } = useMemberCardData();

  const onClick = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    if (landingPageState.stateStatus === 'Parent' || landingPageState.stateStatus === 'AdultIndividual'
      || landingPageState.stateStatus === 'MinorIndividual') {
      navigate(NavLinks.LANDING);
    } else if (landingPageState.stateStatus === 'Child') {
      navigate(NavLinks.LANDING, { state: { childPersonId: landingPageState.currentPerson?.personId } });
    }
  };

  useEffect(() => {
    if (location.state && location.state?.personaId && landingPageState.currentPerson && landingPageState.currentPerson?.personId) {
      getMemberCard(landingPageState.currentPerson?.personId, location.state?.personaId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingPageState])

  useEffect(() => {
    if (landingPageState.stateStatus === 'Loading') {
      navigate(NavLinks.LANDING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingPageState])

  return {
    onClick,
    memberCardState
  };
};

export default useOfficialMemberCard;