import { useEffect } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../NavLinks";

import useAccountManagementData from "../../../state/accountManagement/UseAccountManagementData";
import useAccountManagementUserGroupData from "../../../state/accountManagement/accountManagementUserGroup/UseAccountManagementUserGroupData";

const useAccountManagementUserGroup = () => {
  const navigate = useNavigate();
  const { accountManagementState } = useAccountManagementData();
  const { accountManagementUserGroupState, accountManagementUserGroupSaveState,
    getAccountManagementUserGroups, putAccountManagementUserGroup
  } = useAccountManagementUserGroupData();

  const onSubmitFormCallback = (formState) => {
    const userAccountId = accountManagementState.objData?.userAccountId;

    const putAccountManagementUserGroupPromise = putAccountManagementUserGroup(userAccountId, createUserGroupsArray(userAccountId, accountManagementUserGroupState.arrayData, formState.userGroup));

    if (putAccountManagementUserGroupPromise ?? false) {
      putAccountManagementUserGroupPromise.then((newState) => {
        if (newState ?? false) {
          navigate(NavLinks.ACCOUNT_MANAGEMENT_USER_GROUP, { state: { doRefresh: true } });
        }
      }).catch((e) => {
        //context error
      });
    }
  };

  const onBackClicked = () => {
    navigate(NavLinks.ACCOUNT_MANAGEMENT_USER_GROUP);
  };

  useEffect(() => {
    if (accountManagementUserGroupState.isArrayLoaded === false) {
      const userAccountId = accountManagementState.objData?.userAccountId;

      const getAccountManagementUserGroupsPromise = getAccountManagementUserGroups(userAccountId);

      if (getAccountManagementUserGroupsPromise ?? false) {
        getAccountManagementUserGroupsPromise.catch((e) => {
          //context error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountManagementUserGroupState.isArrayLoaded]);

  function createUserGroupsArray(userAccountId, existingUserGroups, newUserGroups) {
    const userGroupsArray = [...existingUserGroups];

    for (const userGroup of newUserGroups) {
      if (!userGroupsArray.find((x) => x.userGroupId === userGroup.id)) {
        userGroupsArray.push({ userAccountId, userGroupId: userGroup.id, userGroupName: userGroup.name });
      }
    }

    return userGroupsArray;
  };

  return {
    isLoading: accountManagementUserGroupState.isArrayLoading,
    isSaving: accountManagementUserGroupSaveState.isArrayLoading,
    userGroupsData: accountManagementUserGroupState.arrayData,
    onSubmitFormCallback,
    onBackClicked
  };
};

export default useAccountManagementUserGroup;