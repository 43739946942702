import React, { Fragment } from 'react';

import useCourseEnrollmentsContextView from './UseCourseEnrollmentsContextView';

import Headings from '../../../common/components/headings/Headings';
import LeftArrowIcon from '../../../common/components/icons/LeftArrowIcon';
import PageNotFound from '../../../common/components/PageNotFound';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';

import Constants from '../../../common/utils/Constants';
import { formatDate } from '../../../common/utils/DateFunctions';

import global from '../../../common/components/GlobalStyle.module.css';

const CourseEnrollmentsContextView = ({ children }) => {
  const {
    courseEnrollmentsState,
    backToLabel,
    onBackClicked
  } = useCourseEnrollmentsContextView();
  const findBirthDate = (courseEnrollmentsState) => {
    let dateofBirth = '';

    if (courseEnrollmentsState.personId !== '' || courseEnrollmentsState.personId !== null) {
      dateofBirth = courseEnrollmentsState?.arrayData?.find(x => x.personId === courseEnrollmentsState?.personId).birthDate;
    }
    return formatDate(dateofBirth)
  }
  if (!courseEnrollmentsState || courseEnrollmentsState.isObjLoaded === false) {
    return courseEnrollmentsState.isObjLoading === false
      ? (
        <Fragment>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <button className={global.BackToSearchButton}
                type="button"
                onClick={onBackClicked}>
                <LeftArrowIcon />  {backToLabel}
              </button>
            </div>
          </div>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <PageNotFound />
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PopUpModal
              widthPct={50}
              maxWidth={250}
              title={Constants.LOADING_MSG}
              displayPopUp={true} />
          </div>
        </div>
      );
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.HeaderButton}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              Course Enrollments
            </Headings.H3>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.BackToSearchButton}
            type="button"
            onClick={onBackClicked}>
            <LeftArrowIcon />  {backToLabel}
          </button>
        </div>
      </div>
      <div className="row">
        <div className={["col-xs-12", global.HeaderText].join(' ')}>
          <p className={global.HeaderText}><strong>Editing:</strong>&nbsp;{courseEnrollmentsState.objData.firstName || ''} {courseEnrollmentsState.objData.lastName || ''}</p>
          <p className={global.HeaderText}><strong>Member Id:</strong>&nbsp;{courseEnrollmentsState.objData?.memberId || ''}</p>
          <p className={global.HeaderText}><strong>BirthDate:</strong>&nbsp;{findBirthDate(courseEnrollmentsState)}</p>
        </div>
      </div>
      {children}
    </Fragment>
  );
};

export default CourseEnrollmentsContextView;