import { useState } from 'react';
import ActionsData from './ActionsData';

const useActionsData = () => {
  const [ actionsState, setActionsState ] = useState(ActionsData.INITIAL_STATE);

  const getActions = (personId) => {
    if (personId && actionsState.isObjLoading !== true && actionsState.isObjLoaded !== true) {
      ActionsData.getActions(personId, actionsState, setActionsState);
    }
  };
  
  return { actionsState, getActions };
};

export default useActionsData;