import { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Outlet } from '../common/wrappers/ReactRouterDom';

import useNavRoutes from '../common/state/security/UseNavRoutes';

import LoadingModal from '../common/components/dialogs/LoadingModal';
import PageNotFound from '../common/components/PageNotFound';
import UnexpectedError from '../common/components/UnexpectedError';
import SecuredRouteElement from '../common/components/security/SecuredRouteElement';
import ErrorBoundary from '../common/components/errors/ErrorBoundary';
import ErrorBoundaryFallback from '../common/components/errors/ErrorBoundaryFallback';

import Payments from './views/payments/Payments';
import ManageCarts from './views/manageCarts/ManageCarts';
import LandingPages from './views/landingPages/LandingPages';
import ManagePayments from './views/managePayments/ManagePayments';
import AccountManagement from './views/accountManagement/AccountManagement';
import CourseEnrollments from './views/courseEnrollments/CourseEnrollments';
import MatchCheckPayments from './views/matchCheckPayments/MatchCheckPayments';
import MatchCheckPaymentsDetail from './views/matchCheckPayments/MatchCheckPaymentsDetail';

const AppRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <Routes>
        <Route path="/" element={<Fragment><Outlet /></Fragment>}>
          <Route path={navRoutes.HUB_LANDING_PAGES?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes.HUB_LANDING_PAGES}
                editElement={<LandingPages />} />
            }
          />
          <Route path={navRoutes.PAYMENTS_ROOT?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes.PAYMENTS_ROOT}
                editElement={<Payments />} />
            }
          />
          <Route path={navRoutes.COURSE_ENROLLMENTS_ROOT?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes.COURSE_ENROLLMENTS_ROOT}
                editElement={<CourseEnrollments />} />
            }
          />
          <Route path={navRoutes.ACCOUNT_MANAGEMENT_ROOT?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes.ACCOUNT_MANAGEMENT_ROOT}
                editElement={<AccountManagement />} />
            }
          />
          <Route path={navRoutes.MANAGE_PAYMENTS?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes.MANAGE_PAYMENTS}
                editElement={<ManagePayments />} />
            }
          />
          <Route path={navRoutes.MANAGE_SHOPPING_CART?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes.MANAGE_SHOPPING_CART}
                editElement={<ManageCarts />} />
            }
          />
          <Route path={navRoutes.MATCH_CHECK_PAYMENTS?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes.MATCH_CHECK_PAYMENTS}
                editElement={<MatchCheckPayments />} />
            }
          />
          <Route path={navRoutes.MATCH_CHECK_PAYMENTS_DETAIL?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes.MATCH_CHECK_PAYMENTS_DETAIL}
                editElement={<MatchCheckPaymentsDetail />} />
            }
          />

          <Route path="500" element={<UnexpectedError />} />
          <Route path="/*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </ErrorBoundary>
  );
};

export default AppRoutes;