import { useState } from 'react';

import AccountManagementDeleteUserAccountData from './AccountManagementDeleteUserAccountData';

import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

const INITIAL_DELETE_PERSON_PARENT_CHILD = {
  ...BASIC_INITIAL_STATE
};

const useAccountManagementDeleteUserAccountData = () => {
  const [deleteUserAccountState, setDeleteUserAccountState] = useState(INITIAL_DELETE_PERSON_PARENT_CHILD);

  const deleteUserAccount = () => {
    return AccountManagementDeleteUserAccountData.deleteUserAccountData(deleteUserAccountState, setDeleteUserAccountState);
  };

  const deleteUserAccountByPersonId = (personId) => {
    return AccountManagementDeleteUserAccountData.deleteUserAccountByPersonIdData(personId, deleteUserAccountState, setDeleteUserAccountState);
  };

  const resetDeleteUserAccountState = () => {
    setDeleteUserAccountState({
      ...INITIAL_DELETE_PERSON_PARENT_CHILD
    });
  };

  return {
    deleteUserAccountState,
    deleteUserAccount,
    deleteUserAccountByPersonId,
    resetDeleteUserAccountState
  };
};

export default useAccountManagementDeleteUserAccountData;