import { useState, useEffect } from "react";

const X_LEFT_OFFSET = 50;
const X_RIGHT_OFFSET = -550;
const Y_TOP_OFFSET = -25;
const Y_BOTTOM_OFFSET = -100;

const INITIAL_STATE = {
  displayPopup: false,
  pageX: 0,
  pageY: 0
};

const useAlreadyLinkedSpan = () => {
  const [state, setState] = useState(INITIAL_STATE);
  const [windowSizeState, setWindowSizeState] = useState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });

  const onEnterIcon = (event) => {
    if (event) {
      setState({
        ...state,
        displayPopup: true,
        pageX: event.pageX,
        pageY: event.pageY
      });
    } else {
      setState({
        ...state,
        displayPopup: true
      });
    }
  };

  const onLeaveIcon = () => {
    setState(INITIAL_STATE);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSizeState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  function getLeftPx() {
    const pageX = state.pageX;

    // on right side
    if (pageX > (windowSizeState.innerWidth / 2)) {
      return `${pageX + X_RIGHT_OFFSET}px`;
    } else {
      return `${pageX + X_LEFT_OFFSET}px`;
    }
  };

  function getTopPx() {
    const pageY = state.pageY;

    // on bottom
    if (pageY > (windowSizeState.innerHeight / 1.5)) {
      return `${pageY + Y_BOTTOM_OFFSET}px`;
    } else {
      return `${pageY + Y_TOP_OFFSET}px`;
    }
  };

  return {
    state,
    onEnterIcon,
    onLeaveIcon,
    getLeftPx,
    getTopPx
  };
};

export default useAlreadyLinkedSpan;