import { Fragment } from 'react';

import useMyPayments from './UseMyPayments';

import Spinner from '../../../../../common/components/spinners/Spinner';
import ActionButton from '../../../../../common/components/buttons/ActionButton';
import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';
import { convertNumberToCurrencyString } from '../../../../../common/utils/CurrencyUtils';

import style from './../../LandingPages.module.css';
import styleMP from './MyPayments.module.css';

const PaymentReceipt = ({ payment, onViewReceiptClicked }) => (
  <tr>
    <td>{payment.internalReceiptNumber}</td>
    <td>{payment.receiptNumber}</td>
    <td>{formatDate(payment.paymentDate)}</td>
    <td>${convertNumberToCurrencyString(payment.amount / 100)}</td>
    <td>
      <ActionIntraPageButton onClick={(e) => onViewReceiptClicked(e, payment)} className={style.ActionButton}>View</ActionIntraPageButton>
    </td>
  </tr>
);

const MyPayments = () => {
  const {
    payments,
    isLoading,
    isReceiptLoading,
    isReceiptError,
    personPaymentsState,
    showMore,
    onShowMore,
    onViewReceiptClicked,
    onReceiptErrorClosed
  } = useMyPayments();

  if (isLoading === true) {
    return <Spinner />;
  } else if (Array.isArray(payments) !== true || payments.length === 0) {
    return <Fragment />;
  } else {
    return (
      <Fragment>
        <div className={[style.SectionBorder, styleMP.MyPayments].join(' ')}>
          <div className={style.SectionTitle}>My Payments</div>
          <table>
            <thead>
              <tr className='visible-lg'>
                <th>USAS Receipt Number</th>
                <th>Stripe Receipt Number</th>
                <th>Transaction Date</th>
                <th>Amount</th>
                <th></th>
              </tr>
              <tr className='visible-md'>
                <th>USAS Receipt #</th>
                <th>Stripe Receipt #</th>
                <th>Date</th>
                <th>Amount</th>
                <th></th>
              </tr>
              <tr className='visible-sm'>
                <th>USAS Rcpt.#</th>
                <th>Stripe Rcpt.#</th>
                <th>Date</th>
                <th>Amount</th>
                <th></th>
              </tr>
              <tr className='visible-xs'>
                <th>USAS R.#</th>
                <th>Stripe R.#</th>
                <th>Date</th>
                <th>Amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment, i) => <PaymentReceipt key={i} payment={payment} onViewReceiptClicked={onViewReceiptClicked} />)}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={5}>
                  {personPaymentsState.arrayData.length > 5 && <ActionButton onClick={onShowMore}>{showMore === true ? 'Show More' : 'Show Less'}</ActionButton>}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        {isReceiptError === true
          ? (
            <PopUpModal
              widthPct={90}
              maxWidth={720}
              title={'Receipt Error'}
              displayPopUp={true}
              onModalCanceled={onReceiptErrorClosed}>
              <div className="row">
                <div className="col-xs-12">
                  <p>An error occurred while trying to retrieve your receipt.</p>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 usas-extra-top-margin">
                  <SecondaryButton type="button" onClick={onReceiptErrorClosed}>Close</SecondaryButton>
                </div>
              </div>
            </PopUpModal>
          ) : (
            <PopUpModal
              widthPct={50}
              maxWidth={250}
              title={Constants.LOADING_MSG}
              displayPopUp={isReceiptLoading} />
          )
        }
      </Fragment>
    );
  }
};

export default MyPayments;