import { Fragment } from "react";

import useAccountCreationValidateEmail from "./UseAccountCreationValidateEmail";

import ValidateEmailForm from "../../components/forms/ValidateEmailForm";

import Headings from "../../../../../../../common/components/headings/Headings";
// import LoginInstructions from "../../../../../../../common/components/loginInstructions/LoginInstructions";
import PopUpModal from "../../../../../../../common/components/dialogs/PopUpModal";
import SecondaryButton from "../../../../../../../common/components/buttons/SecondaryButton";

import Constants from "../../../../../../../common/utils/Constants";

import style from '../../../../LandingPages.module.css';

const AccountCreationValidateEmail = () => {
  const {
    isLoading,
    apiErrorPopupState,
    loginErrorPopupState,
    username,
    onBackClicked,
    onCloseAPIErrorPopupClicked,
    onReturnToLoginClicked,
    onSubmitFormCallback
  } = useAccountCreationValidateEmail();

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 col-md-4" />
        <div className={['col-xs-12', 'col-md-4', style.CenterPageContent].join(' ')}>
          <Headings.H3>Validate Email</Headings.H3>
          <p>Please enter the verification code that was emailed to you to complete creating your login account.</p>
          <p>Your new account username will be: <b>{username}</b></p>
          <ValidateEmailForm
            submitButtonText={'Submit'}
            onSubmitFormCallback={onSubmitFormCallback}
            onSecondaryButtonClicked={onBackClicked} />
        </div>
        <div className="col-xs-12 col-md-4" />
        {/* <LoginInstructions /> */}
      </div>
      <PopUpModal
        widthPct={60}
        maxWidth={720}
        title={'Account Creation Error'}
        displayPopUp={apiErrorPopupState.displayPopup === true}>
        <div className="row">
          <div className="col-xs-12">
            <p>{apiErrorPopupState.errorMessage || 'An error occurred.'}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SecondaryButton type={'button'} onClick={onCloseAPIErrorPopupClicked}>Close</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={60}
        maxWidth={720}
        title={'Login Error'}
        displayPopUp={loginErrorPopupState.displayPopup === true}>
        <div className="row">
          <div className="col-xs-12">
            <p>{loginErrorPopupState.errorMessage || 'An error occurred.'}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SecondaryButton type={'button'} onClick={onReturnToLoginClicked}>Return To Login</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default AccountCreationValidateEmail;