import useEmailForm from "./UseEmailForm";

import Input from "../../../../../common/components/inputs/Input";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";

const EmailForm = ({ submitButtonText = 'Save', emailObj, onSubmitFormCallback }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    resetForm
  } = useEmailForm(onSubmitFormCallback, emailObj);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label='Email*'
            name="email"
            value={formState.email}
            error={errorState.email}
            message={errorState.email}
            onChange={(value) => { updateFormState('email', value) }} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default EmailForm;