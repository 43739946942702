import useLandingPageData from '../../../../state/landingPages/UseLandingPageData';

const useChild = (child) => {
  const { setChildAsCurrent } = useLandingPageData();
  const showMemberId = child?.personas?.some(x => x.personaName !== 'Parent');

  const onClick = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setChildAsCurrent(child.personId)
  };

  return {
    showMemberId,
    onClick,
  };
};

export default useChild;