import { useContext, useState } from 'react';

import AccountManagementMFAData from './AccountManagementMFAData';

import { AccountManagementMFAStateContext } from './AccountManagementMFAContextProvider';

import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

const INITIAL_ACCOUNT_MANAGEMENT_MFA_SAVE_STATE = {
  ...BASIC_INITIAL_STATE
};

const useAccountManagementMFAData = () => {
  const [accountManagementMFAState, setAccountManagementMFAState] = useContext(AccountManagementMFAStateContext);
  const [accountManagementMFASaveState, setAccountManagementMFASaveState] = useState(INITIAL_ACCOUNT_MANAGEMENT_MFA_SAVE_STATE);

  const getAccountManagementMFAByUserAccountId = (userAccountId) => {
    return AccountManagementMFAData.getAccountManagementMFAByUserAccountIdData(userAccountId, accountManagementMFAState, setAccountManagementMFAState);
  };

  const getAccountManagementMFA = () => {
    return AccountManagementMFAData.getAccountManagementMFAData(accountManagementMFAState, setAccountManagementMFAState);
  };

  const putAccountManagementMFAByUserAccountId = (userAccountId, mfaObj) => {
    return AccountManagementMFAData.putAccountManagementMFAByUserAccountIdData(userAccountId, mfaObj, accountManagementMFASaveState, setAccountManagementMFASaveState);
  };

  const putAccountManagementMFA = (mfaObj) => {
    return AccountManagementMFAData.putAccountManagementMFAData(mfaObj, accountManagementMFASaveState, setAccountManagementMFASaveState);
  };

  const resetMFASaveState = () => {
    setAccountManagementMFASaveState({
      ...INITIAL_ACCOUNT_MANAGEMENT_MFA_SAVE_STATE
    });
  };

  return {
    accountManagementMFAState,
    accountManagementMFASaveState,
    resetMFASaveState,
    getAccountManagementMFAByUserAccountId,
    getAccountManagementMFA,
    putAccountManagementMFAByUserAccountId,
    putAccountManagementMFA
  };
};

export default useAccountManagementMFAData;