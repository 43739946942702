
export const localValidate = (formState) => {
  let errors = {};

  return errors;
};

const MatchCheckPaymentsValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default MatchCheckPaymentsValidation;