import { Fragment } from "react";

import useAccountManagementPassword from "./UseAccountManagementPassword";

import PasswordForm from "../components/forms/PasswordForm";
import PasswordAdminForm from "../components/forms/PasswordAdminForm";
import ValidationSuccessErrorPopup from "../components/dialogs/ValidationSuccessErrorPopup";

import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";

import Constants from "../../../../common/utils/Constants";

const AccountManagementPassword = () => {
  const {
    isSaving,
    isLoading,
    userName,
    isAdminView,
    saveStateData,
    resetSaveState,
    onSubmitFormCallback
  } = useAccountManagementPassword();

  return (
    <Fragment>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12'>
          <Headings.H3>Password</Headings.H3>
        </div>
      </div>
      {isSaving !== true &&
        <Fragment>
          {isAdminView === true ? (
            <PasswordAdminForm
              userName={userName}
              onSubmitFormCallback={onSubmitFormCallback} />
          ) : (
            <PasswordForm
              userName={userName}
              onSubmitFormCallback={onSubmitFormCallback} />
          )}
        </Fragment>
      }
      <ValidationSuccessErrorPopup
        displayPopup={saveStateData?.success === false}
        errorMessage={saveStateData?.errorMessage || ''}
        onCancelClicked={resetSaveState} />
      <PopUpModal
        widthPct={50}
        maxWidth={720}
        title={'Password Updated'}
        displayPopUp={saveStateData?.success === true}
        onModalCanceled={resetSaveState}>
        <div className="row">
          <div className="col-xs-12 usas-extra-bottom-margin">
            <p>Password was updated successfully</p>
          </div>
          <div className="col-xs-12">
            <SecondaryButton onClick={resetSaveState}>Ok</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default AccountManagementPassword;