import { useEffect, useState } from 'react';

import useActionsData from '../../../../state/landingPages/actions/UseActionsData';
import useLandingPageData from '../../../../state/landingPages/UseLandingPageData';

const INITIAL_STATE = {
  actions: [],
  isLoading: true
};

const useMyActions = () => {
  const { landingPageState } = useLandingPageData();
  const { actionsState, getActions } = useActionsData();
  const [ state, setState ] = useState(INITIAL_STATE);

  useEffect(() => {
    if (landingPageState.currentPerson?.personId) {
      getActions(landingPageState.currentPerson?.personId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingPageState.currentPerson?.personId]);

  useEffect(() => {
    if (actionsState.isArrayLoading === false && actionsState.isArrayLoaded === true) {
      setState({
        actions: actionsState.arrayData,
        isLoading: false
      });
    }
  }, [actionsState]);

  return {...state};
};

export default useMyActions;