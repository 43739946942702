import { useEffect } from "react";
import { useLocation, useNavigate } from "../../../common/wrappers/ReactRouterDom";

import NavLinks from "./NavLinks";

import validate from './MatchCheckPaymentsDetailValidation';

import usePaymentsByCheckData from "../../../common/state/paymentsByCheck/UsePaymentsByCheckData";

import Constants from "../../../common/utils/Constants";
import UseForm from "../../../common/utils/UseForm";
import { formatDate } from "../../../common/utils/DateFunctions";
import { FormatMoneyWithSymbol } from "../../../common/utils/FormatMoney";

const INITIAL_FORM_STATE = {
  transactionId: Constants.DEFAULT_ID,
  checkDate: Constants.BLANK_DATE_STRING,
  checkNumber: '',
  checkAmount: '',
  externalId: ''
};

const useMatchCheckPaymentsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { paymentsByCheckState, postPaymentByCheck, setPaymentsByCheckState } = usePaymentsByCheckData();
  const { formState, errorState, setFormData, onFormValueChanged, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const onBackClicked = () => {
    navigate(NavLinks.MATCH_CHECK_PAYMENTS);
  };

  useEffect(() => {
    if (location.state && location.state?.payment) {
      const payment = location.state.payment;
      setFormData({
        ...formState,
        organization: `${payment.orgUnitName || ''} ${payment.orgUnitName === 'Unattached' ? ' - ' + payment.parentOrgUnitName : ''} ${payment.parentOrgUnitCode ? '(' + payment.parentOrgUnitCode + ')' : ''}`,
        paymentPerson: (payment.firstName || payment.middleName || payment.lastName) ? `${payment.firstName || ''} ${payment.preferredName !== '' && payment.preferredName !== payment.firstName ? '"' + payment.preferredName + '"' : ''} ${payment.middleName || ''} ${payment.lastName || ''}` : '',
        paymentSubsystem: payment.paymentSubsystem || '',
        paymentDate: payment.paymentDate ? formatDate(payment.paymentDate) : '',
        amount: payment.amount >= 0 ? FormatMoneyWithSymbol(payment.amount/100) : '',
        transactionId: payment.transactionId || Constants.DEFAULT_ID,
        checkDate: Constants.BLANK_DATE_STRING,
        checkNumber: payment.checkNumber || '',
        checkAmount: payment.checkAmount || '',
        externalId: payment.externalId || ''
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function submitFormCallback(formState) {
    const paymentByCheckObj = {
      transactionId: formState.transactionId,
      checkDate: formState.checkDate,
      checkNumber: formState.checkNumber.trim(),
      checkAmount: formState.checkAmount,
      externalId: formState.externalId.trim()
    };

    const postPaymentByCheckPromise = postPaymentByCheck(paymentByCheckObj);

    if (postPaymentByCheckPromise !== null) {
      postPaymentByCheckPromise.then((newState) => {
        if (newState ?? false) {
          setPaymentsByCheckState({
            ...newState,
            isSaving: false, isSaved: true
          });
          navigate(NavLinks.MATCH_CHECK_PAYMENTS, { state: { performGet: true } });
        }
      }).catch(() => {
        //TODO
      });
    }
  }

  return {
    isSaving: paymentsByCheckState.isSaving,
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    onBackClicked
  };
};

export default useMatchCheckPaymentsDetail;