import { Fragment } from "react";

import useMatchCheckPaymentsDetail from "./UseMatchCheckPaymentsDetail";

import MatchCheckPaymentsDetailForm from "./MatchCheckPaymentsDetailForm";

import Headings from "../../../common/components/headings/Headings";
import PopUpModal from "../../../common/components/dialogs/PopUpModal";

import Constants from "../../../common/utils/Constants";

const MatchCheckPaymentsDetail = () => {
  const {
    isSaving,
    formState,
    errorState,
    onFormValueChanged,
    handleSubmit,
    onBackClicked
  } = useMatchCheckPaymentsDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12 usas-extra-bottom-margin">
          <Headings.H3>Match Check Payment to Check</Headings.H3>
        </div>
      </div>
      <MatchCheckPaymentsDetailForm
        formState={formState}
        errorState={errorState}
        onFormValueChanged={onFormValueChanged}
        handleSubmit={handleSubmit}
        onBackClicked={onBackClicked} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default MatchCheckPaymentsDetail;