import { useEffect } from "react";

import useAccountManagementData from "../../../state/accountManagement/UseAccountManagementData";
import useAccountManagementPasswordData from "../../../state/accountManagement/accountManagementPassword/UseAccountManagementPasswordData";
import useAccountManagementUsernameData from "../../../state/accountManagement/accountManagementUsername/UseAccountManagementUsernameData";

const useAccountManagementPassword = () => {
  const { accountManagementState } = useAccountManagementData();
  const { accountManagementUsernameState, getAccountManagementUsername, getAccountManagementUsernameByUserAccountId
  } = useAccountManagementUsernameData();
  const { accountManagementPasswordState, resetPasswordSaveState, postAccountManagementPassword,
    postAccountManagementPasswordByUserAccountId
  } = useAccountManagementPasswordData();

  const onSubmitFormCallback = (formState) => {
    const userAccountId = accountManagementState.objData?.userAccountId;
    const passwordObj = {
      currentPassword: accountManagementState.isAdminView === true
        ? undefined
        : formState.currentPassword.trim(),
      newPassword: formState.newPassword.trim()
    };

    const postAccountManagementPasswordPromise = accountManagementState.isAdminView === true
      ? postAccountManagementPasswordByUserAccountId(userAccountId, passwordObj)
      : postAccountManagementPassword(passwordObj);

    if (postAccountManagementPasswordPromise ?? false) {
      postAccountManagementPasswordPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData?.success === true) {

          }
        }
      }).catch((e) => {
        //state error
      });
    }
  };

  useEffect(() => {
    if (accountManagementUsernameState.isObjLoaded === false) {
      const userAccountId = accountManagementState.objData?.userAccountId;

      const getAccountManagementUsernamePromise = accountManagementState.isAdminView === true
        ? getAccountManagementUsernameByUserAccountId(userAccountId)
        : getAccountManagementUsername();

      if (getAccountManagementUsernamePromise ?? false) {
        getAccountManagementUsernamePromise.catch((e) => {
          //context error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountManagementUsernameState.isObjLoaded]);

  return {
    isLoading: accountManagementUsernameState.isObjLoading,
    isSaving: accountManagementPasswordState.isObjLoading,
    userName: accountManagementUsernameState.objData?.userName,
    isAdminView: accountManagementState.isAdminView === true,
    saveStateData: accountManagementPasswordState.objData,
    resetSaveState: resetPasswordSaveState,
    onSubmitFormCallback
  };
};

export default useAccountManagementPassword;