import { useEffect } from "react";
import { useLocation, useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../NavLinks";

import useAccountManagementData from "../../../state/accountManagement/UseAccountManagementData";
import useAccountManagementSecurityRolesData from "../../../state/accountManagement/accountManagementSecurityRoles/UseAccountManagementSecurityRolesData";

const useAccountManagementSecurityRoles = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { accountManagementState } = useAccountManagementData();
  const { accountManagementSecurityRolesState, accountManagementSecurityRolesSaveState,
    getAccountManagementSecurityRoles, deleteAccountManagementSecurityRole
  } = useAccountManagementSecurityRolesData();

  const onAddSecurityRolesClicked = () => {
    navigate(NavLinks.ACCOUNT_MANAGEMENT_SECURITY_ROLES_ADD);
  };

  const onEditSecurityRolesClicked = (securityRole) => {
    navigate(NavLinks.ACCOUNT_MANAGEMENT_SECURITY_ROLES_EDIT, { state: { securityRole } });
  };

  const onDeleteSecurityRolesClicked = (personOrgRoleDurationId) => {
    if (personOrgRoleDurationId > 0) {
      const deleteAccountManagementSecurityRolePromise = deleteAccountManagementSecurityRole(personOrgRoleDurationId);

      if (deleteAccountManagementSecurityRolePromise ?? false) {
        deleteAccountManagementSecurityRolePromise.then((newState) => {
          if (newState ?? false) {
            const userAccountId = accountManagementState.objData?.userAccountId;

            const getAccountManagementSecurityRolesPromise = getAccountManagementSecurityRoles(userAccountId);

            if (getAccountManagementSecurityRolesPromise ?? false) {
              getAccountManagementSecurityRolesPromise.catch((e) => {
                //context error
              });
            }
          }
        }).catch((e) => {
          //state error
        });
      }
    }
  };

  useEffect(() => {
    if (accountManagementSecurityRolesState.isArrayLoaded === false || location.state?.doRefresh === true) {
      const userAccountId = accountManagementState.objData?.userAccountId;

      const getAccountManagementSecurityRolesPromise = getAccountManagementSecurityRoles(userAccountId);

      if (getAccountManagementSecurityRolesPromise ?? false) {
        getAccountManagementSecurityRolesPromise.catch((e) => {
          //context error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: accountManagementSecurityRolesState.isArrayLoading,
    isSaving: accountManagementSecurityRolesSaveState.isObjLoading,
    gridData: accountManagementSecurityRolesState.arrayData,
    onAddSecurityRolesClicked,
    onEditSecurityRolesClicked,
    onDeleteSecurityRolesClicked
  };
};

export default useAccountManagementSecurityRoles;