import useAccountManagementLanding from "./UseAccountManagementLanding";

import PopUpModal from "../../../../common/components/dialogs/PopUpModal";

import Constants from "../../../../common/utils/Constants";

const AccountManagementLanding = () => {
  useAccountManagementLanding();

  return (
    <PopUpModal
      widthPct={90}
      maxWidth={250}
      title={Constants.LOADING_MSG}
      displayPopUp={true} />
  );
};

export default AccountManagementLanding;