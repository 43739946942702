import React, { Fragment } from 'react';

import ChildrenGridLarge from './ChildrenGridLarge';
import ChildrenGridSmall from './ChildrenGridSmall';

const ChildrenGrid = ({ data, isLoading, onDeleteClicked }) => (
  <Fragment>
    <ChildrenGridLarge
      data={data}
      isLoading={isLoading}
      onDeleteClicked={onDeleteClicked} />
    <ChildrenGridSmall
      data={data}
      isLoading={isLoading}
      onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default ChildrenGrid;