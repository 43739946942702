import { Fragment } from "react";

import useAccountManagementSecurityRolesAdd from "./UseAccountManagementSecurityRolesAdd";

import SecurityRolesForm from "../components/forms/SecurityRolesForm";

import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";

import Constants from "../../../../common/utils/Constants";

const AccountManagementSecurityRolesAdd = () => {
  const {
    isSaving,
    onSubmitFormCallback,
    onBackClicked
  } = useAccountManagementSecurityRolesAdd();

  return (
    <Fragment>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12'>
          <Headings.H3>Add Security Role</Headings.H3>
        </div>
      </div>
      <SecurityRolesForm
        onSubmitFormCallback={onSubmitFormCallback}
        onSecondaryButtonClicked={onBackClicked} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default AccountManagementSecurityRolesAdd;