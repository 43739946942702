import { BLANK_DATE_STRING } from "../../../../../common/utils/Constants";
import { isValidDate } from "../../../../../common/utils/validation";

export const localValidate = (formState) => {
  let errors = {};
  const effectiveDate = new Date(formState.effectiveDate);
  const expirationDate = new Date(formState.expirationDate);

  if (formState.hasNoExpirationDate === false) {
    if (formState.expirationDate === BLANK_DATE_STRING
      || !isValidDate(formState.expirationDate)) {
      errors.expirationDate = 'Expiration Date must be a valid date';
    }

    if (expirationDate < effectiveDate) {
      errors.expirationDate = 'Expiration Date must be after the Effective Date';
    }

    if (formState.effectiveDate !== BLANK_DATE_STRING
      && formState.expirationDate === BLANK_DATE_STRING) {
      errors.expirationDate = 'Expiration Date is required if there is a Effective Date';
    }
  }

  return errors;
};

const UserGroupAddFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default UserGroupAddFormValidation;