import { initCustomApi } from "../../../../utils/AccountCreationApiHelper";

const postServerSideLoginData = (username, password, state, setState) => {
  if (username && password) {
    const newState = {
      ...state,
      isServerSideLogin: true,
      isClientSideLogin: false
    };

    const api = initCustomApi(newState, setState);
    const fullUrl = `${import.meta.env.VITE_SECURITYAPI_URL}/Account/Login`;
    const headers = {
      "Content-Type": "application/json"
    };
    const payload = {
      username,
      password
    };

    return api?.executeApi ? api.executeApi(fullUrl, headers, 'POST', payload, 'include') : null;
  }
};

const postClientSideLoginData = (username, password, environmentId, flowId, state, setState) => {
  if (username && password) {
    const newState = {
      ...state,
      isServerSideLogin: false,
      isClientSideLogin: true
    };

    const api = initCustomApi(newState, setState);
    const fullUrl = `https://auth.pingone.com/${environmentId}/flows/${flowId}`;
    const headers = {
      "Content-Type": "application/vnd.pingidentity.usernamePassword.check+json"
    };
    const payload = {
      username,
      password
    };

    return api?.executeApi ? api.executeApi(fullUrl, headers, 'POST', payload, 'include') : null;
  }
};

const AccountCreationLoginData = {
  postServerSideLoginData,
  postClientSideLoginData
};

export default AccountCreationLoginData;