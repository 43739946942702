import useCSSForm from "./UseCSSForm";

import Input from "../../../../../common/components/inputs/Input";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";

const CSSForm = ({ submitButtonText = 'Save', userAccountObj, onSubmitFormCallback }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    resetForm
  } = useCSSForm(onSubmitFormCallback, userAccountObj);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label='Css User Id*'
            name="cssUserId"
            value={formState.cssUserId}
            error={errorState.cssUserId}
            message={errorState.cssUserId}
            onChange={(value) => { updateFormState('cssUserId', value) }} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default CSSForm;