import { useEffect, useState } from "react";

import useOrgRoleData from "../../../../../common/state/orgRole/UseOrgRoleData";

import { DEFAULT_ID } from "../../../../../common/utils/Constants";

const useOrgRoleByRoleGroupDropdown = (roleGroupId, orgUnitId) => {
  const { orgRoleState, getOrgRoleDataByRoleGroupIdWithOrgLevelIdOrOrgUnitId } = useOrgRoleData();
  const [optionsState, setOptionsState] = useState([]);

  useEffect(() => {
    if (roleGroupId > 0 && orgUnitId) {
      if (orgRoleState.roleGroupId !== roleGroupId || orgRoleState.orgUnitId !== orgUnitId) {
        getOrgRoleDataByRoleGroupIdWithOrgLevelIdOrOrgUnitId(roleGroupId, undefined, orgUnitId);
      } else if (orgRoleState.isArrayLoaded === true) {
        const options = orgRoleState.arrayData.map(x => { return { id: x.orgRoleId, name: x.orgRoleName } });

        options.unshift({ id: DEFAULT_ID, name: '--' });

        setOptionsState(options);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleGroupId, orgUnitId, orgRoleState.roleGroupId, orgRoleState.orgUnitId, orgRoleState.isArrayLoaded]);

  return {
    orgRoleState,
    options: optionsState
  };
};

export default useOrgRoleByRoleGroupDropdown;