import { initCustomApi } from "../../../../utils/AccountCreationApiHelper";

const postServerSideResetPasswordData = (href, currentPassword, newPassword, state, setState) => {
  if (href && currentPassword && newPassword) {
    const newState = {
      ...state,
      isServerSideLogin: true,
      isClientSideLogin: false
    };

    const api = initCustomApi(newState, setState);
    const fullUrl = `${import.meta.env.VITE_SECURITYAPI_URL}/Account/PasswordReset`;
    const headers = {
      "Content-Type": "application/json"
    };
    const payload = {
      href: href,
      currentPassword: currentPassword,
      newPassword: newPassword
    };

    return api?.executeApi ? api.executeApi(fullUrl, headers, 'POST', payload, 'include') : null;
  }
};

const postClientSideResetPasswordData = (href, currentPassword, newPassword, state, setState) => {
  if (href && currentPassword && newPassword) {
    const newState = {
      ...state,
      isServerSideLogin: false,
      isClientSideLogin: true
    };

    const api = initCustomApi(newState, setState);
    const fullUrl = `${href}`;
    const headers = {
      "Content-Type": "application/vnd.pingidentity.otp.check+json"
    };
    const payload = {
      currentPassword: currentPassword,
      newPassword: newPassword
    };

    return api?.executeApi ? api.executeApi(fullUrl, headers, 'POST', payload, 'include') : null;
  }
};

const AccountCreationExpiredPasswordData = {
  postServerSideResetPasswordData,
  postClientSideResetPasswordData
};

export default AccountCreationExpiredPasswordData;