import { useEffect } from "react";

import validate from './ExistingAccountFormValidation';

import UseForm from "../../../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  hasExistingAccount: ''
};

const useExistingAccountForm = (onSubmitFormCallback, existingAccountData) => {
  const { formState, errorState, isDirty, handleSubmit, updateFormState, setIsDirty, setFormData
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    if (existingAccountData?.hasExistingAccount === true || existingAccountData?.hasExistingAccount === false) {
      setFormData({
        ...formState,
        hasExistingAccount: existingAccountData.hasExistingAccount
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDirty !== true) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
  };
};

export default useExistingAccountForm;