import { useEffect } from 'react';
import { useLocation, useNavigate } from '../../../common/wrappers/ReactRouterDom';

import NavLinks from './NavLinks';

import useCourseEnrollmentsData from '../../state/courseEnrollments/UseCourseEnrollmentsData';

const BACK_TO_LABELS = {
  searchBackToLabel: 'Back to Course Enrollment Search'
};

const useCourseEnrollmentsContextView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { courseEnrollmentsState, getCourseEnrollments } = useCourseEnrollmentsData();

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.COURSE_ENROLLMENTS_SEARCH);
  };

  useEffect(() => {
    if (location.state?.performGet === true && courseEnrollmentsState.personId) {
      getCourseEnrollments(courseEnrollmentsState.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  function getBackToLabel() {
    return BACK_TO_LABELS.searchBackToLabel;
  };

  return {
    courseEnrollmentsState,
    backToLabel: getBackToLabel(),
    onBackClicked
  };
};

export default useCourseEnrollmentsContextView;