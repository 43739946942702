import { useState } from 'react';

import AccountCreationChangePasswordData from './AccountCreationChangePasswordData';

import { BASIC_INITIAL_STATE } from '../../../../../common/utils/HttpHelper';

const INITIAL_ACCOUNT_CREATION_CHANGE_PASSWORD_STATE = {
  ...BASIC_INITIAL_STATE,
};

const useAccountCreationChangePasswordData = () => {
  const [accountCreationChangePasswordState, setAccountCreationChangePasswordState] = useState(INITIAL_ACCOUNT_CREATION_CHANGE_PASSWORD_STATE);

  const postChangePassword = (changePasswordObj) => {
    return AccountCreationChangePasswordData.postChangePasswordData(changePasswordObj, accountCreationChangePasswordState, setAccountCreationChangePasswordState);
  };

  const resetAccountCreationChangePasswordState = () => {
    setAccountCreationChangePasswordState(INITIAL_ACCOUNT_CREATION_CHANGE_PASSWORD_STATE);
  };

  return {
    accountCreationChangePasswordState,
    resetAccountCreationChangePasswordState,
    postChangePassword
  };
};

export default useAccountCreationChangePasswordData;