import React, { Fragment } from "react";

import useCourseEnrollmentsManage from "./UseCourseEnrollmentsManage";

import CourseEnrollmentsManagementGrid from "../components/grids/CourseEnrollmentsManagementGrid";

import ActionIntraPageButton from "../../../../common/components/buttons/ActionIntraPageButton";
import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import Constants from "../../../../common/utils/Constants";

import style from './CourseEnrollmentsManage.module.css';

const CourseEnrollmentsManage = () => {
  const {
    isSaving,
    courseEnrollmentsState,
    onAddCompletedCourse,
    onEditCourseEnrollment,
    onDeleteCourseEnrollment,
    onAddEnrollmentNoCompletion
  } = useCourseEnrollmentsManage();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Course Enrollment Management</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className='col-xs-12 col-md-3 usas-extra-top-margin'>
          <ActionIntraPageButton onClick={onAddCompletedCourse}>Add Completed Course</ActionIntraPageButton>
        </div>
        <div className='col-xs-12 col-md-3 usas-extra-top-margin'>
          <ActionIntraPageButton className={style.NoCompletionButton} onClick={onAddEnrollmentNoCompletion}>Add Enrollment</ActionIntraPageButton>
        </div>
      </div>
      <div className='row'>
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <CourseEnrollmentsManagementGrid
            data={courseEnrollmentsState.objData?.courses || []}
            isLoading={courseEnrollmentsState.isObjLoading}
            onEditCourseEnrollment={onEditCourseEnrollment}
            onDeleteCourseEnrollment={onDeleteCourseEnrollment} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default CourseEnrollmentsManage;