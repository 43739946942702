import { Fragment } from "react";

import useAccountCreationChangePassword from "./UseAccountCreationChangePassword";

import ChangePasswordForm from "../../components/forms/ChangePasswordForm";

import Headings from "../../../../../../../common/components/headings/Headings";
import PopUpModal from "../../../../../../../common/components/dialogs/PopUpModal";
import SecondaryButton from "../../../../../../../common/components/buttons/SecondaryButton";

import Constants from "../../../../../../../common/utils/Constants";

const AccountCreationChangePassword = () => {
  const {
    isLoading,
    apiErrorPopupState,
    username,
    onCancelClicked,
    onCloseAPIErrorPopupClicked,
    onSubmitFormCallback
  } = useAccountCreationChangePassword();

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <Headings.H3>Set New Password</Headings.H3>
        <p>If you have an active account with a valid email address, you will receive an email with a recovery code which you may enter here, along with a new password. If you do not have an account or email, please contact USA Swimming to recover your password.</p>
        <ChangePasswordForm
          submitButtonText={'Submit'}
          secondaryButtonText={'Cancel'}
          userName={username}
          onSubmitFormCallback={onSubmitFormCallback}
          onSecondaryButtonClicked={onCancelClicked} />
      </div>
      <PopUpModal
        widthPct={60}
        maxWidth={720}
        title={'Error'}
        displayPopUp={apiErrorPopupState.displayPopup === true}>
        <div className="row">
          <div className="col-xs-12">
            <p>{apiErrorPopupState.errorMessage || 'An error occurred.'}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SecondaryButton type={'button'} onClick={onCloseAPIErrorPopupClicked}>Close</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default AccountCreationChangePassword;