import Constants from "../../../../../../../common/utils/Constants";
import { isValidBirthDate, isValidDate, isValidEmail, isValidName } from "../../../../../../../common/utils/validation";

export const localValidate = (formState) => {
  let errors = {};

  //First Name
  if (formState.firstName.trim() === '') {
    errors.firstName = 'Legal First Name is required';
  } else if (!isValidName(formState.firstName.trim())) {
    errors.firstName = 'Legal First Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  //Preferred Name
  if (formState.preferredName.trim() !== '') {
    if (!isValidName(formState.preferredName.trim())) {
      errors.preferredName = 'Preferred Name cannot contain numbers, special characters, or exceed 100 characters';
    }
  }

  //Middle Name
  if (formState.middleName.trim() !== '') {
    if (!isValidName(formState.middleName.trim())) {
      errors.middleName = 'Middle Name cannot contain numbers, special characters, or exceed 100 characters';
    }
  }

  //Last Name
  if (formState.lastName.trim() === '') {
    errors.lastName = 'Legal Last Name is required';
  } else if (!isValidName(formState.lastName.trim())) {
    errors.lastName = 'Legal Last Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  //Email
  if (formState.email.trim() === '') {
    errors.email = 'Email is required';
  } else if (!isValidEmail(formState.email.trim())) {
    errors.email = 'Email must be in a valid format';
  } else if (formState.email.trim().length > 512) {
    errors.email = 'Email cannot exceed 512 characters'
  }

  //Birth Date
  if (formState.birthDate === Constants.BLANK_DATE_STRING) {
    errors.birthDate = 'Birth Date is required';
  } else if (!isValidDate(formState.birthDate)) {
    errors.birthDate = 'Birth Date must be a valid date';
  } else if (!isValidBirthDate(formState.birthDate)) {
    errors.birthDate = 'Birth Date cannot be in the future';
  }

  // Password
  if (formState.password !== formState.confirmPassword) {
    errors.confirmPassword = 'Confirm Password does not match Password';
  }

  return errors;
};

const UserInfoFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default UserInfoFormValidation;