import { useEffect, useState } from 'react';

import useGlobalRoutesData from '../../../../../common/state/security/UseGlobalRoutesData';
import useMemberPersistentStorage from '../../../../../common/state/memberPersistentStorage/UseMemberPersistentStorage';
import useLandingPageData from '../../../../state/landingPages/UseLandingPageData';

const INITIAL_STATE = {
  links: [],
  isLoading: true
};

const useMyAccount = () => {
  const [state, setState] = useState(INITIAL_STATE);
  const { landingPageState, navigateToDeepRoute } = useLandingPageData();
  const { getGlobalRoute } = useGlobalRoutesData();
  const { setPersistentStorage } = useMemberPersistentStorage();

  const handleClick = (routeObj) => {
    setPersistentStorage(
      landingPageState.currentPerson?.personId,
      import.meta.env.VITE_PROJECT_NAME,
      getGlobalRoute('HUB_LANDING_PAGES')?.route,
      'Back to My Dashboard'
    );
    
    navigateToDeepRoute(routeObj);
  };

  useEffect(() => {
    const links = [
      { label: 'General Info', action: () => handleClick(getGlobalRoute('MEMBER_INFO')) },
      { label: 'Contacts', action: () => handleClick(getGlobalRoute('MEMBER_CONTACTS')) },
      { label: 'Photos', action: () => handleClick(getGlobalRoute('MEMBER_PROFILE_PHOTO')) },
      { label: 'Groups', action: () => handleClick(getGlobalRoute('MEMBER_PROFILE_GROUPS')) }
    ];
    if (landingPageState.currentPerson?.personas.find(x => 'Athlete Coach'.includes(x.personaName))) {
      links.push({ label: 'Club Transfer', action: () => handleClick(getGlobalRoute('MEMBER_CLUB_TRANSFER')) });
    }
    if (landingPageState.currentPerson?.personas.find(x => x.personaName === 'Athlete')) {
      links.push({ label: 'Upgrades', action: () => handleClick(getGlobalRoute('MEMBER_UPGRADE')) });
      links.push({ label: 'Times', action: () => handleClick(getGlobalRoute('MEMBER_TIMES')) });
    }
    setState({
      links,
      isLoading: false
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...state
  };
};

export default useMyAccount;