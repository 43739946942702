import useAccountCreation from "./UseAccountCreation";

import AccountCreationLogin from "./subViews/login/AccountCreationLogin";
import AccountCreationExistingAccount from "./subViews/existingAccount/AccountCreationExistingAccount";
import AccountCreationConfirmAge from "./subViews/confirmAge/AccountCreationConfirmAge";
import AccountCreationUserInfo from "./subViews/userInfo/AccountCreationUserInfo";
import AccountCreationValidateEmail from "./subViews/validateEmail/AccountCreationValidateEmail";
import AccountCreationOneTimePassword from "./subViews/oneTimePassword/AccountCreationOneTimePassword";
import AccountCreationMFA from "./subViews/mfa/AccountCreationMFA";
import AccountCreationForgotUsername from "./subViews/forgotUsername/AccountCreationForgotUsername";
import AccountCreationForgotPassword from "./subViews/forgotPassword/AccountCreationForgotPassword";
import AccountCreationChangePassword from "./subViews/changePassword/AccountCreationChangePassword";
import AccountCreationExpiredPassword from "./subViews/expiredPassword/AccountCreationExpiredPassword";

import {
  AC_STEP_CONFIRM_AGE, AC_STEP_EXISTING_ACCOUNT, AC_STEP_FORGOT_PASSWORD, AC_STEP_FORGOT_USERNAME,
  AC_STEP_LOGIN, AC_STEP_MFA, AC_STEP_ONE_TIME_PASSWORD, AC_STEP_CHANGE_PASSWORD, AC_STEP_USER_INFO, AC_STEP_VALIDATE_EMAIL, AC_STEP_EXPIRED_PASSWORD
} from "./utils/AccountCreationConstants";

const AccountCreation = () => {
  const {
    currentStep
  } = useAccountCreation();

  switch (currentStep) {
    case AC_STEP_LOGIN:
      return <AccountCreationLogin />;

    case AC_STEP_ONE_TIME_PASSWORD:
      return <AccountCreationOneTimePassword />;

    case AC_STEP_EXPIRED_PASSWORD:
      return <AccountCreationExpiredPassword />;

    case AC_STEP_MFA:
      return <AccountCreationMFA />;

    case AC_STEP_FORGOT_PASSWORD:
      return <AccountCreationForgotPassword />;

    case AC_STEP_CHANGE_PASSWORD:
      return <AccountCreationChangePassword />;

    case AC_STEP_FORGOT_USERNAME:
      return <AccountCreationForgotUsername />;

    case AC_STEP_EXISTING_ACCOUNT:
      return <AccountCreationExistingAccount />;

    case AC_STEP_CONFIRM_AGE:
      return <AccountCreationConfirmAge />;

    case AC_STEP_USER_INFO:
      return <AccountCreationUserInfo />;

    case AC_STEP_VALIDATE_EMAIL:
      return <AccountCreationValidateEmail />;

    default:
      return <AccountCreationLogin />;
  }
};

export default AccountCreation;