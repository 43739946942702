import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const getUserGroupsData = (state, setState) => {
  const api = initApi(API_NAMES.COMMON, state, setState);
  const url = `/UserGroup`;

  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const UserGroupData = {
  getUserGroupsData
};

export default UserGroupData;