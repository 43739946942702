import { useContext, useState } from 'react';

import AccountManagementUserGroupData from './AccountManagementUserGroupData';

import { AccountManagementUserGroupStateContext } from './AccountManagementUserGroupContextProvider';

import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

const INITIAL_ACCOUNT_MANAGEMENT_USER_GROUP_SAVE_STATE = {
  ...BASIC_INITIAL_STATE
};

const useAccountManagementUserGroupData = () => {
  const [accountManagementUserGroupState, setAccountManagementUserGroupState] = useContext(AccountManagementUserGroupStateContext);
  const [accountManagementUserGroupSaveState, setAccountManagementUserGroupSaveState] = useState(INITIAL_ACCOUNT_MANAGEMENT_USER_GROUP_SAVE_STATE);

  const getAccountManagementUserGroups = (userAccountId) => {
    return AccountManagementUserGroupData.getAccountManagementUserGroupsData(userAccountId, accountManagementUserGroupState, setAccountManagementUserGroupState);
  };

  const putAccountManagementUserGroup = (userAccountId, userGroupObj) => {
    return AccountManagementUserGroupData.putAccountManagementUserGroupData(userAccountId, userGroupObj, accountManagementUserGroupSaveState, setAccountManagementUserGroupSaveState);
  };

  const deleteAccountManagementUserGroup = (userAccountGroupId) => {
    return AccountManagementUserGroupData.deleteAccountManagementUserGroupData(userAccountGroupId, accountManagementUserGroupSaveState, setAccountManagementUserGroupSaveState);
  };

  const resetUserGroupSaveState = () => {
    setAccountManagementUserGroupSaveState({
      ...INITIAL_ACCOUNT_MANAGEMENT_USER_GROUP_SAVE_STATE
    });
  };

  return {
    accountManagementUserGroupState,
    accountManagementUserGroupSaveState,
    resetUserGroupSaveState,
    getAccountManagementUserGroups,
    putAccountManagementUserGroup,
    deleteAccountManagementUserGroup
  };
};

export default useAccountManagementUserGroupData;