import { Fragment } from "react";

import useAccountManagementCSS from "./UseAccountManagementCSS";

import CSSForm from "../components/forms/CSSForm";

import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";

import Constants from "../../../../common/utils/Constants";

const AccountManagementCSS = () => {
  const {
    isLoading,
    isSaving,
    userAccountObj,
    onSubmitFormCallback
  } = useAccountManagementCSS();

  return (
    <Fragment>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12'>
          <Headings.H3>Css User Id</Headings.H3>
        </div>
      </div>
      <CSSForm
        userAccountObj={userAccountObj}
        onSubmitFormCallback={onSubmitFormCallback} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default AccountManagementCSS;