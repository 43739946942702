export const localValidate = (formState) => {
  let errors = {};

  if (formState.oneTimePassword.trim() === '') {
    errors.oneTimePassword = 'One Time Code is required';
  }

  return errors;
};

const OneTimePasswordFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default OneTimePasswordFormValidation;