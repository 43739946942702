import React, { Fragment } from "react";

import useCourseEnrollmentsManageDetail from "./UseCourseEnrollmentsManageDetail";

import CourseEnrollmentDetailForm from "../components/forms/CourseEnrollmentDetailForm";

import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";

import Constants from "../../../../common/utils/Constants";

const CourseEnrollmentsManageDetail = () => {
  const {
    isEdit,
    isSaving,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onCourseEnrollmentDateChanged,
    onNoExpirationDateChanged,
    onHasExemptionChanged,
    onBackClicked
  } = useCourseEnrollmentsManageDetail();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Completed Course Enrollment {isEdit ? 'Edit' : 'Add'}</Headings.H3>
        </div>
      </div>
      <CourseEnrollmentDetailForm
        formState={formState}
        errorState={errorState}
        isEdit={isEdit}
        onFormValueChanged={onFormValueChanged}
        onValueTextPairChanged={onValueTextPairChanged}
        onCourseEnrollmentDateChanged={onCourseEnrollmentDateChanged}
        onNoExpirationDateChanged={onNoExpirationDateChanged}
        onHasExemptionChanged={onHasExemptionChanged}
        handleSubmit={handleSubmit}
        onBackClicked={onBackClicked} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default CourseEnrollmentsManageDetail;