import { useState } from 'react';

import AccountCreationOneTimePasswordData from './AccountCreationOneTimePasswordData';

import { BASIC_INITIAL_STATE } from '../../../../../common/utils/HttpHelper';

const INITIAL_ACCOUNT_CREATION_ONE_TIME_PASSWORD_STATE = {
  ...BASIC_INITIAL_STATE,
  isServerSideLogin: false,
  isClientSideLogin: false
};

const useAccountCreationOneTimePasswordData = () => {
  const [accountCreationOneTimePasswordState, setAccountCreationOneTimePasswordState] = useState(INITIAL_ACCOUNT_CREATION_ONE_TIME_PASSWORD_STATE);

  const postServerSideOneTimePassword = (href, oneTimePassword) => {
    return AccountCreationOneTimePasswordData.postServerSideOneTimePasswordData(href, oneTimePassword, accountCreationOneTimePasswordState, setAccountCreationOneTimePasswordState);
  };

  const postClientSideOneTimePassword = (href, oneTimePassword) => {
    return AccountCreationOneTimePasswordData.postClientSideOneTimePasswordData(href, oneTimePassword, accountCreationOneTimePasswordState, setAccountCreationOneTimePasswordState);
  };

  const resetAccountCreationOneTimePasswordState = () => {
    setAccountCreationOneTimePasswordState(INITIAL_ACCOUNT_CREATION_ONE_TIME_PASSWORD_STATE);
  };

  return {
    accountCreationOneTimePasswordState,
    resetAccountCreationOneTimePasswordState,
    postServerSideOneTimePassword,
    postClientSideOneTimePassword
  };
};

export default useAccountCreationOneTimePasswordData;