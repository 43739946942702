import { useState, useEffect } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../NavLinks";

import useCourseEnrollmentsData from "../../../state/courseEnrollments/UseCourseEnrollmentsData";

import useSecurityData from "../../../../common/state/security/UseSecurityData";

const INITIAL_STATE = {
  isItemSelected: false
};

const TAXONOMIES = [''];
const SCOPE = 'CourseEnrollments';

const useCourseEnrollmentsSearch = () => {
  const navigate = useNavigate();
  const { getContextSecurity } = useSecurityData();
  const { courseEnrollmentsState, courseEnrollmentsFilterState, searchCourseEnrollments, getCourseEnrollments,
    clearObjData, setShowFilters } = useCourseEnrollmentsData();
  const [state, setState] = useState(INITIAL_STATE);

  const onShowFiltersClicked = () => {
    setShowFilters(!courseEnrollmentsFilterState.showFilters);
  };

  const onEditPersonCourseEnrollments = (personId) => {
    if (personId) {
      getCourseEnrollments(personId);
      getContextSecurity(personId, TAXONOMIES, SCOPE);

      setState({
        ...state,
        isItemSelected: true
      });
    }
  };

  useEffect(() => {
    if (state.isItemSelected === true) {
      if (courseEnrollmentsState.isObjLoading !== true && courseEnrollmentsState.isObjLoaded === true) {
        if (courseEnrollmentsState.personId) {
          navigate(NavLinks.COURSE_ENROLLMENTS_MANAGE);
        } else {
          setState(INITIAL_STATE);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, courseEnrollmentsState]);

  useEffect(() => {
    clearObjData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onSubmitFormCallback(formState) {
    const filterObject = createSearchFilterObj(formState);

    searchCourseEnrollments(filterObject);
  };

  function createSearchFilterObj(formState) {
    return {
      firstName: formState.firstName ? formState.firstName.trim() : undefined,
      lastName: formState.lastName ? formState.lastName.trim() : undefined,
      lmsCourseId: formState.lmsCourseId > 0 ? formState.lmsCourseId : undefined,
      memberId: formState.memberId ? formState.memberId.trim() : undefined,
    };
  };

  return {
    courseEnrollmentsState,
    courseEnrollmentsFilterState,
    onSubmitFormCallback,
    onShowFiltersClicked,
    onEditPersonCourseEnrollments
  };
};

export default useCourseEnrollmentsSearch;