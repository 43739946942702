import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";

const getAccountManagementUsernameData = (state, setState) => {
  const api = initApi(API_NAMES.SECURITY, state, setState);
  const url = `/UserAccounts/Username`;

  return api?.executeObject ? api.executeObject(url, 'GET') : null;
};

const getAccountManagementUsernameByUserAccountIdData = (userAccountId, state, setState) => {
  if (userAccountId > 0) {
    const api = initApi(API_NAMES.SECURITY, state, setState);
    const userAccountIdForUrl = encodeURIComponent(userAccountId);
    const url = `/UserAccounts/${userAccountIdForUrl}/Username`;

    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const putAccountManagementUsernameData = (usernameObj, state, setState) => {
  const api = initApi(API_NAMES.SECURITY, state, setState);
  const url = `/UserAccounts/Username`;

  return api?.executeObject ? api.executeObject(url, 'PUT', usernameObj) : null;
};

const putAccountManagementUsernameByUserAccountIdData = (userAccountId, usernameObj, state, setState) => {
  if (userAccountId > 0) {
    const api = initApi(API_NAMES.SECURITY, state, setState);
    const userAccountIdForUrl = encodeURIComponent(userAccountId);
    const url = `/UserAccounts/${userAccountIdForUrl}/Username`;

    return api?.executeObject ? api.executeObject(url, 'PUT', usernameObj) : null;
  }
};

const AccountManagementUsernameData = {
  getAccountManagementUsernameData,
  getAccountManagementUsernameByUserAccountIdData,
  putAccountManagementUsernameData,
  putAccountManagementUsernameByUserAccountIdData
};

export default AccountManagementUsernameData;