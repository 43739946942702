import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const PutPersonNotificationSettingData = (personId, notificationSettingsArray, state, setState) => {
  if (state.isSaving !== true && !state.message) {
    const newState = {
      ...state,
      isSaving: true,
      isSaved: false,
      message: 'Saving...'
    };
    setState(newState);

    const personIdForUrl = personId ? encodeURIComponent(personId) : 0;
    const url = `/PersonNotificationSetting/Person/${personIdForUrl}`;
    PersonHttpHelper(url, 'PUT', notificationSettingsArray)
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            arrayData,
            isSaving: false,
            isSaved: true,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isSaving: false,
          isSaved: false,
          message: message
        });
      });
  }
};

export default PutPersonNotificationSettingData;