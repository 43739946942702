import { useContext, useState } from 'react';

import AccountManagementEmailData from './AccountManagementEmailData';

import { AccountManagementEmailStateContext } from './AccountManagementEmailContextProvider';

import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

const INITIAL_ACCOUNT_MANAGEMENT_EMAIL_SAVE_STATE = {
  ...BASIC_INITIAL_STATE
};

const useAccountManagementEmailData = () => {
  const [accountManagementEmailState, setAccountManagementEmailState] = useContext(AccountManagementEmailStateContext);
  const [accountManagementEmailSaveState, setAccountManagementEmailSaveState] = useState(INITIAL_ACCOUNT_MANAGEMENT_EMAIL_SAVE_STATE);

  const getAccountManagementEmailByUserAccountId = (userAccountId) => {
    return AccountManagementEmailData.getAccountManagementEmailByUserAccountIdData(userAccountId, accountManagementEmailState, setAccountManagementEmailState);
  };

  const getAccountManagementEmail = () => {
    return AccountManagementEmailData.getAccountManagementEmailData(accountManagementEmailState, setAccountManagementEmailState);
  };

  const putAccountManagementEmailByUserAccountId = (userAccountId, emailObj) => {
    return AccountManagementEmailData.putAccountManagementEmailByUserAccountIdData(userAccountId, emailObj, accountManagementEmailSaveState, setAccountManagementEmailSaveState);
  };

  const putAccountManagementEmail = (emailObj) => {
    return AccountManagementEmailData.putAccountManagementEmailData(emailObj, accountManagementEmailSaveState, setAccountManagementEmailSaveState);
  };

  const putAccountManagementEmailConfirmation = (confirmationObj) => {
    return AccountManagementEmailData.putAccountManagementEmailConfirmationData(confirmationObj, accountManagementEmailSaveState, setAccountManagementEmailSaveState);
  };

  const resetEmailSaveState = () => {
    setAccountManagementEmailSaveState({
      ...INITIAL_ACCOUNT_MANAGEMENT_EMAIL_SAVE_STATE
    });
  };

  return {
    accountManagementEmailState,
    accountManagementEmailSaveState,
    resetEmailSaveState,
    getAccountManagementEmailByUserAccountId,
    getAccountManagementEmail,
    putAccountManagementEmailByUserAccountId,
    putAccountManagementEmail,
    putAccountManagementEmailConfirmation
  };
};

export default useAccountManagementEmailData;