/**
 * Custom Api Helper used by some calls in AccountCreation that do not fit the standard Api Helper pattern
 * Have non-standard urls, headers, & credentials
 */
import { API_ERROR_MESSAGE, CORS_ERROR_MESSAGE, NO_DATA_MESSAGE } from "../../common/utils/HttpHelper";

// Custom Error object that also includes the status code returned by the API
function ApiException(message, status) {
  const error = new Error(message || API_ERROR_MESSAGE);
  error.code = status || 500;

  return error;
}
ApiException.prototype = Object.create(Error.prototype);

const handleUnsuccessfulResponse = (response) => {
  if (response.status === 400) {
    const responseText = response.text();

    responseText.then((text) => {
      throw new ApiException(text, response.status);
    });
  } else if (response.status === 404) {
    throw new ApiException(NO_DATA_MESSAGE, response.status);
  } else if (response.status >= 500) {
    throw new ApiException(CORS_ERROR_MESSAGE, response.status);
  } else {
    throw new ApiException(API_ERROR_MESSAGE, response.status);
  }
};

const jsonApiHelper = async (fullUrl, headers, method, payload, credentials) => {
  return fetch(fullUrl, {
    method,
    headers: headers,
    body: JSON.stringify(payload),
    credentials: credentials || 'same-origin'
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      handleUnsuccessfulResponse(response);
    }
  });
};

const ApiHelper = (fullUrl, headers, method, payload, credentials) => {
  console.log(`${method}: ${fullUrl}`);

  return jsonApiHelper(fullUrl, headers, method, payload, credentials);
};

const executeObjectApi = async (state, setState, fullUrl, headers, method, payload, credentials) => {
  const apiHelper = ApiHelper;
  if (state.message || state.isObjLoading === true || !apiHelper) {
    return null;
  }

  let newState = {
    ...state,
    isObjLoading: true,
    isObjLoaded: false,
    message: 'Loading...'
  };
  setState({ ...newState });

  return apiHelper(fullUrl, headers, method, payload, credentials)
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      } else {
        newState = {
          ...newState,
          isObjLoading: false,
          isObjLoaded: true,
          objData,
          message: ''
        };
        setState({ ...newState });

        return newState;
      }
    }).catch((e) => {
      setState({
        ...newState,
        isObjLoading: false,
        isObjLoaded: false,
        message: e?.message || NO_DATA_MESSAGE
      });

      throw e;
    });
};

export const initCustomApi = (state, setState) => {
  const executeApi = async (fullUrl, headers, method, payload, credentials = undefined) => {
    return executeObjectApi(state, setState, fullUrl, headers, method, payload, credentials);
  };

  return {
    executeApi
  };
};

const AccountCreationApiHelper = { initCustomApi };

export default AccountCreationApiHelper;