import { useEffect, useState } from 'react';

import useLandingPageData from '../../../../state/landingPages/UseLandingPageData';
import usePersonParentChildData from '../../../../state/landingPages/personParentChild/UsePersonParentChildData';
// import useAccountCreationPersistentStorage from '../../../../state/accountCreationPersistentStorage/UseAccountCreationPersistentStorage';

import useSecurityData from '../../../../../common/state/security/UseSecurityData';

import { formatDate } from '../../../../../common/utils/DateFunctions';

const useChildLanding = () => {
  const { landingPageState, childrenState, setLoggedInAsCurrent } = useLandingPageData();
  const { personParentChildState, getPersonParentChildByPersonId, deletePersonParentChild } = usePersonParentChildData();
  const [modalState, setModalState] = useState(getInitialModalState());
  const { logoutGoToAccountCreation } = useSecurityData();
  // const { acCookies } = useAccountCreationPersistentStorage();

  const onClick = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    setLoggedInAsCurrent();
  };

  const onOpenModal = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState({ ...modalState, displayPopUp: true });
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setModalState(getInitialModalState());
  };

  const onRemoveLinkToParentClick = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    onModalCanceled();
    deletePersonParentChild(personParentChildState.objData.personParentChildId);
  };

  const onCreateLogin = (e, memberId) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    createAccountCreationChildLogin(memberId);
  };

  useEffect(() => {
    if (landingPageState.currentPerson && landingPageState.currentPerson?.personId) {
      getPersonParentChildByPersonId(landingPageState.currentPerson?.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingPageState]);

  useEffect(() => {
    if (personParentChildState.isSaved) {
      setLoggedInAsCurrent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personParentChildState.isSaved]);

  function createAccountCreationChildLogin(memberId) {
    const targetChild = childrenState.arrayData.find(x => x.memberId === memberId);
    const paramsObj = {
      firstName: targetChild?.firstName,
      lastName: targetChild?.lastName,
      middleName: targetChild?.middleName,
      preferredName: targetChild?.preferredName,
      birthDate: targetChild?.birthDate ? formatDate(targetChild.birthDate) : undefined,
      memberId: targetChild?.memberId
    };

    logoutGoToAccountCreation(paramsObj);
  }

  function getInitialModalState() {
    return {
      modalTitle: "Remove from 'My Family' Confirmation",
      displayPopUp: false,
    }
  }

  return {
    onClick,
    onOpenModal,
    onModalCanceled,
    onRemoveLinkToParentClick,
    personParentChildState,
    isLoading: personParentChildState.isObjLoading,
    isSaving: personParentChildState.isSaving,
    modalState,
    landingPageState,
    onCreateLogin
  };
};

export default useChildLanding;