import { useState } from "react";
import { useSearchParams } from "../../../../../../../common/wrappers/ReactRouterDom";

import useAccountCreationData from "../../../../../../state/landingPages/accountCreation/UseAccountCreationData";
import useAccountCreationMFAData from "../../../../../../state/landingPages/accountCreation/accountCreationMFA/UseAccountCreationMFAData";

const INITIAL_LOGIN_ERROR_POPUP_STATE = {
  displayPopup: false,
  errorMessage: ''
};

const useAccountCreationMFA = () => {
  const [searchParams,] = useSearchParams();
  const { accountCreationState, resetAccountCreationState } = useAccountCreationData();
  const { accountCreationMFAState, postSetMFA, resetAccountCreationMFAState } = useAccountCreationMFAData();
  const [loginErrorPopupState, setLoginErrorPopupState] = useState(INITIAL_LOGIN_ERROR_POPUP_STATE);

  const onCancelClicked = () => {
    resetAccountCreationState(); // clears state and sends back to login
  };

  const onCloseLoginErrorPopupClicked = () => {
    resetAccountCreationMFAState();
    setLoginErrorPopupState(INITIAL_LOGIN_ERROR_POPUP_STATE);
  };

  const onSubmitFormCallback = (formState) => {
    const accessToken = accountCreationState.loginData?.accessToken;
    const email = formState.useEmail === true;
    const textMessage = formState.useTextMessage === true;
    const phoneNumber = formState.useTextMessage === true ? formState.phoneNumber : '';

    const postSetMFAPromise = postSetMFA(accessToken, email, textMessage, phoneNumber);

    if (postSetMFAPromise ?? false) {
      postSetMFAPromise.then((newState) => {
        if (newState ?? false) {
          // Successful login
          if (searchParams.get('retUrl')) {
            window.location.href = `${searchParams.get('retUrl')}?token=${accessToken}`;
          } else if (window.location.href.indexOf('?') === -1) {
            window.location.href = `${window.location.href}?token=${accessToken}`;
          } else {
            window.location.href = `${window.location.href}&token=${accessToken}`;
          }
        }
      }).catch((e) => {
        setLoginErrorPopupState({
          ...loginErrorPopupState,
          displayPopup: true,
          errorMessage: e?.message
        });
      });
    }
  };

  return {
    isLoading: accountCreationMFAState.isObjLoading,
    device: accountCreationState.loginData?.emailAddress || '',
    loginErrorPopupState,
    onSubmitFormCallback,
    onCancelClicked,
    onCloseLoginErrorPopupClicked
  };
};

export default useAccountCreationMFA;