import { useEffect } from 'react';
import useForm from "../../../common/utils/UseForm";

import useShoppingCartItemData from '../../state/shoppingCartItem/UseShoppingCartItemData';

import validate from "./ManageCartsValidation";

const useManageCarts = () => {
  const { formState, errorState, handleSubmit, onFormValueChanged, isDirty, setIsDirty } = useForm(getInitialFormState(), submitFormCallback, validate);
  const { shoppingCartItemState, getShoppingCartItems, deleteShoppingCartItem, clearArrayData } = useShoppingCartItemData();

  const onDeleteCartItem = (personId, deletionAction) => {
    deleteShoppingCartItem(personId, deletionAction.replace(/'/g, ""));
  };

  const onCloseErrorPopup = () => {
    clearArrayData();
  };

  useEffect(() => {
    if (isDirty !== true) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  function submitFormCallback() {
    getShoppingCartItems(formState.memberId);
  }

  function getInitialFormState() {
    return {
      memberId: ''
    }
  }

  return {
    isError: shoppingCartItemState.isArrayLoading !== true && shoppingCartItemState.message,
    shoppingCartItemState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onDeleteCartItem,
    onCloseErrorPopup
  };
};

export default useManageCarts;