import { Fragment } from "react";

import useAccountManagementUserGroup from "./UseAccountManagementUserGroup";

import UserGroupGrid from "../components/grids/UserGroupGrid";

import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import ActionIntraPageButton from "../../../../common/components/buttons/ActionIntraPageButton";

import Constants from "../../../../common/utils/Constants";

const AccountManagementUserGroup = () => {
  const {
    isLoading,
    isSaving,
    gridData,
    onAddUserGroupClicked,
    onDeleteUserGroupClicked
  } = useAccountManagementUserGroup();

  return (
    <Fragment>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12'>
          <Headings.H3>User Group</Headings.H3>
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12'>
          <ActionIntraPageButton type='button' onClick={onAddUserGroupClicked}>Add User Groups</ActionIntraPageButton>
        </div>
      </div>
      <UserGroupGrid
        data={gridData}
        isLoading={isLoading}
        onDeleteClicked={onDeleteUserGroupClicked} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default AccountManagementUserGroup;