import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';
import getSystemMessages from './GetSystemMessageData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const SystemMessageData = { INITIAL_STATE: { ...INITIAL_STATE }, getSystemMessages };

export default SystemMessageData;
