import { Fragment } from "react";

import useAccountCreationLogin from "./UseAccountCreationLogin";

import LoginForm from "../../components/forms/LoginForm";

import Headings from "../../../../../../../common/components/headings/Headings";
// import LoginInstructions from "../../../../../../../common/components/loginInstructions/LoginInstructions";
import PopUpModal from "../../../../../../../common/components/dialogs/PopUpModal";
import SecondaryButton from "../../../../../../../common/components/buttons/SecondaryButton";

import Constants from "../../../../../../../common/utils/Constants";

const AccountCreationLogin = () => {
  const {
    isLoading,
    loginErrorPopupState,
    onSubmitFormCallback,
    onForgotUsernameClicked,
    onForgotPasswordClicked,
    onCreateALoginClicked,
    onCloseLoginErrorPopupClicked
  } = useAccountCreationLogin();

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 col-sm-2 col-md-3" />
        <div className="col-xs-12 col-sm-8 col-md-6">
          <Headings.H3>Login</Headings.H3>
          <p>Please login with your username and password.</p>
          <LoginForm
            onSubmitFormCallback={onSubmitFormCallback}
            onForgotUsernameClicked={onForgotUsernameClicked}
            onForgotPasswordClicked={onForgotPasswordClicked}
            onCreateALoginClicked={onCreateALoginClicked} />
        </div>
        <div className="col-xs-12 col-sm-2 col-md-3" />
        {/* <div className="col-xs-12 col-md-6 usas-extra-bottom-margin">
          <Headings.H3>Help</Headings.H3>
          <div style={{ marginTop: -21 }}>
            <LoginInstructions hideHeading={true} textAlign="left" />
          </div>
        </div> */}
      </div>
      <PopUpModal
        widthPct={60}
        maxWidth={720}
        title={'Login Error'}
        displayPopUp={loginErrorPopupState.displayPopup === true}>
        <div className="row">
          <div className="col-xs-12">
            <p>{loginErrorPopupState.errorMessage || 'An error occurred.'}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SecondaryButton type={'button'} onClick={onCloseLoginErrorPopupClicked}>Close</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment >
  );
};

export default AccountCreationLogin;