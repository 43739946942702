import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getShoppingCartItemData from './GetShoppingCartItemData';
import deleteShoppingCartItemData from './DeleteShoppingCartItemData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const ShoppingCartItemData = {
  INITIAL_STATE,
  getShoppingCartItemData,
  deleteShoppingCartItemData
};

export default ShoppingCartItemData;