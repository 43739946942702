export const localValidate = (formState) => {
  let errors = {};

  if (formState.hasExistingAccount !== true && formState.hasExistingAccount !== false) {
    errors.hasExistingAccount = 'Must select Yes or No';
  }

  return errors;
};

const ExistingAccountFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default ExistingAccountFormValidation;