/* eslint-disable react/no-unescaped-entities */
import { Fragment } from "react";

import useAccountCreationConfirmAge from "./UseAccountCreationConfirmAge";

import ConfirmAgeForm from "../../components/forms/ConfirmAgeForm";

import Headings from "../../../../../../../common/components/headings/Headings";
import PopUpModal from "../../../../../../../common/components/dialogs/PopUpModal";
// import LoginInstructions from "../../../../../../../common/components/loginInstructions/LoginInstructions";
import SecondaryButton from "../../../../../../../common/components/buttons/SecondaryButton";

import style from '../../../../LandingPages.module.css';

const AccountCreationConfirmAge = () => {
  const {
    confirmAgeData,
    displayInvalidPopupState,
    onBackClicked,
    onReturnToLoginClicked,
    onSubmitFormCallback
  } = useAccountCreationConfirmAge();

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12 col-md-4" />
        <div className={['col-xs-12', 'col-md-4', style.CenterPageContent].join(' ')}>
          <Headings.H3>Confirm Age</Headings.H3>
          <p>Please confirm your age.</p>
          <ConfirmAgeForm
            confirmAgeData={confirmAgeData}
            onSubmitFormCallback={onSubmitFormCallback}
            onSecondaryButtonClicked={onBackClicked} />
        </div>
        <div className="col-xs-12 col-md-4" />
        {/* <LoginInstructions /> */}
      </div>
      <PopUpModal
        widthPct={60}
        maxWidth={720}
        title={'Invalid Age'}
        displayPopUp={displayInvalidPopupState === true}>
        <div className="row">
          <div className="col-xs-12">
            <p>You must be over 16 years old in order to create an account. If you are under the required age please have a legal guardian create a parent user account.</p>
          </div>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <p>If you are a parent/legal guardian, please view the following article for instructions:</p>
            <p><a href="https://usaswimming.thecloudtutorialusers.com/main/articles/1660871752664" target="_blank" rel="noreferrer">How do I link my child to my parent account and then create a login for my child? - ARTICLE</a></p>
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SecondaryButton type={'button'} onClick={onReturnToLoginClicked}>Return To Login</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default AccountCreationConfirmAge;