import React, { createContext, useState } from 'react';

import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

export const AccountManagementMFAStateContext = createContext();

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const AccountManagementMFAContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <AccountManagementMFAStateContext.Provider value={stateHook}>
      {children}
    </AccountManagementMFAStateContext.Provider>
  );
};

export default AccountManagementMFAContextProvider;