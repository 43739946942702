import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircle, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import useUserInfoForm from "./UseUserInfoForm";

import PrimaryButton from "../../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../../common/components/buttons/SecondaryButton";
import Input from "../../../../../../../common/components/inputs/Input";
import DatePicker from "../../../../../../../common/components/datepickers/DatePicker";
import ReadOnly from "../../../../../../../common/components/readOnly/ReadOnly";

const UserInfoForm = ({ userInfoData, childData, submitButtonText = 'Continue', secondaryButtonText = 'Back', onSubmitFormCallback,
  onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    requirementsState,
    enableSaveButtonState,
    hidePasswordState,
    handleSubmit,
    updateFormState,
    onPasswordChanged,
    onEmailChanged,
    onToggleHideShowPassword
  } = useUserInfoForm(onSubmitFormCallback, userInfoData, childData);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          {childData?.firstName
            ? (
              <ReadOnly
                label={'Child First Name'}
                name={'firstName'}
                value={formState.firstName} />
            ) : (
              <Input
                label={'Legal First Name*'}
                name={'firstName'}
                value={formState.firstName}
                error={errorState.firstName}
                message={errorState.firstName}
                onChange={(value) => updateFormState('firstName', value)} />
            )
          }
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          {childData // Not needed for dup match so if childData is provided make readonly
            ? (
              <ReadOnly
                label={'Child Preferred Name'}
                name={'preferredName'}
                value={formState.preferredName} />
            ) : (
              <Input
                label={'Preferred Name'}
                name={'preferredName'}
                value={formState.preferredName}
                error={errorState.preferredName}
                message={errorState.preferredName}
                onChange={(value) => updateFormState('preferredName', value)} />
            )
          }
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          {childData // Not needed for dup match so if childData is provided make readonly
            ? (
              <ReadOnly
                label={'Child Middle Name'}
                name={'middleName'}
                value={formState.middleName} />
            ) : (
              <Input
                label={'Middle Name'}
                name={'middleName'}
                value={formState.middleName}
                error={errorState.middleName}
                message={errorState.middleName}
                onChange={(value) => updateFormState('middleName', value)} />
            )
          }
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          {childData?.lastName
            ? (
              <ReadOnly
                label={'Child Last Name'}
                name={'lastName'}
                value={formState.lastName} />
            ) : (
              <Input
                label={'Legal Last Name*'}
                name={'lastName'}
                value={formState.lastName}
                error={errorState.lastName}
                message={errorState.lastName}
                onChange={(value) => updateFormState('lastName', value)} />
            )
          }
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label={`Email* (This will also be your${childData ? ` child's` : ''} username)`}
            name={'email'}
            value={formState.email}
            error={errorState.email}
            message={errorState.email}
            onChange={(value) => onEmailChanged(value)} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          {childData?.birthDate
            ? (
              <ReadOnly
                label={'Child Birth Date'}
                name={'birthDate'}
                value={formState.birthDate} />
            ) : (
              <DatePicker
                label="Birth Date*"
                name="birthDate"
                value={formState.birthDate}
                error={errorState.birthDate}
                message={errorState.birthDate}
                onChange={(value) => updateFormState('birthDate', value)} />
            )
          }
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          {childData?.memberId
            ? (
              <ReadOnly
                label={'Child Member Id'}
                name={'memberId'}
                value={formState.memberId} />
            ) : (
              <Input
                label={'Member Id (Use to link to an existing person)'}
                name={'memberId'}
                value={formState.memberId}
                error={errorState.memberId}
                message={errorState.memberId}
                onChange={(value) => updateFormState('memberId', value)} />
            )
          }
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          Password Requirements
          <p style={{ fontSize: 14 }}>
            <FontAwesomeIcon color={requirementsState.password10Characters === true ? 'green' : '#c1c6c8'} icon={requirementsState.password10Characters === true ? faCheckCircle : faCircle}></FontAwesomeIcon> No less than 10 characters<br />
            <FontAwesomeIcon color={requirementsState.passwordNumber === true ? 'green' : '#c1c6c8'} icon={requirementsState.passwordNumber === true ? faCheckCircle : faCircle}></FontAwesomeIcon> 1 number<br />
            <FontAwesomeIcon color={requirementsState.passwordSpecialCharacter === true ? 'green' : '#c1c6c8'} icon={requirementsState.passwordSpecialCharacter === true ? faCheckCircle : faCircle}></FontAwesomeIcon> 1 special character<br />
            <FontAwesomeIcon color={requirementsState.passwordLowercaseCharacter === true ? 'green' : '#c1c6c8'} icon={requirementsState.passwordLowercaseCharacter === true ? faCheckCircle : faCircle}></FontAwesomeIcon> 1 lowercase character<br />
            <FontAwesomeIcon color={requirementsState.passwordUppercaseCharacter === true ? 'green' : '#c1c6c8'} icon={requirementsState.passwordUppercaseCharacter === true ? faCheckCircle : faCircle}></FontAwesomeIcon> 1 uppercase character<br />
            <FontAwesomeIcon color={requirementsState.passwordNotEqualToEmail === true ? 'green' : '#c1c6c8'} icon={requirementsState.passwordNotEqualToEmail === true ? faCheckCircle : faCircle}></FontAwesomeIcon> Not equal to email
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <FontAwesomeIcon icon={hidePasswordState.hidePassword === false ? faEye : faEyeSlash} onClick={() => onToggleHideShowPassword('hidePassword')} style={{ cursor: 'pointer', marginRight: hidePasswordState.hidePassword === false ? 26 : 25, marginTop: 40, position: 'absolute', right: 0 }}></FontAwesomeIcon>
          <Input
            label={'Password*'}
            name={'password'}
            value={formState.password}
            error={errorState.password}
            message={errorState.password}
            type={hidePasswordState.hidePassword === false ? "text" : "password"}
            onChange={(value) => onPasswordChanged(value)} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <FontAwesomeIcon icon={hidePasswordState.hideConfirmPassword === false ? faEye : faEyeSlash} onClick={() => onToggleHideShowPassword('hideConfirmPassword')} style={{ cursor: 'pointer', marginRight: hidePasswordState.hideConfirmPassword === false ? 26 : 25, marginTop: 40, position: 'absolute', right: 0 }}></FontAwesomeIcon>
          <Input
            label={'Confirm Password*'}
            name={'confirmPassword'}
            value={formState.confirmPassword}
            error={errorState.confirmPassword}
            message={errorState.confirmPassword}
            type={hidePasswordState.hideConfirmPassword === false ? "text" : "password"}
            onChange={(value) => updateFormState('confirmPassword', value)} />
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton isDisabled={enableSaveButtonState !== true} type="submit">{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type='button' onClick={() => onSecondaryButtonClicked(formState)}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default UserInfoForm;