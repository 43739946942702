export const localValidate = (formState) => {
  let errors = {};

  if (formState.username.trim() === '') {
    errors.username = 'Username is required';
  }

  if (formState.password.trim() === '') {
    errors.password = 'Password is required';
  }

  return errors;
};

const LoginFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default LoginFormValidation;