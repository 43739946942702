import { useEffect, useState } from 'react';

import useLandingPageData from '../../../../state/landingPages/UseLandingPageData';
import usePendingPaymentsData from '../../../../state/landingPages/pendingPayments/UsePendingPaymentsData';

const INITIAL_STATE = {
  pendingPayments: [],
  isLoading: true
};

const useMyPendingPayments = () => {
  const { landingPageState } = useLandingPageData();
  const { pendingPaymentsState, getPendingPayments } = usePendingPaymentsData();
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    if (landingPageState.currentPerson?.personId && pendingPaymentsState.isArrayLoading === false && pendingPaymentsState.isArrayLoaded === false) {
      getPendingPayments(landingPageState.currentPerson?.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingPageState.currentPerson?.personId]);

  useEffect(() => {
    if (pendingPaymentsState.isArrayLoading === false && pendingPaymentsState.isArrayLoaded === true) {
      setState({
        pendingPayments: pendingPaymentsState.arrayData,
        isLoading: false,
      });
    }
  }, [pendingPaymentsState]);

  return { ...state };
};

export default useMyPendingPayments;