import { useEffect } from "react";

import useAccountManagementData from "../../../state/accountManagement/UseAccountManagementData";
import useAccountManagementMFAData from "../../../state/accountManagement/accountManagementMFA/UseAccountManagementMFAData";
import useAccountManagementEmailData from "../../../state/accountManagement/accountManagementEmail/UseAccountManagementEmailData";

const useAccountManagementMFA = () => {
  const { accountManagementState } = useAccountManagementData();
  const { accountManagementEmailState, getAccountManagementEmail, getAccountManagementEmailByUserAccountId
  } = useAccountManagementEmailData();
  const { accountManagementMFAState, accountManagementMFASaveState, resetMFASaveState, getAccountManagementMFA,
    getAccountManagementMFAByUserAccountId, putAccountManagementMFA, putAccountManagementMFAByUserAccountId
  } = useAccountManagementMFAData();

  const onSubmitFormCallback = (formState) => {
    const userAccountId = accountManagementState.objData?.userAccountId;
    const mfaObj = {
      isEnabled: formState.isMFAEnabled === true,
      email: formState.useEmail === true,
      textMessage: formState.useTextMessage === true,
      phoneNumber: formState.useTextMessage === true ? formState.phoneNumber : ''
    };

    const putAccountManagementMFAPromise = accountManagementState.isAdminView === true
      ? putAccountManagementMFAByUserAccountId(userAccountId, mfaObj)
      : putAccountManagementMFA(mfaObj);

    if (putAccountManagementMFAPromise ?? false) {
      putAccountManagementMFAPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData?.success === true) {
            const getAccountManagementMFAPromise = accountManagementState.isAdminView === true
              ? getAccountManagementMFAByUserAccountId(userAccountId)
              : getAccountManagementMFA();

            if (getAccountManagementMFAPromise ?? false) {
              getAccountManagementMFAPromise.catch((e) => {
                //context error
              });
            }
          }
        }
      }).catch((e) => {
        //context error
      });
    }
  };

  useEffect(() => {
    if (accountManagementEmailState.isObjLoaded === false) {
      const userAccountId = accountManagementState.objData?.userAccountId;

      const getAccountManagementEmailPromise = accountManagementState.isAdminView === true
        ? getAccountManagementEmailByUserAccountId(userAccountId)
        : getAccountManagementEmail();

      if (getAccountManagementEmailPromise ?? false) {
        getAccountManagementEmailPromise.catch((e) => {
          //context error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountManagementEmailState.isObjLoaded]);

  useEffect(() => {
    if (accountManagementMFAState.isObjLoaded === false) {
      const userAccountId = accountManagementState.objData?.userAccountId;

      const getAccountManagementMFAPromise = accountManagementState.isAdminView === true
        ? getAccountManagementMFAByUserAccountId(userAccountId)
        : getAccountManagementMFA();

      if (getAccountManagementMFAPromise ?? false) {
        getAccountManagementMFAPromise.catch((e) => {
          //context error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountManagementMFAState.isObjLoaded]);

  return {
    isLoading: accountManagementMFAState.isObjLoading || accountManagementEmailState.isObjLoading,
    isSaving: accountManagementMFASaveState.isObjLoading,
    emailAddress: accountManagementEmailState.objData?.emailAddress || '',
    mfaObj: accountManagementMFAState.objData,
    saveStateData: accountManagementMFASaveState.objData,
    resetSaveState: resetMFASaveState,
    onSubmitFormCallback
  };
};

export default useAccountManagementMFA;