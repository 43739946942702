import { useContext, useState } from 'react';

import AccountManagementChildrenData from './AccountManagementChildrenData';
import { AccountManagementChildrenStateContext } from './AccountManagementChildrenContextProvider';

import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

const INITIAL_PERSON_PARENT_CHILD = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_DELETE_PERSON_PARENT_CHILD = {
  ...BASIC_INITIAL_STATE
};

const useAccountManagementChildrenData = () => {
  const [accountManagementChildrenState, setAccountManagementChildrenState] = useContext(AccountManagementChildrenStateContext);
  const [personParentChildState, setPersonParentChildState] = useState(INITIAL_PERSON_PARENT_CHILD);
  const [deletePersonParentChildState, setDeletePersonParentChildState] = useState(INITIAL_DELETE_PERSON_PARENT_CHILD);

  const postPersonParentChild = (parentPersonId, childMemberId) => {
    return AccountManagementChildrenData.postPersonParentChildData(parentPersonId, childMemberId, personParentChildState, setPersonParentChildState);
  };

  const getAccountManagementChildren = (personId) => {
    return AccountManagementChildrenData.getAccountManagementChildrenData(personId, accountManagementChildrenState, setAccountManagementChildrenState);
  };

  const deletePersonParentChild = (personParentChildId) => {
    return AccountManagementChildrenData.deletePersonParentChildData(personParentChildId, deletePersonParentChildState, setDeletePersonParentChildState);
  };

  return {
    accountManagementChildrenState,
    personParentChildState,
    deletePersonParentChildState,
    getAccountManagementChildren,
    postPersonParentChild,
    deletePersonParentChild
  };
};

export default useAccountManagementChildrenData;