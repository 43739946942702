import DeleteIcon from '../../../common/components/icons/DeleteIcon';

import global from '../../../common/components/GlobalStyle.module.css';

const GridRow = ({ cartItem, onDelete }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{cartItem.itemDescription || ''}</span>&nbsp;
      <button className={global.IconButton} type="button" onClick={(e) => onDelete(e, cartItem.personId, cartItem.deleteAction)}>
        <DeleteIcon />
      </button>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Item Description</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{cartItem.itemDescription}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Item Price</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{cartItem.itemPrice}</div>
      </div>
    </div>
  </div>
);

const ManageCartGridSmall = ({ data, isLoading, onDelete }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(data) && data.length > 0
        ? data.map((cartItem, i) => (
          <GridRow
            key={i}
            cartItem={cartItem}
            onDelete={onDelete} />
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Cart Items</div>
            </div>
          </div>
        )
    }
  </div >
);

export default ManageCartGridSmall;