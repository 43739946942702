import { useEffect, useState } from "react";

import useAccountManagementData from "../../../state/accountManagement/UseAccountManagementData";
import useAccountManagementChildrenData from "../../../state/accountManagement/accountManagementChildren/UseAccountManagementChildrenData";

const INITIAL_STATE = {
  displayFormPopup: false,
  displayDeletePopup: false,
  childToDelete: {}
};

const useAccountManagementChildren = () => {
  const { accountManagementState } = useAccountManagementData();
  const { accountManagementChildrenState, personParentChildState, deletePersonParentChildState,
    getAccountManagementChildren, postPersonParentChild, deletePersonParentChild
  } = useAccountManagementChildrenData();
  const [state, setState] = useState(INITIAL_STATE);

  const onSubmitFormCallback = (formState) => {
    const personId = accountManagementChildrenState.personId;
    const memberId = formState.memberId;

    const postPersonParentChildPromise = postPersonParentChild(personId, memberId);

    if (postPersonParentChildPromise ?? false) {
      postPersonParentChildPromise.then((newState) => {
        if (newState ?? false) {
          const personId = accountManagementChildrenState.personId;

          const getAccountManagementChildrenPromise = getAccountManagementChildren(personId);

          if (getAccountManagementChildrenPromise ?? false) {
            getAccountManagementChildrenPromise.catch((e) => {
              //context error
            });
          }
        }
      }).catch((e) => {
        //state error
      });
    }

    setState({ ...state, displayFormPopup: false });
  };

  const onAddChildClicked = () => {
    setState({ ...state, displayFormPopup: true });
  };

  const onCancelAddChildClicked = () => {
    setState({ ...state, displayFormPopup: false });
  };

  const onDeleteChildClicked = (child) => {
    setState({
      ...state,
      displayDeletePopup: true,
      childToDelete: child
    });
  };

  const onDeleteChildPopupConfirmClicked = () => {
    const personParentChildId = state.childToDelete?.personParentChildId;

    const deletePersonParentChildPromise = deletePersonParentChild(personParentChildId);

    if (deletePersonParentChildPromise ?? false) {
      deletePersonParentChildPromise.then((newState) => {
        if (newState ?? false) {
          const personId = accountManagementChildrenState.personId;

          const getAccountManagementChildrenPromise = getAccountManagementChildren(personId);

          if (getAccountManagementChildrenPromise ?? false) {
            getAccountManagementChildrenPromise.catch((e) => {
              //context error
            });
          }
        }
      }).catch((e) => {
        //state error
      });
    }

    setState({
      ...state,
      displayDeletePopup: false,
      childToDelete: {}
    });
  };

  const onDeleteChildPopupCancelClicked = () => {
    setState({
      ...state,
      displayDeletePopup: false,
      childToDelete: {}
    });
  };

  const getPersonasString = (personasArray) => {
    let personasString = '';

    if (Array.isArray(personasArray) === true && personasArray.length > 0) {
      for (const persona of personasArray) {
        if (personasString === '') {
          personasString = `${persona.personaName}`;
        } else {
          personasString += `, ${persona.personaName}`;
        }
      }
    }

    return personasString || 'None';
  };

  useEffect(() => {
    if (accountManagementState.objData?.personId !== accountManagementChildrenState.personId) {
      const personId = accountManagementState.objData.personId;

      const getAccountManagementChildrenPromise = getAccountManagementChildren(personId);

      if (getAccountManagementChildrenPromise ?? false) {
        getAccountManagementChildrenPromise.catch((e) => {
          //context error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountManagementChildrenState.objData]);

  return {
    isLoading: accountManagementChildrenState.isArrayLoading,
    isSaving: personParentChildState.isObjLoading || deletePersonParentChildState.isObjLoading,
    gridData: accountManagementChildrenState.arrayData,
    state,
    onSubmitFormCallback,
    onAddChildClicked,
    onCancelAddChildClicked,
    onDeleteChildClicked,
    onDeleteChildPopupConfirmClicked,
    onDeleteChildPopupCancelClicked,
    getPersonasString
  };
};

export default useAccountManagementChildren;