import { useState } from 'react';

import AccountCreationMFAData from './AccountCreationMFAData';

import { BASIC_INITIAL_STATE } from '../../../../../common/utils/HttpHelper';

const INITIAL_ACCOUNT_CREATION_MFA_STATE = {
  ...BASIC_INITIAL_STATE,
};

const useAccountCreationMFAData = () => {
  const [accountCreationMFAState, setAccountCreationMFAState] = useState(INITIAL_ACCOUNT_CREATION_MFA_STATE);

  const postSetMFA = (accessToken, email, textMessage, phoneNumber) => {
    return AccountCreationMFAData.postSetMFAData(accessToken, email, textMessage, phoneNumber, accountCreationMFAState, setAccountCreationMFAState);
  };

  const resetAccountCreationMFAState = () => {
    setAccountCreationMFAState(INITIAL_ACCOUNT_CREATION_MFA_STATE);
  };

  return {
    accountCreationMFAState,
    resetAccountCreationMFAState,
    postSetMFA
  };
};

export default useAccountCreationMFAData;