import React from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ securityRole, onEditClicked, onDeleteClicked }) => (
  <tr>
    <td>{securityRole.orgRoleName || ''}</td>
    <td>{securityRole.orgUnitName || ''}</td>
    <td>{securityRole.effectiveDate ? formatDate(securityRole.effectiveDate) : ''}</td>
    <td>{securityRole.expirationDate ? formatDate(securityRole.expirationDate) : ''}</td>
    <td>
      <button
        className={global.IconButtonMargin}
        type="button"
        onClick={() => { onEditClicked(securityRole) }}>
        <EditIcon />
      </button>
      <button
        className={global.IconButtonMargin}
        type="button"
        onClick={() => { onDeleteClicked(securityRole.personOrgRoleDurationId) }}>
        <DeleteIcon />
      </button>
    </td>
  </tr>
);

const SecurityRolesGridLarge = ({ data, isLoading, onEditClicked, onDeleteClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Role</th>
        <th>Organization</th>
        <th>Effective Date</th>
        <th>Expiration Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="5">Loading...</td></tr>
        : Array.isArray(data) && data.length > 0
          ? data.map((securityRole, i) => <GridRow key={i} securityRole={securityRole} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked} />)
          : <tr><td colSpan="5">No Security Roles</td></tr>
      }
    </tbody>
  </table>
);

export default SecurityRolesGridLarge;