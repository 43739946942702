import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate, useSearchParams } from '../../../common/wrappers/ReactRouterDom';

import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import ShoppingCart from '../../../common/components/payments/ShoppingCart';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';

const Payments = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();
  const [searchParams,] = useSearchParams();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  let itemSubsystemId = null;
  if (searchParams.get('ItemSubsystemId') && Number.isInteger(Number.parseInt(searchParams.get('ItemSubsystemId')))) {
    itemSubsystemId = Number.parseInt(searchParams.get('ItemSubsystemId'));
  }

  return (
    <Routes>
      <Route path={navRoutes.PAYMENTS_SHOPPING_CART?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes.PAYMENTS_SHOPPING_CART}
            editElement={<ShoppingCart title="Shopping Cart" isBarOnBottom={false} itemSubsystemId={itemSubsystemId} />} />
        }
      />
      <Route path="/"
        element={
          <Navigate
            to={navRoutes.PAYMENTS_SHOPPING_CART?.path} />}
      />
      {/*TODO doesn't lead anywhere?*/}
      <Route path="/*"
        element={
          <Navigate
            to={navRoutes.PAYMENTS_TRANSACTION_LIST?.route} />
        }
      />
    </Routes>
  );
};

export default Payments;