import { useNavigate } from "../../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../../NavLinks";

const useCardButton = (card) => {
  const navigate = useNavigate();

  const onClick = (e, card) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (card.name === 'Athlete') {
      navigate(NavLinks.LANDING_ATHLETE, { state: { personaId: card.personaId } });
    }
    else if (card.name === 'Coach') {
      navigate(NavLinks.LANDING_COACH, { state: { personaId: card.personaId } });
    }
    else if (card.name === 'Official') {
      navigate(NavLinks.LANDING_OFFICIAL, { state: { personaId: card.personaId } });
    }
    else {
      navigate(NavLinks.LANDING_MEMBER, { state: { personaId: card.personaId } });
    }
  };

  return {
    onClick
  };
};

export default useCardButton;