import { useContext } from 'react';

import AccountManagementLMSUserData from './AccountManagementLMSUserData';

import { AccountManagementLMSUserStateContext } from './AccountManagementLMSUserContextProvider';

const useAccountManagementLMSUserData = () => {
  const [accountManagementLMSUserState, setAccountManagementLMSUserState] = useContext(AccountManagementLMSUserStateContext);

  const postLMSUser = (personId, userAccountCssObj) => {
    return AccountManagementLMSUserData.postLMSUserData(personId, userAccountCssObj, accountManagementLMSUserState, setAccountManagementLMSUserState);
  };

  const getLMSUser = (personId) => {
    return AccountManagementLMSUserData.getLMSUserData(personId, accountManagementLMSUserState, setAccountManagementLMSUserState);
  };

  const putLMSUser = (lmsUserAccountId, userAccountObj) => {
    return AccountManagementLMSUserData.putLMSUserData(lmsUserAccountId, userAccountObj, accountManagementLMSUserState, setAccountManagementLMSUserState);
  };

  return {
    accountManagementLMSUserState,
    setAccountManagementLMSUserState,
    postLMSUser,
    getLMSUser,
    putLMSUser
  };
};

export default useAccountManagementLMSUserData;