import React, { Fragment } from 'react';

import useToolTipIconWithPopup from './UseAlreadyLinkedSpan';

import ToolTipIcon from '../../../../../../../common/components/icons/ToolTipIcon';

import style from './AlreadyLinkedSpan.module.css';

const ToolTipPopup = ({ toolTipText, onEnterIcon, onLeaveIcon, getLeftPx, getTopPx }) => (
  <span
    onMouseEnter={(e) => { onEnterIcon(undefined) }}
    onMouseLeave={onLeaveIcon}
    className={style.ToolTipDetailPopup}
    style={{ left: getLeftPx(), top: getTopPx() }}>
    <div className={style.ToolTipDetailPopupHead}>
      <span>PERSON ALREADY LINKED</span>&nbsp;
    </div>
    <div className={style.ToolTipDetailPopupBody}>
      <div className='row'>
        <p>{toolTipText}</p>
      </div>
    </div>
  </span>
);

const AlreadyLinkedSpan = ({ toolTipText }) => {
  const {
    state,
    onEnterIcon,
    onLeaveIcon,
    getLeftPx,
    getTopPx
  } = useToolTipIconWithPopup();

  if (state.displayPopup === true) {
    return (
      <Fragment>
        <span
          onMouseEnter={(e) => { onEnterIcon(e); }}
          onMouseLeave={onLeaveIcon}>
          <b>ALREADY LINKED</b> <ToolTipIcon />
        </span>
        <ToolTipPopup
          toolTipText={toolTipText}
          onEnterIcon={onEnterIcon}
          onLeaveIcon={onLeaveIcon}
          getLeftPx={getLeftPx}
          getTopPx={getTopPx} />
      </Fragment>
    );
  } else {
    return (
      <span
        onMouseEnter={(e) => { onEnterIcon(e); }}
        onMouseLeave={onLeaveIcon}>
        <b>ALREADY LINKED</b> <ToolTipIcon />
      </span>
    );
  }
};

export default AlreadyLinkedSpan;