import { useEffect } from 'react';

import useLmsAdminCoursesData from '../../../../state/courseEnrollments/lmsAdminCourses/UseLmsAdminCoursesData';

const useLmsAdminCoursesDropdown = () => {
  const { lmsAdminCoursesState, getLmsAdminCourses } = useLmsAdminCoursesData();

  useEffect(() => {
    if (lmsAdminCoursesState.isArrayLoading !== true && lmsAdminCoursesState.isArrayLoaded !== true) {
      getLmsAdminCourses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    lmsAdminCoursesState
  };
};

export default useLmsAdminCoursesDropdown;