import { useEffect, useState } from 'react';
import Constants from '../../../../../common/utils/Constants';
import { formatDate } from '../../../../../common/utils/DateFunctions';
import useCommunicationsHubData from '../../../../state/communicationsHub/UseCommunicationsHubData';
import useLandingPageData from '../../../../state/landingPages/UseLandingPageData';

const INITIAL_STATE = {
  messages: [],
  isLoading: true
};

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Message',
  id: Constants.DEFAULT_ID,
  message: ''
};

const useMyCommunications = () => {
  const { landingPageState } = useLandingPageData();
  const { messageState, setMessageState, getMessagesData, putMessageData, putCustomMessageData } = useCommunicationsHubData();
  const [state, setState] = useState(INITIAL_STATE);
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);

  const onClick = (message) => {
    setModalState({ ...modalState, displayPopUp: true, id: message.id, message: message.message });
    onReadMessage(message);
  };

  const onReadMessage = (message) => {
    if (message.status !== 'Read') {
      const newMessageState = { ...messageState };
      const newMessage = { ...message };
      let messageIndex = -1;
      if (message.personInboxId) {
        messageIndex = newMessageState.arrayData.findIndex(x => x.personInboxId === newMessage.personInboxId);
      }
      else if (message.personCustomInboxId) {
        messageIndex = newMessageState.arrayData.findIndex(x => x.personCustomInboxId === newMessage.personCustomInboxId);
      }
      newMessage.status = 'Read';
      newMessage.unreadMessage = false;
      newMessageState.arrayData[messageIndex] = newMessage;
      setMessageState(newMessageState);
      if (message.personInboxId) {
        putMessageData(newMessage.personInboxId, newMessage);
      }
      else if (message.personCustomInboxId) {
        putCustomMessageData(newMessage.personCustomInboxId, newMessage);
      }
    }
  };

  const onModalCanceled = () => {
    setModalState(INITIAL_MODAL_STATE);
  }

  useEffect(() => {
    if (landingPageState.currentPerson?.personId && messageState.isArrayLoaded !== true) {
      getMessagesData(landingPageState.currentPerson.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageState.isArrayLoaded, landingPageState.currentPerson?.personId]);

  useEffect(() => {
    if (messageState.isArrayLoading === false && messageState.isArrayLoaded === true) {
      setState({
        messages: (messageState.arrayData || []).map(x => {
          return {
            subject: x.subject,
            isNew: x.status === 'New',
            dateSent: formatDate(x.dateSent),
            onClick: () => onClick(x)
          };
        }),
        isLoading: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageState]);

  return {
    ...state,
    modalState,
    onModalCanceled
  };
};

export default useMyCommunications;