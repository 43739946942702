import useEmailVerificationPopupForm from "./UseEmailVerificationPopupForm";

import Input from "../../../../../common/components/inputs/Input";
import PopUpModal from "../../../../../common/components/dialogs/PopUpModal";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";

import global from '../../../../../common/components/GlobalStyle.module.css';

const EmailVerificationPopupForm = ({ emailAddress, errorMessage, onSubmitFormCallback, onCancelClicked }) => {
  const {
    formState,
    errorState,
    updateFormState,
    handleSubmit
  } = useEmailVerificationPopupForm(onSubmitFormCallback);

  return (
    <PopUpModal
      widthPct={50}
      maxWidth={720}
      title={'Validate New Email'}
      displayPopUp={true}
      onModalCanceled={onCancelClicked}>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-bottom-margin">
            <p>Please enter the verification code that was emailed to {emailAddress}.</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12">
            <Input
              label={'Verification Code*'}
              name={'verificationCodeFromUser'}
              value={formState.verificationCodeFromUser}
              error={errorState.verificationCodeFromUser}
              message={errorState.verificationCodeFromUser}
              onChange={(value) => updateFormState('verificationCodeFromUser', value)} />
          </div>
        </div>
        {errorMessage &&
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <p className={global.ErrorMessage}>{errorMessage}</p>
            </div>
          </div>
        }
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Submit</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </form>
    </PopUpModal>
  );
};

export default EmailVerificationPopupForm;