import { useEffect } from "react";
import { useLocation, useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../NavLinks";

import useAccountManagementData from "../../../state/accountManagement/UseAccountManagementData";
import useAccountManagementUserGroupData from "../../../state/accountManagement/accountManagementUserGroup/UseAccountManagementUserGroupData";

const useAccountManagementUserGroup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { accountManagementState } = useAccountManagementData();
  const { accountManagementUserGroupState, accountManagementUserGroupSaveState,
    getAccountManagementUserGroups, deleteAccountManagementUserGroup
  } = useAccountManagementUserGroupData();

  const onAddUserGroupClicked = () => {
    navigate(NavLinks.ACCOUNT_MANAGEMENT_USER_GROUP_ADD);
  };

  const onDeleteUserGroupClicked = (userAccountGroupId) => {
    if (userAccountGroupId > 0) {
      const deleteAccountManagementUserGroupPromise = deleteAccountManagementUserGroup(userAccountGroupId);

      if (deleteAccountManagementUserGroupPromise ?? false) {
        deleteAccountManagementUserGroupPromise.then((newState) => {
          if (newState ?? false) {
            const userAccountId = accountManagementState.objData?.userAccountId;

            const getAccountManagementUserGroupsPromise = getAccountManagementUserGroups(userAccountId);

            if (getAccountManagementUserGroupsPromise ?? false) {
              getAccountManagementUserGroupsPromise.catch((e) => {
                //context error
              });
            }
          }
        }).catch((e) => {
          //state error
        });
      }
    }
  };

  useEffect(() => {
    if (accountManagementUserGroupState.isArrayLoaded === false || location.state?.doRefresh === true) {
      const userAccountId = accountManagementState.objData?.userAccountId;

      const getAccountManagementUserGroupsPromise = getAccountManagementUserGroups(userAccountId);

      if (getAccountManagementUserGroupsPromise ?? false) {
        getAccountManagementUserGroupsPromise.catch((e) => {
          //context error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: accountManagementUserGroupState.isArrayLoading,
    isSaving: accountManagementUserGroupSaveState.isObjLoading,
    gridData: accountManagementUserGroupState.arrayData,
    onAddUserGroupClicked,
    onDeleteUserGroupClicked
  };
};

export default useAccountManagementUserGroup;