import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";

const postPersonParentChildData = (parentPersonId, childMemberId, state, setState) => {
  if (parentPersonId && childMemberId) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const personIdForUrl = encodeURIComponent(parentPersonId);
    const memberIdForUrl = encodeURIComponent(childMemberId);
    const url = `/PersonParentChild/ParentPersonId/${personIdForUrl}/Member/${memberIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'POST') : null;
  }
};

const getAccountManagementChildrenData = (personId, state, setState) => {
  if (personId) {
    const newState = {
      ...state,
      personId
    };
    const api = initApi(API_NAMES.PERSON, newState, setState);
    const personIdForUrl = encodeURIComponent(personId);
    const url = `/person/${personIdForUrl}/children`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const deletePersonParentChildData = (personParentChildId, state, setState) => {
  if (personParentChildId > 0) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const personParentChildIdForUrl = encodeURIComponent(personParentChildId);
    const url = `/PersonParentChild/${personParentChildIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const AccountManagementChildrenData = {
  postPersonParentChildData,
  getAccountManagementChildrenData,
  deletePersonParentChildData
};

export default AccountManagementChildrenData;