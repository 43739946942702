import { Fragment } from "react";

import useAccountManagementUserGroupAdd from "./UseAccountManagementUserGroupAdd";

import UserGroupAddForm from "../components/forms/UserGroupAddForm";

import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";

import Constants from "../../../../common/utils/Constants";

const AccountManagementUserGroupAdd = () => {
  const {
    isLoading,
    isSaving,
    onSubmitFormCallback,
    onBackClicked
  } = useAccountManagementUserGroupAdd();

  return (
    <Fragment>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12'>
          <Headings.H3>User Group Add</Headings.H3>
        </div>
      </div>
      <UserGroupAddForm
        onSubmitFormCallback={onSubmitFormCallback}
        onSecondaryButtonClicked={onBackClicked} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default AccountManagementUserGroupAdd;