import React, { useState, createContext } from 'react';
import SystemMessageData from './SystemMessageData';

export const SystemMessageStateContext = createContext();

const SystemMessageContextProvider = ({ children }) => {
  const stateHook = useState(SystemMessageData.INITIAL_STATE);

  return (
    <SystemMessageStateContext.Provider value={stateHook}>
      {children}
    </SystemMessageStateContext.Provider>
  );
};

export default SystemMessageContextProvider;