import React from 'react';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ userGroup, onDeleteClicked }) => (
  <tr>
    <td>{userGroup.userGroup?.userGroupName || ''}</td>
    <td>
      <button
        className={global.IconButtonMargin}
        type="button"
        onClick={() => { onDeleteClicked(userGroup.userAccountGroupId) }}>
        <DeleteIcon />
      </button>
    </td>
  </tr>
);

const UserGroupGridLarge = ({ data, isLoading, onDeleteClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>User Group</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="2">Loading...</td></tr>
        : Array.isArray(data) && data.length > 0
          ? data.map((userGroup, i) => <GridRow key={i} userGroup={userGroup} onDeleteClicked={onDeleteClicked} />)
          : <tr><td colSpan="2">No User Groups</td></tr>
      }
    </tbody>
  </table>
);

export default UserGroupGridLarge;