import { Fragment } from "react";

import useManageCarts from "./UseManageCarts";
import ManageCartsGrid from "./ManageCartsGrid";

import Input from "../../../common/components/inputs/Input";
import PrimaryButton from "../../../common/components/buttons/PrimaryButton";
import PopUpModal from "../../../common/components/dialogs/PopUpModal";
import Headings from "../../../common/components/headings/Headings";
import SecondaryButton from "../../../common/components/buttons/SecondaryButton";

import Constants from "../../../common/utils/Constants";

const ManageCarts = () => {
  const {
    isError,
    shoppingCartItemState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onDeleteCartItem,
    onCloseErrorPopup
  } = useManageCarts();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Manage Shopping Carts</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Member ID*"
              name="memberId"
              value={formState.memberId}
              error={errorState.memberId}
              message={errorState.memberId}
              onChange={(value) => { onFormValueChanged('memberId', value) }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Lookup Shopping Carts</PrimaryButton>&nbsp;
          </div>
        </div>
      </form>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <ManageCartsGrid
            data={shoppingCartItemState.arrayData}
            isLoading={shoppingCartItemState.isArrayLoading}
            onDelete={onDeleteCartItem} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={shoppingCartItemState.isArrayLoading} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Error...'}
        displayPopUp={isError === true}
        onModalCanceled={onCloseErrorPopup}>
        <div className="row">
          <div className="col-xs-12">
            <p>{shoppingCartItemState.message}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <SecondaryButton type="button" onClick={onCloseErrorPopup}>Close</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default ManageCarts;