import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

import searchCourseEnrollmentsData from './SearchCourseEnrollmentsData';
import getCourseEnrollmentsData from "./GetCourseEnrollmentsData";
import putCourseEnrollmentData from "./PutCourseEnrollmentData";
import postCourseEnrollmentData from "./PostCourseEnrollmentData";
import deleteCourseEnrollmentData from "./DeleteCourseEnrollmentData";
import postCourseEnrollmentNotPassed from "./PostCourseEnrollmentNotPassedData";

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  personId: ''
};

const CourseEnrollmentsData = {
  INITIAL_STATE,
  searchCourseEnrollmentsData,
  getCourseEnrollmentsData,
  putCourseEnrollmentData,
  postCourseEnrollmentData,
  deleteCourseEnrollmentData,
  postCourseEnrollmentNotPassed
};

export default CourseEnrollmentsData;