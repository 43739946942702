import { useContext, useState } from "react";

import AccountCreationData from "./AccountCreationData";
import { AccountCreationStateContext, INITIAL_STATE } from "./AccountCreationContextProvider";

import { BASIC_INITIAL_STATE } from "../../../../common/utils/HttpHelper";

const INITIAL_ACCOUNT_CREATION_STEP_1_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_ACCOUNT_CREATION_STEP_2_STATE = {
  ...BASIC_INITIAL_STATE
};

const useAccountCreationData = () => {
  const [accountCreationState, setAccountCreationState] = useContext(AccountCreationStateContext);
  const [accountCreationStep1State, setAccountCreationStep1State] = useState(INITIAL_ACCOUNT_CREATION_STEP_1_STATE);
  const [accountCreationStep2State, setAccountCreationStep2State] = useState(INITIAL_ACCOUNT_CREATION_STEP_2_STATE);

  // ignoreMatches will force a validation email to be sent to the emailAddress provided
  const postAccountCreationStep1 = (stepObj, ignoreMatches = false) => {
    const newStepObj = {
      ...stepObj,
      ignoreMatches
    };

    return AccountCreationData.postAccountCreateStep1Data(newStepObj, accountCreationStep1State, setAccountCreationStep1State);
  };

  const postAccountCreationStep2 = (stepObj) => {
    return AccountCreationData.postAccountCreateStep2Data(stepObj, accountCreationStep2State, setAccountCreationStep2State);
  };

  const updateAccountCreationStep = (newStep, pageData = null, pageDataName = null) => {
    if (pageData !== null && pageDataName !== null) {
      setAccountCreationState({
        ...accountCreationState,
        [pageDataName]: pageData,
        currentStep: newStep
      });
    } else {
      setAccountCreationState({
        ...accountCreationState,
        currentStep: newStep
      });
    }
  };

  const updateAccountCreationPageData = (pageData, pageDataName) => {
    setAccountCreationState({
      ...accountCreationState,
      [pageDataName]: pageData
    });
  };

  const resetAccountCreationState = () => {
    setAccountCreationState(INITIAL_STATE);
  };

  const resetAccountCreationStep1State = () => {
    setAccountCreationStep1State(INITIAL_ACCOUNT_CREATION_STEP_1_STATE);
  };

  const resetAccountCreationStep2State = () => {
    setAccountCreationStep2State(INITIAL_ACCOUNT_CREATION_STEP_2_STATE);
  };

  return {
    accountCreationState,
    accountCreationStep1State,
    accountCreationStep2State,
    postAccountCreationStep1,
    postAccountCreationStep2,
    updateAccountCreationStep,
    updateAccountCreationPageData,
    resetAccountCreationState,
    resetAccountCreationStep1State,
    resetAccountCreationStep2State
  };
};

export default useAccountCreationData;