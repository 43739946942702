import { useState } from 'react';

import AccountCreationForgotUsernameData from './AccountCreationForgotUsernameData';

import { BASIC_INITIAL_STATE } from '../../../../../common/utils/HttpHelper';

const INITIAL_ACCOUNT_CREATION_FORGOT_USERNAME_STATE = {
  ...BASIC_INITIAL_STATE,
};

const useAccountCreationForgotUsernameData = () => {
  const [accountCreationForgotUsernameState, setAccountCreationForgotUsernameState] = useState(INITIAL_ACCOUNT_CREATION_FORGOT_USERNAME_STATE);

  const postForgotUsername = (emailAddress) => {
    return AccountCreationForgotUsernameData.postForgotUsernameData(emailAddress, accountCreationForgotUsernameState, setAccountCreationForgotUsernameState);
  };

  const resetAccountCreationForgotUsernameState = () => {
    setAccountCreationForgotUsernameState(INITIAL_ACCOUNT_CREATION_FORGOT_USERNAME_STATE);
  };

  return {
    accountCreationForgotUsernameState,
    resetAccountCreationForgotUsernameState,
    postForgotUsername
  };
};

export default useAccountCreationForgotUsernameData;