import { Fragment } from "react";

import useAccountManagementUsername from "./UseAccountManagementUsername";

import UsernameForm from "../components/forms/UsernameForm";
import ValidationSuccessErrorPopup from "../components/dialogs/ValidationSuccessErrorPopup";

import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";

import Constants from "../../../../common/utils/Constants";

const AccountManagementUsername = () => {
  const {
    isLoading,
    isSaving,
    usernameObj,
    saveStateData,
    resetSaveState,
    onSubmitFormCallback
  } = useAccountManagementUsername();

  return (
    <Fragment>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12'>
          <Headings.H3>Username</Headings.H3>
        </div>
      </div>
      <UsernameForm
        usernameObj={usernameObj}
        onSubmitFormCallback={onSubmitFormCallback} />
      <ValidationSuccessErrorPopup
        displayPopup={saveStateData?.success === false}
        errorMessage={saveStateData?.errorMessage || ''}
        onCancelClicked={resetSaveState} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default AccountManagementUsername;