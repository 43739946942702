import React, { Fragment } from 'react';

import useAccountManagementContextView from './UseAccountManagementContextView';

import Headings from '../../../common/components/headings/Headings';
import LargeContextBasedNav from '../../../common/components/contextBasedNav/LargeContextBasedNav';
import MobileContextBasedNav from '../../../common/components/contextBasedNav/MobileContextBasedNav';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import LeftArrowIcon from '../../../common/components/icons/LeftArrowIcon';

import Constants from '../../../common/utils/Constants';

import global from '../../../common/components/GlobalStyle.module.css';

const AccountManagementContextView = ({ children }) => {
  const {
    isLoaded,
    accountUserInfo,
    backToLabel,
    onHomeClicked,
    onBackClicked
  } = useAccountManagementContextView();

  if (isLoaded !== true) {
    return (
      <PopUpModal
        widthPct={90}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={true} />
    );
  } else {
    return (
      <Fragment>
        <div>
          <div className="row">
            <div className="col-xs-12">
              <button className={global.HeaderButton} onClick={onHomeClicked}>
                <Headings.H3
                  className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
                  excludeBar={true}>
                  Account Management
                </Headings.H3>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <hr className="usas-bar-turmeric" />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <button className={global.BackToSearchButton}
                type="button"
                onClick={onBackClicked}>
                <LeftArrowIcon /> &nbsp;
                {backToLabel}
              </button>
            </div>
          </div>
          <div className="row">
            <div className={["col-xs-12", global.HeaderText].join(' ')}>
              <p className={global.HeaderText}><strong>Member Name:</strong>&nbsp;{accountUserInfo.firstName || ''} {accountUserInfo.preferredName ? `"${accountUserInfo.preferredName}"` : ''} {accountUserInfo.middleName || ''} {accountUserInfo.lastName || ''}</p>
              <p className={global.HeaderText}><strong>Member Id:</strong>&nbsp;{accountUserInfo.memberId || ''}</p>
            </div>
          </div>
          <div className="row">
            <div className="visible-xs col-xs-12">
              <MobileContextBasedNav taxonomyName="AccountManagementDetail" renderPillsOnly={true} />
            </div>
            <div className="hidden-xs col-xs-12">
              <LargeContextBasedNav taxonomyName="AccountManagementDetail" renderPillsOnly={true} />
            </div>
          </div>
        </div>
        {children}
      </Fragment>
    );
  }
};

export default AccountManagementContextView;