import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../NavLinks";

import useCourseEnrollmentsData from "../../../state/courseEnrollments/UseCourseEnrollmentsData";

const INITIAL_OBJ_STATE = {
  objData: {},
  isObjLoading: false,
  isObjLoaded: true,
  isSaving: false,
  isSaved: false
};

const useCourseEnrollmentsManage = () => {
  const navigate = useNavigate();
  const { courseEnrollmentsState, getCourseEnrollments, deleteCourseEnrollment } = useCourseEnrollmentsData();
  const [objState, setObjState] = useState(INITIAL_OBJ_STATE)

  const onAddCompletedCourse = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.COURSE_ENROLLMENTS_MANAGE_DETAIL);
  };

  const onEditCourseEnrollment = (courseEnrollment) => {
    navigate(NavLinks.COURSE_ENROLLMENTS_MANAGE_DETAIL, { state: { courseEnrollment } })
  };

  const onAddEnrollmentNoCompletion = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.COURSE_ENROLLMENTS_MANAGE_ADD_NO_COMPLETION);
  };

  const onDeleteCourseEnrollment = (courseEnrollmentId) => {
    if (courseEnrollmentId > 0) {
      deleteCourseEnrollment(courseEnrollmentId, objState, setObjState);
    }
  };

  useEffect(() => {
    if (objState.isSaved === true && courseEnrollmentsState.personId) {
      getCourseEnrollments(courseEnrollmentsState.personId);

      setObjState({
        ...INITIAL_OBJ_STATE
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objState.isSaved]);

  return {
    isSaving: objState.isSaving,
    courseEnrollmentsState,
    onAddCompletedCourse,
    onEditCourseEnrollment,
    onDeleteCourseEnrollment,
    onAddEnrollmentNoCompletion
  };
};

export default useCourseEnrollmentsManage;