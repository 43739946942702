import React, { Fragment } from "react";

import useCourseEnrollmentsManageNoCompletion from "./UseCourseEnrollmentsManageNoCompletion";
import CourseEnrollmentNoCompletionForm from "../components/forms/CourseEnrollmentNoCompletionForm";

import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import Constants from "../../../../common/utils/Constants";

const CourseEnrollmentsManageNoCompletion = () => {
  const {
    isSaving,
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    handleSubmit,
    onBackClicked
  } = useCourseEnrollmentsManageNoCompletion();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Course Enrollment Add</Headings.H3>
        </div>
      </div>
      <CourseEnrollmentNoCompletionForm
        formState={formState}
        errorState={errorState}
        onFormValueChanged={onFormValueChanged}
        onValueTextPairChanged={onValueTextPairChanged}
        handleSubmit={handleSubmit}
        onBackClicked={onBackClicked} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default CourseEnrollmentsManageNoCompletion;