import { useEffect, useState } from 'react';

import useLandingPageData from '../../../../state/landingPages/UseLandingPageData';

const INITIAL_VIEW_STATE = {
  cards: [],
  isLoading: true
};

const useMyMemberCards = () => {
  const { landingPageState } = useLandingPageData();
  const [state, setState] = useState(INITIAL_VIEW_STATE);

  useEffect(() => {
    const cards = (landingPageState.currentPerson?.personas || [])
      .filter(p => p.personaName !== 'Parent')
      .map(x => { return { name: x.personaName, status: (x.isInGoodStanding === 1 || x.isInGoodStanding === true) ? 'Good Standing' : (x.isInGoodStanding === 0 || x.isInGoodStanding === false) ? 'Not In Good Standing' : 'Calculating Standing', personaId: x.personaId }; });
    setState({
      cards,
      isLoading: false
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingPageState.currentPerson]);

  return {
    ...state,
    landingPageState
  };
};

export default useMyMemberCards;