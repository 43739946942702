import React, { createContext, useState } from 'react';

import AccountManagementConstants from '../../views/accountManagement/utils/AccountManagementConstants';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

export const AccountManagementStateContext = createContext();

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  backToSystem: AccountManagementConstants.BACK_TO_SYSTEM_DASHBOARD,
  isAdminView: false
};

const AccountManagementContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <AccountManagementStateContext.Provider value={stateHook}>
      {children}
    </AccountManagementStateContext.Provider>
  );
};

export default AccountManagementContextProvider;