import React, { createContext, useState } from 'react';

import { AC_STEP_LOGIN } from '../../../views/landingPages/views/accountCreation/utils/AccountCreationConstants';

export const AccountCreationStateContext = createContext();

export const INITIAL_STATE = {
  currentStep: AC_STEP_LOGIN
};

const AccountCreationContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <AccountCreationStateContext.Provider value={stateHook}>
      {children}
    </AccountCreationStateContext.Provider>
  );
};

export default AccountCreationContextProvider;