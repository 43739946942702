import React, { Fragment } from 'react';

import useMyPendingPayments from './UseMyPendingPayments';

import Spinner from '../../../../../common/components/spinners/Spinner';
import PendingPayment from './PendingPayment';

import style from './../../LandingPages.module.css';

const MyPendingPayments = () => {
  const {
    pendingPayments,
    isLoading
  } = useMyPendingPayments();

  if (isLoading === true) {
    return <Spinner />;
  } else if (Array.isArray(pendingPayments) !== true || pendingPayments.length === 0) {
    return <Fragment />;
  } else {
    return (
      <Fragment>
        <div className={style.SectionBorder}>
          <div className={style.SectionTitle}>My Pending Payments</div>
          <table className={style.Table}>
            <thead>
              <tr>
                <th style={{ textAlign: 'left', paddingTop: '15px' }}></th>
              </tr>
            </thead>
            <tbody>
              {pendingPayments.map((pendingPayments, i) => <PendingPayment pendingPayment={pendingPayments} key={i} />)}
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  }
};

export default MyPendingPayments;