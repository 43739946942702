import React, { Fragment } from 'react';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import EditIcon from '../../../../../common/components/icons/EditIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ user, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <tr>
      <td>{user.firstName || ''} {user.preferredName ? `"${user.preferredName}"` : ''} {user.middleName || ''} {user.lastName || ''}</td>
      <td style={{ maxWidth: '175px', overflowWrap: 'break-word' }}>{user.userName || ''}</td>
      <td style={{ maxWidth: '175px', overflowWrap: 'break-word' }}>{user.email || ''}</td>
      <td style={{ maxWidth: '175px', overflowWrap: 'break-word' }}>{user.personEmail || ''}</td>
      <td>{user.memberId || ''}</td>
      <td style={{ maxWidth: '200px', overflowWrap: 'break-word' }}>{user.cssUserId || ''}</td>
      <td style={{ minWidth: '150px' }}>
        <button
          className={global.IconButtonMargin}
          onClick={() => onEditClicked(user)}>
          <EditIcon />
        </button>
        <button
          className={global.IconButtonMargin}
          onClick={() => onDeleteClicked(user)}>
          <DeleteIcon />
        </button>
      </td>
    </tr>
  </Fragment>
);

const UserAccountSearchGridLarge = ({ isLoading, gridData, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Member Name</th>
          <th>Username</th>
          <th>User Account Email</th>
          <th>Member Email</th>
          <th>Member Id</th>
          <th>Css User Id</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="7">Loading...</td></tr>
          : Array.isArray(gridData) && gridData.length > 0
            ? gridData.map((user, i) =>
              <GridRow
                key={i}
                user={user}
                onEditClicked={onEditClicked}
                onDeleteClicked={onDeleteClicked} />
            ) : <tr><td colSpan="7">No Results</td></tr>
        }
      </tbody>
    </table>
  </Fragment>
);

export default UserAccountSearchGridLarge;