import { useState } from "react";

import useAccountCreationData from "../../../../../../state/landingPages/accountCreation/UseAccountCreationData";
import useAccountCreationForgotUsernameData from "../../../../../../state/landingPages/accountCreation/accountCreationForgotUsername/UseAccountCreationForgotUsernameData";

const INITIAL_API_ERROR_POPUP_STATE = {
  displayPopup: false,
  errorMessage: ''
};

const useAccountCreationForgotUsername = () => {
  const { resetAccountCreationState } = useAccountCreationData();
  const { accountCreationForgotUsernameState, postForgotUsername,
    resetAccountCreationForgotUsernameState } = useAccountCreationForgotUsernameData();
  const [apiErrorPopupState, setAPIErrorPopupState] = useState(INITIAL_API_ERROR_POPUP_STATE);

  const onCancelClicked = () => {
    resetAccountCreationState(); // clears state and sends back to login
  };

  const onCloseAPIErrorPopupClicked = () => {
    resetAccountCreationForgotUsernameState();
    setAPIErrorPopupState(INITIAL_API_ERROR_POPUP_STATE);
  };

  const onReturnToLoginClicked = () => {
    resetAccountCreationState(); // clears state and sends back to login
  };

  const onSubmitFormCallback = (formState) => {
    const postForgotUsernamePromise = postForgotUsername(formState.email);

    if (postForgotUsernamePromise ?? false) {
      postForgotUsernamePromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData?.errorMessage !== null) {
            setAPIErrorPopupState({
              ...apiErrorPopupState,
              displayPopup: true,
              errorMessage: newState.objData.errorMessage
            });
          }
        }
      }).catch((e) => {
        setAPIErrorPopupState({
          ...apiErrorPopupState,
          displayPopup: true,
          errorMessage: e?.message
        });
      });
    }
  };

  return {
    isLoading: accountCreationForgotUsernameState.isObjLoading,
    displayUsernameSentPopup: accountCreationForgotUsernameState.isObjLoaded === true && accountCreationForgotUsernameState.objData?.errorMessage === null,
    apiErrorPopupState,
    onSubmitFormCallback,
    onCancelClicked,
    onCloseAPIErrorPopupClicked,
    onReturnToLoginClicked
  };
};

export default useAccountCreationForgotUsername;