import React, { Fragment } from 'react';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import EditIcon from '../../../../../common/components/icons/EditIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ user, onEditClicked, onDeleteClicked }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}><span className='hidden-xs'>{user.firstName || ''} {user.preferredName ? `"${user.preferredName}"` : ''} {user.middleName || ''} {user.lastName || ''}</span>&nbsp;
        <button className={global.IconButton}
          type="button"
          onClick={() => onDeleteClicked(user)}>
          <DeleteIcon />
        </button>
        <button className={global.IconButtonMobileMargin}
          onClick={() => onEditClicked(user)}>
          <EditIcon />
        </button>
      </div>
      <div className={[global.SmallTableRowInfo, 'visible-xs'].join('')}>
        <div className='row'>
          <div className={[global.SmallTableRowLabels, 'col-xs-6 hidden-sm'].join(' ')}>Member Name</div>
          <div className={[global.SmallTableRowData, 'col-xs-6 hidden-sm'].join(' ')}>{user.firstName || ''} {user.preferredName ? `"${user.preferredName}"` : ''} {user.middleName || ''} {user.lastName || ''} </div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Username</div>
          <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}> {user.userName || ''}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>User Account Email</div>
          <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{user.email || ''}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Member Email</div>
          <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{user.personEmail || ''}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Member Id</div>
          <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{user.memberId || ''}</div>
          <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Css User Id</div>
          <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{user.cssUserId || ''}</div>
        </div>
      </div>
    </div>
  </Fragment>
);

const UserAccountSearchGridSmall = ({ isLoading, gridData, onEditClicked, onDeleteClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (<div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>&nbsp;</div>
        <div className={global.SmallTableRowInfo}>
          <div className={global.SmallTableRowLabels}>
            Loading...
          </div>
        </div>
      </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((user, i) =>
          <GridRow key={i}
            user={user}
            onEditClicked={onEditClicked}
            onDeleteClicked={onDeleteClicked} />
        ) : (<div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              No Results
            </div>
          </div>
        </div>
        )
    }
  </div>
);

export default UserAccountSearchGridSmall;