import React, { Fragment } from 'react';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const SmallGridRow = ({ enrollmentCourse, onEdit, onDelete }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{enrollmentCourse.courseCode || ''}</span>&nbsp;
      <button
        className={global.IconButton}
        type="button"
        onClick={(e) => onDelete(enrollmentCourse.courseEnrollmentId)}>
        <DeleteIcon />
      </button>
      <button
        className={global.IconButtonMobileMargin}
        type="button"
        onClick={(e) => onEdit(enrollmentCourse)}>
        <EditIcon />
      </button>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 visible-xs'].join(' ')}>Course Code</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 visible-xs'].join(' ')}>{enrollmentCourse.courseCode || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Enrollment Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{enrollmentCourse.courseEnrollmentDate ? formatDate(enrollmentCourse.courseEnrollmentDate) : <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Completion Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{enrollmentCourse.courseCompletionDate ? formatDate(enrollmentCourse.courseCompletionDate) : <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Expiration Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{enrollmentCourse.courseExpirationDate ? formatDate(enrollmentCourse.courseExpirationDate) : <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Course Passed?</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{enrollmentCourse.coursePassed === true ? 'Yes' : 'No'}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6'].join(' ')}>Extneral Css Course Id</div>
        <div className={[global.SmallTableRowData, 'col-xs-6'].join(' ')}>{enrollmentCourse.hasExemption === true ? 'Yes' : ''}</div>
      </div>
    </div>
  </div>
);

const CourseEnrollmentsManagementGridSmall = ({ data, isLoading, onEdit, onDelete }) => (
  <Fragment>
    <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
      {isLoading === true
        ? (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>Loading...</div>
            </div>
          </div>
        ) : Array.isArray(data) && data.length > 0
          ? data.map((enrollmentCourse, i) => <SmallGridRow key={i} enrollmentCourse={enrollmentCourse} onEdit={onEdit} onDelete={onDelete} />)
          : (
            <div className={global.SmallTableRow}>
              <div className={global.SmallTableRowHead}>&nbsp;</div>
              <div className={global.SmallTableRowInfo}>
                <div className={global.SmallTableRowLabels}>No Course Enrollments</div>
              </div>
            </div>
          )
      }
    </div>
  </Fragment>
);

export default CourseEnrollmentsManagementGridSmall;