import { useState } from 'react';
import PersonPaymentsData from './PersonPaymentsData';

const usePersonPaymentsData = () => {
  const [personPaymentsState, setPersonPaymentsState] = useState(PersonPaymentsData.INITIAL_STATE);

  const getPersonPayments = (personId) => {
    if (personId && personPaymentsState.isArrayLoading !== true && personPaymentsState.isArrayLoaded !== true) {
      PersonPaymentsData.getPersonPayments(personId, personPaymentsState, setPersonPaymentsState);
    }
  };

  return { personPaymentsState, getPersonPayments };
};

export default usePersonPaymentsData;