import { useEffect, useState } from 'react';

import useUserGroupData from '../../../../state/userGroup/UseUserGroupData';

const useUserGroupMultiSelect = () => {
  const { userGroupState, getUserGroups } = useUserGroupData();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (userGroupState.isArrayLoading === false && userGroupState.isArrayLoaded === false) {
      getUserGroups();
    } else if (userGroupState.isArrayLoading === false && userGroupState.isArrayLoaded === true) {
      const options = [];

      for (const userGroup of userGroupState.arrayData) {
        if (userGroup.userGroupId > 0) {
          options.push({ id: userGroup.userGroupId, name: userGroup.userGroupName });
        }
      }

      setOptions(options);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroupState]);

  return {
    userGroupState,
    options
  };
};

export default useUserGroupMultiSelect;