import React, { Fragment } from 'react';

import useMyActions from './UseMyActions';

import Spinner from '../../../../../common/components/spinners/Spinner';
import Action from './Action';

import style from './../../LandingPages.module.css';

const MyActions = () => {
  const { actions, isLoading } = useMyActions();

  if (isLoading === true) {
    return <Spinner />;
  } else if (Array.isArray(actions) !== true || actions.length === 0) {
    return <Fragment />;
  } else {
    return (
      <Fragment>
        <div className={style.SectionBorder}>
          <div className={style.SectionTitle}>My Actions</div>
          <table className={style.Table}>
            <tbody>
              {actions.map((action, i) => <Action action={action} key={i} />)}
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  }
};

export default MyActions;