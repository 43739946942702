import { useState } from "react";

import { AC_STEP_EXISTING_ACCOUNT, AC_STEP_USER_INFO } from "../../utils/AccountCreationConstants";

import useAccountCreationData from "../../../../../../state/landingPages/accountCreation/UseAccountCreationData";

const useAccountCreationConfirmAge = () => {
  const { accountCreationState, updateAccountCreationStep, resetAccountCreationState } = useAccountCreationData();
  const [displayInvalidPopupState, setDisplayInvalidPopupState] = useState(false);

  const onBackClicked = (formState) => {
    updateAccountCreationStep(AC_STEP_EXISTING_ACCOUNT, formState, 'confirmAgeData');
  };

  const onReturnToLoginClicked = () => {
    resetAccountCreationState();
  };

  const onSubmitFormCallback = (formState) => {
    if (formState.isOver16 === true) {
      updateAccountCreationStep(AC_STEP_USER_INFO, formState, 'confirmAgeData');
    } else {
      setDisplayInvalidPopupState(true);
    }
  };

  return {
    confirmAgeData: accountCreationState?.confirmAgeData || {},
    displayInvalidPopupState,
    onSubmitFormCallback,
    onBackClicked,
    onReturnToLoginClicked
  };
};

export default useAccountCreationConfirmAge;