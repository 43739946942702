import { isValidName, isValidMemberId, isValidEmail } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};
  let filterCount = 0;

  if (formState.firstName.trim() !== '') {
    if (!isValidName(formState.firstName.trim())) {
      errors.firstName = 'Legal First Name or Preferred Name cannot contain numbers, special characters, or exceed 100 characters';
    } else {
      filterCount += 1;
    }
  }

  if (formState.lastName.trim() !== '') {
    if (!isValidName(formState.lastName.trim())) {
      errors.lastName = 'Legal Last Name or Maiden Name cannot contain numbers, special characters, or exceed 100 characters';
    } else {
      filterCount += 1;
    }
  }

  if (formState.memberId.trim() !== '') {
    if (!isValidMemberId(formState.memberId.trim())) {
      errors.memberId = 'Member Id must be 14 alphanumeric characters';
    } else {
      filterCount += 1;
    }
  }

  if (formState.email.trim() !== '') {
    if (!isValidEmail(formState.email.trim())) {
      errors.email = 'Email must be in a valid format';
    } else if (formState.email.trim().length > 512) {
      errors.email = 'Email cannot exceed 512 characters';
    } else {
      filterCount += 1;
    }
  }

  if (formState.userName.trim() !== '') {
    filterCount += 1;
  }

  if (!(filterCount > 0)) {
    errors.filter = 'Please provide a Member Id or at least one value for the following filters: Legal First Name, Legal Last Name, Email, or Username';
  }

  return errors;
};

const UserAccountSearchFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default UserAccountSearchFormValidation;