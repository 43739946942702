import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useAccountManagementData from "../../../state/accountManagement/UseAccountManagementData";
import useAccountManagementDeleteUserAccountData from "../../../state/accountManagement/accountManagementDeleteUserAccount/UseAccountManagementDeleteUserAccountData";

import useSecurityData from "../../../../common/state/security/UseSecurityData";

const INITIAL_STATE = {
  apiErrorMessage: '',
  displayDeletePopup: false,
  isAdminView: false
};

const useAccountManagementDeleteAccount = () => {
  const navigate = useNavigate();
  const { userInfo, logout } = useSecurityData();
  const { accountManagementState } = useAccountManagementData();
  const { deleteUserAccountState, resetDeleteUserAccountState, deleteUserAccount, deleteUserAccountByPersonId
  } = useAccountManagementDeleteUserAccountData();
  const [state, setState] = useState(INITIAL_STATE);

  const onDeleteAccountClicked = () => {
    setState({ ...state, displayDeletePopup: true });
  };

  const onDeleteAccountModalConfirmClicked = () => {
    if (userInfo.memberId === accountManagementState.objData?.memberId) {
      const deleteMyAccountPromise = deleteUserAccount();

      if (deleteMyAccountPromise ?? false) {
        deleteMyAccountPromise.then((newState) => {
          if (newState ?? false) {
            if (newState.objData?.errorMessage) {
              setState({
                ...state,
                apiErrorMessage: newState.objData.errorMessage,
                displayDeletePopup: false
              });
            } else {
              logout();
            }
          }
        }).catch((e) => {
          setState({
            ...state,
            apiErrorMessage: e?.message || 'An error occurred.',
            displayDeletePopup: false
          });
        });
      }
    } else {
      const personId = accountManagementState.objData?.personId;

      const deleteMyAccountPersonIdPromise = deleteUserAccountByPersonId(personId);

      if (deleteMyAccountPersonIdPromise ?? false) {
        deleteMyAccountPersonIdPromise.then((newState) => {
          if (newState ?? false) {
            if (newState.objData?.errorMessage) {
              setState({
                ...state,
                apiErrorMessage: newState.objData?.errorMessage,
                displayDeletePopup: false
              });
            } else {
              const route = userInfo.appRoutes.find((x) => x.routeName === 'ACCOUNT_MANAGEMENT_SEARCH');

              navigate(route?.route);
            }
          }
        }).catch((e) => {
          setState({
            ...state,
            apiErrorMessage: e?.message || 'An error occurred.',
            displayDeletePopup: false
          });
        });
      }
    }
  };

  const onDeleteAccountModalCancelClicked = () => {
    setState({ ...state, displayDeletePopup: false });
  };

  const onCloseErrorPopupClicked = () => {
    resetDeleteUserAccountState();
    setState({ ...state, apiErrorMessage: '' });
  };

  useEffect(() => {
    if (userInfo.memberId !== accountManagementState.objData?.memberId) {
      setState({ ...state, isAdminView: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, accountManagementState.objData]);

  return {
    isLoading: deleteUserAccountState.isObjLoading,
    accountUserInfo: accountManagementState.objData,
    state,
    onCloseErrorPopupClicked,
    onDeleteAccountClicked,
    onDeleteAccountModalConfirmClicked,
    onDeleteAccountModalCancelClicked
  };
};

export default useAccountManagementDeleteAccount;