import { useState } from 'react';
import PendingPaymentsData from './PendingPaymentsData';

const usePendingPaymentsData = () => {
  const [pendingPaymentsState, setPendingPaymentsState] = useState(PendingPaymentsData.INITIAL_STATE);

  const getPendingPayments = () => {
    if (pendingPaymentsState.isObjLoading !== true && pendingPaymentsState.isObjLoaded !== true) {
      PendingPaymentsData.getPendingPayments(pendingPaymentsState, setPendingPaymentsState);
    }
  };

  return { pendingPaymentsState, getPendingPayments };
};

export default usePendingPaymentsData;