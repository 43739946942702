import useForgotUsernameForm from "./UseForgotUsernameForm";

import PrimaryButton from "../../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../../common/components/buttons/SecondaryButton";
import Input from "../../../../../../../common/components/inputs/Input";

const ForgotUsernameForm = ({ submitButtonText = 'Continue', secondaryButtonText = 'Back', onSubmitFormCallback,
  onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    onValueChanged
  } = useForgotUsernameForm(onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12">
          <Input
            label={'Email*'}
            name={'email'}
            value={formState.email}
            error={errorState.email}
            message={errorState.email}
            onChange={(value) => onValueChanged('Email', 'email', value)} />
        </div>
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>
        </div>
        <div className="col-xs-12">
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default ForgotUsernameForm;