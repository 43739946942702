import { Fragment } from "react";

import useAccountManagementMFA from "./UseAccountManagementMFA";

import MFAForm from "../components/forms/MFAForm";
import ValidationSuccessErrorPopup from "../components/dialogs/ValidationSuccessErrorPopup";

import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";

import Constants from "../../../../common/utils/Constants";

const AccountManagementMFA = () => {
  const {
    isLoading,
    isSaving,
    emailAddress,
    mfaObj,
    saveStateData,
    resetSaveState,
    onSubmitFormCallback
  } = useAccountManagementMFA();

  return (
    <Fragment>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12'>
          <Headings.H3>Multi-Factor Authentication</Headings.H3>
        </div>
      </div>
      <MFAForm
        mfaObj={mfaObj}
        canToggleMFA={mfaObj.isRequired !== true}
        emailAddress={emailAddress}
        onSubmitFormCallback={onSubmitFormCallback} />
      <ValidationSuccessErrorPopup
        displayPopup={saveStateData?.success === false}
        errorMessage={saveStateData?.errorMessage || ''}
        onCancelClicked={resetSaveState} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default AccountManagementMFA;