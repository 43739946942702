export const localValidate = (formState) => {
  let errors = {};

  // if (formState.cssUserId.trim() === '') {
  //   errors.cssUserId = 'Css User Id is required';
  // }

  return errors;
};

const CSSFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default CSSFormValidation;