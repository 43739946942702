import { Fragment } from 'react';

import ManageCartsGridLarge from './ManageCartsGridLarge';
import ManageCartsGridSmall from './ManageCartsGridSmall';

const ManageCartsGrid = ({ data, isLoading, onDelete }) => (
  <Fragment>
    <ManageCartsGridLarge data={data} isLoading={isLoading} onDelete={onDelete} />
    <ManageCartsGridSmall data={data} isLoading={isLoading} onDelete={onDelete} />
  </Fragment>
);

export default ManageCartsGrid;