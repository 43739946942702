import { Fragment } from 'react';

import MyActions from '../../sections/myActions/MyActions';
import MyAccount from '../../sections/myAccount/MyAccount';
import PersonBanner from '../../sections/personBanner/PersonBanner';
import MyMemberCards from '../../sections/myMemberCards/MyMemberCards';
import MyCommunications from '../../sections/myCommunications/MyCommunications';

import style from '../../LandingPages.module.css';

const MinorIndividualLanding = () => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12">
        <PersonBanner />
      </div>
    </div>
    <div className={['row', style.Dashboard].join(' ')}>
      <div className='col-xs-12 col-sm-6'>
        <MyMemberCards />
        <MyActions />
      </div>
      <div className='col-xs-12 col-sm-6'>
        <MyAccount />
        <MyCommunications />
      </div>
    </div>
  </Fragment>
);

export default MinorIndividualLanding;