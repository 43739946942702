import { useContext, useState } from 'react';

import AccountManagementData from './AccountManagementData';

import { AccountManagementStateContext } from './AccountManagementContextProvider';
import { AccountManagementFiltersStateContext } from './AccountManagementFiltersContextProvider';

import AccountManagementConstants from '../../views/accountManagement/utils/AccountManagementConstants';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_ACCOUNT_MANAGEMENT_SEARCH_STATE = {
  ...BASIC_INITIAL_STATE
};

const useAccountManagementData = () => {
  const [accountManagementState, setAccountManagementState] = useContext(AccountManagementStateContext);
  const [accountManagementFiltersState, setAccountManagementFilterState] = useContext(AccountManagementFiltersStateContext);
  const [accountManagementSearchState, setAccountManagementSearchState] = useState(INITIAL_ACCOUNT_MANAGEMENT_SEARCH_STATE);

  const searchUserAccounts = (filterObject, sortBy) => {
    setAccountManagementFilterState({ ...accountManagementFiltersState, filterObject, sortBy });
    return AccountManagementData.searchUserAccountsData(JSON.stringify(filterObject), sortBy, accountManagementSearchState, setAccountManagementSearchState);
  };

  const getAccountManagementPerson = (memberId) => {
    return AccountManagementData.getAccountManagementPersonData(memberId, accountManagementState, setAccountManagementState);
  };

  const setObjData = (userAccount, backToSystem = AccountManagementConstants.BACK_TO_SYSTEM_ADMIN, isAdminView = true) => {
    setAccountManagementState({
      ...accountManagementState,
      objData: userAccount,
      isObjLoaded: true,
      isObjLoading: false,
      backToSystem,
      isAdminView
    });
  };

  const toggleAccountManagementFilters = () => {
    setAccountManagementFilterState({
      ...accountManagementFiltersState,
      showFilters: !accountManagementFiltersState.showFilters
    });
  };

  const resetAccountManagementState = () => {
    setAccountManagementState({
      ...BASIC_INITIAL_STATE,
      backToSystem: AccountManagementConstants.BACK_TO_SYSTEM_DASHBOARD,
      isAdminView: false
    });
  };

  return {
    accountManagementState,
    accountManagementFiltersState,
    accountManagementSearchState,
    setObjData,
    toggleAccountManagementFilters,
    resetAccountManagementState,
    searchUserAccounts,
    getAccountManagementPerson
  };
};

export default useAccountManagementData;