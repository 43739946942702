import { Fragment } from 'react';
import LargeSystemMessage from './LargeSystemMessageGrid';
import SmallSystemMessage from './SmallSystemMessageGrid';
import useExpandedRow from '../../../common/components/grids/UseExpandedRow';

const SystemMessage = ({ message }) => {
  const { onClick, expandedId } = useExpandedRow();

  return (
    <Fragment>
      <LargeSystemMessage message={message} onClick={onClick} expandedId={expandedId} />
      <SmallSystemMessage message={message} onClick={onClick} expandedId={expandedId} />
    </Fragment>
  );
}

export default SystemMessage;