import { Fragment } from "react";

import useManagePayments from "./UseManagePayments";

import Headings from "../../../common/components/headings/Headings";
import Input from "../../../common/components/inputs/Input";
import PrimaryButton from "../../../common/components/buttons/PrimaryButton";
import PopUpModal from "../../../common/components/dialogs/PopUpModal";
import SecondaryButton from "../../../common/components/buttons/SecondaryButton";

import Constants from "../../../common/utils/Constants";

const ManagePayments = () => {
  const {
    isLoading,
    formState,
    errorState,
    receiptErrorState,
    handleSubmit,
    onFormValueChanged,
    onErrorModalClosed
  } = useManagePayments();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Manage Payments</Headings.H3>
        </div>
      </div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Receipt Number*"
              name="receiptNumber"
              value={formState.receiptNumber}
              error={errorState.receiptNumber}
              message={errorState.receiptNumber}
              onChange={(value) => { onFormValueChanged('receiptNumber', value) }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Lookup Receipt</PrimaryButton>
          </div>
        </div>
      </form>
      {receiptErrorState.isError === true
        ? (
          <PopUpModal
            widthPct={90}
            maxWidth={720}
            title={'Receipt Error'}
            displayPopUp={true}
            onModalCanceled={onErrorModalClosed}>
            <div className="row">
              <div className="col-xs-12">
                <p>{receiptErrorState.errorMessage}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 usas-extra-top-margin">
                <SecondaryButton type="button" onClick={onErrorModalClosed}>Close</SecondaryButton>
              </div>
            </div>
          </PopUpModal>
        ) : (
          <PopUpModal
            widthPct={50}
            maxWidth={250}
            title={Constants.LOADING_MSG}
            displayPopUp={isLoading} />
        )
      }
    </Fragment>
  );
};

export default ManagePayments;