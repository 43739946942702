import { useContext } from "react";

import CourseEnrollmentsData from "./CourseEnrollmentsData";

import { CourseEnrollmentsStateContext } from "./CourseEnrollmentsContextProvider";
import { CourseEnrollmentsFilterStateContext } from "./CourseEnrollmentsFiltersContextProvider";

const useCourseEnrollmentsData = () => {
  const [courseEnrollmentsState, setCourseEnrollmentsState] = useContext(CourseEnrollmentsStateContext);
  const [courseEnrollmentsFilterState, setCourseEnrollmentsFilterState] = useContext(CourseEnrollmentsFilterStateContext);

  const searchCourseEnrollments = (filterObject) => {
    setCourseEnrollmentsFilterState({ ...courseEnrollmentsFilterState, filterObject: JSON.parse(JSON.stringify(filterObject)) });
    CourseEnrollmentsData.searchCourseEnrollmentsData(JSON.stringify(filterObject), courseEnrollmentsState, setCourseEnrollmentsState);
  };

  const getCourseEnrollments = (personId) => CourseEnrollmentsData.getCourseEnrollmentsData(personId, courseEnrollmentsState, setCourseEnrollmentsState);

  const postCourseEnrollment = (courseEnrollmentObj, state, setState) => {
    CourseEnrollmentsData.postCourseEnrollmentData(courseEnrollmentObj, state, setState);
  };

  const postCourseEnrollmentNotPassed = (courseEnrollmentObj, state, setState) => {
    CourseEnrollmentsData.postCourseEnrollmentNotPassed(courseEnrollmentObj, state, setState);
  };

  const putCourseEnrollment = (courseEnrollmentId, courseEnrollmentObj, state, setState) => {
    CourseEnrollmentsData.putCourseEnrollmentData(courseEnrollmentId, courseEnrollmentObj, state, setState);
  };

  const deleteCourseEnrollment = (courseEnrollmentId, state, setState) => CourseEnrollmentsData.deleteCourseEnrollmentData(courseEnrollmentId, state, setState);

  const setShowFilters = (showFilters) => {
    setCourseEnrollmentsFilterState({
      ...courseEnrollmentsFilterState,
      showFilters: showFilters ? true : false
    });
  };

  const clearObjData = () => {
    setCourseEnrollmentsState({
      ...courseEnrollmentsState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  return {
    courseEnrollmentsState,
    courseEnrollmentsFilterState,
    searchCourseEnrollments,
    getCourseEnrollments,
    postCourseEnrollment,
    putCourseEnrollment,
    deleteCourseEnrollment,
    setShowFilters,
    clearObjData,
    postCourseEnrollmentNotPassed
  };
};

export default useCourseEnrollmentsData;