import React, { createContext, useState } from 'react';

import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

export const AccountManagementLMSUserStateContext = createContext();

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const AccountManagementLMSUserContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <AccountManagementLMSUserStateContext.Provider value={stateHook}>
      {children}
    </AccountManagementLMSUserStateContext.Provider>
  );
};

export default AccountManagementLMSUserContextProvider;