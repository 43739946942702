import Constants from "../../../common/utils/Constants";
import { isValidBirthDate, isValidDate } from "../../../common/utils/validation";

export const localValidate = (formState) => {
  let errors = {};

  if (formState.checkDate === Constants.BLANK_DATE_STRING || formState.checkDate === '') {
    errors.checkDate = 'Check Date is required';
  } else if (!isValidDate(formState.checkDate)) {
    errors.checkDate = 'Check Date must be a valid date';
  } else if (!isValidBirthDate(formState.checkDate)) {
    errors.checkDate = 'Check Date cannot be in the future';
  }

  if (formState.checkNumber.trim() === '') {
    errors.checkNumber = 'Check Number is required';
  } else if (formState.checkNumber.trim().length > 200) {
    errors.checkNumber = 'Check Number cannot exceed 200 characters'
  }

  if (!formState.checkAmount) {
    errors.checkAmount = 'Check Amount is required';
  } else if (formState.checkAmount < 0) {
    errors.checkAmount = 'Check Amount cannot be negative';
  }

  if (formState.externalId.trim() === '') {
    errors.externalId = 'External Id is required';
  } else if (formState.externalId.trim().length > 200) {
    errors.externalId = 'External Id cannot exceed 200 characters'
  }

  return errors;
};

const MatchCheckPaymentsDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default MatchCheckPaymentsDetailValidation;