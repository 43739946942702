import { Fragment } from "react";

import useAccountManagementSecurityRoles from "./UseAccountManagementSecurityRoles";

import SecurityRolesGrid from "../components/grids/SecurityRolesGrid";

import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import ActionIntraPageButton from "../../../../common/components/buttons/ActionIntraPageButton";

import Constants from "../../../../common/utils/Constants";

const AccountManagementSecurityRoles = () => {
  const {
    isLoading,
    isSaving,
    gridData,
    onAddSecurityRolesClicked,
    onEditSecurityRolesClicked,
    onDeleteSecurityRolesClicked
  } = useAccountManagementSecurityRoles();

  return (
    <Fragment>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12'>
          <Headings.H3>Security Roles</Headings.H3>
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12'>
          <ActionIntraPageButton type='button' onClick={onAddSecurityRolesClicked}>Add Security Roles</ActionIntraPageButton>
        </div>
      </div>
      <SecurityRolesGrid
        data={gridData}
        isLoading={isLoading}
        onEditClicked={onEditSecurityRolesClicked}
        onDeleteClicked={onDeleteSecurityRolesClicked} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default AccountManagementSecurityRoles;