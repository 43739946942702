import React, { Fragment } from 'react';

import UseMyAccount from './UseMyAccount';

import Spinner from '../../../../../common/components/spinners/Spinner';
import LinkButton from '../../../../../common/components/buttons/LinkButton';

import style from './../../LandingPages.module.css';

const MyAccount = () => {
  const { links, isLoading } = UseMyAccount();

  if (isLoading === true) {
    return <Spinner />;
  } else if (Array.isArray(links) !== true || links.length === 0) {
    return <Fragment />;
  } else {
    return (
      <Fragment>
        <div className={style.SectionBorder}>
          <div className={style.SectionTitle}>My Account</div>
          <div className="row">
            {links.map((link, i) => (
              <div className="col-xs-6 col-sm-4 text-center" key={i}>
                <LinkButton action={link.action}>{link.label}</LinkButton>
              </div>))}
          </div>
        </div>
      </Fragment>
    );
  }
};

export default MyAccount;