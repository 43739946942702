import { useEffect, useState } from 'react';

import useOrgUnitData from '../../../../../common/state/orgUnit/UseOrgUnitData';

const USA_SWIMMING_ROOT_ORG_UNIT_NAME = 'USAS';

const useTopTwoOrgUnitTreeView = (limitToUSASwimming) => {
  const { orgUnitState, getOrgUnitsTopTwoLevels } = useOrgUnitData();
  const [optionsState, setOptionsState] = useState([]);

  useEffect(() => {
    if (orgUnitState.isArrayLoaded !== true) {
      getOrgUnitsTopTwoLevels();
    } else if (orgUnitState.isArrayLoaded === true) {
      if (limitToUSASwimming === true) {
        const options = orgUnitState.treeData.filter(x => x.name === USA_SWIMMING_ROOT_ORG_UNIT_NAME);

        setOptionsState(options);
      } else {
        setOptionsState(orgUnitState.treeData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitState.isArrayLoaded]);

  return {
    orgUnitState,
    options: optionsState
  };
};

export default useTopTwoOrgUnitTreeView;