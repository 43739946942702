import { Fragment } from "react";

import useAccountManagementChildren from "./UseAccountManagementChildren";

import AddChildPopupForm from "../components/forms/AddChildPopupForm";
import ChildrenGrid from "../components/grids/ChildrenGrid";

import Headings from "../../../../common/components/headings/Headings";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import ActionIntraPageButton from "../../../../common/components/buttons/ActionIntraPageButton";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";

import Constants from "../../../../common/utils/Constants";
import { formatDate } from "../../../../common/utils/DateFunctions";

const AccountManagementChildren = () => {
  const {
    isLoading,
    isSaving,
    gridData,
    state,
    onSubmitFormCallback,
    onAddChildClicked,
    onCancelAddChildClicked,
    onDeleteChildClicked,
    onDeleteChildPopupConfirmClicked,
    onDeleteChildPopupCancelClicked,
    getPersonasString
  } = useAccountManagementChildren();

  return (
    <Fragment>
      <div className='row usas-extra-top-margin'>
        <div className='col-xs-12'>
          <Headings.H3>Manage Children</Headings.H3>
        </div>
      </div>
      <div className='row usas-extra-top-margin'>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ActionIntraPageButton type="button" onClick={onAddChildClicked}>Add Child</ActionIntraPageButton>
        </div>
      </div>
      {state.displayFormPopup === true &&
        <AddChildPopupForm
          onSubmitFormCallback={onSubmitFormCallback}
          onSecondaryButtonClicked={onCancelAddChildClicked} />
      }
      <ChildrenGrid
        data={gridData}
        isLoading={isLoading}
        onDeleteClicked={onDeleteChildClicked} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete Child From Parent Account'}
        displayPopUp={state.displayDeletePopup}
        onModalCanceled={onDeleteChildPopupCancelClicked}>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <p><strong>Member Name: </strong> &nbsp;{state.childToDelete?.firstName || ''} {state.childToDelete?.middleName || ''} {state.childToDelete?.lastName || ''}</p>
            <p><strong>Member Id:</strong>&nbsp;{state.childToDelete?.memberId || ''}</p>
            <p><strong>Birth Date:</strong>&nbsp;{state.childToDelete?.birthDate ? formatDate(state.childToDelete.birthDate) : ''}</p>
            <p><strong>Persona(s):</strong>&nbsp;{getPersonasString(state.childToDelete?.personas || [])}</p>
            <p>Are you sure you want to delete this child account from the parent account?</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteChildPopupConfirmClicked}>Yes, Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteChildPopupCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default AccountManagementChildren;