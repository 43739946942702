import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";

const postLMSUserData = (personId, userAccountCssObj, state, setState) => {
  if (personId) {
    const newState = {
      ...state,
      isSaving: true
    };
    const api = initApi(API_NAMES.LMS, newState, setState);
    const personIdForUrl = encodeURIComponent(personId);
    const url = `/User/person/${personIdForUrl}/css`;

    return api?.executeObject ? api.executeObject(url, 'POST', userAccountCssObj) : null;
  }
};

const getLMSUserData = (personId, state, setState) => {
  if (personId) {
    const api = initApi(API_NAMES.LMS, state, setState);
    const personIdForUrl = encodeURIComponent(personId);
    const url = `/User/user/${personIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const putLMSUserData = (lmsUserAccountId, userAccountObj, state, setState) => {
  if (lmsUserAccountId > 0) {
    const newState = {
      ...state,
      isSaving: true
    };
    const api = initApi(API_NAMES.LMS, newState, setState);
    const lmsUserAccountIdForUrl = encodeURIComponent(lmsUserAccountId);
    const url = `/User/${lmsUserAccountIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'PUT', userAccountObj) : null;
  }
};

const AccountManagementLMSUserData = {
  postLMSUserData,
  getLMSUserData,
  putLMSUserData
};

export default AccountManagementLMSUserData;