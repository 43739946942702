export const localValidate = (formState) => {
  let errors = {};

  if (formState.newPassword !== formState.confirmPassword) {
    errors.confirmPassword = 'Confirm Password does not match New Password';
  }

  return errors;
};

const PasswordFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default PasswordFormValidation;