import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import useAccountManagementData from '../../../state/accountManagement/UseAccountManagementData';
import useAccountManagementDeleteUserAccountData from '../../../state/accountManagement/accountManagementDeleteUserAccount/UseAccountManagementDeleteUserAccountData';

import useSecurityData from '../../../../common/state/security/UseSecurityData';

const sortByOptions = [
  { id: 1, name: "First Name", value: "firstName desc" },
  { id: 2, name: "Last Name", value: "lastName desc" },
  { id: 3, name: "Username", value: 'userName' },
  { id: 4, name: "Email", value: 'email' },
  { id: 5, name: "Member Id", value: 'memberId' }
];

const INITIAL_STATE = {
  apiErrorMessage: '',
  displayDeletePopUp: false,
  userAccountToDelete: {}
};

const TAXONOMIES = ['AccountManagementDetail'];
const SCOPE = 'AccountManagement';

const useAccountManagementSearch = () => {
  const navigate = useNavigate();
  const { getContextSecurity } = useSecurityData();
  const { accountManagementSearchState, accountManagementFiltersState,
    searchUserAccounts, setObjData, resetAccountManagementState, toggleAccountManagementFilters
  } = useAccountManagementData();
  const { deleteUserAccountState, resetDeleteUserAccountState, deleteUserAccountByPersonId } = useAccountManagementDeleteUserAccountData();
  const [state, setState] = useState(INITIAL_STATE);

  const onSubmitFormCallback = (formState) => {
    let sortBy = undefined;
    for (const sortByOption of sortByOptions) {
      if (formState.sortById === sortByOption.id) {
        sortBy = sortByOption.value;
        break;
      }
    }

    const searchUserAccountsPromise = searchUserAccounts(createSearchFilterObj(formState), sortBy);

    if (searchUserAccountsPromise ?? false) {
      searchUserAccountsPromise.catch((e) => {
        //context error
      });
    }
  };

  const onEditUserAccountClicked = (userAccount) => {
    setObjData(userAccount);
    getContextSecurity(userAccount.personId, TAXONOMIES, SCOPE);

    navigate(NavLinks.ACCOUNT_MANAGEMENT_USERNAME);
  };

  const onDeleteAccountClicked = (userAccount) => {
    setState({
      ...state,
      displayDeletePopUp: true,
      userAccountToDelete: userAccount
    });
  };

  const onDeleteAccountModalConfirmClicked = () => {
    const personId = state.userAccountToDelete?.personId;

    const deleteUserAccountByPersonIdPromise = deleteUserAccountByPersonId(personId);

    if (deleteUserAccountByPersonIdPromise ?? false) {
      deleteUserAccountByPersonIdPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData?.errorMessage) {
            setState({
              ...state,
              apiErrorMessage: newState.objData?.errorMessage,
              displayDeletePopUp: false
            });
          } else {
            const searchUserAccountsPromise = searchUserAccounts(createSearchFilterObj(accountManagementFiltersState.filterObject), accountManagementFiltersState.sortBy);

            if (searchUserAccountsPromise ?? false) {
              searchUserAccountsPromise.catch((e) => {
                //context error
              });
            }

            setState({ ...INITIAL_STATE });
          }
        }
      }).catch((e) => {
        setState({
          ...state,
          apiErrorMessage: e?.message || 'An error occurred.',
          displayDeletePopUp: false
        });
      });
    }
  };

  const onDeleteAccountModalCancelClicked = () => {
    setState({ ...INITIAL_STATE });
  };

  const onCloseErrorPopupClicked = () => {
    resetDeleteUserAccountState();
    setState({ ...INITIAL_STATE });
  };

  useEffect(() => {
    resetAccountManagementState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createSearchFilterObj(formState) {
    return {
      userName: formState.userName ? formState.userName.trim() : undefined,
      memberId: formState.memberId ? formState.memberId.trim() : undefined,
      email: formState.email ? formState.email.trim() : undefined,
      firstName: formState.firstName ? formState.firstName.trim() : undefined,
      lastName: formState.lastName ? formState.lastName.trim() : undefined
    };
  }

  return {
    isSaving: deleteUserAccountState.isObjLoading,
    isLoading: accountManagementSearchState.isArrayLoading,
    searchFilterObject: accountManagementFiltersState.filterObject || {},
    showFilters: accountManagementFiltersState.showFilters,
    sortBy: accountManagementFiltersState.sortBy,
    gridData: accountManagementSearchState.arrayData || [],
    state,
    onSubmitFormCallback,
    toggleAccountManagementFilters,
    onEditUserAccountClicked,
    onDeleteAccountClicked,
    onDeleteAccountModalConfirmClicked,
    onDeleteAccountModalCancelClicked,
    onCloseErrorPopupClicked
  };
};

export default useAccountManagementSearch;