import React, { Fragment } from 'react';

import useMyMemberCards from './UseMyMemberCards';

import Spinner from '../../../../../common/components/spinners/Spinner';
import CardButton from './CardButton';

import style from './../../LandingPages.module.css';
import RenewMemberships from '../../../../../common/components/renewMemberships/RenewMemberships';

const MyMemberCards = () => {
  const { cards, isLoading, landingPageState } = useMyMemberCards();

  if (isLoading === true) {
    return <Spinner />;
  }

  if (Array.isArray(cards) !== true || cards.length === 0) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <div className={style.SectionBorder}>
        <div className={style.SectionTitle}>My Member Cards</div>
        <div className={style.SectionSubTitle}>(Click to view card)</div>
        {cards.map((card, i) => <CardButton card={card} key={i} />)}
        <RenewMemberships omrView={false} myFamilyView={false} landingPageState={landingPageState} />
      </div>
    </Fragment >
  );
};

export default MyMemberCards;