import { useContext, useState } from 'react';

import AccountManagementUsernameData from './AccountManagementUsernameData';

import { AccountManagementUsernameStateContext } from './AccountManagementUsernameContextProvider';

import { BASIC_INITIAL_STATE } from '../../../../common/utils/HttpHelper';

const INITIAL_ACCOUNT_MANAGEMENT_USERNAME_SAVE_STATE = {
  ...BASIC_INITIAL_STATE
};

const useAccountManagementUsernameData = () => {
  const [accountManagementUsernameState, setAccountManagementUsernameState] = useContext(AccountManagementUsernameStateContext);
  const [accountManagementUsernameSaveState, setAccountManagementUsernameSaveState] = useState(INITIAL_ACCOUNT_MANAGEMENT_USERNAME_SAVE_STATE);

  const getAccountManagementUsernameByUserAccountId = (userAccountId) => {
    return AccountManagementUsernameData.getAccountManagementUsernameByUserAccountIdData(userAccountId, accountManagementUsernameState, setAccountManagementUsernameState);
  };

  const getAccountManagementUsername = () => {
    return AccountManagementUsernameData.getAccountManagementUsernameData(accountManagementUsernameState, setAccountManagementUsernameState);
  };

  const putAccountManagementUsernameByUserAccountId = (userAccountId, usernameObj) => {
    return AccountManagementUsernameData.putAccountManagementUsernameByUserAccountIdData(userAccountId, usernameObj, accountManagementUsernameSaveState, setAccountManagementUsernameSaveState);
  };

  const putAccountManagementUsername = (usernameObj) => {
    return AccountManagementUsernameData.putAccountManagementUsernameData(usernameObj, accountManagementUsernameSaveState, setAccountManagementUsernameSaveState);
  };

  const resetUsernameSaveState = () => {
    setAccountManagementUsernameSaveState({
      ...INITIAL_ACCOUNT_MANAGEMENT_USERNAME_SAVE_STATE
    });
  };

  return {
    accountManagementUsernameState,
    accountManagementUsernameSaveState,
    resetUsernameSaveState,
    getAccountManagementUsernameByUserAccountId,
    getAccountManagementUsername,
    putAccountManagementUsernameByUserAccountId,
    putAccountManagementUsername
  };
};

export default useAccountManagementUsernameData;