import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";

const getAccountManagementUserGroupsData = (userAccountId, state, setState) => {
  if (userAccountId > 0) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const userAccountIdForUrl = encodeURIComponent(userAccountId);
    const url = `/UserAccountGroup/UserAccount/${userAccountIdForUrl}`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const putAccountManagementUserGroupData = (userAccountId, userGroupObj, state, setState) => {
  if (userAccountId > 0) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const userAccountIdForUrl = encodeURIComponent(userAccountId);
    const url = `/UserAccountGroup/UserAccount/${userAccountIdForUrl}`;

    return api?.executeArray ? api.executeArray(url, 'PUT', userGroupObj) : null;
  }
};

const deleteAccountManagementUserGroupData = (userAccountGroupId, state, setState) => {
  if (userAccountGroupId > 0) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const userAccountGroupIdForUrl = encodeURIComponent(userAccountGroupId);
    const url = `/UserAccountGroup/${userAccountGroupIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const AccountManagementUserGroupData = {
  getAccountManagementUserGroupsData,
  putAccountManagementUserGroupData,
  deleteAccountManagementUserGroupData
};

export default AccountManagementUserGroupData;