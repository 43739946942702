import React, { useState, createContext } from 'react';

const INITIAL_STATE = {
  filterObject: {},
  sortBy: '',
  showFilters: true
};

export const ManageUserAccountsFilterStateContext = createContext();

const ManageUserAccountsFilterContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <ManageUserAccountsFilterStateContext.Provider value={stateHook}>
      {children}
    </ManageUserAccountsFilterStateContext.Provider>
  );
};

export default ManageUserAccountsFilterContextProvider;