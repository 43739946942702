const NavLinks = {
  LANDING: '/landing',
  LANDING_ATHLETE: '/landing/athlete',
  LANDING_COACH: '/landing/coach',
  LANDING_OFFICIAL: '/landing/official',
  LANDING_MEMBER: '/landing/member',
  MANAGE_USER_ACCOUNTS: '/manageuseraccounts',
  MANAGE_USER_ACCOUNT_DETAIL: '/manageuseraccounts/detail',
  MANAGE_USER_ACCOUNTS_CHILDREN: '/manageuseraccounts/children',
};

export default NavLinks;