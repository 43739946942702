import { Fragment } from 'react';
import {VisuallyHidden} from '@reach/visually-hidden';
import HideIcon from '../icons/HideIcon';
import ShowIcon from '../icons/ShowIcon';
import global from '../../../common/components/GlobalStyle.module.css';

const LargeSystemMessage = ({ message, onClick, expandedId }) => {

  return (
    <Fragment>
      <table className={[global.UsasTable, 'visible-md visible-lg usas-extra-top-margin usas-extra-bottom-margin'].join(' ')}>
        <thead>
          <tr>
            <th>{message?.messageTitle}
              <span className='pull-right'>
                <button className={global.IconButtonMargin}
                  type="button"
                  onClick={(e) => onClick(e, message?.systemMessageId)}>
                  <VisuallyHidden>{expandedId === message?.systemMessageId ? 'Hide' : 'Show'}Detail for{message?.messageTitle}</VisuallyHidden>
                  {expandedId === message?.systemMessageId ? <HideIcon /> : <ShowIcon />}
                </button>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {expandedId === message?.systemMessageId &&
            <tr className='usas-extra-bottom-margin'>
              <td className='col-xs-12' dangerouslySetInnerHTML={{ __html: message?.messageBody }} />
            </tr>
          }
        </tbody>
      </table>
    </Fragment>
  );
}

export default LargeSystemMessage;