import { useEffect, useState } from 'react';

import validate from './MyFamilyValidation';

import useLandingPageData from '../../../../state/landingPages/UseLandingPageData';
import usePersonParentChildData from '../../../../state/landingPages/personParentChild/UsePersonParentChildData';
import usePersonData from '../../../../state/landingPages/person/UsePersonData';
import useChildrenData from '../../../../state/landingPages/children/UseChildrenData';

import usePersonAcknowledgementInfoData from '../../../../../common/state/personAcknowledgementInfo/UsePersonAcknowledgementInfoData';
import usePersonAcknowledgementAddAFamilyMemberData from '../../../../../common/state/personAcknowledgementAddAFamilyMember/UsePersonAcknowledgementAddAFamilyMemberData';

import useForm from '../../../../../common/utils/UseForm';
// import useShoppingCartData from '../../../../../common/state/shoppingCart/UseShoppingCartData';

const useMyFamily = () => {
  const { landingPageState } = useLandingPageData();
  const { personParentChildState, postPersonParentChildByMemberId, confirmSave } = usePersonParentChildData();
  const { personState } = usePersonData();
  const { childrenState, getChildrenAfterFamilyMemberChanges } = useChildrenData();
  const [uiState, setUiState] = useState({ addClicked: false });
  const [acknowledgementsModalState, setAcknowledgementsModalState] = useState({ modalTitle: 'Complete the Following Legal Acknowledgements for the Member You Wish to Add to Your Family', displayPopUp: false });
  const { resetPersonAcknowledgementInfoPageViewState, setPersonAcknowledgementInfoPageViewState, personAcknowledgementInfoPageViewState } = usePersonAcknowledgementInfoData();
  const { personAcknowledgementAddAFamilyMemberState, resetPersonAcknowledgementsAddAFamilyMemberState } = usePersonAcknowledgementAddAFamilyMemberData();
  const { handleSubmit, formState, setFormState, errorState, onFormValueChanged, setErrors, isSubmitting }
    = useForm(getInitialFormState(), submitFormCallback, validate);
  // const { openOmrCartItemsState, getOpenOmrCartItems } = useShoppingCartData();

  const setLoggedInUserMemberId = () => {
    if (personState?.isObjLoaded === true) {
      setFormState({
        ...formState,
        loggedInUserMemberId: personState?.objData.memberId,
        childArray: landingPageState?.currentPerson?.children
      });
    }
  }

  const onAddFamilyMemberClicked = () => {
    setUiState({ ...uiState, addClicked: true });
    setLoggedInUserMemberId();

  };

  const onSaveFamilyMemberClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
  };

  const onAddFamilyMemberModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setUiState({ ...uiState, addClicked: false });
    setFormState(getInitialFormState());
    setErrors({});
  };

  const onAcknowledgmentsModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setAcknowledgementsModalState({ ...acknowledgementsModalState, displayPopUp: false });
    resetPersonAcknowledgementInfoPageViewState();
  };

  // useEffect(() => {
  //   //if (openOmrCartItemsState.isArrayLoaded !== true) {
  //   if (openOmrCartItemsState.isObjLoaded !== true) {
  //     getOpenOmrCartItems();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (errorState.acknowledgementsRequired === true) {
      setAcknowledgementsModalState({ ...acknowledgementsModalState, displayPopUp: true });
      setPersonAcknowledgementInfoPageViewState({ ...personAcknowledgementInfoPageViewState, selectedPerson: {}, showConfirmContactInfoView: false, showChangeContactInfoView: false, showAcknowledgeSwitchesView: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorState])

  useEffect(() => {
    if (personAcknowledgementAddAFamilyMemberState.isSaved === true) {
      resetPersonAcknowledgementsAddAFamilyMemberState();
      onAcknowledgmentsModalCanceled();
      if (formState.newMemberId.trim()) {
        postPersonParentChildByMemberId(formState.newMemberId.trim());
      }
      else if (formState.memberId.trim()) {
        postPersonParentChildByMemberId(formState.memberId.trim());
      }
      onAddFamilyMemberModalCanceled();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personAcknowledgementAddAFamilyMemberState.isSaved])

  useEffect(() => {
    if (personState?.objData?.personId && personParentChildState.isSaved === true) {
      confirmSave();
      getChildrenAfterFamilyMemberChanges(personState?.objData?.personId);
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState?.objData?.personId, personParentChildState.isSaved]);

  function submitFormCallback() {
    if (formState.newMemberId.trim()) {
      postPersonParentChildByMemberId(formState.newMemberId.trim());
      onAddFamilyMemberModalCanceled();
    }
    else if (formState.memberId.trim()) {
      postPersonParentChildByMemberId(formState.memberId.trim());
      onAddFamilyMemberModalCanceled();
    }
  }

  function getInitialFormState() {
    return {
      memberId: '',
      newMemberId: '',
      loggedInUserMemberId: '',
      childArray: []
    };
  };

  return {
    children: landingPageState?.currentPerson?.children || [],
    isLoading: landingPageState?.stateStatus === 'Loading' || personParentChildState.isObjLoading
      || childrenState.isArrayLoading,
    isSaving: personParentChildState.isSaving,
    isSubmitting: isSubmitting,
    uiState,
    formState,
    errorState,
    //hasUnpaidRegistrations: openOmrCartItemsState.objData?.cartItem?.length > 0,
    // hasUnpaidRegistrations: openOmrCartItemsState.objData?.count > 0,
    onFormValueChanged,
    onAddFamilyMemberClicked,
    onSaveFamilyMemberClicked,
    onAddFamilyMemberModalCanceled,
    landingPageState,
    acknowledgementsModalState,
    onAcknowledgmentsModalCanceled
  };
};

export default useMyFamily;