import Constants from "../../../../../common/utils/Constants";
import { getTodaysDate } from "../../../../../common/utils/DateFunctions";
import { isValidDate } from "../../../../../common/utils/validation";

export const CourseEnrollmentDetailFormValidation = (formState) => {
  let errors = {};

  const courseEnrollmentDate = new Date(formState.courseEnrollmentDate);
  const courseCompletionDate = new Date(formState.courseCompletionDate);
  const courseExpirationDate = new Date(formState.courseExpirationDate);
  const todaysDate = getTodaysDate();
  const dateOfToday = new Date(todaysDate);

  if (formState.lmsCourseId < 0) {
    errors.lmsCourseId = 'Course is required';
  }

  if (formState.courseEnrollmentDate === Constants.BLANK_DATE_STRING || formState.courseEnrollmentDate === '') {
    errors.courseEnrollmentDate = 'Enrollment Date is required';
  } else if (!isValidDate(formState.courseEnrollmentDate)) {
    errors.courseEnrollmentDate = 'Enrollment Date must be a valid date';
  } else if (courseCompletionDate < courseEnrollmentDate) {
    errors.courseEnrollmentDate = 'Enrollment Date cannot be after the Completion Date';
  }

  if (formState.courseCompletionDate === Constants.BLANK_DATE_STRING || formState.courseCompletionDate === '') {
    errors.courseCompletionDate = 'Completion Date is required';
  } else if (!isValidDate(formState.courseCompletionDate)) {
    errors.courseCompletionDate = 'Completion Date must be a valid date';
  } else if (courseCompletionDate < courseEnrollmentDate) {
    errors.courseCompletionDate = 'Completion Date cannot be before the Enrollment Date';
  } else if (courseExpirationDate < courseCompletionDate) {
    errors.courseCompletionDate = 'Completion Date cannot be after the Expiration Date';
  } else if (courseCompletionDate > dateOfToday) {
    errors.courseCompletionDate = 'Completion Date cannot be in the future';
  }

  if (formState.noExpirationDate !== true) {
    if (formState.courseExpirationDate === Constants.BLANK_DATE_STRING || formState.courseExpirationDate === '') {
      errors.courseExpirationDate = 'Expiration Date is required';
    } else if (!isValidDate(formState.courseExpirationDate)) {
      errors.courseExpirationDate = 'Expiration Date must be a valid date';
    } else if (courseExpirationDate < courseCompletionDate) {
      errors.courseExpirationDate = 'Expiration Date cannot be before the Completion Date';
    }
  }

  return errors;
};

export default CourseEnrollmentDetailFormValidation;