import React from 'react';

import useAction from './UseAction';

import ExclamationIcon from '../../../../../common/components/icons/ExclamationIcon';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import lpstyle from './../../LandingPages.module.css';
import style from './Actions.module.css';

const Action = ({ action }) => {
  const { onClick } = useAction(action);

  return (
    <tr>
      <td className={style.FirstColumn}><ExclamationIcon /></td>
      <td className={style.Bold} style={{ padding: "0px 10px 0px 0px" }}>{action.messageText}</td>
      <td className={lpstyle.LastColumn}><ActionIntraPageButton onClick={onClick} className={lpstyle.ActionButton}>View</ActionIntraPageButton></td>
    </tr>
  );
};

export default Action;