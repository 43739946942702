import { Fragment } from "react";

import useMemberMemberCard from "./UseMemberMemberCard";

import PersonBanner from "../../personBanner/PersonBanner";

import InGoodStandingIcon from "../../../../../../common/components/icons/InGoodStandingIcon";
import NotInGoodStandingIcon from "../../../../../../common/components/icons/NotInGoodStandingIcon";
import QuestionIcon from "../../../../../../common/components/icons/QuestionIcon";
import PopUpModal from "../../../../../../common/components/dialogs/PopUpModal";

import { formatDate } from "../../../../../../common/utils/DateFunctions";
import Constants from "../../../../../../common/utils/Constants";

import style from '../MyMemberCards.module.css';

const MemberMemberCard = () => {
    const { onClick, memberCardState } = useMemberMemberCard();

    return (
        <Fragment>
            <div className="row">
                <div className="col-xs-12">
                    <button className={style.BackToMemberDashboardButton} onClick={onClick}>Back to Member Dashboard</button>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <PersonBanner />
                </div>
            </div>
            <div className="row usas-extra-bottom-margin">
                <div className="col-xs-12 usas-extra-bottom-margin">
                    <div className={style.SectionTitle}>Member Card</div>
                </div>
            </div>
            {memberCardState.isObjLoaded === true ?
                <div className="row usas-extra-top-margin">
                    <div className="col-xs-12">
                        <div className={style.CardHeader}>
                            <img className={style.Shield} src={`${import.meta.env.VITE_CDN_URL}/swimShield.svg`} alt="Logo" />&nbsp;
                            <div className={style.Name}>{memberCardState.objData?.firstName || <span>&nbsp;</span>} {memberCardState.objData?.preferredName !== '' && memberCardState.objData?.preferredName !== memberCardState.objData?.firstName ? '"' + memberCardState.objData?.preferredName + '"' : ''} {memberCardState.objData?.middleName || ''} {memberCardState.objData?.lastName || <span>&nbsp;</span>}</div>
                            <div className={style.MemberId}>Member ID: {memberCardState.objData?.memberId || <span>&nbsp;</span>}</div>
                            <div className={style.MembershipType}>{memberCardState.objData?.personaName || <span>&nbsp;</span>}&nbsp;
                                <span className={style.IconSizing}>{memberCardState.objData?.isInGoodStanding === true ? <span style={{ color: ' #b1953a' }}><InGoodStandingIcon /></span> :
                                    memberCardState.objData?.isInGoodStanding === false ?
                                        <span style={{ color: '#ba0c2f' }}><NotInGoodStandingIcon /></span> :
                                        <span><QuestionIcon /></span>}
                                </span>
                            </div>
                            <div className={style.ClubAndLsc}>{memberCardState.objData?.qualifiedOrgUnitCode ? 'LSC and Club: ' + memberCardState.objData?.qualifiedOrgUnitCode : ''}</div>
                            {memberCardState.objData?.isInGoodStanding === null ?
                                <div className={style.ValidUntil}><span>Calculating Standing</span></div> :
                                memberCardState.objData?.isInGoodStanding === false ?
                                    <div className={style.ValidUntil}><span style={{ color: '#BA0C2F' }}>Not In Good Standing</span></div> :
                                    <div className={style.ValidUntil}>{memberCardState.objData?.isInGoodStandingExpirationDate && formatDate(memberCardState.objData?.isInGoodStandingExpirationDate) !== '01/01/0001' ?
                                        <span style={{ color: '#b1953a' }}>In Good Standing Until: {formatDate(memberCardState.objData?.isInGoodStandingExpirationDate)}</span> :
                                        <span style={{ color: '#BA0C2F' }}>Not In Good Standing</span>}</div>}
                            <div className={style.ExpirationDate}>{memberCardState.objData?.registrationExpirationDate && formatDate(memberCardState.objData?.registrationExpirationDate) !== Constants.BLANK_DATE_STRING ? new Date(formatDate(memberCardState.objData?.registrationExpirationDate)) >= new Date() ?
                                <span style={{ color: '#b1953a' }}>Expiration Date: {formatDate(memberCardState.objData?.registrationExpirationDate)}</span> :
                                <span style={{ color: '#BA0C2F' }}>Expiration Date: {formatDate(memberCardState.objData?.registrationExpirationDate)}</span> :
                                <span style={{ color: '#BA0C2F' }}>Expiration Date: {memberCardState.objData?.registrationExpirationDate && formatDate(memberCardState.objData?.registrationExpirationDate) !== Constants.BLANK_DATE_STRING ? formatDate(memberCardState.objData?.registrationExpirationDate) : 'Unknown'}</span>
                            }
                            </div>
                        </div>
                        <div className={style.CardBody}>
                            <div className="row">
                                {memberCardState.objData.personRequirements &&
                                    memberCardState.objData.personRequirements.map((requirement, i) => (
                                        <div key={i}>
                                            <div className={style.LeftColumnSizing}>
                                                <div className={style.CertColumn}>{requirement?.courseName ? `${requirement?.courseName}${requirement?.isGrandfathered === true ? ' (Grandfathered)' : ''}:` : <span>&nbsp;</span>}</div>
                                            </div>
                                            <div className={style.RightColumnSizing}>
                                                <div className={style.ExpDateColumn}>
                                                    {requirement?.certificationId !== null && (requirement?.courseCompletionDate === requirement?.courseExpirationDate) ?
                                                        <span style={{ color: '#BA0C2F' }}>Incomplete</span>
                                                        :
                                                        <Fragment>
                                                            {requirement?.coursePassed === true || requirement?.coursePassed === null ? (
                                                                <Fragment>
                                                                    {requirement?.courseExpirationDate && formatDate(requirement?.courseExpirationDate) !== Constants.BLANK_DATE_STRING ?
                                                                        (new Date(formatDate(requirement?.courseExpirationDate)) >= new Date() ?
                                                                            <span style={{ color: '#b1953a' }}>{formatDate(requirement?.courseExpirationDate)}</span> :
                                                                            <span style={{ color: '#BA0C2F' }}>{formatDate(requirement?.courseExpirationDate)}</span>) :
                                                                        <span style={{ color: '#BA0C2F' }}>Incomplete</span>}
                                                                </Fragment>) :
                                                                <span style={{ color: '#BA0C2F' }}>Not Passed</span>}
                                                        </Fragment>}
                                                </div>
                                            </div>
                                        </div>))}
                            </div>
                        </div>
                        <div className={style.DisclaimerBody}>
                            <div className={style.DisclaimerText}>
                                By becoming a member of USA Swimming, I hereby agree to abide by the rules, regulations, and Code of Conduct of USA Swimming
                            </div>
                        </div>
                    </div>
                </div> :
                <Fragment>
                    {memberCardState.message !== 'Loading...' && memberCardState.message !== '' &&
                        <div className="row usas-extra-top-margin">
                            <div className="col-xs-12">
                                Currently Unavailable
                            </div>
                        </div>}
                </Fragment>
            }
            <PopUpModal
                widthPct={50}
                maxWidth={250}
                title={Constants.LOADING_MSG}
                displayPopUp={memberCardState.isObjLoading} />
        </Fragment >
    );
};

export default MemberMemberCard;