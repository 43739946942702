import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";

const getAccountManagementMFAByUserAccountIdData = (userAccountId, state, setState) => {
  if (userAccountId > 0) {
    const api = initApi(API_NAMES.SECURITY, state, setState);
    const userAccountIdForUrl = encodeURIComponent(userAccountId);
    const url = `/UserAccounts/${userAccountIdForUrl}/MFA`;

    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const getAccountManagementMFAData = (state, setState) => {
  const api = initApi(API_NAMES.SECURITY, state, setState);
  const url = `/UserAccounts/MFA`;

  return api?.executeObject ? api.executeObject(url, 'GET') : null;
};

const putAccountManagementMFAByUserAccountIdData = (userAccountId, mfaObj, state, setState) => {
  if (userAccountId > 0) {
    const api = initApi(API_NAMES.SECURITY, state, setState);
    const userAccountIdForUrl = encodeURIComponent(userAccountId);
    const url = `/UserAccounts/${userAccountIdForUrl}/MFA`;

    return api?.executeObject ? api.executeObject(url, 'PUT', mfaObj) : null;
  }
};

const putAccountManagementMFAData = (mfaObj, state, setState) => {
  const api = initApi(API_NAMES.SECURITY, state, setState);
  const url = `/UserAccounts/MFA`;

  return api?.executeObject ? api.executeObject(url, 'PUT', mfaObj) : null;
};

const AccountManagementMFAData = {
  getAccountManagementMFAByUserAccountIdData,
  getAccountManagementMFAData,
  putAccountManagementMFAByUserAccountIdData,
  putAccountManagementMFAData
};

export default AccountManagementMFAData;