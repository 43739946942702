import React, { Fragment } from "react";

import CourseEnrollmentsManagementGridLarge from "./CourseEnrollmentsManagementGridLarge";
import CourseEnrollmentsManagementGridSmall from "./CourseEnrollmentsManagementGridSmall";

const CourseEnrollmentsManagementGrid = ({ data, isLoading, onEditCourseEnrollment, onDeleteCourseEnrollment }) => (
  <Fragment>
    <CourseEnrollmentsManagementGridLarge data={data} isLoading={isLoading} onEdit={onEditCourseEnrollment} onDelete={onDeleteCourseEnrollment} />
    <CourseEnrollmentsManagementGridSmall data={data} isLoading={isLoading} onEdit={onEditCourseEnrollment} onDelete={onDeleteCourseEnrollment} />
  </Fragment>
);

export default CourseEnrollmentsManagementGrid;