import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";

const postAccountManagementSecurityRoleData = (userAccountId, securityRoleObj, state, setState) => {
  if (userAccountId > 0) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const userAccountIdForUrl = encodeURIComponent(userAccountId);
    const url = `/Person/userAccount/${userAccountIdForUrl}/SecurityRole`;

    return api?.executeObject ? api.executeObject(url, 'POST', securityRoleObj) : null;
  }
};

const getAccountManagementSecurityRolesData = (userAccountId, state, setState) => {
  if (userAccountId > 0) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const userAccountIdForUrl = encodeURIComponent(userAccountId);
    const url = `/Person/userAccount/${userAccountIdForUrl}/SecurityRole`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const putAccountManagementSecurityRoleData = (userAccountId, personOrgRoleDurationId, securityRoleObj, state, setState) => {
  if (userAccountId > 0 && personOrgRoleDurationId > 0) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const userAccountIdForUrl = encodeURIComponent(userAccountId);
    const personOrgRoleDurationIdForUrl = encodeURIComponent(personOrgRoleDurationId);
    const url = `/Person/userAccount/${userAccountIdForUrl}/SecurityRole/personOrgRoleDuration/${personOrgRoleDurationIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'PUT', securityRoleObj) : null;
  }
};

const deleteAccountManagementSecurityRoleData = (personOrgRoleDurationId, state, setState) => {
  if (personOrgRoleDurationId > 0) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const personOrgRoleDurationIdForUrl = personOrgRoleDurationId ? encodeURIComponent(personOrgRoleDurationId) : 'NaN';
    const url = `/PersonOrgRoleDuration/${personOrgRoleDurationIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const AccountManagementSecurityRolesData = {
  postAccountManagementSecurityRoleData,
  getAccountManagementSecurityRolesData,
  putAccountManagementSecurityRoleData,
  deleteAccountManagementSecurityRoleData
};

export default AccountManagementSecurityRolesData;