import { useContext } from "react";

import LmsAdminCoursesData from "./LmsAdminCoursesData";

import { LmsAdminCoursesStateContext } from "./LmsAdminCoursesContextProvider";

const useLmsAdminCoursesData = () => {
  const [lmsAdminCoursesState, setLmsAdminCoursesState] = useContext(LmsAdminCoursesStateContext);

  const getLmsAdminCourses = () => LmsAdminCoursesData.getLmsAdminCourses(lmsAdminCoursesState, setLmsAdminCoursesState);

  return {
    lmsAdminCoursesState,
    getLmsAdminCourses
  };
};

export default useLmsAdminCoursesData;