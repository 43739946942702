import PrimaryButton from "../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../common/components/buttons/SecondaryButton";
import DatePicker from "../../../common/components/datepickers/DatePicker";
import Input from "../../../common/components/inputs/Input";
import CurrencyInput from "../../../common/components/inputs/CurrencyInput";
import ReadOnly from "../../../common/components/readOnly/ReadOnly";

import global from '../../../common/components/GlobalStyle.module.css';
import Headings from "../../../common/components/headings/Headings";

const MatchCheckPaymentsDetailForm = ({ formState, errorState, onFormValueChanged,
  handleSubmit, onBackClicked }) => (
  <form onSubmit={handleSubmit} noValidate>
    <div className="row">
      <div className="col-xs-12">
        <Headings.H5>Check Payment</Headings.H5>
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Organization"
          name="organization"
          value={formState.organization}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Payment Person"
          name="paymentPerson"
          value={formState.paymentPerson}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Payment Subsystem"
          name="paymentSubsystem"
          value={formState.paymentSubsystem}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Payment Date"
          name="paymentDate"
          value={formState.paymentDate}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <ReadOnly
          label="Payment Amount"
          name="amount"
          value={formState.amount}
        />
      </div>
    </div>
    <div className="row usas-extra-top-margin usas-extra-bottom-margin"></div>
    <div className="row">
    <div className="col-xs-12">
        <Headings.H5>Check</Headings.H5>
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <DatePicker
          label="Check Date*"
          name="checkDate"
          value={formState.checkDate}
          error={errorState.checkDate}
          message={errorState.checkDate}
          onChange={(value) => { onFormValueChanged('checkDate', value); }}
          countOfYears={10}
          startYearOffset={-10} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="Check Number*"
          name="checkNumber"
          value={formState.checkNumber}
          error={errorState.checkNumber}
          message={errorState.checkNumber}
          onChange={(value) => { onFormValueChanged('checkNumber', value); }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <CurrencyInput
          label="Check Amount*"
          name="checkAmount"
          value={formState.checkAmount}
          error={errorState.checkAmount}
          message={errorState.checkAmount}
          onChange={(value) => { onFormValueChanged('checkAmount', value); }} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Input
          label="External Id*"
          name="externalId"
          value={formState.externalId}
          error={errorState.externalId}
          message={errorState.externalId}
          onChange={(value) => { onFormValueChanged('externalId', value); }} />
      </div>
    </div>
    {errorState.errorOnSubmitAction &&
      <div className="row">
        <div className="col-xs-12">
          <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
        </div>
      </div>
    }
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12 usas-extra-top-margin">
        <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
        <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
      </div>
    </div>
  </form>
);

export default MatchCheckPaymentsDetailForm;