import { useEffect } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../NavLinks";

import useAccountManagementData from "../../../state/accountManagement/UseAccountManagementData";

import useSecurityData from "../../../../common/state/security/UseSecurityData";

const TAXONOMIES = ['AccountManagementDetail'];
const SCOPE = 'AccountManagement';

const useAccountManagementLanding = () => {
  const navigate = useNavigate();
  const { userInfo, getContextSecurity } = useSecurityData();
  const { getAccountManagementPerson } = useAccountManagementData();

  useEffect(() => {
    if (userInfo?.memberId) {
      const getAccountManagementPersonPromise = getAccountManagementPerson(userInfo.memberId);

      if (getAccountManagementPersonPromise) {
        getAccountManagementPersonPromise.then((newState) => {
          if (newState) {
            getContextSecurity(newState.objData?.personId, TAXONOMIES, SCOPE);

            navigate(NavLinks.ACCOUNT_MANAGEMENT_USERNAME);
          }
        }).catch((e) => {
          //context error
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.memberId]);
};

export default useAccountManagementLanding;