import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getNotificationFrequencyData from './GetNotificationFrequencyData';
import getPersonNotificationSettingData from './GetPersonNotificationSettingData';
import putPersonNotificationSettingData from './PutPersonNotificationSettingData';
import getMessagesData from './GetMessagesData';
import putMessageData from './PutMessageData';
import deleteMessageData from './DeleteMessageData';
import putCustomMessageData from './PutCustomMessageData';
import deleteCustomMessageData from './DeleteCustomMessageData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const CommunicationsHubData = {
  INITIAL_STATE,
  getNotificationFrequencyData,
  getPersonNotificationSettingData,
  getMessagesData,
  putPersonNotificationSettingData,
  putMessageData,
  deleteMessageData,
  putCustomMessageData,
  deleteCustomMessageData
};

export default CommunicationsHubData;