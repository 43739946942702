import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';

const GetSystemMessageData = (destination, state, setState) => {
  if (state?.isArrayLoading !== true && !state?.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const destinationForUrl = destination !== undefined ? destination : '';
    const url = `/SystemMessage?destination=${destinationForUrl}`;
    return CommonHttpHelper(url, 'GET')
      .then((arrayData) => {
        newState = {
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: true,
          arrayData: arrayData || [],
          message: '',
        };
        setState(newState);

        return newState;
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  } else {
    return null;
  }
};

export default GetSystemMessageData;