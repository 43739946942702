import { useContext, useState } from 'react';

import { CommunicationsHubStateContext } from './CommunicationsHubContextProvider';
import CommunicationsHubData from './CommunicationsHubData';

const useCommunicationsHubData = () => {
  const [communicationsHubState, setCommunicationsHubState] = useContext(CommunicationsHubStateContext);
  const [notificationFrequncyState, setNotificationFrequencyState] = useState(CommunicationsHubData.INITIAL_STATE);
  const [personNotificationSettingState, setPersonNotificationSettingState] = useState(CommunicationsHubData.INITIAL_STATE);
  const [messageState, setMessageState] = useState(CommunicationsHubData.INITIAL_STATE);

  const getNotificationFrequencyData = () => {
    CommunicationsHubData.getNotificationFrequencyData(notificationFrequncyState, setNotificationFrequencyState);
  };

  const getPersonNotificationSettingData = (personId) => {
    CommunicationsHubData.getPersonNotificationSettingData(personId, personNotificationSettingState, setPersonNotificationSettingState);
  };

  const getMessagesData = (personId) => {
    CommunicationsHubData.getMessagesData(personId, messageState, setMessageState);
  };
  
  const putPersonNotificationSettingData = (personId, notificationSettingsArray) => {
    CommunicationsHubData.putPersonNotificationSettingData(personId, notificationSettingsArray, personNotificationSettingState, setPersonNotificationSettingState);
  };
  
  const putMessageData = (personInboxId, messageObj) => {
    CommunicationsHubData.putMessageData(personInboxId, messageObj, messageState, setMessageState);
  };

  const deleteMessageData = (personInboxId) => {
    CommunicationsHubData.deleteMessageData(personInboxId, messageState, setMessageState);
  };

  const putCustomMessageData = (personCustomInboxId, messageObj) => {
    CommunicationsHubData.putCustomMessageData(personCustomInboxId, messageObj, messageState, setMessageState);
  };

  const deleteCustomMessageData = (personCustomInboxId) => {
    CommunicationsHubData.deleteCustomMessageData(personCustomInboxId, messageState, setMessageState);
  };

  return {
    communicationsHubState,
    setCommunicationsHubState,
    notificationFrequncyState,
    personNotificationSettingState,
    messageState,
    setMessageState,
    getNotificationFrequencyData,
    getPersonNotificationSettingData,
    getMessagesData,
    putPersonNotificationSettingData,
    putMessageData,
    deleteMessageData,
    putCustomMessageData,
    deleteCustomMessageData
  };
};

export default useCommunicationsHubData;