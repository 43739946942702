import React from 'react';

import usePersonBanner from './usePersonBanner';

import ProfilePic from '../../../../components/profilePic/ProfilePic';
import NameBox from '../../../../components/nameBox/NameBox';

import style from './PersonBanner.module.css';

const PersonBanner = () => {
  const { profile, showMemberId } = usePersonBanner();

  return (
    <div className={style.Container}>
      <div className={style.PicContainer}>
        <ProfilePic profile={profile} size="Large" />
      </div>
      <div className={style.NameContainer}>
        <NameBox profile={profile} showMemberId={showMemberId} size="Large" />
      </div>
    </div>
  );
};

export default PersonBanner;