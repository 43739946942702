import React, { Fragment } from 'react';
import {VisuallyHidden} from '@reach/visually-hidden';
import HideIcon from '../../../common/components/icons/HideIcon';
import ShowIcon from '../../../common/components/icons/ShowIcon';
import global from '../../../common/components/GlobalStyle.module.css';

const GridRowIcons = ({ message, onClick, expandedId }) => (
  <Fragment>
    {message?.messageBody &&
      <button
        className={global.IconButtonMobileMargin}
        type="button"
        onClick={(e) => onClick(e, message?.systemMessageId)}>
        <VisuallyHidden>{expandedId === message?.systemMessageId ? 'Hide' : 'Show'}Detail For{message?.messageBody}</VisuallyHidden>
        {expandedId === message?.systemMessageId ? <HideIcon /> : <ShowIcon />}
      </button>
    }
  </Fragment>
);

const GridRowContent = ({ message, expandedId }) => {
  return (
    <Fragment>
      {expandedId === message?.systemMessageId &&
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={['col-xs-12', global.SmallTableRowLabels].join(' ')}>
              <p dangerouslySetInnerHTML={{ __html: message?.messageBody }} />
            </div>
          </div>
        </div>
      }
    </Fragment>
  );
}

const GridRow = ({ message, onClick, expandedId }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          <span>{message?.messageTitle}</span>&nbsp;
          <GridRowIcons
            id={message?.systemMessageId}
            message={message}
            onClick={onClick}
            expandedId={expandedId} />
        </div>
        <GridRowContent message={message} onClick={onClick} expandedId={expandedId} />
      </div>
    </Fragment>
  );
}
const SmallSystemMessageGrid = ({ message, onClick, expandedId }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg usas-extra-bottom-margin'].join(' ')}>
    <GridRow
      message={message}
      onClick={onClick}
      expandedId={expandedId} />
  </div>
);

export default SmallSystemMessageGrid;