import useUserGroupAddForm from "./UseUserGroupAddForm";

import UserGroupMultiSelect from "../multiselect/UserGroupMultiSelect";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";

const UserGroupAddForm = ({ submitButtonText = 'Save', SecondaryButtonText = 'Back', onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState
  } = useUserGroupAddForm(onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <UserGroupMultiSelect
            label={'User Group*'}
            name={'userGroup'}
            value={formState.userGroup}
            error={errorState.userGroup}
            message={errorState.userGroup}
            onChange={(value) => { updateFormState('userGroup', value); }} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{SecondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default UserGroupAddForm;