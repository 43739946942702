import useAccountCreationData from "../../../../state/landingPages/accountCreation/UseAccountCreationData";

const useAccountCreation = () => {
  const { accountCreationState } = useAccountCreationData();

  return {
    currentStep: accountCreationState.currentStep,
  };
};

export default useAccountCreation;