/* eslint-disable react/no-unescaped-entities */
import { Fragment } from 'react';

import useChildLanding from './UseChildLanding';

import MyAccount from '../../sections/myAccount/MyAccount';
import MyActions from '../../sections/myActions/MyActions';
import PersonBanner from '../../sections/personBanner/PersonBanner';
import MyMemberCards from '../../sections/myMemberCards/MyMemberCards';
import MyCommunications from '../../sections/myCommunications/MyCommunications';

import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

import Constants from '../../../../../common/utils/Constants';

import style from './ChildLanding.module.css';
import lpStyle from '../../LandingPages.module.css';

const ChildLandingHeaderLarge = ({ onClick, onOpenModal, personParentChildState, landingPageState, onCreateLogin }) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={style.BackToParentButton} onClick={onClick}>Back to Parent</button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-9">
          <PersonBanner />
        </div>
        <div className="col-xs-3 usas-extra-top-margin usas-extra-bottom-margin">
          <div className={style.FloatRight}>
            {personParentChildState.objData?.hasParentAssociated === true &&
              <PrimaryButton onClick={onOpenModal}>Remove From 'My Family'</PrimaryButton>}
            <div className="usas-extra-top-margin">
              {landingPageState.currentPerson?.hasUserAccount === false && landingPageState.currentPerson?.isMinorEligible === true
                ? <ActionIntraPageButton onClick={(e) => onCreateLogin(e, landingPageState.currentPerson?.memberId)}>Create A Login Account</ActionIntraPageButton>
                : landingPageState.currentPerson?.hasUserAccount === true
                  ? <span>Login Account Has Been Created</span>
                  : ''
              }
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const ChildLandingHeaderSmall = ({ onClick, onOpenModal, personParentChildState, landingPageState, onCreateLogin }) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <PersonBanner />
        </div>
        <div className="col-xs-12">
          <button className={style.BackToParentButton} onClick={onClick}>Back to Parent</button>
        </div>
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          {personParentChildState.objData?.hasParentAssociated === true &&
            <PrimaryButton onClick={onOpenModal}>Remove From 'My Family'</PrimaryButton>
          }
          <div className="usas-extra-top-margin usas-extra-bottom-margin">
            {landingPageState.currentPerson?.hasUserAccount === false && landingPageState.currentPerson?.isMinorEligible === true
              ? <ActionIntraPageButton onClick={(e) => onCreateLogin(e, landingPageState.currentPerson?.memberId)}>Create A Login Account</ActionIntraPageButton>
              : landingPageState.currentPerson?.hasUserAccount === true
                ? <span>Login Account Has Been Created</span>
                : ''
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const ChildLanding = () => {
  const { onClick, onRemoveLinkToParentClick, personParentChildState, isLoading, isSaving,
    modalState, onOpenModal, onModalCanceled, landingPageState, onCreateLogin } = useChildLanding();
  return (
    <Fragment>
      <div className="visible-xs">
        <ChildLandingHeaderSmall onClick={onClick} onOpenModal={onOpenModal} personParentChildState={personParentChildState} landingPageState={landingPageState} onCreateLogin={onCreateLogin} />
      </div>
      <div className="visible-sm visible-md visible-lg">
        <ChildLandingHeaderLarge onClick={onClick} onOpenModal={onOpenModal} personParentChildState={personParentChildState} landingPageState={landingPageState} onCreateLogin={onCreateLogin} />
      </div>
      <div className={['row', lpStyle.Dashboard].join(' ')}>
        <div className='col-xs-12 col-sm-6'>
          <MyMemberCards />
          <MyActions />
        </div>
        <div className='col-xs-12 col-sm-6'>
          <MyAccount />
          <MyCommunications />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p>Are you sure you would like to remove <b>{landingPageState?.currentPerson?.name}</b> from your family?</p>
            <p><b>You will no longer be able to view this member.</b></p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onRemoveLinkToParentClick}>Remove From 'My Family'</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading}
      />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={isSaving}
      />
    </Fragment>
  );
};

export default ChildLanding;