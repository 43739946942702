import React, { createContext, useState } from 'react';

import CourseEnrollmentsData from './CourseEnrollmentsData';

export const CourseEnrollmentsStateContext = createContext();

const CourseEnrollmentsContextProvider = ({ children }) => {
  const stateHook = useState(CourseEnrollmentsData.INITIAL_STATE);

  return (
    <CourseEnrollmentsStateContext.Provider value={stateHook}>
      {children}
    </CourseEnrollmentsStateContext.Provider>
  );
};

export default CourseEnrollmentsContextProvider;