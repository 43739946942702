/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from '../../../common/wrappers/ReactRouterDom';

import useChildrenData from '../../state/landingPages/children/UseChildrenData';
import usePersonData from '../../state/landingPages/person/UsePersonData';
import useLandingPageData from '../../state/landingPages/UseLandingPageData';
// import useAccountCreationPersistentStorage from '../../state/accountCreationPersistentStorage/UseAccountCreationPersistentStorage';

import useSecurityData from '../../../common/state/security/UseSecurityData';
import useMemberPersistentStorage from '../../../common/state/memberPersistentStorage/UseMemberPersistentStorage';

import Constants from '../../../common/utils/Constants';

const useLandingPages = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { securityState } = useSecurityData();
  const { personState, getPersonByMemberId } = usePersonData();
  const { childrenState, getChildren } = useChildrenData();
  const { landingPageState, createLandingPageState, setAnonymous, setChildAsCurrent } = useLandingPageData();
  const [searchParams,] = useSearchParams();
  const { cookies, resetPersistentStorage } = useMemberPersistentStorage();
  // const { acCookies } = useAccountCreationPersistentStorage();

  useEffect(() => {
    if (!personState?.objData?.personId
      && securityState?.isObjLoaded === true) {
      if (!securityState?.userInfo?.memberId || searchParams.get('flowId')) {
        setAnonymous();
      } else if (securityState?.userInfo?.memberId !== 'Unregistered') {
        //remove memberId search param, if there is one
        const memberId = searchParams.get('memberId');
        if (memberId) {
          const url = window.location.origin + window.location.pathname;
          window.location.search = '';
          window.location.href = url;
        }
        getPersonByMemberId(securityState?.userInfo?.memberId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityState]);

  useEffect(() => {
    if (personState?.objData?.personId) {
      getChildren(personState?.objData?.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState?.objData?.personId, securityState]);

  useEffect(() => {
    if (childrenState.isArrayLoaded === true) {
      createLandingPageState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenState.isArrayLoaded, securityState]);

  useEffect(() => {
    const cookiesObj = cookies[Constants.MEMBER_STORAGE_NAME];
    if (childrenState.isArrayLoaded === true && location.state && location.state?.childPersonId
      && landingPageState.stateStatus !== 'Loading') {
      navigate(location.pathname, { replace: true });
      setChildAsCurrent(location.state.childPersonId);
    }
    else if (childrenState.isArrayLoaded === true && landingPageState.currentPerson && cookiesObj &&
      landingPageState.currentPerson?.personId !== cookiesObj?.personId
      && landingPageState.stateStatus !== 'Loading') {
      resetPersistentStorage();
      navigate(location.pathname, { replace: true });
      setChildAsCurrent(cookiesObj?.personId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenState.isArrayLoaded, location.state, landingPageState.stateStatus, securityState]);

  return {
    stateStatus: landingPageState.stateStatus,
    // useNewAccountCreation: acCookies['accountCreation']?.useNewAccountCreation === true
  };
};

export default useLandingPages;