import React from 'react';

import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';
import NameBox from '../../../../components/nameBox/NameBox';
import ProfilePic from '../../../../components/profilePic/ProfilePic';

import useChild from './UseChild';

import lpstyle from './../../LandingPages.module.css';
import style from './Child.module.css';

const Child = ({ child }) => {
  const { showMemberId, onClick } = useChild(child);

  return (
    <tr>
      <td className={style.FirstColumn}><ProfilePic profile={child} size="Small" /></td>
      <td style={{ padding: "0px 10px 0px 0px" }}><NameBox profile={child} showMemberId={showMemberId} size="Small" /></td>
      <td className={lpstyle.LastColumn}><ActionIntraPageButton onClick={onClick} className={lpstyle.ActionButton}>View</ActionIntraPageButton></td>
    </tr>
  );
};

export default Child;