import { Fragment } from "react";

import useSecurityRolesForm from "./UseSecurityRolesForm";

import OrgRoleByRoleGroupDropdown from "../dropdown/OrgRoleByRoleGroupDropdown";
import TopTwoOrgUnitTreeView from "../treeView/TopTwoOrgUnitTreeView";

import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";
import YesNoSwitch from "../../../../../common/components/yesNoSwitch/YesNoSwitch";
import DatePicker from "../../../../../common/components/datepickers/DatePicker";
import ReadOnly from "../../../../../common/components/readOnly/ReadOnly";

const SecurityRolesForm = ({ submitButtonText = 'Save', SecondaryButtonText = 'Back', securityRoleData = undefined,
  onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged,
    onOrgUnitChanged,
    ACCOUNT_MANAGEMENT_SECURITY_ROLES_ROLE_GROUP_ID
  } = useSecurityRolesForm(onSubmitFormCallback, securityRoleData);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          {securityRoleData
            ? (
              <ReadOnly
                label={'Organization'}
                name={'orgUnitName'}
                value={securityRoleData.orgUnitName || ''} />
            ) : (
              <TopTwoOrgUnitTreeView
                label={'Organization*'}
                name={'orgUnit'}
                value={formState.orgUnit}
                error={errorState.orgUnit}
                message={errorState.orgUnit}
                selectableLeavesOnly={false}
                singleSelect={true}
                limitToUSASwimming={true}
                onChange={(value) => { onOrgUnitChanged(value) }} />
            )}
        </div>
        {ACCOUNT_MANAGEMENT_SECURITY_ROLES_ROLE_GROUP_ID &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            {securityRoleData
              ? (
                <ReadOnly
                  label={'Security Role'}
                  name={'orgRoleName'}
                  value={securityRoleData.orgRoleName || ''} />
              ) : (
                <Fragment>
                  {formState.orgUnit[0]?.id &&
                    <OrgRoleByRoleGroupDropdown
                      label={'Security Role*'}
                      name={'orgRoleId'}
                      value={formState.orgRoleId}
                      error={errorState.orgRoleId}
                      message={errorState.orgRoleId}
                      roleGroupId={ACCOUNT_MANAGEMENT_SECURITY_ROLES_ROLE_GROUP_ID}
                      orgUnitId={formState.orgUnit[0].id}
                      onChange={(value, valueLabel) => { onValueTextPairChanged(value, 'orgRoleId', valueLabel, 'orgRoleName') }} />
                  }
                </Fragment>
              )}
          </div>
        }
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          {securityRoleData
            ? (
              <ReadOnly
                label={'Effective Date'}
                name={'effectiveDate'}
                value={formState.effectiveDate || ''} />
            ) : (
              <DatePicker
                label={"Effective Date*"}
                name={"effectiveDate"}
                value={formState.effectiveDate}
                error={errorState.effectiveDate}
                message={errorState.effectiveDate}
                startYearOffset={-150}
                onChange={(value) => updateFormState('effectiveDate', value)} />
            )}
        </div>
        <div className='col-xs-12 col-sm-6 col-md-4'>
          <YesNoSwitch
            label={'No Expiration Date?'}
            name={'hasNoExpirationDate'}
            checked={formState.hasNoExpirationDate === true}
            error={formState.hasNoExpirationDate}
            message={formState.hasNoExpirationDate}
            onChange={(value) => updateFormState('hasNoExpirationDate', value)} />
        </div>
        {formState.hasNoExpirationDate === false &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label={"Expiration Date*"}
              name={"expirationDate"}
              value={formState.expirationDate}
              error={errorState.expirationDate}
              message={errorState.expirationDate}
              startYearOffset={-140}
              onChange={(value) => updateFormState('expirationDate', value)} />
          </div>
        }
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{SecondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default SecurityRolesForm;