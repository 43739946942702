import useExistingAccountForm from "./UseExistingAccountForm";

import PrimaryButton from "../../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../../common/components/buttons/SecondaryButton";
import YesNoSwitch from "../../../../../../../common/components/yesNoSwitch/YesNoSwitch";

const ExistingAccountForm = ({ existingAccountData, submitButtonText = 'Continue', secondaryButtonText = 'Back', onSubmitFormCallback,
  onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState
  } = useExistingAccountForm(onSubmitFormCallback, existingAccountData);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12">
          <YesNoSwitch
            label={<p style={{ textAlign: 'center' }}>Do you have an existing account?*</p>}
            name={'hasExistingAccount'}
            checked={formState.hasExistingAccount}
            error={errorState.hasExistingAccount}
            message={errorState.hasExistingAccount}
            onChange={(value) => updateFormState('hasExistingAccount', value)} />
        </div>
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>
        </div>
        <div className="col-xs-12">
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default ExistingAccountForm;