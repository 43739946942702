import AccountManagementConstants from "../../views/accountManagement/utils/AccountManagementConstants";

import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";

const searchUserAccountsData = (filterObject, sortBy, state, setState) => {
  if (filterObject) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const filterObjectForUrl = encodeURIComponent(filterObject);
    const sortByForUrl = encodeURIComponent(sortBy);
    filterObjectForUrl.replace('&', '%26');

    const url = `/Person/searchAccount?filters=${filterObjectForUrl}&sortBy=${sortByForUrl}`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const getAccountManagementPersonData = (memberId, state, setState) => {
  if (memberId) {
    const newState = {
      ...state,
      backToSystem: AccountManagementConstants.BACK_TO_SYSTEM_DASHBOARD,
      isAdminView: false
    };

    const api = initApi(API_NAMES.PERSON, newState, setState);
    const memberIdForUrl = encodeURIComponent(memberId);
    const url = `/Person/flat/memberId/${memberIdForUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const AccountManagementData = {
  searchUserAccountsData,
  getAccountManagementPersonData
};

export default AccountManagementData;