import { useEffect, useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import NavLinks from "../NavLinks";

import validate from '../components/forms/CourseEnrollmentNoCompletionFormValidation';

import useCourseEnrollmentsData from "../../../state/courseEnrollments/UseCourseEnrollmentsData";

import Constants from "../../../../common/utils/Constants";
import useForm from "../../../../common/utils/UseForm";

const INITIAL_OBJ_STATE = {
  objData: {},
  isObjLoading: false,
  isObjLoaded: true,
  isSaving: false,
  isSaved: false
};

const INITIAL_FORM_STATE = {
  lmsCourseId: Constants.DEFAULT_ID,
  lmsCourseName: '',
  courseEnrollmentDate: Constants.DEFAULT_MAX_DATE,
  externalCssCourseId: '',
  courseCompletionDate: '',
  courseExpirationDate: '',
  coursePassed: false
};

const useCourseEnrollmentsManageNoCompletion = () => {
  const navigate = useNavigate();
  const { courseEnrollmentsState, postCourseEnrollmentNotPassed } = useCourseEnrollmentsData();
  const { formState, errorState, updateFormState, onValueTextPairChanged, handleSubmit
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);
  const [objState, setObjState] = useState(INITIAL_OBJ_STATE);

  const onBackClicked = () => {
    navigate(NavLinks.COURSE_ENROLLMENTS_MANAGE);
  };

  useEffect(() => {
    if (objState.isSaved === true) {
      navigate(NavLinks.COURSE_ENROLLMENTS_MANAGE, { state: { performGet: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objState.isSaved]);

  function submitFormCallback(formState) {
    const courseEnrollmentObj = {
      personId: courseEnrollmentsState.personId,
      lmsCourseId: formState.lmsCourseId,
      courseEnrollmentDate: formState.courseEnrollmentDate,
      courseCompletionDate: formState.courseCompletionDate,
      courseExpirationDate: formState.courseExpirationDate,
      coursePassed: false
    };

    postCourseEnrollmentNotPassed(courseEnrollmentObj, objState, setObjState);
  };

  return {
    isSaving: objState.isSaving,
    formState,
    errorState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onBackClicked
  };
};

export default useCourseEnrollmentsManageNoCompletion;