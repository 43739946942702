import { useState } from "react";
import { useSearchParams } from "../../../../../../../common/wrappers/ReactRouterDom";

import { AC_STEP_ONE_TIME_PASSWORD } from "../../utils/AccountCreationConstants";

import useAccountCreationData from "../../../../../../state/landingPages/accountCreation/UseAccountCreationData";
import useAccountCreationExpiredPasswordData from "../../../../../../state/landingPages/accountCreation/accountCreationExpiredPassword/UseAccountCreationExpiredPasswordData";

const INITIAL_LOGIN_ERROR_POPUP_STATE = {
  displayPopup: false,
  errorMessage: '',
  doFullReset: false
};

const useAccountCreationExpiredPassword = () => {
  const [searchParams,] = useSearchParams();
  const { accountCreationState, resetAccountCreationState, updateAccountCreationStep } = useAccountCreationData();
  const { accountCreationExpiredPasswordState, postClientSideResetPassword, postServerSideResetPassword,
    resetAccountCreationExpiredPasswordState } = useAccountCreationExpiredPasswordData();
  const [loginErrorPopupState, setLoginErrorPopupState] = useState(INITIAL_LOGIN_ERROR_POPUP_STATE);

  const onCancelClicked = () => {
    resetAccountCreationState(); // clears state and sends back to login
  };

  const onCloseLoginErrorPopupClicked = () => {
    if (loginErrorPopupState.doFullReset === true) {
      resetAccountCreationState(); // clears state and sends back to login
    } else {
      resetAccountCreationExpiredPasswordState();
      setLoginErrorPopupState(INITIAL_LOGIN_ERROR_POPUP_STATE);
    }
  };

  const onSubmitFormCallback = (formState) => {
    if (accountCreationState.loginData?.isServerSideLogin === true) {
      doServerSideLogin(accountCreationState.loginData.href, formState.currentPassword, formState.newPassword);
    } else if (accountCreationState.loginData?.isClientSideLogin === true) {
      doClientSideLogin(accountCreationState.loginData.href, formState.currentPassword, formState.newPassword);
    } else {
      setLoginErrorPopupState({
        ...loginErrorPopupState,
        displayPopup: true,
        doFullReset: true
      });
    }
  };

  function doServerSideLogin(href, currentPassword, newPassword) {
    const postServerSideResetPasswordPromise = postServerSideResetPassword(href, currentPassword, newPassword);

    if (postServerSideResetPasswordPromise ?? false) {
      postServerSideResetPasswordPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData?.oneTimePasswordRequired === true) {
            const loginData = {
              href: newState.objData?.href,
              device: newState.objData?.device,
              isServerSideLogin: true,
              isClientSideLogin: false
            };

            updateAccountCreationStep(AC_STEP_ONE_TIME_PASSWORD, loginData, 'loginData');
          } else if (newState.objData?.errorMessage !== null) {
            setLoginErrorPopupState({
              ...loginErrorPopupState,
              displayPopup: true,
              errorMessage: newState.objData.errorMessage
            });
          } else {
            // Successful login
            if (searchParams.get('retUrl')) {
              window.location.href = `${searchParams.get('retUrl')}?token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            } else if (window.location.href.indexOf('?') === -1) {
              window.location.href = `${window.location.href}?token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            } else {
              window.location.href = `${window.location.href}&token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            }
          }
        }
      }).catch((e) => {
        setLoginErrorPopupState({
          ...loginErrorPopupState,
          displayPopup: true,
          errorMessage: e?.message
        });
      });
    }
  };

  function doClientSideLogin(href, currentPassword, newPassword) {
    const postClientSideResetPasswordPromise = postClientSideResetPassword(href, currentPassword, newPassword);

    if (postClientSideResetPasswordPromise ?? false) {
      postClientSideResetPasswordPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData?.status === 'OTP_REQUIRED') {
            const selectedDeviceId = newState.objData?.selectedDevice?.id;
            let selectedDevice = '';

            for (const device of newState.objData?._embedded?.devices) {
              if (device.id === selectedDeviceId) {
                selectedDevice = device.email || device.phone;
                break;
              }
            }

            const loginData = {
              href: newState.objData?._links['otp.check'].href,
              device: selectedDevice,
              isServerSideLogin: false,
              isClientSideLogin: true
            };

            updateAccountCreationStep(AC_STEP_ONE_TIME_PASSWORD, loginData, 'loginData');
          } else if (newState.objData.code === 'INVALID_DATA') {
            const code = newState.objData.details?.first().code;
            const target = newState.objData.details[0]?.target;

            let error = null;

            if (code === 'INVALID_VALUE' && target === 'currentPassword') {
              error = 'The provided current password is invalid.';
            } else if (code === 'INVALID_VALUE' && target === 'newPassword') {
              const unsatisfiedRequirement = newState.objData.details[0]?.innerError?.unsatisfiedRequirements[0];

              if (unsatisfiedRequirement === 'excludesCommonlyUsed') {
                error = 'The provided new password (or a variant of that new password) was found in a list of prohibited passwords.';
              } else if (unsatisfiedRequirement === 'excludesProfileData') {
                error = 'The provided new password was found in another attribute in the user entry.';
              } else if (unsatisfiedRequirement === 'history') {
                error = 'The provided new password cannot be the same as the current password or any password in the user\'s password history.';
              } else if (unsatisfiedRequirement === 'notSimilarToCurrent') {
                error = 'The provided new password cannot be similar to the current password.';
              } else {
                error = 'An unknown error occurred. Please contact USA Swimming for assistance.';
              }
            } else if (code === 'PASSWORD_TOO_YOUNG') {
              error = 'The password cannot be changed because it has not been long enough since the last password change.';
            } else {
              error = 'An unknown error occurred. Please contact USA Swimming for assistance.';
            }

            setLoginErrorPopupState({
              ...loginErrorPopupState,
              displayPopup: true,
              errorMessage: error
            });
          } else {
            const environmentId = searchParams.get('environmentId');
            const flowId = searchParams.get('flowId');

            // Successful login
            if (newState.objData?.resumeUrl) {
              window.location.href = `https://auth.pingone.com/${environmentId}/as/resume?flowId=${flowId}`;
            } else if (searchParams.get('retUrl')) {
              window.location.href = `${searchParams.get('retUrl')}?token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            } else if (window.location.href.indexOf('?') === -1) {
              window.location.href = `${window.location.href}?token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            } else {
              window.location.href = `${window.location.href}&token=${newState.objData.accessToken}&refresh_token=${newState.objData.refreshToken}&expiration_datetime=${newState.objData.expirationDatetime}&username=${encodeURIComponent(newState.objData.username)}`;
            }
          }
        }
      }).catch((e) => {
        setLoginErrorPopupState({
          ...loginErrorPopupState,
          displayPopup: true,
          errorMessage: e?.message
        });
      });
    }
  };

  return {
    isLoading: accountCreationExpiredPasswordState.isObjLoading,
    username: accountCreationState.loginData?.username,
    loginErrorPopupState,
    onSubmitFormCallback,
    onCancelClicked,
    onCloseLoginErrorPopupClicked
  };
};

export default useAccountCreationExpiredPassword;