import useLandingPageData from "../../../../state/landingPages/UseLandingPageData";

import useMemberPersistentStorage from '../../../../../common/state/memberPersistentStorage/UseMemberPersistentStorage';
import useGlobalRoutesData from "../../../../../common/state/security/UseGlobalRoutesData";

const useAction = (action) => {
  const { navigateToDeepRoute, navigateToRedirectUrl } = useLandingPageData();
  const { getGlobalRoute } = useGlobalRoutesData();
  const { setPersistentStorage } = useMemberPersistentStorage();

  const onClick = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    //TODO temp fix
    if (action?.scope === 'Member') {
      setPersistentStorage(
        action.personId,
        import.meta.env.VITE_PROJECT_NAME,
        getGlobalRoute('HUB_LANDING_PAGES')?.route,
        'Back to My Dashboard'
      );
      
      navigateToDeepRoute(action);
    } else if (action?.redirectUrl) {
      navigateToRedirectUrl(action);
    } else {
      navigateToDeepRoute(action);
    }
  };

  return { onClick };
};

export default useAction;
