import React, { createContext, useState } from 'react';

export const AccountManagementFiltersStateContext = createContext();

const INITIAL_STATE = {
  filterObject: {},
  sortBy: '',
  showFilters: true
};

const AccountManagementFiltersContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <AccountManagementFiltersStateContext.Provider value={stateHook}>
      {children}
    </AccountManagementFiltersStateContext.Provider>
  );
};

export default AccountManagementFiltersContextProvider;